import React from "react";
import SidebarLeftImg from "../Assets/img/dashboard/right-sidebar-left-img.jpg";
import SidebarRightImg from "../Assets/img/dashboard/right-sidebar-right-img.jpg";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

import { INITIAL_EVENTS, createEventId } from "../utils/event-utils";

// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Modal,
    Row,
    Container,
    Col,
    CardTitle,
} from "reactstrap";

import { Button as MButton } from "@material-ui/core";
import MainLayout from "../Layouts/MainLayout";
import moment from "moment";
import CalendarMain from "./CalendarMain";
import { RouteComponentProps, withRouter } from "react-router-dom";
import AppointmentManagerModal from "../Forms/AppointmentManagerModal";
import { IPatient } from "../@types/patient";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import firebase from "../Services/firebase";
import TimeLine from "../Components/TimeLine";

import { DateRange, DateRangePicker } from "react-date-range";
import withPatientState, { PatientProps } from "../Enhanchers/withPatientState";
import withPrepState, { PrepProps } from "../Enhanchers/withPrepState";
import withAppointmentState, {
    AppointmentProps,
} from "../Enhanchers/withAppointmentState";

import view_arrow from "../Assets/img/icons/common/view-arrow.png";
import withOutreachState, {
    OutreachProps,
} from "../Enhanchers/withOutreachState";
import PrepManagerModal from "../Components/PrepManagerModal";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import { upperCase } from "lodash";
import DashboardSearchPage from "./DashboardSearchPage";
import DashboardInfoPage from "./DashboardInfoPage";
import ViewPatientTable from "../Components/ViewPatientTable";
import ViewOutreachTable from "../Components/ViewOutreachTable";
import PrepCheck from "../Components/PrepCheck";

interface IState {
    active_page: string;
    prepCheck: boolean;
    selected_district: string;
    app_: any;
    app_show: boolean;
    patient_results: any;
    outreach_results: any;
    appointment_results: any;
    prep_results: any;
}

type Props = RouteComponentProps &
    AuthProps &
    PatientProps &
    PrepProps &
    AppointmentProps &
    OutreachProps;

// const calendarRef

class Dashboard extends React.Component<Props, IState> {
    temp: any = {};

    state = {
        active_page: "info_page",
        prepCheck: false,
        selected_district: "",
        app_: this.temp,
        app_show: false,

        // Search Results
        patient_results: [],
        outreach_results: [],
        appointment_results: [],
        prep_results: [],
    };

    componentDidMount() {}
    showApp = (app_d: any) => {
        console.log(app_d);
        this.setState({
            app_: app_d,
            app_show: true,
        });
    };

    dashboardSwitch = (sw_: string) => {
        if (sw_.length > 0) {
            this.setState({
                active_page: sw_,
            });
        } else {
            this.setState({
                active_page: sw_,
            });
        }
    };

    toggleModal = (b: any) => {
        this.setState({
            app_show: !this.state.app_show,
        });
    };
    togglePrepCheck = () => {
        this.setState({
            prepCheck: !this.state.prepCheck,
        });
    };

    render() {
        return (
            <>
                <MainLayout>
                    <Modal
                        className='modal-dialog-centered'
                        contentClassName='bg-secondary'
                        isOpen={this.state.app_show}
                    >
                        <div className='modal-header py-3'>
                            <h3 className='heading mt-4'>Appointment</h3>

                            <button
                                aria-label='Close'
                                className='close text-right'
                                data-dismiss='modal'
                                type='button'
                                onClick={() => this.toggleModal(false)}
                            >
                                <span aria-hidden={true}></span>
                            </button>
                        </div>
                        <div className='modal-body py-3'>
                            <div className='text-center'>
                                <h2>{this.state.app_.title}</h2>
                            </div>
                            <Row>
                                <Col sm='3'>Date: </Col>
                                <Col sm='9'>{this.state.app_.start}</Col>
                            </Row>
                            <Row>
                                <Col sm='3'>Status: </Col>
                                <Col sm='9'>{this.state.app_.status}</Col>
                            </Row>
                            {/* {JSON.stringify(this.state.app_)} */}
                        </div>
                        <div className='modal-footer'>
                            <Button
                                className='btn-primary'
                                type='button'
                                onClick={() => this.toggleModal(false)}
                            >
                                Done
                            </Button>
                        </div>
                    </Modal>

                    <Container fluid style={{ marginTop: 35 }}>
                        {this.state.prepCheck ? (
                            <PrepCheck
                                isOpen={this.state.prepCheck}
                                togglefunct={this.togglePrepCheck}
                                _isPrepCheck={false}
                            />
                        ) : null}
                        <Row xs='12' className='w-100'>
                            <Col
                                xs='12'
                                className='w-100 d-flex justify-content-between align-items-center'
                            >
                                {this.state.active_page !== "search_page" ? (
                                    <h1>Dashboard</h1>
                                ) : (
                                    <h1>Searching ...</h1>
                                )}
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {this.state.active_page ===
                                    "search_page" ? (
                                        <Button
                                            className='fullcalendar-btn-prev btn-neutral bg-danger'
                                            color='default'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.dashboardSwitch(
                                                    "info_page"
                                                );
                                            }}
                                            size='sm'
                                        >
                                            <i className='text-white fas fa-times' />
                                        </Button>
                                    ) : (
                                        <>
                                            <Button
                                                className='menu-btn'
                                                onClick={() => {
                                                    this.togglePrepCheck();
                                                }}
                                                style={{
                                                    borderColor: "#178CCC",
                                                    backgroundColor: "#178CCC",
                                                }}
                                            >
                                                <span className='btn-inner--icon'>
                                                    <i className='far fa-plus-square fa-2x' />
                                                </span>
                                                <span className='btn-inner--text ml-1'>
                                                    Prep Check
                                                </span>
                                            </Button>
                                            <Button
                                                className='menu-btn'
                                                onClick={() => {
                                                    this.props.history.push({
                                                        pathname:
                                                            "/outreach-create",
                                                        // state: patient,
                                                    });
                                                }}
                                                style={{
                                                    borderColor: "#F4EB76",
                                                    backgroundColor: "#F4EB76",
                                                }}
                                            >
                                                <span className='btn-inner--icon'>
                                                    <i className='far fa-plus-square fa-2x' />
                                                </span>
                                                <span className='btn-inner--text ml-1'>
                                                    Outreach Form
                                                </span>
                                            </Button>
                                            <Button
                                                className='menu-btn'
                                                onClick={() => {
                                                    this.props.history.push({
                                                        pathname:
                                                            "/group-activity-create",
                                                        // state: patient,
                                                    });
                                                }}
                                                style={{
                                                    borderColor: "#47C8D0",
                                                    backgroundColor: "#47C8D0",
                                                }}
                                            >
                                                <span className='btn-inner--icon'>
                                                    <i className='far fa-plus-square fa-2x' />
                                                </span>
                                                <span className='btn-inner--text ml-1'>
                                                    Group Activity Form
                                                </span>
                                            </Button>
                                        </>
                                    )}
                                    <Input
                                        name='search'
                                        className='menu-searchbar-dashboard'
                                        type='text'
                                        placeholder='Search'
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            var search_val = e.target.value;

                                            var patient_table = this.props.patients.patients.filter(
                                                (pat_: any) => {
                                                    return JSON.stringify(pat_)
                                                        .toLowerCase()
                                                        .includes(
                                                            search_val.toLowerCase()
                                                        );
                                                }
                                            );
                                            console.log(patient_table);
                                            patient_table = patient_table.map(
                                                (pat: any) => {
                                                    return {
                                                        first_name:
                                                            pat.first_name_id,
                                                        surname: pat.surname_id,
                                                        form: "patient",
                                                        record_id:
                                                            pat.record_id,
                                                        form_status:
                                                            pat.client_id_complete,
                                                    };
                                                }
                                            );

                                            var outreach_table = this.props.outreach.outreach_all.filter(
                                                (pat_: any) => {
                                                    return JSON.stringify(pat_)
                                                        .toLowerCase()
                                                        .includes(
                                                            search_val.toLowerCase()
                                                        );
                                                }
                                            );
                                            console.log(outreach_table);

                                            outreach_table = outreach_table.map(
                                                (pat: any) => {
                                                    return {
                                                        first_name:
                                                            pat.first_name_out,
                                                        surname:
                                                            pat.surname_out,
                                                        form:
                                                            "patient: outreach/group-activity",
                                                        record_id:
                                                            pat.outreach_lwazi_id,
                                                        form_status:
                                                            pat.form_complete,
                                                    };
                                                }
                                            );
                                            var temp_list = patient_table.concat(
                                                outreach_table
                                            );
                                            console.log(temp_list);
                                            this.setState({
                                                patient_results: temp_list,
                                            });

                                            this.setState({
                                                outreach_results: outreach_table,
                                            });

                                            var outreach_table = this.props.outreach.outreach.filter(
                                                (pat_: any) => {
                                                    return JSON.stringify(pat_)
                                                        .toLowerCase()
                                                        .includes(
                                                            search_val.toLowerCase()
                                                        );
                                                }
                                            );
                                            console.log(patient_table);
                                            this.setState({
                                                patient_results: patient_table,
                                            });

                                            // var outreach_table = this.props.pa
                                        }}
                                        onFocus={(e) => {
                                            e.preventDefault();
                                            this.dashboardSwitch("search_page");
                                        }}
                                        style={{
                                            marginTop: "-0.1rem",
                                            marginRight: 20,
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row xs='12'>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                    </Container>
                    {this.state.active_page === "info_page" ? (
                        <DashboardInfoPage
                            dashboard_switch={this.dashboardSwitch}
                            active_dashboard={this.state.active_page}
                        />
                    ) : (
                        <>
                            <div style={{ marginLeft: 25, marginRight: 10 }}>
                                {this.state.patient_results.length > 0 ? (
                                    <ViewPatientTable
                                        list={this.state.patient_results}
                                    />
                                ) : null}
                            </div>
                            <div style={{ marginLeft: 25, marginRight: 10 }}>
                                {this.state.outreach_results.length > 0 ? (
                                    <ViewOutreachTable
                                        list={this.state.outreach_results}
                                    />
                                ) : null}
                            </div>
                        </>
                    )}
                </MainLayout>
            </>
        );
    }
}

export default withOutreachState(
    withAppointmentState(
        withPrepState(withPatientState(withAuthState(withRouter(Dashboard))))
    )
);
