import firebase from "firebase";
import { useEffect, useState, useCallback } from "react";
import { setUser } from "../Store/modules/auth/actions";

firebase.initializeApp({
    apiKey: "AIzaSyAGSOBf6RU6KYVBfllw_RwtJ2Bkv7ocQAk",
    authDomain: "prepi-a437f.firebaseapp.com",
    databaseURL: "https://prepi-a437f.firebaseio.com",
    projectId: "prepi-a437f",
    storageBucket: "prepi-a437f.appspot.com",
    messagingSenderId: "1060729062763",
    appId: "1:1060729062763:web:6658ff828012c786dbb82e",
    measurementId: "G-2BV5QMTXN9",
});
//   firebase.initializeApp(config);
firebase
    .firestore()
    .enablePersistence()
    .catch(function (err) {
        if (err.code == "failed-precondition") {
            // Multiple tabs open, persistence can only be enabled
            // in one tab at a a time.
            // ...
        } else if (err.code == "unimplemented") {
            // The current browser does not support all of the
            // features required to enable persistence
            // ...
        }
    });

// Subsequent queries will use persistence, if it was enabled successfully

let db = firebase.firestore();
let auth = firebase.auth();
let messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
//exported firebase functionality;

const useFirebase = () => {
    const [authUser, setAuthUser] = useState(firebase.auth().currentUser);

    // useEffect(() => {
    //   const unsubscribe = firebase.auth()
    //     .onAuthStateChanged((user) =>{  console.log(user); setUser({user:user})})
    //   return () => {
    //     unsubscribe()
    //   };
    // }, []);

    const login = useCallback(
        (email, password) =>
            firebase.auth().signInWithEmailAndPassword(email, password),
        []
    );

    const logout = useCallback(() => firebase.auth().signOut(), []);

    return { login, authUser, logout };
};

const useFirestore = () => {
    const getDocument = (documentPath: string, onUpdate: any) => {
        firebase.firestore().doc(documentPath).onSnapshot(onUpdate);
    };

    const saveDocument = (
        documentPath: string,
        document: firebase.firestore.DocumentData
    ) => {
        firebase.firestore().doc(documentPath).set(document);
    };

    const getCollection = (
        collectionPath: string,
        onUpdate: {
            next?:
                | ((
                      snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
                  ) => void)
                | undefined;
            error?: ((error: Error) => void) | undefined;
            complete?: (() => void) | undefined;
        }
    ) => {
        firebase.firestore().collection(collectionPath).onSnapshot(onUpdate);
    };

    const saveCollection = (
        collectionPath: string,
        collection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    ) => {
        firebase.firestore().collection(collectionPath).add(collection);
    };

    return { getDocument, saveDocument, getCollection, saveCollection };
};
export default { firebase, db, auth, messaging, useFirebase, useFirestore };
