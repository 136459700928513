export const key_pop: any = {
    "": "",
    "1": "Client",
    "2": "Community",
    "3": "FSW",
    "4": "MSM",
    "5": "Transgender",
    "7": "MSM-SW",
    "10": "TGM",
    "11": "TGM-SW",
    "12": "TGNC",
    "13": "TGNC-SW",
    "8": "TGW",
    "9": "TGW-SW",
};

export const getGender = (value: any) => {
    switch (String(value)) {
        case "0":
            return "Gender Not Found";
        case "1":
            return "Female";
        case "2":
            return "Male";
        case "3":
            return "Gender Non-Conforming";
        case "4":
            return "TGM";
        case "5":
            return "TGW";
    }
};

export const getGenderFromABV = (value: any) => {
    switch (value) {
        case value:
            break;

        default:
            break;
    }
};

export const getSexAtBirth = (id: any) => {
    switch (id) {
        case 1:
            return "Female";
        case 2:
            return "Male";

        default:
            break;
    }
};

export const getRace = (race_id: number) => {
    switch (String(race_id)) {
        case "1":
            return "Asian";
        case "2":
            return "Black";
        case "3":
            return "Coloured";
        case "4":
            return "Mixed Race";
        case "5":
            return "White";
        case "6":
            return "Other";

        default:
            return "race not found";
    }
};

export const getDistrict = (district: string) => {
    switch (district) {
        case "tg_buffalo_city":
            return "Buffalo City Metropolitan Municipality";
        case "tg_buffalo_city_she":
            return "Buffalo City Metropolitan Municipality SHE";

        case "tg_cpt":
            return "City of Cape Town Metropolitan Municipality";
        case "sw_cpt":
            return "City of Cape Town Metropolitan Municipality GDX";

        case "johannesburg":
            return "City of Johannesburg Metropolitan Municipality";
        case "oasis_johannesburg":
            return "City of Johannesburg Metropolitan Municipality";
        case "phru_johannesburg":
            return "City of Johannesburg Metropolitan Municipality";
        case "tshwane":
            return "City of Tshwane Metropolitan Municipality";

        case "ekurhuleni":
            return "Ekurhuleni Metropolitan Municipality";
        case "ek":
            return "Ekurhuleni Metropolitan Municipality CPC";

        case "tg_mandelabay":
            return "Nelson Mandela Bay Municipality";
        case "she_mandelabay":
            return "Nelson Mandela Bay Municipality SHE";

        case "vhembe":
            return "Vhembe District Municipality";
        case "vhembe_tvep":
            return "Vhembe District Municipality TVEP";

        default:
            return "City of Cape Town Metropolitan Municipality";
    }
};

export const getTransferClinic = (clinic: string) => {
    switch (clinic) {
        case "0":
            return "Pretoria Park Clinic";
        case "1":
            return "Sallisburg Clinic";

        default:
            break;
    }
};

export const getClientID = (client_id: number) => {
    switch (client_id) {
        case 0:
            return "Review";
        case 1:
            return "Incomplete";
        case 2:
            return "Complete";

        default:
            break;
    }
};

export const getActivity = (activity: number) => {
    switch (String(activity)) {
        case "1" || "Outreach":
            return "Outreach";
        case "2" || "Group Activity":
            return "Group Activity";
    }
};

export const getPrepDecideToInitiateResults = (input: string) => {
    switch (String(input)) {
        case "0":
            return "Yes";
        case "1":
            return "No";
        default:
            break;
    }
};

export const getPrepSTIScreening = (input: string) => {
    switch (String(input)) {
        case "0":
            return "Positive";

        case "1":
            return "Negative";

        default:
            break;
    }
};

export const getPrepPregnancyTestResults = (input: string) => {
    switch (String(input)) {
        case "0":
            return "Positive";
        case "1":
            return "Negative";
        default:
            return "Negative";
    }
};

export const getHIVTestDone = (input: string) => {
    switch (String(input)) {
        case "0":
            return "Yes";
        case "1":
            return "No";
        default:
            break;
    }
};

export const getHIVTestResult = (input: string) => {
    switch (String(input)) {
        case "1":
            return "HIV Positive";

        case "2":
            return "HIV Negative";

        case "3":
            return "Indeterminant";
        default:
            break;
    }
};

export const getOutreachFormFieldLabels = (input: string) => {
    switch (input) {
        case "risk_assess_done_out":
            return "Risk Assessment Done";
        case "hiv_status_out":
            return "HIV Status";
        case "test_conducted_out":
            return "HIV Test Done";
        case "on_prep_out":
            return "On Prep?";
        case "on_art_out":
            return "On ART?";
        case "test_results_out":
            return "HIV Test Results";
        case "counselor_responsible":
            return "Counsellor Responsible";
        case "referral_out":
            return "Referral for Additional Services Made";
        case "contact_number_out":
            return "Contact Number";
        case "facilitator_name_out":
            return "Facilitators Name";
        case "theme_out":
            return "Theme";
        case "group_activity_out":
            return "Group Activity";
        case "key_population_id":
            return "Key Population";
        default:
            break;
    }
};

export const getSiteGroupNumber = (site: any) => {
    switch (site) {
        case "tg_buffalo_city":
            return "3903";
        case "tg_cpt":
            return "3900";
        case "johannesburg":
            return "3895";
        case "oasis_johannesburg":
            return "3902";
        case "phru_johannesburg":
            return "3901";
        case "tshwane":
            return "3896";
        case "ekurhuleni":
            return "3898";
        case "tg_mandelabay":
            return "3904";
        case "vhembe":
            return "3897";

        default:
            return "undefined";
    }
};

export const getSiteValue = (site: string) => {
    let temp = site;
    switch (temp) {
        case "Buffalo City Metropolitan Municipality":
            return 1;

        case "Buffalo City Metropolitan Municipality SHE":
            return 13;

        case "City of Cape Town Metropolitan Municipality":
            return 2;

        case "City of Cape Town Metropolitan Municipality GDX":
            return 12;

        case "City of Johannesburg Metropolitan Municipality":
            return 3;

        case "City of Johannesburg Metropolitan Municipality OASIS":
            return 9;

        case "City of Johannesburg Metropolitan Municipality PHRU":
            return 8;

        case "City of Tshwane Metropolitan Municipality":
            return 4;

        case "Ekurhuleni Metropolitan Municipality":
            return 5;

        case "Ekurhuleni Metropolitan Municipality CPC":
            return 10;

        case "Nelson Mandela Bay Municipality":
            return 6;

        case "Nelson Mandela Bay Municipality SHE":
            return 14;

        case "Vhembe District Municipality":
            return 7;

        case "Vhembe District Municipality TVEP":
            return 11;

        default:
            return 2;
    }
};

// export const REDCAP_API_KEY = "B8F2DB167538E747E468E39298334B28"; //OLD API KEY
export const REDCAP_API_KEY = "6A9E708FF5197C7F2EDFB9CD72785D23";
