import React, { useState, Fragment } from 'react';
import { createStyles, withStyles, WithStyles, ClickAwayListener, Toolbar, AppBar, fade, MenuItem, CssBaseline, Badge, Grid, Theme, Drawer, ListItem, ListItemIcon, ListItemText, List, Divider, Icon, Button} from '@material-ui/core';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
// import { loadCSS } from 'fg-loadcss';

// import home from '@material-ui/icons/Dashboard';
// import prep from '@material-ui/icons/AssignmentOutlined';
import outreach from '@material-ui/icons/AssignmentReturnOutlined';
import patient from '@material-ui/icons/AssignmentInd';
// import prepform_img from '../Assets/Navbar/nav-prep-form.png';
// import outreach_img from '../Assets/Navbar/nav-outreach-form.png';
 import Home from '@material-ui/icons/Home';
 import dashboard_img from '../Assets/Navbar/nav-dashboard-active.png';
import patient_img from '../Assets/Navbar/nav-patients.png'
import appointments_img from '../Assets/Navbar/nav-appointments.png'
import report_img from '../Assets/Navbar/nav-reports.png'
import notifications_img from '../Assets/Navbar/nav-notifications.png'
import prepform_img from '../Assets/Navbar/nav-prep-form.png';
// import outreach_img from '../Assets/Navbar/nav-outreach-form.png';
// import Home from '@material-ui/icons/Home';


import { Card, CardBody, CardTitle, Container, Row, Col, Form,FormGroup, Input, Table } from "reactstrap";
import { IPatient } from '../@types/patient';


interface IProps{
    patient:any;
    prep_records: any;
}


type Props = WithStyles<typeof styles> & IProps & RouteComponentProps;



class PatientPrepTab extends React.Component<Props>{






  render() {
    const pos_check: any = {
      0: 'Positive',
      1: 'Negative',
    }
    const yes_and_now: any = {
      0: 'Yes',
      1: 'No',
    }

      return(
          <>
<CardBody>

  <h6 className="heading-small text-muted mb-4">
    Prep Initiation
  </h6>
{this.props.prep_records.length > 0?
  <Table className="align-items-center" style={{tableLayout: "fixed", }}>
          <thead className="thead-light" >
          <tr>
    <th rowSpan={3}>Date of Visit</th>
    <th rowSpan={3}>HIV Test Results</th> 
    <th rowSpan={3}  style={{wordBreak:"break-word",  overflowWrap: 'break-word' }}>Prep Counseling{'\n'} Conducted</th>
    <th rowSpan={3} style={{wordBreak:"break-all", overflowWrap: 'break-word'}} colSpan={2}>Proceed With Screening</th>
    <th style={{textAlign:'center', verticalAlign: 'top'}} colSpan={6}>Prep Screening</th>
    <th rowSpan={3}>Actions</th>
  </tr>
  <tr>
    <th>
        Creatinine{'\n'}
(eGFR)
</th>
    <th>Pregnancy</th>
    <th>Weight(Kgs)</th>
    <th> Hep B Result</th>
<th>PrEP Screening</th>
<th>Decide toInitiate</th>
  </tr>
          </thead>
          <tbody>
      
          {this.props.prep_records[0].prep_by_month.map((record_:any)=>{
            // if(record_.prep_stage === 0){
              return(
                <tr>
                <td>{record_.actual_visit_date}</td>
                <td>{pos_check[record_.test_results.hiv_test_results]}</td>
                <td>{yes_and_now[record_.test_results.prep_counseling_conducted]}</td>
                <td>{yes_and_now[record_.test_results.proceed_screening]}</td>
                <td>-</td>
                <td>{pos_check[record_.test_results.pregnancy_results]}</td>
                <td>{record_.test_results.weight_results}</td>
                <td>{pos_check[record_.test_results.heb_b_results]}</td>
                <td>{pos_check[record_.test_results.sti_results]}</td>
                <td>{pos_check[record_.test_results.decided_to_initiate_results]}</td>
                    <td><strong onClick={(e)=>{
                  e.preventDefault();
                  var id = record_.outreach_lwazi_id;
                  this.props.history.push("/prep-view?ref=prep_id&"+id);}}
                  >Details</strong></td>




                


                {/* <td><a onClick={(e)=>{
                  e.preventDefault();
                  var id = record_.outreach_lwazi_id;
                  this.props.history.push("/outreach-view/"+id);}}
                  >Details</a></td> */}
              </tr>
              )
                // }
          })} 
      
          </tbody>
          </Table>
  : null}

</CardBody>
{/* <CardBody>

  <h6 className="heading-small text-muted mb-4">
    Prep Follow Up
  </h6>

  <Table className="align-items-center" responsive>
          <thead className="thead-light">
          <tr>
    <th rowSpan={2}>Date of Visit</th>
    <th rowSpan={2}>HIV Test Results</th> 
    <th rowSpan={2}>Prep Counseling Conducted</th>
    <th rowSpan={2}>Proceed With Screening</th>
    <th style={{textAlign:'center', verticalAlign: 'top'}} colSpan={6}>Prep Screening</th>
    <th rowSpan={2}>Actions</th>
  </tr>
  <tr>
    <th>
        Creatinine{'\n'}
(eGFR)
</th>
    <th>Pregnancy</th>
    <th>Weight(Kgs)</th>
    <th> Hep B Result</th>
<th>PrEP Screening</th>
<th>Decide toInitiate</th>
  </tr>
          </thead>
          <tbody>
      
          {this.props.prep_records.prep_by_month.map((record_:any)=>{
            if(record_.prep_stage === 0){
              return(
                <tr>
                <td>{record_.actual_visit_date}</td>
                <td>{record_.test_results.hiv_test_results}</td>
                <td>{record_.test_results.prep_counseling_conducted}</td>
                <td>{record_.test_results.proceed_screening}</td>
                <td>0</td>
                <td>{record_.test_results.pregnancy_results}</td>
                <td>{record_.test_results.weight_results}</td>
                <td>{record_.test_results.heb_b_results}</td>
                <td>{record_.test_results.sti_results}</td>
                <td>{record_.test_results.decided_to_initiate_results}</td>




                


                {/* <td><a onClick={(e)=>{
                  e.preventDefault();
                  var id = record_.outreach_lwazi_id;
                  this.props.history.push("/outreach-view/"+id);}}
                  >Details</a></td> 
              </tr>
              )
                }
          })} 
      
          </tbody>
          </Table>


</CardBody> */}

 </>
 )
  }

}
const styles = (theme: Theme) => createStyles({

  drawer: {
    width: 90,
    flexShrink: 0,

  },
  icon_state: {
    active: '#fff',
    non_active: '#143840'
  },

  drawerPaper: {
    width: 90,
    marginTop:50

  },
  drawerContainer: {
    overflow: 'auto',
    // marginTop: 40
  },
  
});
export default withRouter(withStyles(styles)(PatientPrepTab));