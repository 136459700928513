import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Datetime from "react-datetime";
import moment, { Moment } from "moment";
import ReactDatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// reactstrap components

import {
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    UncontrolledTooltip,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormText,
    Button,
    Modal,
    Card,
    CardBody,
    Collapse,
    CardHeader,
    Container,
} from "reactstrap";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { IPatient } from "../@types/patient";
import { clear } from "console";
import { RouteComponentProps, withRouter } from "react-router-dom";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import firebase from "../Services/firebase";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import "../App.css";
import SubdistrictFields from "../Components/SubdistrictFields";
import { find_and_split } from "../utils/dd_util";
import withOutreachDD, { OutreachDDProps } from "../Enhanchers/withOutreachDD";
import withPatientState, { PatientProps } from "../Enhanchers/withPatientState";
import _ from "lodash";
import MainLayout from "../Layouts/MainLayout";
// import { Button } from "@material-ui/core";

interface IProps {
    outreachRecord: any;
}
type Props = RouteComponentProps &
    AuthProps &
    IProps &
    OutreachDDProps &
    PatientProps;

function OutreachViewForm(props: Props) {
    var empty_arr: any[] = [];
    const [selectOption, setSelectOption] = useState("");
    const [inputValuePatientSearch, setInputValue] = useState("");
    const [patientList, setPatientList] = useState([]);
    const [PatientMode, setPatientMode] = useState(false);
    const [patientOutreachList, setPatientOutreachList] = useState(empty_arr);

    const [metaList, setMetaList] = useState(empty_arr);

    var subdistrict_options_obj: any = "";
    const [subdistrict_options, setSubdistrict_options] = useState(
        subdistrict_options_obj
    );
    const [subdistrict_selected, setSubdistrict_selected] = useState(false);
    const [outreach_record, setOutreachRecord] = useState({
        kp_program_site_out: props.auth.user!.data_access_group.trim(),
        subdistrict_out: "",
        peer_responsible_out: "",
        team_leader_out: "",
    });

    const {
        register,
        handleSubmit,
        // handleChange,
        watch,
        errors,
        formState,
        control,
        setError,
        clearError,
        getValues,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            activity_date_outreach:
                props.outreachRecord?.activity_date_outreach,
            activity_type_out: props.outreachRecord?.activity_type_out,
            age_out: "",
            team_leader_out: props.outreachRecord?.meta?.team_leader_out?.toString(),
            contact_number_out: "",
            sub_district_out: props.outreachRecord?.sub_district_out?.toString(),
            peer_responsible_out: props.outreachRecord?.peer_responsible_out?.toString(),
            area_out: "",
            location_out: "",
            data_capturer_out: "",
            date_of_birth_out: "",
            date_of_capture_out: "",
            first_name_out: "",
            full_name_out: "",
            gender_out: "",
            hiv_status_out: "",
            hiv_status_out_check: "",
            key_population_out: "",
            kp_programme_site_out: props.auth.user!.data_access_group.trim(),
            name_surname_dob_out: "",
            on_prep_out: "",
            outreach_form_complete: "",
            preferred_name_out: "",
            redcap_data_access_group: "",
            referral_out: "",
            risk_assess_done_out: "",
            surname_out: "",
            test_conducted_out: "",
            type_out: "",
        },
    });

    const { dirty, isSubmitting, touched, submitCount } = formState;

    const checkValue = (values: any) => {
        console.log(values, patientOutreachList, subdistrict_options);
        console.log(
            Object.keys(values).length > 3,
            patientOutreachList.length > 0
        );
        if (Object.keys(values).length > 3 && patientOutreachList.length > 0) {
            setPatientModal(true);
        }
    };

    const getSubFields = (obj: any) => {
        var t_list = metaList;
        var filT = t_list;

        filT.forEach((item: any, index: number) => {
            if (obj.field_name === item.field_name) {
                t_list[index] = obj;
            }
        });
    };
    //Modal State
    const [defaultModal, setPatientModal] = useState(false);

    const getPatient = (newValue: any, actionMeta: any) => {
        // console.group('Value Changed');
        // console.log(newValue);
        // console.log(actionMeta)
        // console.log(`action: ${actionMeta.action}`);
        if (actionMeta.action === "select-option") {
            setSelectOption(newValue.value);
            setPatientMode(false);
        }
        console.groupEnd();

        // console.log(patient)
        // setSelectOption(patient);
    };

    var temp_pat_list: any[] = [];
    props.patients.patients.map((patient: any) => {
        temp_pat_list.push({
            value: patient,
            label: patient.first_name_id + " " + patient.surname_id,
        });
    });

    var getGender = (value: string) => {
        if (value === "0") {
            return "none";
        }
        if (value === "1") {
            return "Male";
        }
        if (value === "2") {
            return "Female";
        }
        if (value === "3") {
            return "Transgender Man";
        }
        if (value === "4") {
            return "Transgender Woman";
        }
        if (value === "5") {
            return "Gender Non-Conforming";
        }
    };

    const handleInputChange = (newValue: string) => {
        const inputValue = newValue.replace(/\W/g, "");
        setInputValue(inputValue);
        return inputValue;
    };

    const filterPatientList = (inputValue: string) => {
        var list = temp_pat_list.filter((i: any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        list.sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
        list.slice(0, 20);
        return list;
    };

    const loadOptions = (inputValue: any, callback: any) => {
        setTimeout(() => {
            callback(filterPatientList(inputValue));
        }, 900);
    };

    const add_newPatient = (record: any) => {
        var current_status = PatientMode;
        setPatientMode(!current_status);
        if (record) {
            var temp_list_pat = patientOutreachList;
            temp_list_pat.push(record);
            setPatientOutreachList(temp_list_pat);
        }
    };

    const removePatient = (indexV: number) => {
        // var temp_list_pat = patientOutreachList;
        // console.log("before deleting ", temp_list_pat);
        // console.log("index to delete", index);
        // temp_list_pat = temp_list_pat.splice(index,1);
        // console.log("after delete", temp_list_pat)
        // setPatientOutreachList(temp_list_pat)
        var temp_list_pat = patientOutreachList;
        console.log(indexV);
        var temp_arry = _.remove(temp_list_pat, (crrObj, index) => {
            console.log(crrObj, index);
            return index !== indexV;
        });
        console.log(temp_arry);
        setPatientOutreachList(temp_arry);
    };

    const [isOpen, setIsOpen] = useState(-1);

    const toggle = (index: number) => {
        if (index !== isOpen) {
            setIsOpen(index);
        } else {
            setIsOpen(-1);
        }
    };

    const _today = moment().format("YYYY/MM/DD");

    const [date_visit, _setDateVisit] = useState(
        props.outreachRecord.activity_date_outreach
    );
    const printDownload = () => {
        const input = document.getElementById("divToPrint");

        if (input) {
            html2canvas(input).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF({ orientation: "portrait" });
                pdf.addImage(imgData, "PNG", 0, 0);
                pdf.save("download.pdf");
            });
        } else {
            return;
        }
    };
    const setSelectedDate = (date: Moment | string) => {
        console.log(date);
        _setDateVisit(moment(date).format("YYYY/MM/DD"));
        // setAttended(true);
        return;
    };
    var valid = function (current: moment.MomentInput) {
        return moment(current).isBefore(_today);
    };

    console.log({ dirty, isSubmitting, touched, submitCount });
    console.log(errors);

    const onSubmit = (data: any) => {};

    const getStatus = (cell: any) => {
        if (cell === 2) {
            return (
                <>
                    <span className='badge badge-pill badge-complete'>
                        Complete
                    </span>
                </>
            );
        } else if (cell === 1) {
            return (
                <>
                    <span className='badge badge-pill badge-incomplete'>
                        Incomplete
                    </span>
                </>
            );
        } else if (cell === 0) {
            return (
                <>
                    <span className='badge badge-pill badge-review'>
                        Review
                    </span>
                </>
            );
        } else {
            return (
                <>
                    <span className='badge badge-pill badge-amended'>
                        Amended
                    </span>
                </>
            );
        }
    };
    const getPatient_details = (record_id: string) => {
        var found_pat = props.patients.patients.filter((_pat: any) => {
            return _pat.record_id === record_id;
        });
        if (found_pat.length > 0) {
            return found_pat[0];
        } else {
            return "no patient found";
        }
    };

    // console.log(props.outreach_dd.outreach_dd);
    const risk_asses: any = {
        1: "Yes",
        0: "No",
    };

    const status_out: any = {
        1: "Known Positive",
        2: "Known Negative",
        3: "Unknown",
    };
    const test_conducted_out: any = {
        1: "Yes",
        0: "No",
    };
    const test_result: any = {
        1: "HIV Positive",
        2: "HIV Negative",
        3: "Indeterminate",
    };
    const on_art: any = {
        1: "Yes",
        0: "No",
    };
    const on_prep: any = {
        1: "Yes",
        0: "No",
    };
    const referral_out: any = {
        1: "Yes",
        0: "No",
    };

    return (
        <>
            {props.outreach_dd.all_dd !== undefined ? (
                <>
                    <MainLayout>
                        {/* Outreach View Menu */}

                        <Container fluid className='mt-4 mb-5'>
                            <Row>
                                <Col xs='1'>
                                    <Button
                                        style={{
                                            backgroundColor: "transparent",
                                            marginTop: 13,
                                        }}
                                        onClick={() => {
                                            props.history.goBack();
                                        }}
                                    >
                                        <i
                                            className='fa fa-arrow-left  ml-5'
                                            style={{ fontSize: 20 }}
                                        />
                                    </Button>
                                </Col>
                                <Col xs='11'>
                                    <h1 className='pl-0'>
                                        Outreach / Group Activity
                                    </h1>
                                </Col>
                            </Row>

                            <Row xs='12'>
                                <Col>
                                    <hr />
                                </Col>
                            </Row>
                        </Container>

                        {/* Page content */}
                        <Container fluid>
                            {/* <h1>Patients</h1> */}

                            <Card className='bg-secondary shadow'>
                                <CardHeader className='bg-white border-0'>
                                    <Row xs='12'>
                                        <Col xs='5'>
                                            <h3>View Outreach</h3>
                                        </Col>
                                        <Col xs='2' className='pull-right'>
                                            {getStatus(
                                                props.outreachRecord
                                                    .form_complete
                                            )}
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <div
                                            className='float-right icon mt-5 mr-5 bg-edit text-white'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                printDownload();
                                            }}
                                        >
                                            <i className='text-white fa-lg fa fa-file-text' />
                                        </div>
                                        <div id='divToPrint'>
                                            {props.outreach_dd.all_dd.length >
                                            0 ? (
                                                <section
                                                    style={{
                                                        width: "100%",
                                                        marginTop: -32.5,
                                                        padding: "60px 0",
                                                    }}
                                                >
                                                    {/* <Form onSubmit={handleSubmit(onSubmit)}> */}

                                                    <section
                                                        style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                            alignItems:
                                                                "flex-start",
                                                        }}
                                                    >
                                                        {/* Left Section */}

                                                        <section
                                                            style={{
                                                                width: "65%",
                                                                marginTop: 10,
                                                                marginLeft: 20,
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width:
                                                                        "70%",
                                                                    marginLeft:
                                                                        "2.5rem",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        justifyContent:
                                                                            "space-between",
                                                                        alignItems:
                                                                            "flex-start",
                                                                    }}
                                                                >
                                                                    <p>
                                                                        Date of
                                                                        Visit
                                                                    </p>
                                                                    <strong>
                                                                        {moment(
                                                                            date_visit
                                                                        ).format(
                                                                            "DD MMM 'YY"
                                                                        )}
                                                                    </strong>
                                                                </div>
                                                                {props
                                                                    .outreachRecord
                                                                    .activity_type_out ===
                                                                2 ? (
                                                                    <>
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                                alignItems:
                                                                                    "flex-start",
                                                                            }}
                                                                        >
                                                                            <p>
                                                                                Theme
                                                                            </p>
                                                                            <strong>
                                                                                {
                                                                                    props
                                                                                        .outreachRecord
                                                                                        .meta
                                                                                        .theme_out
                                                                                }
                                                                            </strong>
                                                                        </div>
                                                                    </>
                                                                ) : null}
                                                                <>
                                                                    {props
                                                                        .outreachRecord
                                                                        .activity_type_out ===
                                                                    2 ? (
                                                                        <>
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-between",
                                                                                    alignItems:
                                                                                        "flex-start",
                                                                                }}
                                                                            >
                                                                                <p>
                                                                                    Facilitators
                                                                                    Name
                                                                                </p>
                                                                                <strong>
                                                                                    {
                                                                                        props
                                                                                            .outreachRecord
                                                                                            .meta
                                                                                            .facilitator_name_out
                                                                                    }
                                                                                </strong>
                                                                            </div>
                                                                        </>
                                                                    ) : null}
                                                                </>

                                                                <>
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            justifyContent:
                                                                                "space-between",
                                                                            alignItems:
                                                                                "flex-start",
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            District
                                                                        </p>
                                                                        <strong>
                                                                            {
                                                                                props
                                                                                    .outreachRecord
                                                                                    .district
                                                                            }
                                                                        </strong>
                                                                    </div>
                                                                </>
                                                                <>
                                                                    {props.outreach_dd.district_dd.map(
                                                                        (
                                                                            dictionary_object: any
                                                                        ) => {
                                                                            var dict_obj = dictionary_object;
                                                                            var subdistrict_pat = /sub_/g;
                                                                            var team_leader_pat = /team_leader/g;

                                                                            //peer
                                                                            var peer_out_pat = /peer/g;

                                                                            //  "cpt_sub_district_out"
                                                                            if (
                                                                                subdistrict_pat.test(
                                                                                    dict_obj.field_name
                                                                                )
                                                                            ) {
                                                                                var obj_temp_select_value = find_and_split(
                                                                                    dict_obj.select_choices_or_calculations
                                                                                );
                                                                                console.log(
                                                                                    "selected value select here ==",
                                                                                    obj_temp_select_value
                                                                                );
                                                                                var answer = obj_temp_select_value.filter(
                                                                                    (
                                                                                        l_: any
                                                                                    ) => {
                                                                                        return (
                                                                                            l_.index ==
                                                                                            props
                                                                                                .outreachRecord
                                                                                                ?.sub_district_out
                                                                                        );
                                                                                    }
                                                                                );

                                                                                if (
                                                                                    answer.length >
                                                                                    0
                                                                                ) {
                                                                                    return (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    display:
                                                                                                        "flex",
                                                                                                    justifyContent:
                                                                                                        "space-between",
                                                                                                    alignItems:
                                                                                                        "flex-start",
                                                                                                }}
                                                                                            >
                                                                                                <p>
                                                                                                    Sub
                                                                                                    District
                                                                                                </p>
                                                                                                <strong>
                                                                                                    {
                                                                                                        answer[0]
                                                                                                            .value
                                                                                                    }
                                                                                                </strong>
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                } else {
                                                                                    return (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    display:
                                                                                                        "flex",
                                                                                                    justifyContent:
                                                                                                        "space-between",
                                                                                                    alignItems:
                                                                                                        "flex-start",
                                                                                                }}
                                                                                            >
                                                                                                <p>
                                                                                                    Sub
                                                                                                    District
                                                                                                </p>
                                                                                                <strong>
                                                                                                    Sub
                                                                                                    District
                                                                                                    Not
                                                                                                    Found
                                                                                                </strong>
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            }

                                                                            //Peer Responsible
                                                                            if (
                                                                                peer_out_pat.test(
                                                                                    dict_obj.field_name
                                                                                )
                                                                            ) {
                                                                                var obj_temp_select_value = find_and_split(
                                                                                    dict_obj.select_choices_or_calculations
                                                                                );
                                                                                console.log(
                                                                                    "selected value select here ==",
                                                                                    obj_temp_select_value
                                                                                );
                                                                                var answer = props
                                                                                    .outreachRecord
                                                                                    .meta
                                                                                    .peer_responsible_out
                                                                                    ? obj_temp_select_value.filter(
                                                                                          (
                                                                                              l_: any
                                                                                          ) => {
                                                                                              return (
                                                                                                  l_.index ==
                                                                                                  props
                                                                                                      .outreachRecord
                                                                                                      .meta
                                                                                                      .peer_responsible_out
                                                                                              );
                                                                                          }
                                                                                      )
                                                                                    : [];

                                                                                if (
                                                                                    answer.length >
                                                                                    0
                                                                                ) {
                                                                                    return (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    display:
                                                                                                        "flex",
                                                                                                    justifyContent:
                                                                                                        "space-between",
                                                                                                    alignItems:
                                                                                                        "flex-start",
                                                                                                }}
                                                                                            >
                                                                                                <p>
                                                                                                    Peer
                                                                                                    Responsible
                                                                                                </p>
                                                                                                <strong>
                                                                                                    {
                                                                                                        answer[0]
                                                                                                            .value
                                                                                                    }
                                                                                                </strong>
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                } else {
                                                                                    return (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    display:
                                                                                                        "flex",
                                                                                                    justifyContent:
                                                                                                        "space-between",
                                                                                                    alignItems:
                                                                                                        "flex-start",
                                                                                                }}
                                                                                            >
                                                                                                <p>
                                                                                                    Peer
                                                                                                    Responsible
                                                                                                </p>
                                                                                                <strong>
                                                                                                    Peer
                                                                                                    Not
                                                                                                    Found
                                                                                                </strong>
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            }

                                                                            //Team Leader Responsible
                                                                            if (
                                                                                team_leader_pat.test(
                                                                                    dict_obj.field_name
                                                                                )
                                                                            ) {
                                                                                var obj_temp_select_value = find_and_split(
                                                                                    dict_obj.select_choices_or_calculations
                                                                                );
                                                                                console.log(
                                                                                    "selected value select here ==",
                                                                                    obj_temp_select_value
                                                                                );
                                                                                var answer = obj_temp_select_value.filter(
                                                                                    (
                                                                                        l_: any
                                                                                    ) => {
                                                                                        return (
                                                                                            l_.index ==
                                                                                            props
                                                                                                .outreachRecord
                                                                                                ?.meta
                                                                                                .team_leader_out
                                                                                        );
                                                                                    }
                                                                                );

                                                                                if (
                                                                                    answer.length >
                                                                                    0
                                                                                ) {
                                                                                    return (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    display:
                                                                                                        "flex",
                                                                                                    justifyContent:
                                                                                                        "space-between",
                                                                                                    alignItems:
                                                                                                        "flex-start",
                                                                                                }}
                                                                                            >
                                                                                                <p>
                                                                                                    Team
                                                                                                    Leader
                                                                                                    Responsible
                                                                                                </p>
                                                                                                <strong>
                                                                                                    {
                                                                                                        answer[0]
                                                                                                            .value
                                                                                                    }
                                                                                                </strong>
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                } else {
                                                                                    return (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    display:
                                                                                                        "flex",
                                                                                                    justifyContent:
                                                                                                        "space-between",
                                                                                                    alignItems:
                                                                                                        "flex-start",
                                                                                                }}
                                                                                            >
                                                                                                <p>
                                                                                                    Team
                                                                                                    Leader
                                                                                                    Responsible
                                                                                                </p>
                                                                                                <strong>
                                                                                                    Team
                                                                                                    Leader
                                                                                                    Not
                                                                                                    Found
                                                                                                </strong>
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            }
                                                                        }
                                                                    )}

                                                                    {/* subdistrict  Logic*/}
                                                                    {/* {subdistrict_selected ? (
                    <SubdistrictFields
                      selected_subdistrict_dd={outreach_record}
                      selected_option={subdistrict_options}
                    />
                  ) : null} */}
                                                                </>
                                                            </div>
                                                        </section>

                                                        {/* Right Section */}
                                                        <section
                                                            style={{
                                                                width: "60%",
                                                                marginRight: 240,
                                                            }}
                                                        >
                                                            <Row>
                                                                <h2
                                                                    style={{
                                                                        fontFamily:
                                                                            "brandon-grotesque",
                                                                        fontWeight: 600,
                                                                        fontSize:
                                                                            "1.2rem",
                                                                        color:
                                                                            "#B3B8BF",
                                                                        textTransform:
                                                                            "uppercase",
                                                                        marginLeft: 20,
                                                                    }}
                                                                >
                                                                    Patients
                                                                </h2>
                                                            </Row>
                                                            {props.outreachRecord.patients.map(
                                                                (
                                                                    patient: any,
                                                                    indexV: number
                                                                ) => {
                                                                    var patient_info = getPatient_details(
                                                                        patient.record_id
                                                                    );
                                                                    console.log(
                                                                        patient_info
                                                                    );
                                                                    return (
                                                                        <section className='blue-shadow'>
                                                                            {/* <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <h5
                          style={{
                            fontFamily: 'Gothic A1',
                            fontWeight: 600,
                            fontSize: '1rem',
                            color: '#17A3AB',
                            textTransform: 'capitalize',
                          }}
                          className='mb-0'
                        >
                          {patient.full_name_out}
                        </h5>
                        <i
                          className={
                            isOpen === indexV
                              ? 'text-right fa fa-chevron-up'
                              : 'text-right fa fa-chevron-down'
                          }
                        ></i>
                      </div> */}
                                                                            {props
                                                                                .outreachRecord
                                                                                .activity_type_out !==
                                                                            2 ? (
                                                                                <div
                                                                                    style={{
                                                                                        border:
                                                                                            "1px solid #B3B8BF",
                                                                                        padding: 20,
                                                                                    }}
                                                                                >
                                                                                    <h5
                                                                                        style={{
                                                                                            fontFamily:
                                                                                                "Gothic A1",
                                                                                            fontWeight: 600,
                                                                                            fontSize:
                                                                                                "1rem",
                                                                                            color:
                                                                                                "#17A3AB",
                                                                                            textTransform:
                                                                                                "capitalize",
                                                                                            marginTop: 10,
                                                                                            marginBottom: 30,
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            patient.full_name_out
                                                                                        }
                                                                                    </h5>
                                                                                    <section
                                                                                        style={{
                                                                                            display:
                                                                                                "flex",
                                                                                            justifyContent:
                                                                                                "space-evenly",
                                                                                            alignItems:
                                                                                                "flex-start",
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                fontFamily:
                                                                                                    "Gothic A1",
                                                                                                fontSize:
                                                                                                    "0.5rem",
                                                                                                color:
                                                                                                    "#031F33",
                                                                                                textTransform:
                                                                                                    "capitalize",
                                                                                            }}
                                                                                            className='text-normal'
                                                                                        >
                                                                                            <p>
                                                                                                Legal
                                                                                                First
                                                                                                Name
                                                                                            </p>
                                                                                            <p>
                                                                                                Legal
                                                                                                Surname
                                                                                            </p>
                                                                                            <p>
                                                                                                Preferred
                                                                                                Name
                                                                                            </p>
                                                                                            <p>
                                                                                                Date
                                                                                                of
                                                                                                Birth
                                                                                            </p>
                                                                                            <p>
                                                                                                Contact
                                                                                                Number
                                                                                            </p>
                                                                                            <p>
                                                                                                Gender
                                                                                            </p>
                                                                                            <p>
                                                                                                Risk
                                                                                                Assessment
                                                                                                Done
                                                                                            </p>
                                                                                            <p>
                                                                                                HIV
                                                                                                Status
                                                                                            </p>
                                                                                            <p>
                                                                                                HIV
                                                                                                Test
                                                                                                Done
                                                                                            </p>
                                                                                            <p>
                                                                                                On
                                                                                                Prep?
                                                                                            </p>
                                                                                            <p>
                                                                                                On
                                                                                                ART?
                                                                                            </p>
                                                                                            <p>
                                                                                                HIV
                                                                                                Test
                                                                                                Results
                                                                                            </p>
                                                                                            <p>
                                                                                                Referral
                                                                                                for
                                                                                                Additional
                                                                                                Services
                                                                                                Made
                                                                                            </p>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                fontFamily:
                                                                                                    "Gothic A1",
                                                                                                fontWeight: 600,
                                                                                                fontSize:
                                                                                                    "1rem",
                                                                                                color:
                                                                                                    "#031F33",
                                                                                                textTransform:
                                                                                                    "capitalize",
                                                                                            }}
                                                                                            className='text-bold'
                                                                                        >
                                                                                            <p>
                                                                                                {patient.first_name_out ||
                                                                                                    "--"}
                                                                                            </p>
                                                                                            <p>
                                                                                                {patient.surname_out ||
                                                                                                    "--"}
                                                                                            </p>
                                                                                            <p>
                                                                                                {patient.preferred_name_out ||
                                                                                                    "--"}
                                                                                            </p>
                                                                                            <p>
                                                                                                {patient.date_of_birth_out ||
                                                                                                    "--"}
                                                                                            </p>
                                                                                            <p>
                                                                                                {patient.contact_number_out ||
                                                                                                    "--"}
                                                                                            </p>
                                                                                            <p>
                                                                                                {getGender(
                                                                                                    patient.gender_out
                                                                                                ) ||
                                                                                                    "--"}
                                                                                            </p>
                                                                                            <p>
                                                                                                {
                                                                                                    risk_asses[
                                                                                                        patient
                                                                                                            .risk_assess_done_out
                                                                                                    ]
                                                                                                }
                                                                                            </p>
                                                                                            <p>
                                                                                                {
                                                                                                    status_out[
                                                                                                        patient
                                                                                                            .hiv_status_out
                                                                                                    ]
                                                                                                }
                                                                                            </p>
                                                                                            <p>
                                                                                                {
                                                                                                    test_conducted_out[
                                                                                                        patient
                                                                                                            .test_conducted_out
                                                                                                    ]
                                                                                                }
                                                                                            </p>
                                                                                            <p>
                                                                                                {
                                                                                                    on_prep[
                                                                                                        patient
                                                                                                            .on_prep_out
                                                                                                    ]
                                                                                                }
                                                                                            </p>
                                                                                            <p>
                                                                                                {
                                                                                                    on_art[
                                                                                                        patient
                                                                                                            .on_art_out
                                                                                                    ]
                                                                                                }
                                                                                            </p>
                                                                                            <p>
                                                                                                {
                                                                                                    test_result[
                                                                                                        patient
                                                                                                            .test_results_out
                                                                                                    ]
                                                                                                }
                                                                                            </p>
                                                                                            <p>
                                                                                                {
                                                                                                    referral_out[
                                                                                                        patient
                                                                                                            .referral_out
                                                                                                    ]
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    </section>
                                                                                    {/* <Row>
                                  <Col xs='6'>
                                    <label className='form-control-label'>
                                      Legal First Name:
                                    </label>
                                    <FormGroup>
                                      <Input
                                        disabled
                                        name='first_name_out'
                                        className='form-control-alternative'
                                        type='text'
                                        defaultValue={patient.first_name_out}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col xs='6'>
                                    <label className='form-control-label'>
                                      Legal Surname:
                                    </label>
                                    <FormGroup>
                                      <Input
                                        disabled
                                        name='surname_out'
                                        className='form-control-alternative'
                                        type='text'
                                        defaultValue={patient.surname_out}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col xs='6'>
                                    <label className='form-control-label'>
                                      Preferred Name:
                                    </label>
                                    <FormGroup>
                                      <Input
                                        disabled
                                        name="first_name_id"
                                        className='form-control-alternative'
                                        id='input-username'
                                        type='text'
                                        defaultValue={
                                          patient.preferred_name_out
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs='4'>
                                    <label className='form-control-label'>
                                      Date of Birth:
                                    </label>
                                    <FormGroup>
                                      <Input
                                        disabled
                                        name='date_of_birth_out'
                                        className='form-control-alternative'
                                        type='text'
                                        defaultValue={patient.date_of_birth_out}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col xs='4'>
                                    <label className='form-control-label'>
                                      Contact Number:
                                    </label>
                                    <FormGroup>
                                      <Input
                                        disabled
                                        name='contact_number_out'
                                        className='form-control-alternative'
                                        type='text'
                                        defaultValue={
                                          patient.contact_number_out
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs='6'>
                                    <label className='form-control-label'>
                                      Gender:
                                    </label>
                                    <FormGroup>
                                      <Input
                                        name='gender_out'
                                        className='form-control-alternative'
                                        disabled
                                        defaultValue={patient.gender_out}
                                        type='text'
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs='6'>
                                    <label className='form-control-label'>
                                      Risk Assessment Done:
                                    </label>
                                    <FormGroup>
                                      <Input
                                        name='risk_assess_done_out'
                                        className='form-control-alternative'
                                        type='select'
                                        disabled
                                        defaultValue={
                                          patient.risk_assess_done_out
                                        }
                                      >
                                        <
                                        <option value='0'>Yes</option>
                                        <option value='1'>No</option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs='6'>
                                    <label className='form-control-label'>
                                      HIV Status:
                                    </label>
                                    <FormGroup>
                                      <Input
                                        name='hiv_status_out'
                                        className='form-control-alternative'
                                        type='select'
                                        disabled
                                        defaultValue={patient.hiv_status_out}
                                      >
                                        <>
                                          <
                                          <option value='1'>
                                            Known Positive
                                          </option>
                                          <option value='2'>
                                            Known Negative
                                          </option>
                                          <option value='3'>Other</option>
                                        </>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col xs='6'>
                                    <label className='form-control-label'>
                                      HIV Test Done:
                                    </label>
                                    <FormGroup>
                                      <Input
                                        name='test_conducted_out'
                                        disabled
                                        className='form-control-alternative'
                                        type='select'
                                        defaultValue={
                                          patient.test_conducted_out
                                        }
                                      >
                                        <
                                        <option value='0'>Yes</option>
                                        <option value='1'>No</option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row> */}
                                                                                    {/* //Status Change */}
                                                                                    {/* <Row>
                                  <Col xs='6'>
                                    <label className='form-control-label'>
                                      On Prep?:
                                    </label>
                                    <FormGroup>
                                      <Input
                                        name='on_prep_out'
                                        className='form-control-alternative'
                                        type='select'
                                        disabled
                                        defaultValue={patient.on_prep_out}
                                      >
                                        <>
                                          <
                                          <option value='1'>Yes</option>
                                          <option value='0'>No</option>
                                        </>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col xs='6'>
                                    <label className='form-control-label'>
                                      On ART? :
                                    </label>
                                    <FormGroup>
                                      <Input
                                        name='on_art_out'
                                        className='form-control-alternative'
                                        type='select'
                                        disabled
                                        defaultValue={patient.on_art_out}
                                      >
                                        <
                                        <option value='1'>Yes</option>
                                        <option value='0'>No</option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs='6'>
                                    <label className='form-control-label'>
                                      HIV Test Results:
                                    </label>
                                    <FormGroup>
                                      <Input
                                        name='test_results_out'
                                        className='form-control-alternative'
                                        type='select'
                                        disabled
                                        defaultValue={patient.test_results_out}
                                      >
                                        <
                                        <option value='1'>
                                          Known Positive
                                        </option>
                                        <option value='2'>
                                          Known Negative
                                        </option>
                                        <option value='3'>Other</option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col xs='6'>
                                    <label className='form-control-label'>
                                      Referral for Additional Services Made:
                                    </label>
                                    <FormGroup>
                                      <Input
                                        name='referral_out'
                                        disabled
                                        className='form-control-alternative'
                                        type='select'
                                        defaultValue={patient.referral_out}
                                      >
                                        <
                                        <option value='1'>Yes</option>
                                        <option value='0'>No</option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row> */}
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    <p>
                                                                                        Legal
                                                                                        First
                                                                                        Name{" "}
                                                                                        <span>
                                                                                            {
                                                                                                patient.first_name_out
                                                                                            }
                                                                                        </span>
                                                                                    </p>
                                                                                    <p>
                                                                                        Legal
                                                                                        Surname
                                                                                    </p>
                                                                                    <p>
                                                                                        Preferred
                                                                                        Name
                                                                                    </p>
                                                                                    <p>
                                                                                        Date
                                                                                        of
                                                                                        Birth
                                                                                    </p>
                                                                                    <p>
                                                                                        Contact
                                                                                        Number
                                                                                    </p>
                                                                                    <p>
                                                                                        Gender
                                                                                    </p>
                                                                                </div>
                                                                            )}
                                                                        </section>
                                                                    );
                                                                }
                                                            )}
                                                        </section>
                                                    </section>
                                                    {/* </Form> */}
                                                </section>
                                            ) : null}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Container>
                    </MainLayout>
                </>
            ) : null}
        </>
    );
}

export default withPatientState(
    withOutreachDD(withAuthState(withRouter(OutreachViewForm)))
);
