export function find_and_split(string_input: string, field?: string) {
    // if (field === "SubDistricts") {
    // }
    var split_1st = string_input?.split("|");
    var split2nd: any[] = [];
    split_1st.forEach((field) => {
        var temp_split = field.split(",");
        split2nd.push({
            index: Number(temp_split[0].trim()),
            value: temp_split[1],
        });
    });
    // console.log(split2nd);

    return split2nd;
}

export function createVerifier(dictionary: any[], activity_type_out: string) {
    // var temp_list = list;
    var final_update = dictionary.filter((d_: any) => {
        if (d_.required_field === "y") {
            if (d_.branching_logic) {
                if (
                    d_.field_type === "dropdown" &&
                    d_.branching_logic.includes(
                        "[activity_type_out] = '" + activity_type_out + "'"
                    )
                ) {
                    return {
                        ...d_,
                        choices: find_and_split(
                            d_.select_choices_or_calculations
                        ),
                    };
                } else if (
                    d_.branching_logic.includes(
                        "[activity_type_out] = '" + activity_type_out + "'"
                    )
                ) {
                    return {
                        ...d_,
                    };
                }
            } else {
                return {
                    ...d_,
                };
            }
        }
    });
    return final_update;
}
