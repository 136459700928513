import React from "react";
import DictionaryTranslater from "./Services/DictionaryTranslater";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    RouteComponentProps,
    Switch,
    withRouter,
} from "react-router-dom";

import MAIN from "./Main";
import withAuthState, { AuthProps } from "./Enhanchers/withAuthState";
import PatientListener from "./Services/PatientListener";
import firebase from "./Services/firebase";
import Loading from "./Loading";

import ForgotPassword from "./Pages/ForgotPassword";
import Login from "./Pages/Login";
import withPatientState, { PatientProps } from "./Enhanchers/withPatientState";
import OutreachListener from "./Services/OutreachListener";
import Notification from "./Elements/notification";
import { getDifference } from "./utils/Object";
import PrepListener from "./Services/PrepListener";
import AppointmentListener from "./Services/AppointmentListener";
import withAppointmentState, {
    AppointmentProps,
} from "./Enhanchers/withAppointmentState";
import Register from "./Pages/Register";
import ProtectedRoute from "./ProtectedRoutes";
import { generate_id } from "./Services/API";
interface IState {
    loaded?: boolean;
}

type Props = AuthProps & PatientProps & RouteComponentProps;

class App extends React.Component<Props, IState> {
    state = {
        loaded: false,
    };

    constructor(props: any) {
        super(props);
        firebase.auth.onAuthStateChanged((user: any) => {
            // console.log("props",props);
            if (
                props.history.location.pathname.includes("register") &&
                user != null
            ) {
                firebase.auth.signOut();
                // eslint-disable-next-line no-restricted-globals
                // location.reload();
                alert("Error!, Please Logout To register");

                return;
            }
            // console.log(user);

            if (
                user == null &&
                !props.history.location.pathname.includes("forgot-password") &&
                !props.history.location.pathname.includes("register")
            ) {
                props.history.replace("/login");

                props.setActiveState({ active_state: "non-active" });
                return;
            } else if (user !== null) {
                if (Object.keys(user).length > 0) {
                    var ref = firebase.db.collection("Users").doc(user.uid);
                    ref.get().then((doc) => {
                        //     console.log("just checking" , doc.data());
                        var user_ = doc.data();
                        if (user_) {
                            props.setUser({
                                user: { ...user_, uuid: user.uid },
                            });
                            this.props.setActiveState({
                                active_state: "active",
                            });

                            //  Check if url - then redirect to url , otherwise default home

                            return;
                        }
                    });
                }
            }
        });
    }

    componentDidUpdate(prevProps: any) {
        // generate_id();
        console.log(
            this.props.location.pathname === "/login" ||
                this.props.location.pathname === "/register",
            Object.keys(this.props.auth.user).length > 0
        );
        if (
            (this.props.location.pathname === "/login" ||
                this.props.location.pathname === "/register") &&
            Object.keys(this.props.auth.user).length > 0
        ) {
            this.props.history.replace("/home");
            console.log("called");
        }
    }

    render() {
        return (
            <>
                {this.props.auth.active_state.includes("loading") ? (
                    <Loading />
                ) : (
                    <>
                        <Switch>
                            <Route exact path='/login' component={Login} />
                            <Route
                                exact
                                path='/forgot-password'
                                component={ForgotPassword}
                            />
                            <Route
                                exact
                                path='/register'
                                component={Register}
                            />

                            {this.props.auth.active_state.includes(
                                "non_active"
                            ) ? null : (
                                <>
                                    <ProtectedRoute />

                                    <Notification />
                                    <PatientListener />
                                    <OutreachListener />
                                    <DictionaryTranslater list_name='outreach' />
                                    <PrepListener />
                                    <AppointmentListener />
                                </>
                            )}
                        </Switch>{" "}
                    </>
                )}
            </>
        );
    }
}

export default withRouter(withPatientState(withAuthState(App)));
