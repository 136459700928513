import {
    Badge,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Col,
    Button,
    Modal,
    FormGroup,
    Input,
} from "reactstrap";
import React, { useEffect } from "react";
// import {Button} from "@material-ui/core" ;
import AddIcon from "@material-ui/icons/Add";
import { withRouter, RouteComponentProps } from "react-router-dom";

// eslint-disable-next-line import/first
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

// import paginationFactory from "react-bootstrap-table2-paginator";
// eslint-disable-next-line import/first
import BootstrapTable from "react-bootstrap-table-next";
// eslint-disable-next-line import/first
import paginationFactory from "react-bootstrap-table2-paginator";

// eslint-disable-next-line import/first
import ToolkitProvider, {
    Search,
    CSVExport,
} from "react-bootstrap-table2-toolkit";

// eslint-disable-next-line import/first
import moment from "moment";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import { lowerCase } from "lodash";
import { useForm } from "react-hook-form";
import UserForm from "../Forms/UserForm";
require("react-bootstrap-table-next/dist/react-bootstrap-table2.min.css");

interface IProps {
    list: any;
    onRowSelect: any;
    onEdit: any;
}
interface IState {
    newUser: boolean;
    superAdmin: string | number;
}

type Props = IProps & RouteComponentProps & AuthProps;

class UserTable extends React.Component<Props, IState> {
    state = {
        newUser: false,
        superAdmin: "0",
    };

    updateList = (v: any) => {
        //    console.log( v.target.value)
        this.setState({ superAdmin: v.target.value });
    };

    render() {
        // const { ExportCSVButton } = CSVExport;
        const selectRow = {
            mode: "checkbox",
            clickToSelect: true,
        };

        const pagination = paginationFactory({
            page: 1,
            alwaysShowAllBtns: true,
            showTotal: false,
            withFirstAndLast: false,
            sizePerPageRenderer: ({ options, onSizePerPageChange }) => (
                <>
                    <section
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "brandon-grotesque",
                                fontWeight: 600,
                                fontSize: "0.813rem",
                                color: "#143840",
                                textTransform: "uppercase",
                                marginRight: 10,
                                marginTop: 15,
                            }}
                        >
                            Showing
                        </p>
                        <select
                            style={{
                                width: 80,
                                height: 40,
                                paddingLeft: 10,
                                paddingRight: 10,
                                borderRadius: 3,
                                color: "#143840",
                                fontSize: "0.75rem",
                            }}
                            name='datatable-basic_length'
                            aria-controls='datatable-basic'
                            className='form-control'
                            onChange={(e) =>
                                onSizePerPageChange(e.target.value)
                            }
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                        </select>
                    </section>
                </>
            ),
        });

        const { SearchBar } = Search;

        const camelizeText = (str: string) => {
            return str.replace(/\W+(.)/g, function (match: any, chr: any) {
                return chr.toUpperCase();
            });
        };

        console.log(this.props.list);

        const columns = [
            {
                dataField: "super_admin",
                text: "Role",
                sort: true,
                formatter: (cell: any, row: any) => {
                    if (cell) {
                        return (
                            <i
                                className='fa fa-id-card text-info  ml-5'
                                style={{ fontSize: "30" }}
                            />
                        );
                    } else if (!cell) {
                        return (
                            <a
                                className='text-danger ml-5'
                                style={{ fontSize: "30" }}
                            >
                                {row.role || "no role specified"}
                            </a>
                        );
                    } else {
                        return "not defined";
                    }
                },
            },

            {
                dataField: "email",
                text: "Email",
                sort: true,
            },
            {
                dataField: "name",
                text: "Full Name",
                sort: true,
            },
            {
                dataField: "data_access_group",
                text: "Data Access Group",
                sort: true,
                formatter: (cell: any) => {
                    return cell;
                },
            },
            {
                dataField: "created_by_datetime",
                text: "Date Added",
                sort: true,
                formatter: (cell: any) => {
                    //  console.log(cell);
                    if (cell) {
                        var split_date_and_time = cell.split("|");
                        var new_date = split_date_and_time[0];

                        return moment(new_date).format("DD/MM/YYYY");
                    } else {
                        return "N/A";
                    }
                },
            },
            {
                dataField: "uuid",
                text: "Actions",
                sort: true,
                formatter: (cell: any, row: any) => {
                    console.log(cell);
                    return (
                        <>
                            <a
                                href={`/user-management/profile/${cell}`}
                                className='link mr-4'
                                title='Click to View'
                            >
                                View
                            </a>
                            {/* <Button className='btn-primary' type='button'>
                                Edit
                            </Button> */}
                        </>
                    );
                },
            },
        ];

        return (
            <>
                <ToolkitProvider
                    data={this.props.list}
                    keyField='uid'
                    columns={columns}
                    bootstrap4={true}
                    search
                    // exportCSV
                >
                    {(props: any) => (
                        <div>
                            <Row className='text-rights'>
                                <Col xs='12'>
                                    <div className='float-right col-6'>
                                        <SearchBar
                                            {...props.searchProps}
                                            delay={0}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <BootstrapTable
                                style={{
                                    marginBottom: 5,
                                }}
                                {...props.baseProps}
                                // rowEvents={this.props.onRowSelect}
                                bordered={false}
                                pagination={pagination}
                                // selectRow={ selectRow }
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </>
        );
    }
}

export default withAuthState(withRouter(UserTable));
