import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Datetime from "react-datetime";
import moment, { Moment } from "moment";
import ReactDatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// reactstrap components
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";

import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormText,
  Modal,
  Card,
  CardHeader,
  Collapse,
  CardBody,
} from "reactstrap";
import _ from "lodash";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { IPatient } from "../@types/patient";
import { clear } from "console";
import { RouteComponentProps, withRouter } from "react-router-dom";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import firebase from "../Services/firebase";

import "../App.css";
import Select from "react-select";
import { IOutreach } from "../@types/outreach";

import * as API from "../Services/API";
import withOutreachDD, { OutreachDDProps } from "../Enhanchers/withOutreachDD";
import withPatientState, { PatientProps } from "../Enhanchers/withPatientState";



interface IProps{
    closeModal_: any;
    userMode: "create"| "edit";
}

type Props = RouteComponentProps & AuthProps & OutreachDDProps & PatientProps & IProps;

function UserForm(props: Props) {
    const { register, handleSubmit, watch, errors, formState, control,     setError,
        clearError} = useForm();

        const [superAdmin, setSuperAdmin] = useState("0");

        const [successAdded, SetsuccessAdded] = useState(false);

        const userSubmit = (values: any) =>{
         //   console.log(values);
            var new_user_record;
            if(superAdmin === "0"){
                 new_user_record = {
                    name: values.name,
                    email: values.email,
                    data_access_group: values.data_access_group,
                    role: values.role,
                    role_access: (values.role === 'data_capturer' || values.role === 'nurse') ? 0:1,
                    super_admin: false,
                    status: 'registration',
                    created_by: props.auth.user.uuid,
                    created_by_datetime: moment().format("YYYY/MM/DD | HH:mm:ss")                    
                }
          //      console.log(new_user_record);
            }else{
                 new_user_record ={
                    name: values.name,
                    email: values.email,
                    data_access_group: 'tg_cpt',
                    role: 'data_capturer',
                    role_access: 1,
                    super_admin: true,
                    status: 'registration',
                    created_by: props.auth.user.uuid,
                    created_by_datetime: moment().format("YYYY/MM/DD | HH:mm:ss")
                }
            }
         //   console.log(new_user_record)
           var userRef = firebase.db.collection("Users");
           userRef.add(new_user_record).then(ref =>{
        //       console.log("reference id ", ref.id);
               SetsuccessAdded(true)
           });

        }

        const updateList = (value:any)=>{
         //   console.log(value);
            setSuperAdmin(value.target.value)
        }
        
  return (
    <>{!successAdded ?
      <Form onSubmit={handleSubmit(userSubmit)}>
      <Row>
              <Col xs="5">
            <label className="form-control-label">Full Name</label>
            <FormGroup>
              <Input
                className="form-control-alternative"
                type="text"
                name="name"
                innerRef={register({ required: true})}


              />

            </FormGroup>
          </Col>
          <Col xs="5">
            <label className="form-control-label">Email</label>
            <FormGroup>
              <Input
                className="form-control-alternative"
                type="email"
                name="email"
                innerRef={register({ required: true})}

              />

            </FormGroup>
          </Col>
          </Row>
          <Row>
          <div className="custom-control custom-checkbox" >
          <label className="form-control-label">Is Super Admin</label>

          <Input type="select"
            className="form-control-alternative"
            value={superAdmin}
            // value={this.state.superAdmin}
            onChange={(v)=>updateList(v)}
          >
              <option value="0"> No</option>
              <option value="1">Yes</option>
          </Input>
        </div>
          </Row>              <hr style={{marginTop: 20, marginBottom: 20}}/>

         {superAdmin === "0"?
          <Row>
              <Col xs="6">
            <label className="form-control-label">Data Access Group</label>
            <FormGroup>
              <Input
                className="form-control-alternative"
                type="select"
                name="data_access_group"
                innerRef={register({ required:superAdmin === "0" ? true: false})}

              >
                    <option value="">--Select Access Group--</option>
                  <option value="tg_buffalo_city">
                    Buffalo City Metropolitan Municipality{" "}
                  </option>
                  <option value="tg_cpt">
                    City of Cape Town Metropolitan Municipality{" "}
                  </option>
                  <option value="johannesburg">
                    City of Johannesburg Metropolitan Municipality{" "}
                  </option>
                  <option value="oasis_johannesburg">
                    City of Johannesburg Metropolitan Municipality OASIS{" "}
                  </option>
                  <option value="phru_johannesburg">
                    City of Johannesburg Metropolitan Municipality PHRU{" "}
                  </option>
                  <option value="tshwane">
                    City of Tshwane Metropolitan Municipality{" "}
                  </option>
                  <option value="ekurhuleni">
                    Ekurhuleni Metropolitan Municipality{" "}
                  </option>
                  {/* <option value="10">Ekurhuleni Metropolitan Municipality CPC  */}
                  <option value="tg_mandelabay">
                    Nelson Mandela Bay Municipality{" "}
                  </option>
                  <option value="vhembe">Vhembe District Municipality</option>
                  </Input>

            </FormGroup>
          </Col>
          <Col xs="5">
            <label className="form-control-label">Role</label>
            <FormGroup>
              <Input
                className="form-control-alternative"
                type="select"
                name="role"
                innerRef={register({ required:superAdmin === "0" ? true: false})}

              >
                  <option value="">--SElect your Role--</option>
                  <option value="peer_educator">Peer Educator</option>
                  <option value="nurse">Nurse</option>
                  <option value="data_capturer">Data Capturer</option>
                  <option value="data_quality_officer">
                    Data Quality Officer
                  </option>
                  <option value="site_administrator">
                    {" "}
                    Site Administrator
                  </option>
                  </Input>

            </FormGroup>
          </Col>
          </Row>
             
             
             : null }
             <Row>
             <Col xs="6" className="text-right">

     <Button color="secondary" type="button" onClick={()=>{props.closeModal_(false)}} >
       Cancel
     </Button>
  

     <Button color="info" type="submit"  onClick={handleSubmit(userSubmit)} >
         Create User
     </Button>
   </Col>
             </Row>
      </Form>:
      <div className="text-center">
          <h3>User Added Successfully</h3>
          <Button color="info" type="submit"  onClick={props.closeModal_(false)} >
       Done
     </Button>
      </div>
      }
    </>
  );
}

export default withPatientState(
  withOutreachDD(withAuthState(withRouter(UserForm)))
);
