import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import * as actions from './actions';

export interface IActivityState {
  readonly district_activity: any;
  readonly personal_activity: any;
}

export type  ActivityActions = ActionType<typeof actions>;

const initialState: IActivityState = {
  district_activity: [],
  personal_activity: []
};

export default combineReducers<IActivityState, ActivityActions>({
  district_activity: (state = initialState.district_activity, action) => {
    switch (action.type) {
      case getType(actions.setDistrictActivity): {
        return action.payload.district_activity;
      }
      default: {
        return state;
      }}
    },
      personal_activity:(state = initialState.personal_activity, action) => {
        switch (action.type) {
          case getType(actions.setPersonalActivity): {
            return action.payload.personal_activity;
          }
          default: {
            return state;
          }
        }
    }

});
