import React from 'react'
import logo from '../logo.svg'
import '../App.css'
import TopNavbarMain from '../Components/TopNavbarMain'

import {
  CssBaseline,
  WithStyles,
  Toolbar,
  withStyles,
  Theme,
  createStyles,
} from '@material-ui/core'
import MainLayout from '../Layouts/MainLayout'
import Paper from '@material-ui/core/Paper'
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  FormGroup,
  Form,
  Input,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  CardText,
} from 'reactstrap'
import { CardBody, CardTitle, Col } from 'reactstrap'
import AddIcon from '@material-ui/icons/Add'
import { RouteComponentProps, withRouter, NavLink } from 'react-router-dom'

import MUIDataTable from 'mui-datatables'
import { IPatient } from '../@types/patient'
import PatientInfoTab from '../Components/PatientInfoTab'
import classnames from 'classnames'
import PatientOutreachTab from '../Components/PatientOutreachTab'
import PatientAppointmentTab from '../Components/PatientAppointmentTab'
import editBtn from '../Assets/img/icons/common/edit-btn.svg'
import * as API from '../Services/API'
import moment from 'moment'
import { BorderBottom } from '@material-ui/icons'
import withPrepState, { PrepProps } from '../Enhanchers/withPrepState'
import withAppointmentState, {
  AppointmentProps,
} from '../Enhanchers/withAppointmentState'
import withOutreachState, {
  OutreachProps,
} from '../Enhanchers/withOutreachState'
import PatientPrepTab from '../Components/PatientPrepTab'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'
import { PDFViewer } from '@react-pdf/renderer'
import withPatientState, { PatientProps } from '../Enhanchers/withPatientState'

// import {firebase} from '../Services/firebase';
interface IState {
  patient: any
  prep_record: any
  intake_info: any
  activeTab: string
  loading: boolean
  appointments: any
  outreach: any
}
type Props = WithStyles<typeof styles> &
  RouteComponentProps<{ id: string }> &
  PrepProps &
  AppointmentProps &
  OutreachProps &
  PatientProps
class PatientPrintView extends React.Component<Props, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      patient: this.props.location.state,
      prep_record: [],
      intake_info: {},
      activeTab: '1',
      loading: true,
      appointments: [],
      outreach: [],
    }
  }

  componentDidMount() {
    if (this.props.match.params.id && this.props.location.state) {
      console.log(this.props.location.state)
      if (this.props.location.state) {
        var pat_ = this.getPatient(this.props.match.params.id)
        var prep_record = this.getPatientPrepInfo(pat_)
        var appointments_ = this.getPatientAppointmentInfo(pat_)
        var outreach_ = this.getPatientOutreachInfo(pat_)

        console.log({
          patient: this.props.location.state,
          loading: true,
          prep_record: prep_record,
          appointments: appointments_,
          outreach: outreach_,
        })
        this.setState({
          patient: this.props.location.state,
          loading: true,
          prep_record: prep_record,
          appointments: appointments_,
          outreach: outreach_,
        })
      }
    }
  }

  getPatient = (patient_id: string) => {
    var p_ = this.props.patients.patients.filter(
      (p_: any) => p_.uid === patient_id
    )
    console.log('patient record found', p_)

    return p_
  }

  getPatientPrepInfo = (patient: any) => {
    var p_ = this.props.prep_records.prep_records.filter(
      (prep_rec: any) => prep_rec.patient_id === patient.uid
    )
    console.log('prep record found', p_)

    return p_
  }
  getPatientOutreachInfo = (patient: any) => {
    var p_ = this.props.outreach.outreach_all.filter(
      (out_rec: any) => out_rec.patient_id === patient.record_id
    )
    console.log('prep record found', p_)

    return p_
  }
  getPatientAppointmentInfo = (patient: any) => {
    var p_ = this.props.appointment_records.appointment_records.filter(
      (app_rec: any) => app_rec.patient_id === patient.uid
    )
    console.log('prep record found', p_)

    return p_
  }
  getPatientAge = (date_string: string) => {
    var moment_date = new Date(date_string)
    //console.log(moment_date)
    var a = moment(moment_date)
    var b = moment()
    var years = b.diff(a, 'years', false)
    var months = b.diff(a, 'months', false)
    //console.log(years+" "+months);
    return years
  }

  toggle = (tab: string) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  patientName_ = (patient: any) => {
    //  var pref_name = ""
    // if(patient.pref_name_id  !== undefined){
    //     pref_name = patient.pref_name_id ;
    //   if(pref_name.length > 0){
    // return patient.first_name_id + " (" + patient.pref_name_id + ") " + patient.surname_id
    //   }else{
    //     return patient.first_name_id+" " + patient.surname_id
    //   }
    // }
    // else{
    return patient.first_name_id + ' ' + patient.surname_id
    // }
  }

  render() {
    // var var patient_birthday  moment(this.state.patient.birthdate_id).format()

    // console.log((this.state.patient));
    if (this.state.loading) {
      return (
        <PDFViewer>
          <Document>
            <Page size='A4'>
              <View>
                <div className='pt-5 pt-lg-8'>
                  {/* Header container */}
                  <Container fluid>
                    <div className='header-body'>
                      <Row style={{ marginBottom: 30 }}>
                        <Col xs='1'>
                          {/* <a style={{ backgroundColor: 'transparent', marginTop: 13}} onClick={()=>{this.props.history.goBack()}} className="btn">
                      <i className="fa fa-arrow-left  ml-5" style={{fontSize:20}} />
                    </a> */}
                        </Col>
                        <Col xs='11'>
                          <h1 className='pl-0'>
                            Patient{' '}
                            <div className='float-right'>
                              <Button
                                className='btn-appointment btn-lg'
                                type='button'
                                onClick={() => {
                                  this.props.history.push({
                                    pathname: '/patient-create',
                                    // state: patient,
                                  })
                                }}
                              >
                                <span className='btn-inner--text'>
                                  <span className='add-border'>+</span> Book
                                  Appointment
                                </span>
                              </Button>
                            </div>
                          </h1>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs='12'>
                          <Card className='ml-5 p-4 border-0'>
                            <CardBody>
                              <Row>
                                <Col lg='5'>
                                  <h1 className='sea-green pl-0'>
                                    {this.patientName_(this.state.patient)}
                                  </h1>
                                  <p className='patient-metadata'>
                                    <span className='mb-0 pr-2'>
                                      {' '}
                                      {this.getPatientAge(
                                        this.state.patient.birthdate_id
                                      ) + ' Years Old'}
                                    </span>{' '}
                                    <span className='sea-green'>|</span>
                                    <span className='mb-0 pl-2 pr-2'>
                                      {this.state.patient.gender_id + ' '}{' '}
                                    </span>{' '}
                                    <span className='sea-green'>|</span>
                                    <span className='pl-2 mb-0'>
                                      {this.state.patient.contact_number_id
                                        ? this.state.patient.contact_number_id
                                        : '// '}
                                    </span>
                                  </p>
                                  {/* {this.state.completed_list} */}
                                </Col>
                                <Col lg='7'>
                                  <Row>
                                    <Col xs='3'>
                                      <Card className='border-1 card-outline'>
                                        <CardTitle
                                          tag='h3'
                                          className='text-center mb-0'
                                        >
                                          Date First Seen
                                          <hr />
                                        </CardTitle>
                                        <CardBody>
                                          <p className='small'>
                                            {
                                              this.state.patient.date_of_capture_id.split(
                                                ' '
                                              )[0]
                                            }
                                            {/* {this.state.completed_list} */}
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>

                                    <Col xs='3'>
                                      <Card className='border-1 card-outline'>
                                        <CardTitle
                                          tag='h3'
                                          className='mb-0 text-center'
                                        >
                                         {'Seen for Outreach/\n'} Group Activity
                                          <hr />
                                        </CardTitle>
                                        <CardBody>
                                          <p>{this.state.outreach.length}</p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col xs='3'>
                                      <Card className='border-1 card-outline'>
                                        <CardTitle
                                          tag='h2'
                                          className='text-center mb-0'
                                        >
                                          Seen for Prep
                                          <hr />
                                        </CardTitle>
                                        {Object.keys(this.state.prep_record)
                                          .length > 0 ? (
                                          <CardBody>
                                            <p>
                                              {
                                                this.state.prep_record[0]
                                                  .prep_by_month.length
                                              }
                                            </p>
                                          </CardBody>
                                        ) : (
                                          <CardBody>
                                            <p>--</p>
                                          </CardBody>
                                        )}
                                      </Card>
                                    </Col>
                                    <Col xs='3'>
                                      <Card className='border-1 card-active'>
                                        <CardTitle
                                          tag='h2'
                                          className='text-center mb-0'
                                        >
                                          Total Times Seen
                                          <hr />
                                        </CardTitle>
                                        {Object.keys(this.state.prep_record)
                                          .length > 0 ? (
                                          <CardBody>
                                            <p>
                                              {this.state.prep_record[0]
                                                .prep_by_month.length +
                                                this.state.outreach.length}
                                            </p>
                                          </CardBody>
                                        ) : (
                                          <CardBody>
                                            <p>{this.state.outreach.length}</p>
                                          </CardBody>
                                        )}
                                      </Card>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                </div>
              </View>
              {/* Page content */}
              <View>
                <Container className='mt-5' fluid>
                  <div className=''>
                    <Nav
                      className='nav-fill  flex-column flex-sm-row mb-4'
                      id='tabs-text'
                      pills
                      role='tablist'
                      tabs
                      style={{ borderBottom: '1px solid #E0E0E0' }}
                    >
                      <NavItem className='ml-5' style={{ height: 50 }}>
                        <div
                          aria-selected={this.state.activeTab === '1'}
                          style={{
                            height: '100%',
                            color: '#B3B8BF',
                            borderBottom:
                              this.state.activeTab === '1'
                                ? '2px solid #17A3AB'
                                : '',
                          }}
                          onClick={(e) => this.toggle('1')}
                          // href="#generalInfo"
                          role='tab'
                        >
                          <h4
                            style={{
                              color: '#B3B8BF',

                              // paddingTop: 10,
                            }}
                          >
                            {' '}
                            General
                          </h4>
                        </div>
                      </NavItem>

                      <NavItem>
                        <div
                          aria-selected={this.state.activeTab === '2'}
                          style={{
                            height: '100%',
                            borderBottom:
                              this.state.activeTab === '2'
                                ? '2px solid #17A3AB'
                                : '',
                          }}
                          onClick={(e) => this.toggle('2')}
                          // href="#generalInfo"
                          role='tab'
                        >
                          <h4
                            style={{
                              color: '#B3B8BF',
                            }}
                          >
                            Outreach
                          </h4>
                        </div>
                      </NavItem>

                      <NavItem>
                        <div
                          aria-selected={this.state.activeTab === '3'}
                          style={{
                            height: '100%',
                            borderBottom:
                              this.state.activeTab === '3'
                                ? '2px solid #17A3AB'
                                : '',
                          }}
                          onClick={(e) => this.toggle('3')}
                          // href="#generalInfo"
                          role='tab'
                        >
                          <h4 style={{ color: '#B3B8BF' }}> Appointments</h4>
                        </div>
                      </NavItem>
                      <NavItem>
                        <div
                          aria-selected={this.state.activeTab === '4'}
                          style={{
                            height: '100%',
                            borderBottom:
                              this.state.activeTab === '4'
                                ? '2px solid #17A3AB'
                                : '',
                          }}
                          onClick={(e) => this.toggle('4')}
                          // href="#generalInfo"
                          role='tab'
                        >
                          <h4 style={{ color: '#B3B8BF' }}>Prep</h4>
                        </div>
                      </NavItem>
                    </Nav>
                  </div>

                  <Row>
                    <Col lg='6'>
                      <h1 className='patient-tab-header'>
                        {' '}
                        {this.state.activeTab === '1'
                          ? 'Personal'
                          : this.state.activeTab === '2'
                          ? 'Outreach'
                          : this.state.activeTab === '3'
                          ? 'Appointments'
                          : this.state.activeTab === '4'
                          ? 'Prep'
                          : ''}
                      </h1>
                    </Col>
                    <Col lg='6'>
                      {this.state.activeTab === '1' ? (
                        <div
                          className='float-right icon mt-5 bg-edit text-white'
                          onClick={() => {
                            this.props.history.push({
                              pathname:
                                '/patient-edit/' + this.state.patient.uid,
                              state: this.state.patient,
                            })
                          }}
                        >
                          <img src={editBtn} />
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Card className=' ml-5 mb-5'>
                    {/* Header container */}

                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId='1'>
                        <PatientInfoTab
                          patientInfo={this.state.patient}
                          intakeInfo={this.state.intake_info}
                        />
                      </TabPane>

                      <TabPane tabId='2'>
                        <PatientOutreachTab
                          outreach_record={this.state.outreach}
                        />
                      </TabPane>
                      <TabPane tabId='3'>
                        <PatientAppointmentTab
                          patient={this.state.patient}
                          // prep_record={this.state.prep_record}
                          appointment_records={this.state.appointments}
                        />
                      </TabPane>
                      <TabPane tabId='4'>
                        <PatientPrepTab
                          patient={this.state.patient}
                          prep_records={this.state.prep_record}
                        />
                      </TabPane>
                    </TabContent>
                  </Card>
                </Container>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      )
    } else {
      return (
        <MainLayout>
          <div
            className='header pb-6'
            style={{
              minHeight: '600px',
              backgroundImage:
                'url(' + require('../Assets/img/splash.jpg') + ')',
              backgroundSize: 'cover',
              backgroundPosition: 'center top',
            }}
          >
            {/* <div className={this.props.classes.loader}> */}
            {/* </div> */}
          </div>
          <span className='mask bg-gradient-info opacity-8' />
        </MainLayout>
      )
    }
  }
}
const styles = (theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },

    tableRow: {
      '&:hover': {
        borderLeft: '#17A3AB',
        borderLeftWidth: '10',
        borderLeftStyle: 'solid',
        boxShadow: '0 3px 5px 2px rgba(0,0,0,0.1)',
      },
    },
  })
export default withPatientState(
  withOutreachState(
    withAppointmentState(
      withPrepState(withRouter(withStyles(styles)(PatientPrintView)))
    )
  )
)
