import { applyMiddleware, compose, createStore } from 'redux';
import { logger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducer';
import saga from './saga';

const sagaMiddleware = createSagaMiddleware();

const middlewares: any[] = [];
middlewares.push(sagaMiddleware);
middlewares.push(logger);

export const store = createStore(
    reducer,
    {},
    compose(applyMiddleware(...middlewares))
);

sagaMiddleware.run(saga);

export default store;
