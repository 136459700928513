import { all } from 'redux-saga/effects';

import * as auth from './modules/auth';
import * as patient from './modules/patient';
import * as outreach_dd from './modules/outreach_translator';
import * as outreach from './modules/outreach'
import * as prep from './modules/prep';
import * as appointment from './modules/appointment';
import * as activity_log from './modules/activity_log';


export default function* () {
    yield all([
        auth.sagas(),
        patient.sagas(),
        outreach.sagas(),
        outreach_dd.sagas(),
        prep.sagas(),
        appointment.sagas(),
        activity_log.sagas()
    ]);
}
