import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { useForm, Controller } from "react-hook-form";

import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Button, Nav, NavItem, NavLink, Input, Modal

} from "reactstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import "../App.css";
import withOutreachDD, { OutreachDDProps } from "../Enhanchers/withOutreachDD";
import withPatientState, { PatientProps } from "../Enhanchers/withPatientState";
import ReactDatetime from "react-datetime";
import { Moment }from "moment";
import moment from 'moment';
import ReactQuill from "react-quill";
import firebase from "../Services/firebase";
import AppointmentManagerModal from "../Forms/AppointmentManagerModal";
// interface IState{
//   select


// }
interface IProps{
  appointment?: any;
};

type Props = RouteComponentProps & AuthProps & OutreachDDProps & PatientProps & IProps;

function PrepMainForm(props: Props) {


  const { register, handleSubmit, watch, errors, formState, control,     setError,clearError} = useForm();

  var temp_pat:any ={};
  
  // = {
  //   patient_id: "",
  //   full_name_id: "",
  //   first_name_id: "",
  //   surname_id: "",
  //   preferred_name_id: "",
  //   date_of_birth_id: "",
  //   contact_number_id: "",
  //   gender_id: "",
  //   hiv_status_id: "",
  //   counsellor_initials_id: "",
  //   test_conducted_id: "",
  //   referral_id: "",
  //   risk_assess_done_id: "",
  //   client_id_complete:0
  // };



  const _today = moment().format("YYYY/MM/DD");
  const [followupDate , _setFollowUpDate] = useState(_today);
  const [showManager, _setShowManager ] = useState(false);
  const [selectOption, setSelectOption] = useState(temp_pat);

  // multiLine boxes
  const [commentMultiLine, setCommentMultiLine] = useState("");
  const [allergiesMultiLine, setAllergiesMultiLine] = useState("");
  const [medicalMultiLine, setMedicalMultiLine] = useState("");
  const [supportMultiLine, setSupportMultiLine]= useState("");

  var temp_obj:any;
  const [ app_, _setApp] =useState(temp_obj);
  const [prepMeta, setPrepMeta] = useState(temp_obj);

  const [showMedsDispense, updateShowMedsDispense] = useState(false);

  const [prepFormError, setPrepFormError] = useState(false)
  const [inputValuePatientSearch, setInputValue] = useState("");

  const [patientList, setPatientList] = useState([]);
  const [PatientMode, setPatientMode] = useState(true);
  const [attended, setAttended] = useState(false)
  var subdistrict_options_obj: any = "";
  const [subdistrict_options, setSubdistrict_options] = useState(
    subdistrict_options_obj

  );


  const [monthCount, _setMonthCount] = useState("")
  const [minDate , setMinDate] = useState(_today);


  const [subdistrict_selected, setSubdistrict_selected] = useState(false);
  const [outreach_record, setOutreachRecord] = useState({
    kp_program_site_out: props.auth.user!.data_access_group.trim(),
    subdistrict_out: "",
    peer_responsible_out: "",
    team_leader_out: "",
  });


  // console.log(selectOption);

  // if(selectOption!== undefined ){
  //   setPatientMode(false)
  // }

  const { dirty, isSubmitting, touched, submitCount } = formState;

  const checkValue = (values: any) => {
    // console.log(values);
  };

  const patientStatus = (status: number) =>{
      if(status === 0){
        return(
          <>
          <span className="text-info mr-1">●</span>
          <small>Patient Record Review</small>
          </>
        )
      }
      else if(status === 1){

        return(
          <>
          <span className="text-danger mr-1">●</span>
          <small>Incomplete Record</small>
          </>
        )
      }
      else if(status === 2){
          return(
            <>
            <span className="text-success mr-1">●</span>
            <small>Complete Record</small>
            </>
          )
      }
  }

  const getPatient = (newValue: any, actionMeta?: any) => {
    console.group("Value Changed");
    // console.log(newValue);
    // console.log(actionMeta)
    // console.log(`action: ${actionMeta.action}`);
    if (actionMeta.action === "select-option") {
      console.log(newValue.value);
      setSelectOption(newValue.value);
      setPatientMode(false);
    }
    console.groupEnd();

    // console.log(patient)
    // setSelectOption(patient);
  };

  var temp_pat_list: any[] = [];
  props.patients.patients.map((patient: any) => {
    temp_pat_list.push({
      value: patient,
      label: patient.first_name_id + " " + patient.surname_id,
    });
  });

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, "");
    setInputValue(inputValue);
    return inputValue;
  };

  const filterPatientList = (inputValue: string) => {
    var temp_list = [];
    var list = temp_pat_list.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    temp_list = list.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    );
    temp_list = list.slice(0, 10);
    return temp_list;
  };

  const loadOptions = (inputValue: any, callback: any) => {
    setTimeout(() => {
      callback(filterPatientList(inputValue));
    }, 100);
  };


  const setSelectedDate = (date:  Moment | string)=>{
    console.log(date)
    setAttended(true)
    return
  }


  const setFollowUpDate = (date:  Moment | string)=>{
    console.log(date)
     _setFollowUpDate(moment(date).format("YYYY/MM/DD"));


  }

  // if(values.sub_district_out && subdistrict_selected){
  // console.log(values)

  // // if(dirtyFields.subdistrict_out){
  //   setSubdistrict_selected(true)
  // // }

  // }

  // console.log({ dirty, isSubmitting, touched, submitCount });
  // console.log(errors);
  var today = moment().format("YYYY/MM/DD");
  var tomorrow = moment(today).add( 1, 'day' );
  var yesterday = moment(today).subtract(1,'day')

  var valid = function( current: moment.MomentInput ){
      return moment(current).isSameOrBefore(today);
  };
  var valid_followup = function(current: moment.MomentInput){
    return moment(current).isSameOrAfter(minDate);
  };

  const handleMultiLineChange = (value:any, multi_:string) =>{

    if(multi_ === "comments"){
    setCommentMultiLine(value)
    }
    else if(multi_ === 'allergies'){
      setAllergiesMultiLine(value)
    }
    else if(multi_ === 'medical'){
      setMedicalMultiLine(value)

    }else if(multi_=== "support"){
      setSupportMultiLine(value)
    }
  };

  if(props.appointment){ 
    console.log(props.appointment.patient)
  
    // setSelectOption(props.appointment.patient);
   


    
  }
  const [_init_date , _setInitDate] = useState(_today)

  if(props.location.search.length > 0){
    var split_params = props.location.search.split("&");
    var reason = split_params[0].split("=")[1];
    var recordId = split_params[1].split("=")[1];
    console.log(reason, recordId);

    if(reason === "pref_id" && selectOption !== undefined && PatientMode){  // appointment
    firebase.db.collection('main/'+props.auth.user.data_access_group+"/prep_list")
    .doc(recordId)
    .get()
    .then(data =>{
      console.log({...data.data(), uid: data.id});
      var doc_data =data.data() ;
      var apt_:any = {...doc_data, uid: data.id};

    var  patient_record = props.patients.patients.filter((_pat:any) =>{
               if(_pat.uid === apt_.patient_id){
                   return _pat
               }
      })
    //   console.log(patient_record);
    //   if (apt_.type === "initiation"){
        setSelectOption(patient_record[0]);
        // today = moment(apt_.start).format("YYYY/MM/DD");
        // setSelectOption(apt_);
_setApp(apt_)
            setPatientMode(false)
            // setAttended(true)

      return
    //   }
     
    })
    }

    
  }
  if(props.location.search.length === 0 && PatientMode){
    setPatientMode(false)

    setPrepFormError(true);
  }

  const onSubmit = (values:any) =>{
    
    
    console.log('values ',values);
    var required_fields = values;

    if(Object.keys(required_fields).length > 4 && selectOption && followupDate && app_){
      // var _prep_folder_init = {
      //   patient_id: selectOption.uid,
      //   clinic_info: app_.clinic,

      // }
      setPrepMeta({
        notes:{
          comments: commentMultiLine,
          allergies: allergiesMultiLine,
          medical_history: medicalMultiLine,
          support: supportMultiLine
        },
        test_results: values

      })
      updateShowManager(true)

    }else{

    }

}

const updateShowManager = (v:boolean) =>{
    console.log(selectOption);
    if(app_){
    _setShowManager(v)
    }else{
      _setApp({clinic: ""});
      _setShowManager(v);
    }
}

const [prepContinue, _updatePrepContinue] = useState(true)
const stayingOnPrepChange=(r: number)=>{

  if(r === 1){
    _updatePrepContinue(false);
  }else if(r === 0){
    _updatePrepContinue(true);
  }

}

console.log(selectOption);
//  setPatientMode(false);
  return (
<>
{!prepFormError ?
    <>
      {showManager ?
            <AppointmentManagerModal
            isOpen={showManager}
            togglefunct={updateShowManager}
            _preCheck_patient ={selectOption}
            _isPrepCheck={true}
            _isPrepFollowup={true}
            _date={followupDate}
            _clinic={app_.clinic_id}
            _previous_appointment_info={app_}
            _prep_data={prepMeta}
            _meds_dispense={{dispense: showMedsDispense, months: minDate, _m: monthCount}}

            />
            :null} 
             {/* const [showManager, _setShowManager ] = useState(False) */}
             <>
             {!PatientMode ?
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        {PatientMode ? (
          <>
            <Col xs="12">
              <h3>Patient</h3>
            </Col>
            <Col xs="6">
              <AsyncSelect
                style={{ width: "97px !important", marginTop: "7%" }}
                cacheOptions
                defaultOptions
                isClearable
                loadOptions={loadOptions}
                // value={selectOption}
                onChange={getPatient}
                //  options={temp_pat_list}
                onInputChange={handleInputChange}
              />
            </Col>
          </>
        ) : (
          <>
            {selectOption.first_name_id.length > 0 ?
            <li className="list-group-item" style={{marginLeft: 10}}>
              <div className="align-items-center row">
                <a className="avatar avatar-xs rounded-circle bg-info" href="#">
                  <i className="fa fa-user text-white"></i>
                </a>
                {/* </Col> */}

                <Col ml="2">
                  <h4 className="mb-0">
            <a href="#">{selectOption.first_name_id}</a>
                  </h4>
                  {patientStatus(selectOption.client_id_complete)}
                
                  {/* <div class="col-auto col">
                   */}
                </Col>

                {/* <Col>
                  <Button type="button" color="primary" disabled onClick={()=>setPatientMode(true)}>
                    Change Patient
                  </Button>
                </Col> */}
              </div>
            </li>
            : null}
          </>
        )}
      </Row>
      <Row>
      <h2 className="display-4">Initiation</h2>

      </Row>
       <Row>
      <Col xs="4" >
        <label className="form-control-label">Original Prep Initiation Date</label>
            <FormGroup
              // className={
              //   "" +
              //   (
              //     (((errors.gender_id !== true || errors.gender_id === undefined) && ! (!formState.dirty && formState.submitCount  !== 0) && formState.submitCount > 0))
              //     ? "has-success"
              //     : "") +
              //   " " +
              //   (errors.gender_id ? "has-danger" : "")
              // }
            >
              <Input 
              className="form-control-alternative"
              disabled
                value={app_.initiation_date}
              >
            
              </Input>
              {/* {errors.gender_id ? (
                <a className="text-danger" style={{ fontSize: 8 }}>
                  Please Select Gender
                </a>
              ) : null} */}
            </FormGroup>
        </Col>
      </Row>
      <Row>
                   <h2 className="display-4">Monitoring</h2>


      </Row>
     
      <Row>
      <Col xs="4" >
        <label className="form-control-label">Number of Months on Prep</label>
            <FormGroup
              // className={
              //   "" +
              //   (
              //     (((errors.gender_id !== true || errors.gender_id === undefined) && ! (!formState.dirty && formState.submitCount  !== 0) && formState.submitCount > 0))
              //     ? "has-success"
              //     : "") +
              //   " " +
              //   (errors.gender_id ? "has-danger" : "")
              // }
            >
              <Input 
              className="form-control-alternative"
              disabled
                value={app_.prep_by_month.length}
              >
            
              </Input>
              {/* {errors.gender_id ? (
                <a className="text-danger" style={{ fontSize: 8 }}>
                  Please Select Gender
                </a>
              ) : null} */}
            </FormGroup>
        </Col>
        <Col xs="4" >
        <label className="form-control-label">Actual Visit</label>
        <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
            </InputGroupAddon>
            <ReactDatetime
                inputProps={{
                  placeholder: "Select Date",
                  value: today,

                  name: "actual_date"

                }}
              isValidDate={ valid } 
              timeFormat={false}
              closeOnSelect={true}
              onChange={(v)=>setSelectedDate(v)}
            />
          </InputGroup>
        </FormGroup>
        </Col>
        <Col xs="2" style={{marginTop : "30px"}}>
        {/* <Button type="button" color="primary" onClick={()=>setPatientMode(true)}>
                    Change Patient
                  </Button> */}
                          <Nav
          className="nav-fill flex-column flex-sm-row"
          id="tabs-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={attended}
             className="mb-sm-3 mb-md-0"
              style={{backgroundColor: attended ? "#11cdef": "#fff" ,
                color: attended ? "#fff": "#11cdef"
            }}
              onClick={e => setAttended(!attended)}
              role="tab"
            >
              Attended
            </NavLink>
          </NavItem>
          </Nav>
 
        </Col>
    
      </Row>
      <Row>
      
      <Col xs="4" >
        <label className="form-control-label">Staying On Prep</label>
            <FormGroup
              // className={
              //   "" +
              //   (
              //     (((errors.gender_id !== true || errors.gender_id === undefined) && ! (!formState.dirty && formState.submitCount  !== 0) && formState.submitCount > 0))
              //     ? "has-success"
              //     : "") +
              //   " " +
              //   (errors.gender_id ? "has-danger" : "")
              // }
            >
              <Input
                name="staying_on_prep"
                type="select"
                className="form-control-alternative"
                innerRef={register({ required: true, minLength: 1 })}
                onChange={(e)=>{
                  e.preventDefault();
                  stayingOnPrepChange(parseInt(e.target.value))
                }}

              >
                <option value="">--Select--</option>
                <option value="0">Yes</option>
                <option value="1">No</option>
           
              </Input>
              {/* {errors.gender_id ? (
                <a className="text-danger" style={{ fontSize: 8 }}>
                  Please Select Gender
                </a>
              ) : null} */}
            </FormGroup>
            </Col>
      </Row>
      {prepContinue ?
      <>
      <Row>
 <h2 className="display-4">Test Results (if Applicable)</h2>
 </Row>
      <Row>
      <Col xs="4" >
        <label className="form-control-label">Hiv Test Done</label>
            <FormGroup
              // className={
              //   "" +
              //   (
              //     (((errors.gender_id !== true || errors.gender_id === undefined) && ! (!formState.dirty && formState.submitCount  !== 0) && formState.submitCount > 0))
              //     ? "has-success"
              //     : "") +
              //   " " +
              //   (errors.gender_id ? "has-danger" : "")
              // }
            >
              <Input
                name="hiv_test_done"
                type="select"
                className="form-control-alternative"
                innerRef={register({ required: true, minLength: 1 })}

              >
                <option value="">--Select--</option>
                <option value="0">Yes</option>
                <option value="1">No</option>
           
              </Input>
              {/* {errors.gender_id ? (
                <a className="text-danger" style={{ fontSize: 8 }}>
                  Please Select Gender
                </a>
              ) : null} */}
            </FormGroup>
        </Col>
        <Col xs="5">
            <label className="form-control-label">Creatinine (eGFR)</label>
            <FormGroup>
              <Input
                className="form-control-alternative"
                type="text"
                name="creatine_results"

              />
       
            </FormGroup>
          </Col>
       
      </Row>
   <Row>
          <Col xs="5">
            <label className="form-control-label">Weight (KGs)</label>
            <FormGroup>
              <Input
                              innerRef={register({ required: true, minLength: 1 })}

                className="form-control-alternative"
                type="text"
                name="weight_results"
              />
       
            </FormGroup>
          </Col>
          <Col xs="5">
            <label className="form-control-label">Pregnancy Test Results</label>
            <FormGroup>
            <Input
                name="pregnancy_results"
                type="select"
                className="form-control-alternative"
                innerRef={register({ required: true, minLength: 1 })}

              >
                <option value="">--Select--</option>
                <option value="0">Positive</option>
                <option value="1">Negative</option>
                <option value="1">N/A</option>
              </Input>
            </FormGroup>
          </Col>
          </Row>
          <Row>
          <Col xs="5">
      <label className="form-control-label">STI Screening</label>
            <FormGroup>
            <Input
                name="sti_results"
                type="select"
                className="form-control-alternative"
                innerRef={register({ required: true, minLength: 1 })}

              >
                <option value="">--Select--</option>
                <option value="0">Positive</option>
                <option value="1">Negative</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs="5">
            <label className="form-control-label">Outcome</label>
            <FormGroup>
            <Input
                name="decide_to_initiate_results"
                type="select"
                className="form-control-alternative"
                innerRef={register({ required: true, minLength: 1 })}

              >
                <option value="">--Select--</option>
                <option  value="RIP">&nbsp; RIP</option>
                  <option  value="LTF">&nbsp; LTF</option>
                  <option  value="TFO">&nbsp; TFO</option>
                  <option  value="Sero">&nbsp; Sero</option>
                  <option value="DNA">&nbsp; DNA</option>
                  <option  value="Disc 1">&nbsp; Disc 1</option>
                  <option  value="Disc 2">&nbsp; Disc 2</option>
              </Input>
            </FormGroup>
          </Col>

          </Row>
          <Row>
          <Col xs="5">
            <label className="form-control-label">Month of Outcome</label>
            <FormGroup>
              <Input
                              innerRef={register({ required: true, minLength: 1 })}
                              placeholder="MM/YY"
                className="form-control-alternative"
                type="text"
                name="weight_results"
              />
       
            </FormGroup>
          </Col>
            </Row>

              <Row>
              <h2 className="display-4">Notes and Comments</h2>

              </Row>
              <Row>
              <Col xs="6">
      <div className="ct-example bg-white">
      <label className="form-control-label">Comments: </label>
      <ReactQuill
          value={commentMultiLine}
          onChange={(v)=>handleMultiLineChange(v,'comments')}
          theme="snow"
          modules={{
            toolbar: [
              ["bold", "italic"],
              ["link", "blockquote", "code", "image"],
              [
                {
                  list: "ordered"
                },
                {
                  list: "bullet"
                }
              ]
            ]
          }}
        />  
         </div>
        </Col>
              </Row>
    
              <Row>
        <Col xs="4" style={{marginTop : "20px"}}>

<label className="form-control-label">Meds Dispense:</label>
    <FormGroup>
      <Input
        name="meds_dispense"
        type="select"
        className="form-control-alternative"
        innerRef={register({ required: true, minLength: 1 })}
        onChange={(v)=>{console.log(v.target.value);
          if(v.target.value === "0"){
            updateShowMedsDispense(true)
          }
            else{
              updateShowMedsDispense(false)
              var temp_min = moment(_init_date).add(1,"month")

              setMinDate(moment(temp_min).format("YYYY/MM/DD"));

            }
        }}

      >
        <option value="">--Select--</option>
        <option value="0">Yes</option>
        <option value="1">No</option>

      </Input>
      </FormGroup>
      </Col>

      </Row>
      <Row xs="12">

{showMedsDispense ?
        <Col xs="5" style={{marginTop : "20px"}}>
<label className="form-control-label">For:</label>
    <FormGroup>
    <Input type="select" name="months_dispensed"
            onChange={(v)=>{
              v.preventDefault();

              console.log(v.target.value);
              _setMonthCount(v.target.value)
              var temp_min = moment(_init_date).add(v.target.value,"month")
              console.log(temp_min);
              setMinDate(moment(temp_min).format("YYYY/MM/DD"));
            }}
     >
    <option value="">--Select--</option>
        <option value="2">2 Months</option>
        <option value="3">3 Months</option>
        <option value="4">4 Months</option>
        <option value="5">5 Months</option>
        <option value="6">6 Months</option>

      </Input>


      </FormGroup>
      </Col>
      : null}
      <Col xs="4" style={{marginTop : "20px"}}>
        <h3>Book Next Appointment:</h3>
      <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
            </InputGroupAddon>
            <ReactDatetime
                inputProps={{
                  placeholder: "Select Date",
                  value: followupDate,
                  // disabled: true
                  // innerRef: register({ required: true, minLength: 1 }),
                  name: "next_appointdate",
                  disabled: PatientMode 
                }}
              isValidDate={valid_followup} 
              timeFormat={false}
              closeOnSelect={true}
              onChange={(v)=>setFollowUpDate(v)}
            />
          </InputGroup>
        </FormGroup>
        </Col> 
        


      </Row>
      </>
     :null}
      <Row> 
        <Col xs="6"></Col>
        <Col xs="6" className="text-right">
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => {
                    props.history.goBack();
                  }}
                >
                  Cancel
                </Button>
                <Button color="info" type="submit"  onClick={handleSubmit(onSubmit)}>
       Submit
     </Button>
              </Col>
      </Row>




    
     
    
  
      </form>
 :
  null}
   </>
  </>
:
<Modal
              className="modal-dialog-centered modal-danger"
              contentClassName="bg-gradient-danger"
              isOpen={prepFormError}
              // toggle={() => this.toggleModal("notificationModal")}
            >
              <div className="modal-header">
                {/* <h6 className="modal-title" id="modal-title-notification">
                  Your attention is required
                </h6> */}
                {/* <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("notificationModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button> */}
              </div>
              <div className="modal-body">
                <div className="py-3 text-center">
                  <i className="ni ni-bell-55 ni-3x" />
                  <h4 className="heading mt-4">Error!</h4>
                  <p>
                   Please Complete <strong>Prep Check!</strong>
                  </p>
                  <p>on  The Prep List Page</p>
                </div>
              </div>
              <div className="modal-footer">
                <Button className="btn-white" color="default" type="button"
                                  onClick={() =>props.history.push("/prep")}>
                 Sure
                </Button>
                {/* <Button
                  className="text-white ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>this.props.}
                >
                  Close
                </Button> */}
              </div>
            </Modal>

                }
</>
);
}

export default withPatientState(
  withOutreachDD(withAuthState(withRouter(PrepMainForm)))
);
