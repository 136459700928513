import { createStandardAction } from 'typesafe-actions';
import { User } from 'firebase';

const FETCH_OUTREACH_DD = 'outreachDD/FETCH_OUTREACH_DD';
const SET_OUTREACH_DD = 'outreachDD/SET_OUTREACH_DD';
const SET_OUTREACH_DISTRICT_DD = 'outreachDD/SET_OUTREACH_DISTRICT_DD';

// interface IOutreachDD {
//   outreach_dd: {
//     district_dd: any,
//     all_dd: any
//   }
// }

export const fetchOutreachDD = createStandardAction(FETCH_OUTREACH_DD)();

export interface ISetOutreachDDPayload {

  all_dd: any;

}
export const setOutreachDD = createStandardAction(SET_OUTREACH_DD)<
ISetOutreachDDPayload
>();
export interface ISetOutreachDistrictDDPayload {
  district_dd: any;
}

export const setOutreachDistrictDD = createStandardAction(SET_OUTREACH_DISTRICT_DD)<
ISetOutreachDistrictDDPayload>();
