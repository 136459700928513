import React, { useState, useEffect } from "react";
import {
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    UncontrolledTooltip,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormText,
    Modal,
    CardTitle,
    Button,
} from "reactstrap";
import withPatientState, { PatientProps } from "../Enhanchers/withPatientState";
import AsyncSelect from "react-select/async";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import firebase from "../Services/firebase";
import { IPatient } from "../@types/patient";
import ReactDatetime from "react-datetime";
import moment, { Moment } from "moment";
import { RouteComponentProps, withRouter } from "react-router-dom";
import withPrepState, { PrepProps } from "../Enhanchers/withPrepState";
import withAppointmentState, {
    AppointmentProps,
} from "../Enhanchers/withAppointmentState";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
// import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { DateRange, DateRangePicker } from "react-date-range";

interface IProps {
    isOpen: boolean;
    list: any;
    togglefunct: any;
}

type Props = IProps &
    PatientProps &
    AuthProps &
    RouteComponentProps &
    PrepProps &
    AppointmentProps;

function ExportManager(props: Props) {
    const classes = useStyles();

    const [left, setLeft] = React.useState<string[]>([]);
    const [filterRange, _setFilterRange] = useState(false);
    const [range, setRange] = React.useState([
        {
            startDate: new Date(),
            endDate: null,
            key: "selection",
        },
    ]);

    const [status_, setStatus] = React.useState("");

    if (props.list.length > 0 && left.length === 0) {
        console.log("called");
        var item = props.list[0];
        var list: string[] = [];
        Object.keys(item).forEach((item) => {
            list.push(item);
        });
        setLeft(list);
    }
    const [checked, setChecked] = React.useState<string[]>([]);
    const [right, setRight] = React.useState<string[]>([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };
    const handleSelect = (ranges: any) => {
        console.log(ranges);

        var start_ofweek = moment(ranges.selection.startDate);
        var end_ofweek = moment(ranges.selection.endDate);
        //  this.filterDash(start_ofweek, end_ofweek,this.state.default_filter);
        setRange([ranges.selection]);
    };

    const _setStatus = (status: any) => {
        setStatus(status.target.value);
    };

    const exportCSV = () => {
        console.log("exporting csv");
        console.log(props.list);
        let csvContent = "data:text/csv;charset=utf-8,";
        var pat_k: any[] = [];
        var start = moment(range[0].startDate);
        var end = moment(range[0].endDate);
        // console.log(start,end);
        if (props.list.length > 0) {
            var csv_data: any[];
            if (filterRange) {
                // pat_k = this.state.selected;
                csv_data = [];
                // props.list = list.filter((item) => item !== "record_id");
                // props.list.unshift("record_id");
                csv_data = props.list.filter((i: any) => {
                    // console.log(i);
                    var _idate = moment(i.date_of_capture_id);
                    if (status_.length !== 0) {
                        return (
                            i.client_id_complete == status_ &&
                            _idate.isSameOrAfter(start) &&
                            _idate.isSameOrBefore(end)
                        );
                    } else {
                        return (
                            _idate.isSameOrAfter(start) &&
                            _idate.isSameOrBefore(end)
                        );
                    }
                });
            } else {
                csv_data = props.list;
            }

            // console.log(csv_data);
            if (csv_data.length === 0) {
                alert("no records to export for filtered criteria");
            } else {
                var data: any, filename: any, link: any;
                var csv = convertArrayOfObjectsToCSV({
                    data: csv_data,
                });
                if (csv == null) return;

                filename = "export.csv";

                if (!csv.match(/^data:text\/csv/i)) {
                    csv = "data:text/csv;charset=utf-8," + csv;
                }
                data = encodeURI(csv);

                link = document.createElement("a");
                link.setAttribute("href", data);
                link.setAttribute("download", filename);
                link.click();
                props.togglefunct(false);
            }
        }
    };

    const convertArrayOfObjectsToCSV = (args: any) => {
        var result: any,
            ctr: any,
            keys: any,
            columnDelimiter: any,
            lineDelimiter: any,
            data: any;

        data = args.data || null;
        if (data == null || !data.length) {
            return null;
        }

        columnDelimiter = args.columnDelimiter || ",";
        lineDelimiter = args.lineDelimiter || "\n";
        keys = Object.keys(data[0]);
        var temp = keys[0];
        keys.map((key: string, index: number) => {
            if (key === "record_id") {
                keys[0] = keys[index];
                keys[index] = temp;
            }
        });

        // keys[0] = keys[14];
        // keys[14] = temp;

        result = "";
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach((item: any) => {
            ctr = 0;
            keys.forEach((key: any) => {
                if (ctr > 0) result += columnDelimiter;
                console.log(JSON.stringify(item[key]));
                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    const customList = (items: string[]) => (
        <Paper className={classes.paper}>
            <List dense component='div' role='list'>
                {items.map((value: string) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role='listitem'
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    return (
        <Modal
            className='modal-dialog-centered '
            // size="xs"
            // contentClassName="bg-gradient-info"
            isOpen={props.isOpen}
            toggle={() => props.togglefunct(false)}
        >
            <div className='modal-header text-center'>
                <h1 className='modal-title'>Export Manager</h1>
                <button
                    aria-label='Close'
                    className='close'
                    data-dismiss='modal'
                    type='button'
                    onClick={() => props.togglefunct(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className='modal-body'>
                <Col xs='4'>
                    <label className='form-control-label'>
                        Select Export Type:
                    </label>
                    <FormGroup>
                        <Input
                            type='select'
                            className='form-control-alternative'
                            onChange={(v) => {
                                v.preventDefault();
                                if (v.target.value === "2") {
                                    _setFilterRange(true);
                                } else {
                                    _setFilterRange(false);
                                }
                            }}
                        >
                            <option value='1'>All</option>
                            <option value='2'>Filter Range</option>
                        </Input>
                    </FormGroup>
                </Col>
                {filterRange ? (
                    <>
                        <Col xs='12' style={{ marginTop: "20px" }}>
                            <label
                                className='form-control-label'
                                style={{ marginRight: 66 }}
                            >
                                Select Date Range:
                            </label>
                            <DateRange
                                editableDateInputs={true}
                                onChange={(item: any) => {
                                    handleSelect(item);
                                }}
                                moveRangeOnFirstSelection={true}
                                ranges={range}
                            />
                        </Col>
                        <Col xs='10' style={{ marginTop: "20px" }}>
                            <label className='form-control-label'>
                                Form Status:
                            </label>
                            <FormGroup>
                                <Input
                                    type='select'
                                    name='app_status'
                                    value={status_}
                                    onChange={(v) => _setStatus(v)}
                                >
                                    <option value=''>ALL</option>
                                    <option value='2'>Completed</option>
                                    <option value='1'>InComplete</option>
                                    <option value='0'>Review</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </>
                ) : null}
                <Col xs='12' className='text-center modal-footer'>
                    <Button
                        outline
                        color='primary'
                        type='button'
                        onClick={() => props.togglefunct(false)}
                    >
                        Done
                    </Button>
                    <Button
                        className='btn-primary-alternative pull-right'
                        type='button'
                        onClick={() => exportCSV()}
                    >
                        Create CSV Export
                    </Button>
                </Col>

                {/* <h2>Please Select Fields to export</h2> 
  <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid> */}
            </div>
        </Modal>
    );
}

function not(a: string[], b: string[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: string[], b: string[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: "auto",
        },
        paper: {
            width: 200,
            height: 230,
            overflow: "auto",
        },
        button: {
            margin: theme.spacing(0.5, 0),
        },
    })
);

export default withAppointmentState(
    withPrepState(withRouter(withAuthState(withPatientState(ExportManager))))
);
