import { combineReducers } from 'redux';
import { action, ActionType, getType } from 'typesafe-actions';

import * as actions from './actions';

export interface IAuthState {
  readonly user: any;
  readonly active_state: string;
  readonly loading_msg: string;
}

export type AuthActions = ActionType<typeof actions>;

const initialState: IAuthState = {
  user: {},
  active_state: "loading",
  loading_msg: "none"
};

export default combineReducers<IAuthState, AuthActions>({
  user: (state = initialState.user, action) => {
    switch (action.type) {
      case getType(actions.setUser): {
        return action.payload.user;
      }
      default: {
        return state;
      }
    }
  },
  active_state: (state = initialState.active_state,action) =>{
    switch (action.type){
      case getType(actions.setActiveState):{
        return action.payload.active_state;
      }
      default:{
        return state;
      }
    }
  },
  loading_msg: (state = initialState.loading_msg, action) =>{
    switch (action.type){
      case getType(actions.setLoadingMsgState):{
        return action.payload.loading_msg;
      }
      default:{
        return state;
      }
    }
  }

});
