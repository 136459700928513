import React, { useState, Fragment } from 'react'
import {
  createStyles,
  withStyles,
  WithStyles,
  ClickAwayListener,
  Toolbar,
  AppBar,
  fade,
  MenuItem,
  CssBaseline,
  Badge,
  Grid,
  Theme,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Divider,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import logo from '../Assets/IMG/logo-prepi.png'
import AccountCircle from '@material-ui/icons/AccountCircle'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Menu from '@material-ui/core/Menu'
import moment from 'moment'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import MailIcon from '@material-ui/icons/Mail'
import TopNavbarMain from '../Components/TopNavbarMain'
import SideDrawer from '../Components/SideDrawer'
import withAuthState from '../Enhanchers/withAuthState'
import SideBar from '../Components/SideBar'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface IProps {
  // title: string;
  children: React.ReactNode
  containerClasses?: string
  // subtitle?: string;
  // noMargin?: boolean;
  // contentPadding?: string | number;
  // noShadow?: boolean;
  // right?: React.ReactNode;
  // noHeader?: boolean;
  // scroll?: boolean;
}
type Props = WithStyles<typeof styles> & RouteComponentProps<any> & IProps
class MainLayout extends React.Component<Props> {
  // componentDidUpdate(e:any) {
  // document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   this.refs.mainContent.scrollTop = 0;
  // }

  render() {
    const notify = () => toast('Wow so easy !')

    return (
      <>
        {/* Same as */}
        <ToastContainer />
        <TopNavbarMain />
        <div
          className='row'
          id='header-container'
          style={{ marginLeft: 0, marginRight: 0 }}
        >
          <SideBar
            // {...this.props}
            logo={{
              innerLink: '/admin/index',
              imgSrc: require('../Assets/IMG_main/logo-prepi.png'),
              imgAlt: '...',
            }}
          />
          {/* <div className="main-content" ref="mainContent" style={{marginLeft: 90}}> */}
          <div
            style={{ marginTop: 70 }}
            className={
              this.props.containerClasses
                ? this.props.containerClasses
                : 'col ml-0'
            }
          >
            {this.props.children}
          </div>
        </div>
      </>
    )
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      // backgroundColor: 'red',
      marginLeft: 80,
      marginTop: 10,
    },
  })
export default withRouter(withStyles(styles)(MainLayout))
