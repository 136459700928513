
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ISetPrepPayload,  setPrep} from '../Store/modules/prep/actions';
import { RootState } from '../@types/store';
import { IPrepState as IPrepReduxState } from '../Store/modules/prep/reducer';


const mapStateToProps = (state: RootState) => ({
  prep_records: state.prep_records,

});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setPrep,
      
    },
    dispatch
  );

interface IPrepState {
  prep_records: IPrepReduxState;
}

export interface IPrepDispatchProps {
  setPrep: (payload: ISetPrepPayload) => void;
}

export type PrepProps = IPrepState & IPrepDispatchProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
