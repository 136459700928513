import React, { useState, Fragment } from "react";
import {
    createStyles,
    withStyles,
    WithStyles,
    ClickAwayListener,
    Toolbar,
    AppBar,
    fade,
    MenuItem,
    CssBaseline,
    Badge,
    Grid,
    Theme,
    Drawer,
    ListItem,
    ListItemIcon,
    ListItemText,
    List,
    Divider,
    Icon,
    Button,
} from "@material-ui/core";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
// import { loadCSS } from 'fg-loadcss';

// import home from '@material-ui/icons/Dashboard';
// import prep from '@material-ui/icons/AssignmentOutlined';
import outreach from "@material-ui/icons/AssignmentReturnOutlined";
import patient from "@material-ui/icons/AssignmentInd";
// import prepform_img from '../Assets/Navbar/nav-prep-form.png';
// import outreach_img from '../Assets/Navbar/nav-outreach-form.png';
import Home from "@material-ui/icons/Home";
import dashboard_img from "../Assets/Navbar/nav-dashboard-active.png";
import patient_img from "../Assets/Navbar/nav-patients.png";
import appointments_img from "../Assets/Navbar/nav-appointments.png";
import report_img from "../Assets/Navbar/nav-reports.png";
import notifications_img from "../Assets/Navbar/nav-notifications.png";
import prepform_img from "../Assets/Navbar/nav-prep-form.png";
// import outreach_img from '../Assets/Navbar/nav-outreach-form.png';
// import Home from '@material-ui/icons/Home';

import {
    Card,
    CardBody,
    CardTitle,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
} from "reactstrap";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import { getRace } from "../constants";
interface IProps {
    patientInfo?: any;
    intakeInfo?: any;
}
interface IState {
    sexAtBirth: boolean;
}

type Props = WithStyles<typeof styles> & IProps & RouteComponentProps;

class PatientInfoTab extends React.Component<Props, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            sexAtBirth: false,
        };
    }

    getPopID = (intake_key: number) => {
        if (intake_key === 1) {
            return "Client";
        } else if (intake_key === 2) {
            return "Community";
        } else if (intake_key === 3) {
            return "FSW";
        } else if (intake_key === 4) {
            return "MSM";
        } else if (intake_key === 7) {
            return "MSM-SW";
        } else if (intake_key === 10) {
            return "TGM";
        } else if (intake_key === 11) {
            return "TGM-SW";
        } else if (intake_key === 12) {
            return "TGNC";
        } else if (intake_key === 13) {
            return "TGNC-SW";
        } else if (intake_key === 8) {
            return "TGW";
        } else if (intake_key === 9) {
            return "TGW-SW ";
        } else {
            return "None Specified";
        }
    };

    render() {
        const key_pop: any = {
            "": "",
            "1": "Client",
            "2": "Community",
            "3": "FSW",
            "4": "MSM",
            "5": "Transgender",
            "7": "MSM-SW",
            "10": "TGM",
            "11": "TGM-SW",
            "12": "TGNC",
            "13": "TGNC-SW",
            "8": "TGW",
            "9": "TGW-SW",
        };
        // this.props.children.
        if (this.props.patientInfo) {
            return (
                <CardBody>
                    <Form className='readonly'>
                        <Row>
                            {/* <h1 className="display-4">Personal</h1> */}
                        </Row>
                        <div className='pl-lg-4'>
                            <Row>
                                <Col xs='3'>
                                    <FormGroup>
                                        <label className='form-control-label'>
                                            Key Population Group
                                        </label>
                                        {/* <Input
            className="form-control-alternative"
            defaultValue={this.props.patientInfo.key_population_id}
            id="input-username"
            placeholder="Username"
            type="text"
          /> */}
                                        <p>
                                            {
                                                key_pop[
                                                    this.props.patientInfo
                                                        .key_population_id
                                                ]
                                            }
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col xs='3'>
                                    <FormGroup>
                                        <label className='form-control-label'>
                                            Date of Birth
                                        </label>
                                        <p>
                                            {
                                                this.props.patientInfo
                                                    .birthdate_id
                                            }
                                        </p>

                                        {/* <Input
            className="form-control-alternative"
            id="input-email"
            // placeholder={this.props.patientInfo.}
            type="email"
          /> */}
                                    </FormGroup>
                                </Col>
                                <Col xs='3'>
                                    <FormGroup>
                                        <label className='form-control-label'>
                                            Race
                                        </label>
                                        <p>
                                            {getRace(
                                                this.props.patientInfo.race_id
                                            )}
                                        </p>

                                        {/* <Input
            className="form-control-alternative"
            id="input-email"
            // placeholder={this.props.patientInfo.}
            type="email"
          /> */}
                                    </FormGroup>
                                </Col>
                                <Col xs='3'>
                                    <FormGroup>
                                        <label className='form-control-label'>
                                            Clinic Folder Number
                                        </label>
                                        <p>
                                            {
                                                this.props.patientInfo
                                                    .clinic_folder_number_id
                                            }
                                        </p>

                                        {/* <Input
            className="form-control-alternative"
            id="input-email"
            // placeholder={this.props.patientInfo.}
            type="email"
          /> */}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs='3'>
                                    <FormGroup>
                                        <label className='form-control-label'>
                                            Patient ID
                                        </label>
                                        {/* <Input
            className="form-control-alternative"
            defaultValue={this.props.patientInfo.key_population_id}
            id="input-username"
            placeholder="Username"
            type="text"
          /> */}
                                        <p>
                                            {this.props.patientInfo.record_id}
                                        </p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {Object.keys(this.props.intakeInfo).length !== 0 ? (
                                <>
                                    <h1 className='display-4 text-muted'>
                                        Intake
                                    </h1>
                                    <Row>
                                        <Col xs='3'>
                                            <FormGroup>
                                                <label className='form-control-label'>
                                                    Intake Date
                                                </label>
                                                {/* <Input
            className="form-control-alternative"
            defaultValue={this.props.patientInfo.key_population_id}
            id="input-username"
            placeholder="Username"
            type="text"
          /> */}
                                                <p>
                                                    {
                                                        this.props.intakeInfo.date_of_capture_intake.split(
                                                            " "
                                                        )[0]
                                                    }
                                                </p>
                                            </FormGroup>
                                        </Col>
                                        <Col xs='3'>
                                            <FormGroup>
                                                <label className='form-control-label'>
                                                    Intake Date
                                                </label>
                                                {/* <Input
            className="form-control-alternative"
            defaultValue={this.props.patientInfo.key_population_id}
            id="input-username"
            placeholder="Username"
            type="text"
          /> */}
                                                <p>
                                                    {
                                                        this.props.intakeInfo
                                                            .kp_programme_site_intake
                                                    }
                                                </p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </>
                            ) : null}
                        </div>
                        <hr className='my-4' />

                        {Object.keys(this.props.intakeInfo).length !== 0 ? (
                            <>
                                <h1 className='display-4'>SOCIODEMOGRAPHIC</h1>

                                <div className='pl-lg-4'>
                                    <Row>
                                        <Col xs='3'>
                                            <FormGroup>
                                                <label className='form-control-label'>
                                                    Key Population Group
                                                </label>
                                                {/* <Input
          className="form-control-alternative"
          defaultValue={this.props.patientInfo.key_population_id}
          id="input-username"
          placeholder="Username"
          type="text"
        /> */}
                                                <p>
                                                    {
                                                        this.props.patientInfo
                                                            .key_population_id
                                                    }
                                                </p>
                                            </FormGroup>
                                        </Col>

                                        <Col xs='3'>
                                            <FormGroup>
                                                <label className='form-control-label'>
                                                    Highest Level Of Education
                                                </label>
                                                {/* <Input
          className="form-control-alternative"
          defaultValue={this.props.patientInfo.key_population_id}
          id="input-username"
          placeholder="Username"
          type="text"
        /> */}
                                                <p>
                                                    {
                                                        this.props.intakeInfo
                                                            .highest_education_intake
                                                    }
                                                </p>
                                            </FormGroup>
                                        </Col>
                                        <Col xs='3'>
                                            <FormGroup>
                                                <label className='form-control-label'>
                                                    Relationship Status
                                                </label>
                                                {/* <Input
          className="form-control-alternative"
          defaultValue={this.props.patientInfo.key_population_id}
          id="input-username"
          placeholder="Username"
          type="text"
        /> */}
                                                <p>
                                                    {
                                                        this.props.intakeInfo
                                                            .relationship_status_intake
                                                    }
                                                </p>
                                            </FormGroup>
                                        </Col>
                                        <Col xs='3'>
                                            <FormGroup>
                                                <label className='form-control-label'>
                                                    Pregnancies
                                                </label>
                                                {/* <Input
          className="form-control-alternative"
          defaultValue={this.props.patientInfo.key_population_id}
          id="input-username"
          placeholder="Username"
          type="text"
        /> */}
                                                <p>
                                                    {
                                                        this.props.intakeInfo
                                                            .total_number_of_pregnancie
                                                    }
                                                </p>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs='3'>
                                            <FormGroup>
                                                <label className='form-control-label'>
                                                    {"Children <18"}
                                                </label>
                                                {/* <Input
          className="form-control-alternative"
          defaultValue={this.props.patientInfo.key_population_id}
          id="input-username"
          placeholder="Username"
          type="text"
        /> */}
                                                <p>
                                                    {
                                                        this.props.intakeInfo
                                                            .children_18
                                                    }
                                                </p>
                                            </FormGroup>
                                        </Col>
                                        <Col xs='3'>
                                            <FormGroup>
                                                <label className='form-control-label'>
                                                    Adults Dependants
                                                </label>
                                                {/* <Input
          className="form-control-alternative"
          defaultValue={this.props.patientInfo.key_population_id}
          id="input-username"
          placeholder="Username"
          type="text"
        /> */}
                                                <p>
                                                    {
                                                        this.props.intakeInfo
                                                            .adults
                                                    }
                                                </p>
                                            </FormGroup>
                                        </Col>
                                        <Col xs='3'>
                                            <FormGroup>
                                                <label className='form-control-label'>
                                                    Child Dependents
                                                </label>
                                                {/* <Input
          className="form-control-alternative"
          defaultValue={this.props.patientInfo.key_population_id}
          id="input-username"
          placeholder="Username"
          type="text"
        /> */}
                                                <p>
                                                    {
                                                        this.props.intakeInfo
                                                            .children_18
                                                    }
                                                </p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        ) : null}
                    </Form>
                </CardBody>
            );
        } else {
            return null;
        }
    }
}
const styles = (theme: Theme) =>
    createStyles({
        drawer: {
            width: 90,
            flexShrink: 0,
        },
        icon_state: {
            active: "#fff",
            non_active: "#143840",
        },

        drawerPaper: {
            width: 90,
            marginTop: 50,
        },
        drawerContainer: {
            overflow: "auto",
            // marginTop: 40
        },
    });
export default withRouter(withStyles(styles)(PatientInfoTab));
