import React from 'react'

import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'

import { INITIAL_EVENTS, createEventId } from '../utils/event-utils'

// react component used to create sweet alerts
import ReactBSAlert from 'react-bootstrap-sweetalert'
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Modal,
  Row,
  Container,
  Col,
  CardTitle,
} from 'reactstrap'

import {
  Button as MButton,
  createStyles,
  Theme,
  WithStyles,
} from '@material-ui/core'
import MainLayout from '../Layouts/MainLayout'
import moment from 'moment'
import CalendarMain from './CalendarMain'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import CountBoxNav from '../Components/CountBoxNav'
import TimeLine from '../Components/TimeLine'
import firebase from '../Services/firebase'

interface IState {
  activities: any[]
}

type Props = RouteComponentProps & WithStyles<typeof styles>

// const calendarRef

class ActivityLog extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      activities: [],
    }
  }

  componentDidMount() {
    var temp_activity: any[] = []
    var subscription = firebase.db
      .collection('/activity_log')
      .orderBy('date_and_time')
      .get()
      .then((data) => {
        data.forEach((actv: any) => {
          temp_activity.push({
            ...actv.data(),
            id: actv.id,
          })
        })

        this.setState({
          activities: temp_activity.reverse(),
        })
      })
  }

  render() {
    // console.log(this.state.activities);

    return (
      <MainLayout>
        <div className='header  pb-8 pt-5 pt-md-8' style={{ width: '100%' }}>
          <Container fluid>
            <div className='header-body'>
              {/* Card stats */}
              <Row>
                <h1>Activity Log</h1>
              </Row>
            </div>
          </Container>
        </div>
        <Container className='mt--7' fluid>
          <Card className='shadow'>
            <CardHeader className='bg-transparent border-0'></CardHeader>
            <CardBody>
              {/* <Time */}
              <TimeLine activities={this.state.activities} />
            </CardBody>
          </Card>
        </Container>
      </MainLayout>
    )
  }
}
const styles = createStyles((theme: Theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}))

export default withRouter(ActivityLog)
