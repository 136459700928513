import React from "react";

import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

import { INITIAL_EVENTS, createEventId } from "../utils/event-utils";

// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Modal,
    Row,
    Container,
    Col,
} from "reactstrap";

import { Button as MButton } from "@material-ui/core";
import MainLayout from "../Layouts/MainLayout";
import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router-dom";
import PrepTable from "../Components/PrepTable";
import PrepTableMain from "../Components/PrepTableMain";
import withPrepState, { PrepProps } from "../Enhanchers/withPrepState";
import PrepManagerModal from "../Components/PrepManagerModal";

// // let calendar: Calendar;
// interface IState{
//     events?: IAppointment[]
//     alert?: any;
//     currentDate: any;
//     weekendsVisible?: boolean;
//     currentEvents?:any;
// }

export interface IAppointment {
    title: string;
    start: any;
    end: any;
    className?: any;
    id: any;
    eventTitle?: string;
    startDate?: any;
    endDate?: any;
}

interface IState {
    prepCheck: boolean;
}

type Props = RouteComponentProps & PrepProps;

// const calendarRef

class Prep extends React.Component<Props, IState> {
    state = {
        prepCheck: false,
    };

    constructor(props: any) {
        super(props);
        console.log(this.props.prep_records.prep_records);
    }

    render() {
        return (
            <MainLayout>
                {/* Table */}

                <section
                    style={{
                        width: "95%",
                        marginLeft: 30,
                        paddingBottom: 20,
                    }}
                >
                    {/* {this.props.prep_records.prep_records.length > 0 ? ( */}
                    <PrepTableMain
                        list={this.props.prep_records.prep_records}
                    />
                    {/* ) : null} */}
                </section>
            </MainLayout>
        );
    }
}

export default withPrepState(withRouter(Prep));
