
import * as React from 'react';
import firebase from './firebase';
import withAuthState, { AuthProps } from '../Enhanchers/withAuthState';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import withOutreachState, { OutreachProps } from '../Enhanchers/withOutreachState';
var _ = require('underscore');




type Props = OutreachProps & RouteComponentProps & AuthProps;

class OutreachListener extends React.Component<Props>{
  unsubscribes: Array<() => void> = [];

  unsubscribe = () => {
      this.unsubscribes.forEach(unsubscribe => unsubscribe());
      this.unsubscribes = [];
  };


  componentDidMount(){
    var temp_list:any[] =[];


         var ref = firebase.db.collection('main/'+this.props.auth.user.data_access_group+"/outreach_and_groupactivity")
        .orderBy('activity_date_outreach');
   // console.log(this.props );
        //  if(this.props.outreach.outreach.length === 0){
          const sub = ref.onSnapshot( snapshot =>{
            var temp_list:any[] =[];
             snapshot.docs.map( outreach_rec =>{
                temp_list.push({
                  ...outreach_rec.data(),
                outreach_indiv_id :outreach_rec.id
                })
            })
      
      //      setting all outreach records
            if( temp_list.length !== this.props.outreach.outreach_all.length ){
this.props.setOutreachAll({outreach_all: temp_list});
          }
          });


          // Getting grouped records

          var ref = firebase.db.collection('main/'+this.props.auth.user.data_access_group+"/outreach_grouped")
          .orderBy('activity_date_outreach');


                const sub2 = ref.onSnapshot( snapshot =>{
                  var temp_list:any[] =[];
                   snapshot.docs.map( outreach_rec =>{
                      temp_list.push({
                        ...outreach_rec.data(),
                      outreach_indiv_id :outreach_rec.id
                      })
                  })



                      this.props.setOutreach({outreach: temp_list})
                  console.log(temp_list);
                });
            


          this.unsubscribes.push(sub);
        // }
    

  }
  componentWillUnmount() {
    this.unsubscribe();
}

  render(){
    return null;
  }
}


export default withOutreachState(withAuthState(withRouter(OutreachListener)));