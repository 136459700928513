import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import * as actions from './actions';

export interface IOutreachState {
  readonly outreach: any;
  readonly outreach_all: any;
}

export type  OutreachActions = ActionType<typeof actions>;

const initialState: IOutreachState = {
  outreach: [],
  outreach_all: []
};

export default combineReducers<IOutreachState, OutreachActions>({
  outreach: (state = initialState.outreach, action) => {
    switch (action.type) {
      case getType(actions.setOutreach): {
        return action.payload.outreach;
      }
      default: {
        return state;
      }}
    },
      outreach_all:(state = initialState.outreach_all, action) => {
        switch (action.type) {
          case getType(actions.setOutreachAll): {
            return action.payload.outreach_all;
          }
          default: {
            return state;
          }
        }
    }

});
