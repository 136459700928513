import React, { useState } from "react";
import {
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    UncontrolledTooltip,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    FormText,
    Modal,
    CardTitle,
    Table,
} from "reactstrap";
import AsyncSelect from "react-select/async";

import ReactDatetime from "react-datetime";
import { RouteComponentProps, withRouter } from "react-router-dom";
import moment, { Moment } from "moment";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import firebase from "../Services/firebase";
import ReactQuill from "react-quill";

interface IProps {
    isOpen: boolean;
    togglefunct: any;
    errors: any;
    followDate: any;
    final_object: any;
    deactivateFollowUp: any;
    currentMonitor: any;
    monitorList: any;
    setMonitor: any;
    followUpType?: string; //"edit/ create"
    followUpIndex?: any; // if edit should provide the index of the monitor that gets edited......
    save_follow_up_complete?: any;
}

type Props = IProps & RouteComponentProps & AuthProps;

function PrepFollowUpManager(props: Props) {
    console.log(props);
    const [stage, setStage] = useState<number>(0);
    const [prepReference, setPrepReference] = useState("");
    // const [monitorResults, setMonitorResults ] = useState<any>({});
    // const db = firebase.db
    const today = moment().format("YYYY/MM/DD");
    const [clinicVal, setClinicVal] = useState(props.final_object.prep_clinic);

    const [monthCount, _setMonthCount] = useState("");
    const [minDate, setMinDate] = useState(props.followDate);

    const [pastStatus, setPastStatus] = useState("");
    const _setClinicVal = (e: any) => {
        e.preventDefault();
        var value = e.target.value;
        console.log(e.target);
        setClinicVal(value);
    };
    const [followup, setFollowup] = useState(props.followDate);
    var valid = function (current: Moment) {
        var correct_follow_schedule_time_rule = moment(
            props.final_object.initiation_date
        ).add("1", "month");
        return moment(current).isAfter(minDate);
    };
    const [showMedsDispense, updateShowMedsDispense] = useState(false);

    const _setFollowup = (date: Moment | string) => {
        // console.log(date)
        var new_date = moment(date).format("YYYY/MM/DD");
        setFollowup(new_date);
        return;
    };

    const [commentMultiLine, setCommentMultiLine] = useState("");

    const handleMultiLineChange = (value: any, multi_: string) => {
        // console.log(value)
        if (multi_ === "comments") {
            setCommentMultiLine(value);
        }
    };

    const patientStatus = (status: number) => {
        if (status === 0) {
            return (
                <>
                    <span className='text-info mr-1'>●</span>
                    <small>Patient Record Review</small>
                </>
            );
        } else if (status === 1) {
            return (
                <>
                    <span className='text-danger mr-1'>●</span>
                    <small>Incompleted Record</small>
                </>
            );
        } else if (status === 2) {
            return (
                <>
                    <span className='text-success mr-1'>●</span>
                    <small>Completed Record</small>
                </>
            );
        }
    };

    const discontinue_prep = (s: string) => {
        console.log(props.final_object, commentMultiLine, props.currentMonitor);

        var save_detail = s;
        var prep_ref = firebase.db.collection(
            "main/" + props.auth.user.data_access_group + "/prep_list"
        );
        var batch = firebase.db.batch();
        var appointment_ref = firebase.db.collection(
            "main/" + props.auth.user.data_access_group + "/appointments"
        );

        var new_monitoringList = props.final_object.monitor_history;
        var cop_mon = props.currentMonitor;

        new_monitoringList.push({
            ...cop_mon,
            next_visit_date: moment(followup).format("YYYY/MM/DD"),
            meds_dispense: false,
            actual_visit_date: moment(today).format("YYYY/MM/DD"),
            actual_captured_date: today,
            month_outcome_monitor: moment(followup)
                .subtract(1, "month")
                .format("MM/YY"),
        });

        if (save_detail === "review") {
            var temp_record = {
                ...props.final_object,
                monitor_history: new_monitoringList,
                follow_up_date: followup,
                prep_status: cop_mon.outcome_monitor,
                form_complete: props.auth.user.role_access === 1 ? 2 : 0,
                last_capture_id: props.auth.user.uuid,
                last_date_of_capture_id: moment().format("YYYY/MM/DD HH:mm:ss"),
            };

            prep_ref
                .doc(temp_record.final_object.patient_info.patient_uid)
                .update(temp_record)
                .then((_d: any) => {
                    setStage(3);
                })
                .catch((err: any) => console.log(err));
        } else if (save_detail === "approved") {
            // Create Appointments
            // Save Prep Record
            // Set in Redcap Sync Mode

            setStage(2);
            var temp_record = {
                ...props.final_object,
                monitor_history: new_monitoringList,
                follow_up_date: followup,
                prep_status: cop_mon.outcome_monitor,
                follow_up_status: new_monitoringList.length,
                form_complete: props.auth.user.role_access === 1 ? 2 : 0,
                last_capture_id: props.auth.user.uuid,
                last_date_of_capture_id: moment().format("YYYY/MM/DD HH:mm:ss"),

                // _review_reason:
            };

            batch.update(
                prep_ref.doc(temp_record.patient_info.patient_uid),
                temp_record
            );
            batch.delete(
                appointment_ref.doc(temp_record.patient_info.patient_uid)
            );
            batch
                .commit()
                .then((d_) => {
                    console.log(d_);

                    var activity_ = {
                        activity: "prep_initiation_followup_discontinue",
                        capturer_id: props.auth.user.uuid,
                        capturer_name: props.auth.user.name,
                        date_and_time: moment().format("YYYY/MM/DD HH:mm:ss"),
                        form_status: props.auth.user.role_access === 1 ? 2 : 0,
                        prep_uid: props.final_object.patient_info.patient_uid,
                        _record: props.final_object,
                    };
                    console.log(activity_);
                    var activit_ref = firebase.db.collection("activity_log");
                    activit_ref
                        .add(activity_)
                        .then((data__) => {
                            setStage(3);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((err: any) => {
                    console.log(err);
                });
        }
    };

    const createApp_Prep = (s: string) => {
        //get all appointments for patient

        // ==================

        var save_detail = s;
        var prep_ref = firebase.db.collection(
            "main/" + props.auth.user.data_access_group + "/prep_list"
        );
        var batch = firebase.db.batch();
        var appointment_ref = firebase.db.collection(
            "main/" + props.auth.user.data_access_group + "/appointments"
        );

        var new_monitoringList = Array.isArray(props.final_object.monitor_history) ? props.final_object.monitor_history : [props.final_object.monitor_history] ;
        var prep_t = props.final_object;
        var cop_mon = props.currentMonitor;
        if (monthCount.length > 0 && showMedsDispense) {
            var mc = parseInt(monthCount);
            console.log(
                0,
                new_monitoringList.length - 1,
                new_monitoringList[new_monitoringList.length - 1],
                new_monitoringList[new_monitoringList.length - 1]
                    .next_visit_date
            );

            var obj = {
                ...cop_mon,
                next_visit_date: moment(
                    new_monitoringList[new_monitoringList.length - 1]
                        .next_visit_date
                )
                    .add(1, "month")
                    .format("YYYY/MM/DD"),
                meds_dispense: false,
                actual_visit_date: moment(today).format("YYYY/MM/DD"),
                actual_captured_date: today,
                month_outcome_monitor: moment(
                    new_monitoringList[new_monitoringList.length - 1]
                        .next_visit_date
                )
                    .add(1, "month")
                    .format("MM/YY"),
            };
            new_monitoringList.push(obj);

            for (var i = 1; i < mc; i++) {
                console.log(
                    i,
                    new_monitoringList.length - 1,
                    new_monitoringList[new_monitoringList.length - 1],
                    new_monitoringList[new_monitoringList.length - 1]
                        .next_visit_date
                );
                // var obj = {
                //     ...cop_mon,
                //     next_visit_date: moment(new_monitoringList[new_monitoringList.length-1].next_visit_date).add(i,"month").format("YYYY/MM/DD"),
                //     meds_dispense: true,
                //     actual_captured_date: today,
                //     actual_visit_date: moment(today).format("YYYY/MM/DD"),
                //     month_outcome_monitor:moment(new_monitoringList[new_monitoringList.length-1].next_visit_date).add(i,"month").format('MM/YY'),

                // }
                new_monitoringList.push({
                    ...cop_mon,
                    next_visit_date: moment(
                        new_monitoringList[new_monitoringList.length - 1]
                            .next_visit_date
                    )
                        .add(1, "month")
                        .format("YYYY/MM/DD"),
                    meds_dispense: true,
                    actual_captured_date: today,
                    actual_visit_date: moment(today).format("YYYY/MM/DD"),
                    month_outcome_monitor: moment(
                        new_monitoringList[new_monitoringList.length - 1]
                            .next_visit_date
                    )
                        .add(1, "month")
                        .format("MM/YY"),
                });
            }
        } else {
            console.log(monthCount);
            if(new_monitoringList && Array.isArray(new_monitoringList)){
                new_monitoringList.push({
                    ...cop_mon,
                    next_visit_date: moment(followup).format("YYYY/MM/DD"),
                    meds_dispense: false,
                    actual_visit_date: moment(today).format("YYYY/MM/DD"),
                    actual_captured_date: today,
                    month_outcome_monitor: moment(followup)
                        .subtract(1, "month")
                        .format("MM/YY"),
                });
                // new_monitoringList.push(obj);
            }
        }

        console.log(new_monitoringList);

        if (save_detail === "review") {
            setStage(2);

            //Not creating prep appointments
            // updating form status for prep record
            console.log(props.final_object);
            var temp_record = {
                ...props.final_object,
                monitor_history: new_monitoringList,
                follow_up_date: followup,
                prep_status: cop_mon.outcome_monitor,
                form_complete: props.auth.user.role_access === 1 ? 2 : 0,
                last_capture_id: props.auth.user.uuid,
                last_date_of_capture_id: moment().format("YYYY/MM/DD HH:mm:ss"),
                // _review_reason:
            };
            setPrepReference(temp_record.patient_info.patient_uid);

            prep_ref
                .doc(temp_record.patient_info.patient_uid)
                .update(temp_record)
                .then((_d: any) => {
                    // console.log(_d.id);
                    // if(_d.id){
                    setStage(3);

                    //     setPrepReference(_d.id);
                    // }
                })
                .catch((err: any) => console.log(err));
        } else if (save_detail === "approved") {
            // Create Appointments
            // Save Prep Record
            // Set in Redcap Sync Mode

            setStage(2);
            var appointment = {
                title:
                    "Prep Follow up " +
                    new_monitoringList.length +
                    " : " +
                    props.final_object.patient_info.first_name_prep +
                    " " +
                    props.final_object.patient_info.surname_prep,
                start: moment(followup).format("YYYY/MM/DD"),
                end: moment(followup).format("YYYY/MM/DD"),
                allDay: true,
                className: "bg-info",
                status: pastStatus.length > 0 ? pastStatus : "upcoming",
                clinic: clinicVal,
                patient_uid: props.final_object.patient_info.patient_uid,
                type: "follow_up_" + new_monitoringList.length,
                prep_stage: new_monitoringList.length,
                prep_uid: prep_t.patient_info.patient_uid,
            };
            console.log(prep_t);
            var temp_record = {
                ...prep_t,
                monitor_history: prep_t,
                follow_up_date: followup,
                prep_status: cop_mon.outcome_monitor,
                follow_up_status: new_monitoringList && Array.isArray(new_monitoringList) ? new_monitoringList.length : " ",
                form_complete: props.auth.user.role_access === 1 ? 2 : 0,
                last_capture_id: props.auth.user.uuid,
                last_date_of_capture_id: moment().format("YYYY/MM/DD HH:mm:ss"),

                // _review_reason:
            };
            batch.update(
                prep_ref.doc(temp_record.patient_info.patient_uid),
                temp_record
            );
            batch.set(
                appointment_ref.doc(temp_record.patient_info.patient_uid),
                appointment
            );
            batch
                .commit()
                .then((d_) => {
                    console.log(d_);

                    var activity_ = {
                        activity:
                            "prep_initiation_followup" +
                            new_monitoringList.length,
                        capturer_id: props.auth.user.uuid,
                        capturer_name: props.auth.user.name,
                        date_and_time: moment().format("YYYY/MM/DD HH:mm:ss"),
                        form_status: props.auth.user.role_access === 1 ? 2 : 0,
                        prep_uid: temp_record.patient_info.patient_uid,
                        _record: props.final_object,
                    };
                    console.log(activity_);
                    var activit_ref = firebase.db.collection("activity_log");
                    activit_ref
                        .add(activity_)
                        .then((data__) => {
                            setStage(3);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((err: any) => {
                    console.log(err);
                });
        }
    };

    return (
        <Modal
            className='modal-dialog-centered '
            // size="xs"
            // contentClassName="bg-gradient-info"
            isOpen={props.isOpen}
            toggle={() => props.togglefunct(false)}
        >
            {/* Stage 1 */}
            {stage === 0 ? (
                <>
                    <div className='modal-header text-center'>
                        <h1 className='modal-title'>Step 1: Error Handling</h1>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => props.togglefunct(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className='modal-body'>
                        <div className='py-3 text-left'>
                            <>
                                <Row>
                                    <p>
                                        {" "}
                                        Please Ensure that The following
                                        Sections Is Completed:
                                    </p>
                                </Row>
                                <Row xs='6'>
                                    <Col xs='2'>
                                        {Object.keys(
                                            props.errors.patient_errors
                                        ).length === 0 &&
                                        !props.errors.patientValid ? (
                                            <i className='text-success fa fa-check-square fa-lg'></i>
                                        ) : (
                                            <i className='text-danger fa fa-square fa-lg'></i>
                                        )}
                                    </Col>
                                    <Col xs='4'>
                                        <p>Patient Errors</p>
                                    </Col>
                                </Row>

                                <Row xs='6'>
                                    <Col xs='2'>
                                        {Object.keys(
                                            props.errors.monitor_errors
                                        ).length === 0 &&
                                        props.errors.monitor_valid ? (
                                            <i className='text-success fa fa-check-square fa-lg'></i>
                                        ) : (
                                            <i className='text-danger fa fa-square fa-lg'></i>
                                        )}
                                    </Col>
                                    <Col xs='4'>
                                        <p>Monitor Errors</p>
                                    </Col>
                                </Row>
                                {/* {Object.keys(props.currentMonitor).length  */}
                                <Row xs='6'>
                                    {/* <Col xs="2">{props.currentMonitor.outcome_monitor === '1' && props.currentMonitor.hiv_test_results_monitor === '0' && props.currentMonitor.staying_on_prep === '1'  ?<i className="text-success fa fa-check-square fa-lg"></i>: <i className="text-danger fa fa-square fa-lg" ></i>}</Col> */}
                                    <Col xs='2'>
                                        {props.currentMonitor
                                            .outcome_monitor !== null &&
                                        props.currentMonitor
                                            .hiv_test_results_monitor !==
                                            null &&
                                        props.currentMonitor.staying_on_prep !==
                                            null ? (
                                            <i className='text-success fa fa-check-square fa-lg'></i>
                                        ) : (
                                            <i className='text-danger fa fa-square fa-lg'></i>
                                        )}
                                    </Col>
                                    <Col xs='4'>
                                        <p>Outcome Error!</p>
                                    </Col>
                                </Row>
                                {Object.keys(props.errors.monitor_errors)
                                    .length === 0 &&
                                props.errors.monitor_valid &&
                                Object.keys(props.errors.patient_errors)
                                    .length === 0 ? (
                                    <Row>
                                        <Col xs='6'></Col>
                                        <Col xs='4' className='text-right'>
                                            <Button
                                                color='info'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    // createPrep()
                                                    setStage(1);
                                                }}
                                            >
                                                Next Step
                                            </Button>
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row>
                                        <Col xs='6'>
                                            <Button
                                                color='danger'
                                                outline
                                                disabled={
                                                    props.currentMonitor
                                                        .outcome_monitor === "1"
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    // createPrep()
                                                    // discontinue page
                                                    setCommentMultiLine(
                                                        "<blockquote>Reason For DISCONTINUATION: </blockquote>"
                                                    );
                                                    setStage(4);
                                                }}
                                            >
                                                Discontinue Record
                                            </Button>
                                        </Col>
                                        <Col xs='4' className='text-right'>
                                            <Button
                                                color='danger'
                                                type='button'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    props.togglefunct(false);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        </div>
                    </div>
                </>
            ) : null}

            {stage === 1 ? (
                <>
                    <div className='modal-header text-center'>
                        <h1 className='modal-title'>
                            Step 2: Complete Follow-up
                        </h1>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => props.togglefunct(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className='modal-body'>
                        <div className='align-items-center row'>
                            <Col xs='12 align-items-center text-center'>
                                <a
                                    className='avatar avatar-xs rounded-circle bg-info'
                                    href='#'
                                >
                                    <i className='fa fa-user text-white'></i>
                                </a>
                                {/* </Col> */}

                                <Col xs='12 text-'>
                                    <h4 className='mb-0'>
                                        <a href='#'>
                                            {
                                                props.final_object.patient_info
                                                    .first_name_prep
                                            }
                                        </a>
                                    </h4>
                                    {patientStatus(
                                        props.final_object.patient_info
                                            .patient_form_complete
                                    )}
                                    <a>
                                        {
                                            props.final_object.patient_info
                                                .record_id
                                        }
                                    </a>
                                </Col>
                            </Col>

                            <Col xs='12' style={{ marginTop: "20px" }}>
                                <label className='form-control-label'>
                                    Meds Dispense:
                                </label>
                                <FormGroup>
                                    <Input
                                        name='meds_dispense'
                                        type='select'
                                        defaultValue={"1"}
                                        className='form-control-alternative'
                                        onChange={(v) => {
                                            console.log(v.target.value);
                                            if (v.target.value === "0") {
                                                updateShowMedsDispense(true);
                                            } else {
                                                updateShowMedsDispense(false);
                                                var temp_min = moment(
                                                    props.followDate
                                                );

                                                setMinDate(
                                                    moment(temp_min).format(
                                                        "YYYY/MM/DD"
                                                    )
                                                );
                                                setFollowup(
                                                    moment(temp_min).format(
                                                        "YYYY/MM/DD"
                                                    )
                                                );
                                            }
                                        }}
                                    >
                                        <option value='0'>Yes</option>
                                        <option value='1'>No</option>
                                    </Input>
                                </FormGroup>
                            </Col>

                            {showMedsDispense ? (
                                <>
                                    <Col xs='12' style={{ marginTop: "20px" }}>
                                        <label className='form-control-label'>
                                            For:
                                        </label>
                                        <FormGroup>
                                            <Input
                                                type='select'
                                                name='months_dispensed'
                                                onChange={(v) => {
                                                    v.preventDefault();

                                                    console.log(v.target.value);
                                                    _setMonthCount(
                                                        v.target.value
                                                    );
                                                    var temp_min = moment(
                                                        props.followDate
                                                    ).add(
                                                        v.target.value,
                                                        "month"
                                                    );
                                                    console.log(temp_min);
                                                    setMinDate(
                                                        moment(temp_min).format(
                                                            "YYYY/MM/DD"
                                                        )
                                                    );
                                                    setFollowup(
                                                        moment(temp_min).format(
                                                            "YYYY/MM/DD"
                                                        )
                                                    );
                                                }}
                                            >
                                                <option value=''>
                                                    --Select--
                                                </option>
                                                <option value='2'>
                                                    2 Months
                                                </option>
                                                <option value='3'>
                                                    3 Months
                                                </option>
                                                <option value='4'>
                                                    4 Months
                                                </option>
                                                <option value='5'>
                                                    5 Months
                                                </option>
                                                <option value='6'>
                                                    6 Months
                                                </option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col xs='12'>
                                        <label className='form-control-label'>
                                            Date of Visit:
                                        </label>
                                        <FormGroup>
                                            <InputGroup className='input-group-alternative'>
                                                <InputGroupAddon addonType='prepend'>
                                                    <InputGroupText>
                                                        <i className='ni ni-calendar-grid-58' />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <ReactDatetime
                                                    inputProps={{
                                                        placeholder:
                                                            "Select Date",
                                                        value: followup,
                                                        name: "date",
                                                    }}
                                                    isValidDate={valid}
                                                    timeFormat={false}
                                                    closeOnSelect={true}
                                                    onChange={(v) =>
                                                        _setFollowup(v)
                                                    }
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </>
                            ) : (
                                <Col xs='12'>
                                    <label className='form-control-label'>
                                        Date of Visit:
                                    </label>
                                    <FormGroup>
                                        <InputGroup className='input-group-alternative'>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <i className='ni ni-calendar-grid-58' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <ReactDatetime
                                                inputProps={{
                                                    placeholder: "Select Date",
                                                    value: followup,
                                                    name: "date",
                                                }}
                                                isValidDate={valid}
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                onChange={(v) =>
                                                    _setFollowup(v)
                                                }
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            )}

                            {/* <Col xs="12">
    <label className="form-control-label">Clinic:</label>
    <FormGroup>
      <Input
        name="clinic"
        type="select"
        value={props.final_object.prep_clinic}
        className="form-control-alternative"
        onChange={_setClinicVal}
        disabled={clinicVal !== ""}
      >
        <option value="">Select</option>
        <option value="0">Pretoria Park Clinic</option>
        <option value="1">Sallisburg Clinic</option>
      </Input>
    </FormGroup>
  </Col> */}

                            {moment(followup).isBefore(today) ? (
                                <Col xs='12'>
                                    <label className='form-control-label'>
                                        Appointment Status for Past Follow-up:
                                    </label>
                                    <FormGroup>
                                        <Input
                                            type='select'
                                            name='app_status'
                                            value={pastStatus}
                                            onChange={(v) => {
                                                v.preventDefault();
                                                setPastStatus(v.target.value);
                                            }}
                                        >
                                            <option value='attended'>
                                                Attended
                                            </option>
                                            <option value='missed'>
                                                Missed
                                            </option>
                                            {/* <option value='upcoming'>Upcoming</option> */}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            ) : null}

                            <Col xs='12'>
                                {props.final_object.patient_info.record_id ===
                                "null" ? (
                                    <>
                                        <a className='text-red'>
                                            {" "}
                                            ** Patient Requires Redcap Patient
                                            ID!
                                        </a>
                                    </>
                                ) : null}

                                {props.auth.user.role_access === 0 ? (
                                    <>
                                        <a className='text-red'>
                                            {" "}
                                            ** Requires Data Capturers Approval
                                        </a>
                                    </>
                                ) : null}
                            </Col>

                            <Col xs='12'>
                                {props.final_object.patient_info.record_id !==
                                    "null" &&
                                props.auth.user.role_access === 1 ? (
                                    <Button
                                        type='button'
                                        color='success'
                                        onClick={() =>
                                            createApp_Prep("approved")
                                        }
                                        disabled={
                                            clinicVal.length === 0 
                                            // ||
                                            // followup.length === 0
                                        }
                                    >
                                        Schedule Now
                                    </Button>
                                ) : (
                                    <Button
                                        type='button'
                                        color='success'
                                        onClick={() => createApp_Prep("review")}
                                        disabled={followup.length === 0}
                                    >
                                        Save Review Record
                                    </Button>
                                )}
                            </Col>
                            {/* <Col>
      <Button type="button" color="primary" onClick={()=>setPatientMode(true)}>
        Change Patient
      </Button>
    </Col> */}
                        </div>
                    </div>
                </>
            ) : null}

            {/* Stage 3 */}
            {stage === 2 ? (
                <>
                    <div className='modal-header text-center'>
                        <h3 className='display-4 text-warning'> Saving ... </h3>
                    </div>
                </>
            ) : null}
            {/* // stage 4 */}
            {stage === 3 ? (
                <>
                    <div className='modal-header text-center'>
                        <h3 className='display-4 text-success'>Done!</h3>
                    </div>
                    <Col xs='12'>
                        <Button
                            type='button'
                            color='success'
                            onClick={(e) => {
                                e.preventDefault();
                                props.togglefunct(false);
                                props.save_follow_up_complete(false);
                            }}
                        >
                            Done
                        </Button>
                    </Col>
                </>
            ) : null}

            {/* discontinue */}
            {stage === 4 ? (
                <>
                    <div className='modal-header text-center'>
                        <h4 className='display-3'>
                            Specify Reason For Discontinuation
                        </h4>

                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => props.togglefunct(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <div className='py-3 text-left'>
                            <Table>
                                <thead>
                                    {/* <tr>
                <td colSpan={11} className='wrapping text-center'>
                      <h4 className="display-3">Specify Reason For discontinuation</h4>
                </td>
              </tr> */}
                                    <tr>
                                        <th
                                            colSpan={11}
                                            className='wrapping text-left'
                                        >
                                            Notes: Reason for discontinuation /
                                            adherence support / allergies /
                                            medical history / hospitalisations /
                                            TB history. *Please state reason for
                                            discontinuation in detail (client's
                                            choice / change in <br /> risk
                                            profile / adverse effects, etc.)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={11}>
                                            <ReactQuill
                                                value={commentMultiLine}
                                                onChange={(v) =>
                                                    handleMultiLineChange(
                                                        v,
                                                        "comments"
                                                    )
                                                }
                                                theme='snow'
                                                modules={{
                                                    toolbar: [
                                                        ["bold", "italic"],
                                                        [
                                                            "link",
                                                            "blockquote",
                                                            "code",
                                                            "image",
                                                        ],
                                                        [
                                                            {
                                                                list: "ordered",
                                                            },
                                                            {
                                                                list: "bullet",
                                                            },
                                                        ],
                                                    ],
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <Row className='ml-4 mb-4'>
                        <Col xs='6'>
                            {props.final_object.patient_info.record_id !==
                                "null" && props.auth.user.role_access === 1 ? (
                                <Button
                                    color='success'
                                    outline
                                    onClick={(e) => {
                                        e.preventDefault();
                                        // createPrep()
                                        // discontinue page
                                        discontinue_prep("approved");
                                    }}
                                >
                                    Save Discontinued Record
                                </Button>
                            ) : (
                                <Button
                                    color='success'
                                    outline
                                    onClick={(e) => {
                                        e.preventDefault();
                                        // createPrep()
                                        // discontinue page
                                        discontinue_prep("review");
                                    }}
                                >
                                    Save Review: Discontinued Record
                                </Button>
                            )}
                        </Col>
                        <Col xs='4' className='text-right'>
                            <Button
                                color='danger'
                                type='button'
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.togglefunct(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </>
            ) : null}
        </Modal>
    );
}

export default withAuthState(withRouter(PrepFollowUpManager));
