import React, { useState } from "react";
import {
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    UncontrolledTooltip,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    FormText,
    Modal,
    CardTitle,
} from "reactstrap";
import AsyncSelect from "react-select/async";

import ReactDatetime from "react-datetime";
import moment, { Moment } from "moment";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createParenthesizedType } from "typescript";
import firebase from "../Services/firebase";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import { generate_id, syncDataToRedcap } from "../Services/API";
import {
    getGender,
    getTransferClinic,
    getHIVTestDone,
    getHIVTestResult,
    getPrepDecideToInitiateResults,
    getPrepPregnancyTestResults,
    getPrepSTIScreening,
} from "../constants";

interface IProps {
    isOpen: boolean;
    togglefunct: any;
    errors: any;
    final_object: any;
    transferClinic: any;
}

type Props = IProps & AuthProps & RouteComponentProps;

function PrepCreateManager(props: Props) {
    console.log(props);
    const [stage, setStage] = useState<number>(0);
    const [prepReference, setPrepReference] = useState("");
    // const db = firebase.db
    const today = moment();
    const [clinicVal, setClinicVal] = useState(props.transferClinic);
    console.log(props.transferClinic);
    const [pastStatus, setPastStatus] = useState("");
    const _setClinicVal = (e: any) => {
        e.preventDefault();
        var value = e.target.value;
        console.log(e.target);
        setClinicVal(value);
    };
    const [followup, setFollowup] = useState(props.final_object.follow_up_date);
    var valid = function (current: Moment) {
        var correct_follow_schedule_time_rule = moment(
            props.final_object.initiation_date
        ).add("1", "month");
        return moment(current).isAfter(correct_follow_schedule_time_rule);
    };

    const _setFollowup = (date: Moment | string) => {
        // console.log(date)
        var new_date = moment(date).format("YYYY/MM/DD");
        setFollowup(new_date);
        return;
    };

    const patientStatus = (status: number) => {
        if (status === 0) {
            return (
                <>
                    <span className='text-info mr-1'>●</span>
                    <small>Patient Record Review</small>
                </>
            );
        } else if (status === 1) {
            return (
                <>
                    <span className='text-danger mr-1'>●</span>
                    <small>Incompleted Record</small>
                </>
            );
        } else if (status === 2) {
            return (
                <>
                    <span className='text-success mr-1'>●</span>
                    <small>Completed Record</small>
                </>
            );
        }
    };

    const createPrep = () => {
        console.log(props.final_object);
        // var temp_record = {
        //     ...props.final_object,
        //     prep_clinic: clinicVal,
        //     form_complete: 1,
        // };
        // var prep_ref = firebase.db.collection(
        //     "main/" + props.auth.user.data_access_group + "/prep_list"
        // );
        // setPrepReference(temp_record.patient_info.patient_uid);

        // prep_ref
        //     .doc(temp_record.patient_info.patient_uid)
        //     .set(temp_record)
        //     .then((_d: any) => {})
        //     .catch((err: any) => console.log(err));
    };
    const createApp_Prep = (s: string) => {
        //get all appointments for patient

        // ==================

        var save_detail = s;
        var prep_ref = firebase.db.collection(
            "main/" + props.auth.user.data_access_group + "/prep_list"
        );
        var batch = firebase.db.batch();
        var appointment_ref = firebase.db.collection(
            "main/" + props.auth.user.data_access_group + "/appointments"
        );

        if (save_detail === "review") {
            setStage(2);

            //Not creating prep appointments
            // updating form status for prep record
            console.log(props.final_object);
            var temp_record = {
                ...props.final_object,
                form_complete: 0,
                prep_clinic: clinicVal,
                // _review_reason:
            };
            console.log(clinicVal);
            console.log(temp_record);
            setPrepReference(
                temp_record?.final_object?.patient_info
                    ? temp_record.final_object.patient_info.patient_uid
                    : null
            );
            console.log(temp_record);

            prep_ref
                .doc(temp_record?.patient_info?.patient_uid)
                .set(temp_record)
                .then((_d: any) => {
                    // console.log(_d.id);
                    // if(_d.id){
                    setStage(3);

                    //     setPrepReference(_d.id);
                    // }
                })
                .catch((err: any) => console.log(err));
        } else if (save_detail === "approved") {
            // Create Appointments
            // Save Prep Record
            // Set in Redcap Sync Mode
            setStage(2);
            var appointment = {
                title:
                    "Prep Follow up " +
                    1 +
                    " : " +
                    props.final_object?.patient_info?.first_name_prep +
                    " " +
                    props.final_object?.patient_info?.surname_prep,
                start: moment(followup).format("YYYY-MM-DD"),
                end: moment(followup).format("YYYY-MM-DD"),
                allDay: true,
                className: "bg-info",
                status: pastStatus.length > 0 ? pastStatus : "upcoming",
                clinic: clinicVal,
                patient_uid: props.final_object?.patient_info?.patient_uid,
                type: "follow_up_1",
                prep_stage: 1,
                prep_uid: prepReference,
            };
            console.log(props.final_object);
            var temp_record = {
                ...props.final_object,
                form_complete: 2,
                prep_clinic: clinicVal,

                // _review_reason:
            };
            batch.set(
                prep_ref.doc(temp_record?.patient_info?.patient_uid),
                temp_record
            );
            batch.set(
                appointment_ref.doc(temp_record?.patient_info?.patient_uid),
                appointment
            );
            batch
                .commit()
                .then((d_) => {
                    console.log(d_);
                    console.log(temp_record);

                    // SYNC TO REDCAP
                    const dataToSync = temp_record;
                    dataToSync.prep_complete = dataToSync.form_complete;
                    delete dataToSync.form_complete;

                    delete dataToSync.prep_status;
                    delete dataToSync.follow_up_status;
                    delete dataToSync.follow_up_date;
                    delete dataToSync.date_capture_id;
                    dataToSync.legal_name_prep =
                        dataToSync.patient_info.first_name_prep;
                    dataToSync.legal_surname_prep =
                        dataToSync.patient_info.surname_prep;
                    delete dataToSync.patient_info;
                    delete dataToSync.initiation_date;
                    delete dataToSync.initiation_status;

                    delete dataToSync.monitor_history;
                    delete dataToSync.comment;

                    // dataToSync.transfer_from_clinic_prep = getTransferClinic(
                    //     dataToSync.prep_clinic
                    // );
                    // dataToSync.specify_prep_clinic_hrt = dataToSync.prep_clinic;
                    delete dataToSync.prep_clinic;

                    dataToSync.date_of_capture_prep =
                        dataToSync.date_of_capture_id;
                    delete dataToSync.date_of_capture_id;

                    dataToSync.phone_number_prep =
                        props.final_object.patient_info.contact_number_prep;
                    dataToSync.patient_folder_number_prep =
                        props.final_object.patient_info.folder_number_prep;
                    dataToSync.date_of_birth_prep =
                        props.final_object.patient_info.birthdate_prep;
                    dataToSync.gender_prep = getGender(
                        props.final_object.patient_info.gender_prep
                    );
                    // dataToSync.hiv_test_done_prep = "Yes";
                    // dataToSync.hiv_test_result_prep = getHIVTestResult(
                    //     dataToSync.initiation_history[0].hiv_test_results
                    // );

                    // dataToSync.pregnancy_test_done_prep = getPrepPregnancyTestResults(
                    //     props.final_object.initiation_history[0]
                    //         .pregnancy_results
                    // );
                    // dataToSync.pregnancy_test_done_prep = "Yes";
                    // dataToSync.pregnancy_test_result_prep = getPrepPregnancyTestResults(
                    //     props.final_object.initiation_history[0]
                    //         .pregnancy_results
                    // );

                    generate_id().then((id: any) => {
                        dataToSync.record_id = id;
                        // dataToSync.redcap_event_name =
                        //     "prep_preinitiation_arm_1";
                        dataToSync.redcap_event_name = "prep_monitoring_arm_1";
                        dataToSync.redcap_repeat_instance = "1";
                        dataToSync.redcap_repeat_instrument = "prep";
                        delete dataToSync.initiation_history;

                        console.log(dataToSync);
                        syncDataToRedcap(dataToSync);
                    });
                    // SYNC TO REDCAP

                    var activity_ = {
                        activity: "prep_initiation_create",
                        capturer_id: props.auth.user.uuid,
                        capturer_name: props.auth.user.name,
                        date_and_time: moment().format("YYYY/MM/DD HH:mm:ss"),
                        form_status: props.auth.user.role_access === 1 ? 2 : 0,
                        prep_uid: prepReference,
                        _record: props.final_object,
                    };
                    console.log(activity_);
                    var activit_ref = firebase.db.collection("activity_log");
                    activit_ref
                        .add(activity_)
                        .then((data__) => {
                            setStage(3);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((err: any) => {
                    console.log(err);
                });
        }
    };

    return (
        <Modal
            className='modal-dialog-centered '
            // size="xs"
            // contentClassName="bg-gradient-info"
            isOpen={props.isOpen}
            toggle={() => props.togglefunct(false)}
        >
            {/* Stage 1 */}
            {stage === 0 ? (
                <>
                    <div className='modal-header text-center'>
                        <h1 className='modal-title'>Step 1: Error Handling</h1>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => props.togglefunct(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className='modal-body'>
                        <div className='py-3 text-left'>
                            <>
                                <Row>
                                    <p>
                                        {" "}
                                        Please Ensure that The following
                                        Sections Is Completed:
                                    </p>
                                </Row>
                                <Row xs='6'>
                                    <Col xs='2'>
                                        {Object.keys(
                                            props.errors.patient_errors
                                        ).length === 0 &&
                                        !props.errors.patientValid ? (
                                            <i className='text-success fa fa-check-square fa-lg'></i>
                                        ) : (
                                            <i className='text-danger fa fa-square fa-lg'></i>
                                        )}
                                    </Col>
                                    <Col xs='4'>
                                        <p>Patient Errors</p>
                                    </Col>
                                </Row>
                                <Row xs='6'>
                                    <Col xs='2'>
                                        {Object.keys(
                                            props.errors.initiation_errors
                                        ).length === 0 &&
                                        props.errors.initiation_valid ? (
                                            <i className='text-success fa fa-check-square fa-lg'></i>
                                        ) : (
                                            <i className='text-danger fa fa-square fa-lg'></i>
                                        )}
                                    </Col>
                                    <Col xs='4'>
                                        <p>Initiation History Errors</p>
                                    </Col>
                                </Row>
                                <Row xs='6'>
                                    <Col xs='2'>
                                        {Object.keys(
                                            props.errors.monitor_errors
                                        ).length === 0 &&
                                        props.errors.monitor_valid ? (
                                            <i className='text-success fa fa-check-square fa-lg'></i>
                                        ) : (
                                            <i className='text-danger fa fa-square fa-lg'></i>
                                        )}
                                    </Col>
                                    <Col xs='4'>
                                        <p>Monitor Errors</p>
                                    </Col>
                                </Row>
                                <Row xs='6'>
                                    <Col xs='2'>
                                        {clinicVal ? (
                                            <i className='text-success fa fa-check-square fa-lg'></i>
                                        ) : (
                                            <i className='text-danger fa fa-square fa-lg'></i>
                                        )}
                                    </Col>
                                    <Col xs='4'>
                                        <p>Clinic</p>
                                    </Col>
                                </Row>

                                {Object.keys(props.errors.monitor_errors)
                                    .length === 0 &&
                                !props.errors.patientValid &&
                                props.errors.initiation_valid &&
                                props.errors.monitor_valid &&
                                Object.keys(props.errors.initiation_errors)
                                    .length === 0 &&
                                Object.keys(props.errors.patient_errors)
                                    .length === 0 && clinicVal? (
                                    <Row>
                                        <Col xs='6'></Col>
                                        <Col xs='4' className='text-right'>
                                            <Button
                                                color='info'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    createPrep();
                                                    setStage(1);
                                                }}
                                            >
                                                Next Step
                                            </Button>
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row>
                                        <Col xs='6'></Col>
                                        <Col xs='4' className='text-right'>
                                            <Button
                                                color='danger'
                                                type='button'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    props.togglefunct(false);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        </div>
                    </div>
                </>
            ) : null}

            {/* Stage 2  */}
            {stage === 1 ? (
                <>
                    <div className='modal-header text-center'>
                        <h1 className='modal-title'>
                            Step 2: Prep Follow Up Appointment
                        </h1>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => props.togglefunct(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className='modal-body'>
                        <div className='align-items-center row'>
                            <Col xs='12 align-items-center text-center'>
                                <a
                                    className='avatar avatar-xs rounded-circle bg-info'
                                    href='#'
                                >
                                    <i className='fa fa-user text-white'></i>
                                </a>
                                {/* </Col> */}

                                <Col xs='12 text-'>
                                    <h4 className='mb-0'>
                                        <a href='#'>
                                            {
                                                props.final_object.patient_info
                                                    .first_name_prep
                                            }
                                        </a>
                                    </h4>
                                    {patientStatus(
                                        props.final_object.patient_info
                                            .patient_form_complete
                                    )}
                                    <a>
                                        {
                                            props.final_object.patient_info
                                                .record_id
                                        }
                                    </a>
                                </Col>
                            </Col>
                            <Col xs='12'>
                                <label className='form-control-label'>
                                    Next Visit Date:
                                </label>
                                <FormGroup>
                                    <InputGroup className='input-group-alternative'>
                                        <InputGroupAddon addonType='prepend'>
                                            <InputGroupText>
                                                <i className='ni ni-calendar-grid-58' />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <ReactDatetime
                                            inputProps={{
                                                placeholder: "Select Date",
                                                value: moment(followup).format(
                                                    "YYYY/MM/DD"
                                                ),
                                                //   disabled: props._meds_dispense.dispense,
                                                // innerRef: register({ required: true, minLength: 1 }),
                                                name: "date",
                                            }}
                                            isValidDate={valid}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={(v) => _setFollowup(v)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            {/* <Col xs='12'>
                                <label className='form-control-label'>
                                    Clinic:
                                </label>
                                <FormGroup>
                                    <Input
                                        name='clinic'
                                        type='select'
                                        value={clinicVal}
                                        className='form-control-alternative'
                                        onChange={_setClinicVal}
                                        disabled={clinicVal !== ""}
                                    >
                                        <option value=''>Select</option>
                                        <option value='0'>
                                            Pretoria Park Clinic
                                        </option>
                                        <option value='1'>
                                            Sallisburg Clinic
                                        </option>
                                    </Input>
                                </FormGroup>
                            </Col> */}
                            {moment(followup).isBefore(today) ? (
                                <Col xs='12'>
                                    <label className='form-control-label'>
                                        Appointment Status for Past Follow-up:
                                    </label>
                                    <FormGroup>
                                        <Input
                                            type='select'
                                            name='app_status'
                                            value={pastStatus}
                                            onChange={(v) => {
                                                v.preventDefault();
                                                setPastStatus(v.target.value);
                                            }}
                                        >
                                            <option value='attended'>
                                                Attended
                                            </option>
                                            <option value='missed'>
                                                Missed
                                            </option>
                                            {/* <option value='upcoming'>Upcoming</option> */}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            ) : null}

                            <Col xs='12'>
                                {props.final_object.patient_info.record_id ===
                                "null" ? (
                                    <>
                                        <a className='text-red'>
                                            {" "}
                                            ** Patient Requires Redcap Patient
                                            ID!
                                        </a>
                                    </>
                                ) : null}

                                {props.auth.user.role_access === 0 ? (
                                    <>
                                        <a className='text-red'>
                                            {" "}
                                            ** Requires Data Capturers Approval
                                        </a>
                                    </>
                                ) : null}
                            </Col>

                            <Col xs='12'>
                                {props.final_object.patient_info.record_id !==
                                    "null" &&
                                props.auth.user.role_access === 1 ? (
                                    <Button
                                        type='button'
                                        color='success'
                                        onClick={() =>
                                            createApp_Prep("approved")
                                        }
                                        disabled={
                                            // clinicVal.length === 0 ||
                                            followup.length === 0
                                        }
                                    >
                                        Schedule Now
                                    </Button>
                                ) : (
                                    <Button
                                        type='button'
                                        color='success'
                                        onClick={() => createApp_Prep("review")}
                                        disabled={followup.length === 0}
                                    >
                                        Save Review Record
                                    </Button>
                                )}
                            </Col>
                            {/* <Col>
      <Button type="button" color="primary" onClick={()=>setPatientMode(true)}>
        Change Patient
      </Button>
    </Col> */}
                        </div>
                    </div>
                </>
            ) : null}
            {/* Stage 3 */}
            {stage === 2 ? (
                <>
                    <h3 className='display-4 text-warning'> Saving ... </h3>
                </>
            ) : null}
            {/* // stage 4 */}
            {stage === 3 ? (
                <>
                    <h3 className='display-4 text-success'>Done!</h3>
                    <Col xs='12'>
                        <Button
                            type='button'
                            color='success'
                            onClick={() => props.history.replace("/prep")}
                        >
                            Done
                        </Button>
                    </Col>
                </>
            ) : null}
            {stage === -1 ? (
                <>
                    <h3 className='display-4 text-warning'>Error!</h3>
                    <Col xs='12'>
                        <Button
                            type='button'
                            color='success'
                            onClick={() =>
                                props.history.replace(
                                    "/prep-view?ref=prep_id&id=" +
                                        props.final_object.patient_info
                                            .patient_uid
                                )
                            }
                        >
                            Try Again
                        </Button>
                    </Col>
                </>
            ) : null}
        </Modal>
    );
}

export default withRouter(withAuthState(PrepCreateManager));
