
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ISetAppointmentPayload,  setAppointment} from '../Store/modules/appointment/actions';
import { RootState } from '../@types/store';
import { IAppointmentState as IAppointmentReduxState } from '../Store/modules/appointment/reducer';


const mapStateToProps = (state: RootState) => ({
  appointment_records: state.appointment_records,

});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setAppointment,
      
    },
    dispatch
  );

interface IAppointmentState {
    appointment_records: IAppointmentReduxState;
}

export interface IAppointmentDispatchProps {
  setAppointment: (payload: ISetAppointmentPayload) => void;
}

export type AppointmentProps = IAppointmentState & IAppointmentDispatchProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
