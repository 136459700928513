import React, { useState } from "react";
import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormText,
  Modal,
  CardTitle,
  Table,
} from "reactstrap";
import AsyncSelect from "react-select/async";


import ReactDatetime from "react-datetime";
import { RouteComponentProps, withRouter } from "react-router-dom";
import moment, { Moment } from "moment";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import firebase from '../Services/firebase';
import ReactQuill from "react-quill";


interface IProps {
    isOpen: boolean;
    togglefunct: any;

}

type Props = IProps & RouteComponentProps & AuthProps;

function PrepInitiationManager(props: Props) {
 


  return(

    <Modal
    className="modal-dialog-centered bg-gradient-warning"
    // size="xs"
    // contentClassName="bg-gradient-info"
    isOpen={props.isOpen}
    toggle={() => props.togglefunct(false)}
   
  >

<div className="modal-header text-center">
<h4 className="display-3">Prep Initiation Manager</h4>

            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => props.togglefunct(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
          <div className="py-3 text-left">
    </div>
    </div>

      </Modal>
    )
}

export default withAuthState(withRouter(PrepInitiationManager))
