import React from "react";
import SidebarLeftImg from "../Assets/img/dashboard/right-sidebar-left-img.jpg";
import SidebarRightImg from "../Assets/img/dashboard/right-sidebar-right-img.jpg";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

import { INITIAL_EVENTS, createEventId } from "../utils/event-utils";

// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Modal,
    Row,
    Container,
    Col,
    CardTitle,
} from "reactstrap";

import { Button as MButton } from "@material-ui/core";
import MainLayout from "../Layouts/MainLayout";
import moment from "moment";
import CalendarMain from "./CalendarMain";
import { RouteComponentProps, withRouter } from "react-router-dom";
import AppointmentManagerModal from "../Forms/AppointmentManagerModal";
import { IPatient } from "../@types/patient";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import firebase from "../Services/firebase";
import TimeLine from "../Components/TimeLine";

import { DateRange, DateRangePicker } from "react-date-range";
import withPatientState, { PatientProps } from "../Enhanchers/withPatientState";
import withPrepState, { PrepProps } from "../Enhanchers/withPrepState";
import withAppointmentState, {
    AppointmentProps,
} from "../Enhanchers/withAppointmentState";

import view_arrow from "../Assets/img/icons/common/view-arrow.png";
import withOutreachState, {
    OutreachProps,
} from "../Enhanchers/withOutreachState";
import PrepManagerModal from "../Components/PrepManagerModal";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import { upperCase } from "lodash";
import { Interface } from "readline";
import { getDistrict } from "../constants";
import { TodaySharp } from "@material-ui/icons";

interface IState {
    activities: any[];
    start_ofweek?: any;
    end_ofweek?: any;
    default_filter: any;
    range: any;
    patient_create_count: number;
    outreach_create_count: number;
    prep_create_count: number;

    // All Recent Items
    patient_recent: any[];
    outreach_recent: any[];
    prep_recent: any[];
    appointment_recent: any[];
    prepCheck: boolean;
    selected_district: string;
    app_: any;
    app_show: boolean;
    weekAppointments: any[];
}

//Dashboard Connect

interface IProps {
    dashboard_switch: any;
    active_dashboard: any;
}

type Props = RouteComponentProps &
    AuthProps &
    PatientProps &
    PrepProps &
    AppointmentProps &
    OutreachProps &
    IProps;

// const calendarRef

class DashboardInfoPage extends React.Component<Props, IState> {
    temp: any = {};

    state = {
        activities: [],
        patient_create_count: 0,
        outreach_create_count: 0,
        prep_create_count: 0,
        default_filter: 0,
        range: [
            {
                startDate: new Date(),
                endDate: null,
                key: "selection",
            },
        ],
        patient_recent: [],
        outreach_recent: [],
        prep_recent: [],
        appointment_recent: [],
        prepCheck: false,
        selected_district: "",
        app_: this.temp,
        app_show: false,
        weekAppointments: [],
    };

    showApp = (app_d: any) => {
        console.log(app_d);
        this.setState({
            app_: app_d,
            app_show: true,
        });
    };

    componentDidMount() {
        var today = moment();

        if (this.props.auth.user.role_access === 1) {
            var start_ofweek = moment(today).subtract("1", "w").weekday(1);
            var end_ofweek = moment(today).subtract("1", "w").weekday(5);
            console.log("true");
            this.filterDash(
                start_ofweek,
                end_ofweek,
                this.props.auth.user.data_access_group
            );
            this.setState({
                selected_district: this.props.auth.user.data_access_group,
                range: [
                    {
                        startDate: new Date(start_ofweek.format("YYYY-MM-DD")),
                        endDate: new Date(end_ofweek.format("YYYY-MM-DD")),
                        key: "selection",
                    },
                ],
            });
        } else {
            var start_ofweek = moment(today).subtract("1", "w").weekday(1);
            var end_ofweek = moment(today);
            this.filterDash(start_ofweek, end_ofweek, "0");
            this.setState({
                range: [
                    {
                        startDate: new Date(start_ofweek.format("YYYY-MM-DD")),
                        endDate: new Date(end_ofweek.format("YYYY-MM-DD")),
                        key: "selection",
                    },
                ],
            });
        }
    }

    filterDash = (start: any, end: any, filter: any) => {
        var temp_activity: any[] = [];
        var start_ofweek: any, end_ofweek: any;
        var last_week = moment().subtract("1", "w");
        // start_ofweek = last_week.weekday(1);
        // end_ofweek =  last_week.weekday(5);
        var user = this.props.auth.user;
        start_ofweek = start;
        end_ofweek = end;

        var ref;
        if (filter === "0") {
            ref = firebase.db
                .collection("/activity_log")
                .orderBy("date_and_time")
                .where("capturer_id", "==", user.uuid)
                .get();
        } else {
            if (filter !== "1") {
                ref = firebase.db
                    .collection("/activity_log")
                    .orderBy("date_and_time")
                    .where("district", "==", filter)
                    .get();
            } else {
                ref = firebase.db
                    .collection("/activity_log")
                    .orderBy("date_and_time")
                    .where("district", "==", user.data_access_group)
                    .get();
            }
        }
        ref.then((data) => {
            var accept_flag = false;
            var time;
            var date_, _record_date;
            var temp_activity: any[] = [];
            var _pc = 0;
            var _oc = 0;
            var _oc_list: any[] = [];
            var _pc_list: any[] = [];
            var _ipc_list: any[] = [];
            var _ipc = 0;
            data.forEach((actv: any) => {
                time = actv.data().date_and_time
                    ? actv.data().date_and_time.split(" ")[1]
                    : "";
                date_ = actv.data().date_and_time
                    ? moment(actv.data().date_and_time.split(" ")[0])
                    : moment();
                _record_date = moment(date_);
                console.log(_record_date + " " + start + " " + end);
                console.log(
                    _record_date.isSameOrAfter(start) &&
                        _record_date.isSameOrBefore(end)
                );
                console.log(actv.data());
                let today = moment().format("YYYY/MM/DD HH:MM:SS");
                let newRecDate = actv.data().date_and_time;
                if (moment(newRecDate).isSame(today, "week")) {
                    console.log(
                        moment(today).diff(newRecDate, "days") +
                            " " +
                            today +
                            " " +
                            newRecDate
                    );
                    _pc += actv.data().activity === "patient_create" ? 1 : 0;
                    _oc += actv.data().activity === "outreach_create" ? 1 : 0;
                    _ipc +=
                        actv.data().activity === "prep_initiation_create"
                            ? 1
                            : 0;
                    // _ipc += actv.data().activity === "prep_initiation_create" ? 1: 0;

                    if (actv.data().activity === "outreach_create") {
                        _oc_list.push(actv.data().record_id);
                    }

                    if (actv.data().activity === "prep_initiation_create") {
                        _ipc_list.push(actv.data().record_id);
                    }

                    if (actv.data().activity === "patient_create") {
                        console.log(actv.data());
                        _pc_list.push(actv.data().record_id);
                    }

                    // console.log({
                    //   ...actv.data(),
                    //   id: actv.id,
                    //   _time: time,
                    //   _date:date_.format("YYYY/MM/DD")
                    // })
                    if (actv.data().activity.includes("create")) {
                        temp_activity.push({
                            ...actv.data(),
                            id: actv.id,
                            _time: time,
                            _date: date_.format("YYYY/MM/DD"),
                        });
                    }
                }
            });
            console.log(_oc_list);
            //creating list of recent patients
            var p_r = this.props.patients.patients.filter((i: any) => {
                console.log(i);
                // return _pc_list.includes(i.uid)
                let today = moment().format("YYYY/MM/DD HH:MM:SS");
                moment(i.date_of_capture_id).isSame(today, "week");
                return _pc_list;
            });

            var prep_r = this.props.prep_records.prep_records.filter(
                (i: any) => {
                    // console.log(i);
                    // return _ipc_list.includes(i.uid)
                    return _ipc_list;
                }
            );
            var outreach_r = this.props.outreach.outreach_all.filter(
                (i: any) => {
                    console.log(i);
                    // return _oc_list.includes(i.outreach_lwazi_id)
                    return _oc_list;
                }
            );
            console.log(outreach_r);
            console.log(
                this.props.appointment_records.appointment_records.length
            );
            if (
                this.props.appointment_records.appointment_records.length !== 0
            ) {
                var app_list = this.props.appointment_records.appointment_records.filter(
                    (i: any) => {
                        console.log(i);
                        var _record_date = moment(i.start);
                        if (_record_date === moment()) {
                            recents.push(i);
                        }

                        return _record_date.isSame(start);
                    }
                );
            }
            let recents: any = [];
            let weekAppointments: any = [];
            let today = moment().format("YYYY-MM-DD");
            if (
                this.props.appointment_records.appointment_records.length !== 0
            ) {
                var app_list = this.props.appointment_records.appointment_records.map(
                    (i: any) => {
                        console.log(i);
                        var _record_date = moment(i.start);
                        console.log(i.start + " " + today);

                        if (i.start === today) {
                            recents.push(i);
                        }
                        console.log(i.start + " " + today);
                        if (
                            moment(i.start).isSame(today, "week") &&
                            !Number.isNaN(moment(i.start).diff(today, "week"))
                        ) {
                            weekAppointments.push(i);
                            console.log(i);
                        }
                    }
                );
                console.log(recents);
                this.setState({
                    appointment_recent: recents,
                    weekAppointments: weekAppointments,
                });
                localStorage.setItem('appointment_recent',JSON.stringify(recents));
                localStorage.setItem('weekAppointments', JSON.stringify(weekAppointments));
            }

            console.log(p_r, prep_r, app_list);
            console.log({
                activities: temp_activity.reverse(),
                patient_create_count: _pc,
                outreach_create_count: _oc,
                prep_create_count: _ipc,
                outreach_recent: outreach_r,
            });

            this.setState({
                activities: temp_activity.reverse(),
                patient_create_count: _pc,
                outreach_create_count: _oc,
                prep_create_count: _ipc,
                default_filter: filter,
                patient_recent: p_r,
                outreach_recent: outreach_r,
                prep_recent: prep_r,
            });
            localStorage.setItem('activities',JSON.stringify(temp_activity.reverse()));
            localStorage.setItem('patient_create_count', JSON.stringify(_pc));
            localStorage.setItem('outreach_create_count', JSON.stringify(_oc));
            localStorage.setItem('prep_create_count', JSON.stringify(_ipc));
            localStorage.setItem('default_filter', JSON.stringify(filter));
            localStorage.setItem('patient_recent', JSON.stringify(p_r));
            localStorage.setItem('outreach_recent', JSON.stringify(outreach_r));
            localStorage.setItem('prep_recent', JSON.stringify(prep_r));
            
            return;
        });
    };

    updateFilter = (v: any) => {
        // this.setState({
        // })
        var start_ofweek = moment(this.state.range[0].startDate);
        var end_ofweek = moment(this.state.range[0].endDate);
        this.filterDash(start_ofweek, end_ofweek, v);
    };

    onRowSelect = (patient: any) => {
        //console.log(patient, index);

        if (patient.client_id_complete !== 0) {
            this.props.history.push({
                pathname: "/patient/" + patient.uid,
                state: patient,
            });
        } else {
            console.log("review");
        }
    };

    onEdit = (patient: any) => {
        //console.log(patient, index);

        if (this.props.auth.user.role_access !== 1) {
            this.props.history.push({
                pathname: "/patient-edit/" + patient.uid,
                state: patient,
            });
        } else {
            console.log("review");
            if (
                patient.client_id_complete === 0 ||
                patient.client_id_complete === 1
            ) {
                this.props.history.push({
                    pathname: "/patient-review/" + patient.uid,
                    state: patient,
                });
            } else {
                this.props.history.push({
                    pathname: "/patient-edit/" + patient.uid,
                    state: patient,
                });
            }
        }
    };

    getPatientStatus = (status: number) => {
        console.log(status);
        if (status === 2) {
            return (
                <>
                    <span className='badge badge-pill badge-complete'>
                        Complete
                    </span>
                </>
            );
        } else if (status === 1) {
            return (
                <>
                    <span className='badge badge-pill badge-incomplete'>
                        Incomplete
                    </span>
                </>
            );
        } else if (status === 0) {
            return (
                <>
                    <span className='badge badge-pill badge-review'>
                        Review
                    </span>
                </>
            );
        } else {
            return (
                <>
                    <span className='badge badge-pill badge-amended'>
                        Amended
                    </span>
                </>
            );
        }
    };

    getPatientAction = (row: any) => {
        if (row.client_id_complete === 2 || row.client_id_complete === 3) {
            // if(this.props.auth.user.role_access === 0)}{
            this.onRowSelect(row);
            // }
        } else {
            this.onEdit(row);
        }
    };

    get_app_status = (status: any) => {
        if (status === "attended") {
            return (
                <>
                    <span className='badge badge-pill badge-complete'>
                        Attended
                    </span>
                </>
            );
        } else if (status === "missed") {
            return (
                <>
                    <span className='badge badge-pill badge-incomplete'>
                        Missed
                    </span>
                </>
            );
        } else if (status === "upcoming") {
            return (
                <>
                    <span className='badge badge-pill badge-review'>
                        Upcoming
                    </span>
                </>
            );
        }
    };

    get_app_action = (action: any) => {
        return (
            // <div style={{marginLeft: -25}}>
            <Button
                className='btn-primary'
                type='button'
                onClick={() => this.showApp(action)}
            >
                View
            </Button>
            // </div>
        );
    };

    handleSelect(ranges: any) {
        console.log(ranges);

        if (this.props.auth.user.role_access === 1) {
            var start_ofweek = moment(ranges.selection.startDate);
            var end_ofweek = moment(ranges.selection.endDate);
            this.filterDash(
                start_ofweek,
                end_ofweek,
                this.state.default_filter
            );
            this.setState({
                range: [ranges.selection],
            });
        } else {
            var today = moment();
            var start_ofweek = moment(today).subtract("1", "w").weekday(1);
            var end_ofweek = moment(today);
            this.filterDash(start_ofweek, end_ofweek, "0");
            this.setState({
                range: [
                    {
                        startDate: new Date(start_ofweek.format("YYYY-MM-DD")),
                        endDate: new Date(end_ofweek.format("YYYY-MM-DD")),
                        key: "selection",
                    },
                ],
            });
            this.filterDash(
                start_ofweek,
                end_ofweek,
                this.state.default_filter
            );
            this.setState({
                range: [ranges.selection],
            });
        }
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
    }

    render() {
        return (
            <>
                <Container
                    fluid
                    style={{
                        marginTop: -32,
                    }}
                >
                    <Row xs='12'>
                        {/* Middle Section */}

                        <Col xs='9'>
                            <Col className='mt-5 ml-10' xs='12'>
                                <Row xs='12'>
                                    <Col xs='12' md='8'>
                                        <h3 className='welcome-heading'>
                                            Welcome back,{" "}
                                            {this.props.auth.user.name}
                                        </h3>
                                        <p className='welcome-text mt-0 mb-0'>
                                            Here`s a quick view of what`s
                                            happening at
                                        </p>
                                        <h1 className='news-location-header  pl-0 mt-0'>
                                            {getDistrict(
                                                this.state.selected_district
                                            )}
                                        </h1>
                                        <div className='md-5'>
                                            {this.props.auth.user
                                                .role_access === 0 ? (
                                                <>
                                                    <div>
                                                        <label className='form-control-label'>
                                                            Select Dashboard
                                                            Personalization Type
                                                        </label>
                                                        <FormGroup>
                                                            <Input
                                                                //  innerRef={register({ required: true})}
                                                                name='Dashboard Type'
                                                                type='select'
                                                                className='form-control-alternative'
                                                                defaultValue={
                                                                    this.state
                                                                        .default_filter
                                                                }
                                                                onChange={(
                                                                    v
                                                                ) => {
                                                                    this.updateFilter(
                                                                        v.target
                                                                            .value
                                                                    );
                                                                }}
                                                            >
                                                                <option value=''>
                                                                    --Select--
                                                                </option>
                                                                <option value='0'>
                                                                    Personal
                                                                </option>
                                                                <option value='1'>
                                                                    District
                                                                </option>
                                                            </Input>
                                                        </FormGroup>
                                                    </div>
                                                    <div>
                                                        {this.props.auth.user
                                                            .role_access ===
                                                        1 ? (
                                                            <DateRange
                                                                editableDateInputs={
                                                                    true
                                                                }
                                                                onChange={(
                                                                    item: any
                                                                ) => {
                                                                    this.handleSelect(
                                                                        item
                                                                    );
                                                                }}
                                                                moveRangeOnFirstSelection={
                                                                    true
                                                                }
                                                                ranges={
                                                                    this.state
                                                                        .range
                                                                }
                                                            />
                                                        ) : (
                                                            <h3>
                                                                From:
                                                                {moment(
                                                                    this.state
                                                                        .range[0]
                                                                        .startDate
                                                                ).format(
                                                                    "DD/MM/YYYY"
                                                                )}
                                                                -
                                                                {moment(
                                                                    this.state
                                                                        .range[0]
                                                                        .endDate
                                                                ).format(
                                                                    "DD/MM/YYYY"
                                                                )}
                                                            </h3>
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                <div>
                                                    <Col
                                                        xs='7'
                                                        className='pull-right'
                                                    >
                                                        <FormGroup>
                                                            <Input
                                                                name='district_select'
                                                                type='select'
                                                                value={
                                                                    this.state
                                                                        .selected_district
                                                                }
                                                                className='form-control-alternative'
                                                                onChange={(
                                                                    v
                                                                ) => {
                                                                    v.preventDefault();
                                                                    this.setState(
                                                                        {
                                                                            selected_district:
                                                                                v
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    );
                                                                    // this.updateFilter("1")
                                                                    var today = moment();
                                                                    var start_ofweek = moment(
                                                                        today
                                                                    )
                                                                        .subtract(
                                                                            "1",
                                                                            "w"
                                                                        )
                                                                        .weekday(
                                                                            1
                                                                        );
                                                                    var end_ofweek = moment(
                                                                        today
                                                                    );

                                                                    this.filterDash(
                                                                        start_ofweek,
                                                                        end_ofweek,
                                                                        v.target
                                                                            .value
                                                                    );
                                                                }}
                                                            >
                                                                <option value=''>
                                                                    --Select
                                                                    District--
                                                                </option>
                                                                <option value='tg_buffalo_city'>
                                                                    Buffalo City
                                                                    Metropolitan
                                                                    Municipality
                                                                </option>
                                                                <option value='tg_cpt'>
                                                                    City of Cape
                                                                    Town
                                                                    Metropolitan
                                                                    Municipality
                                                                </option>
                                                                <option value='johannesburg'>
                                                                    City of
                                                                    Johannesburg
                                                                    Metropolitan
                                                                    Municipality
                                                                </option>
                                                                <option value='oasis_johannesburg'>
                                                                    City of
                                                                    Johannesburg
                                                                    Metropolitan
                                                                    Municipality
                                                                    OASIS
                                                                </option>
                                                                <option value='phru_johannesburg'>
                                                                    City of
                                                                    Johannesburg
                                                                    Metropolitan
                                                                    Municipality
                                                                    PHRU
                                                                </option>
                                                                <option value='tshwane'>
                                                                    City of
                                                                    Tshwane
                                                                    Metropolitan
                                                                    Municipality
                                                                </option>
                                                                <option value='ekurhuleni'>
                                                                    Ekurhuleni
                                                                    Metropolitan
                                                                    Municipality
                                                                </option>
                                                                {/* <option value="10">Ekurhuleni Metropolitan Municipality CPC  */}
                                                                <option value='tg_mandelabay'>
                                                                    Nelson
                                                                    Mandela Bay
                                                                    Municipality
                                                                </option>
                                                                <option value='vhembe'>
                                                                    Vhembe
                                                                    District
                                                                    Municipality
                                                                </option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </div>
                                            )}
                                        </div>
                                    </Col>

                                    {/* District Select */}

                                    {/* <Col xs='12' md='4' className='district-select mt-md-5'>
                    </Col> */}
                                </Row>

                                {/* Statistic Cards */}

                                <Row>
                                    <Col xs='4' lg='md' className='stats-cards'>
                                        <h3 className='this-week'>This Week</h3>
                                        <Row xs='12' className='pr-8'>
                                            <Col
                                                className='d-flex flex-column flex-md-row'
                                                style={{
                                                    justifyContent:
                                                        "space-betwen",
                                                }}
                                            >
                                                <Card className='mt-3 mt-md-0 ml-md-3 card-stats border-0 prep-records-card'>
                                                    <CardTitle
                                                        tag='h5'
                                                        className='text-uppercase text-center mb-0'
                                                    >
                                                        New Patients
                                                        <hr />
                                                    </CardTitle>
                                                    <CardBody>
                                                        <Row>
                                                            <div className='col'>
                                                                <span className='h1 text-center w-100 d-block font-weight-bold mb-0'>
                                                                    {/* {this.state.completed_list} */}
                                                                    {
                                                                        this.state.patient_create_count ? 
                                                                        this.state.patient_create_count :
                                                                        JSON.parse(localStorage.getItem("patient_create_count")!) ?
                                                                        JSON.parse(localStorage.getItem("patient_create_count")!) :
                                                                        "0"
                                                                    }
                                                                </span>
                                                            </div>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                                <Card className='card-stats border-0 patient-card mt-2 mt-md-0 ml-md-2'>
                                                    <CardTitle
                                                        tag='h5'
                                                        className='text-uppercase text-center mb-0'
                                                    >
                                                        New Prep Records
                                                        <hr />
                                                    </CardTitle>
                                                    <CardBody>
                                                        <Row>
                                                            <div className='col'>
                                                                <span className='h1 text-center d-block font-weight-bold mb-0'>
                                                                    {/* {this.state.completed_list} */}
                                                                    
                                                                    {
                                                                        this.state.prep_create_count ? 
                                                                        this.state.prep_create_count :
                                                                        JSON.parse(localStorage.getItem("prep_create_count")!) ?
                                                                        JSON.parse(localStorage.getItem("prep_create_count")!) :
                                                                        "0"
                                                                    }
                                                                </span>
                                                            </div>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                                <Card className='card-stats border-0 outreach-card mt-2 mt-md-0 ml-md-2'>
                                                    <CardTitle
                                                        tag='h5'
                                                        className='text-uppercase text-center mb-0'
                                                    >
                                                        New Outreaches
                                                        <hr />
                                                    </CardTitle>
                                                    <CardBody>
                                                        <Row>
                                                            <div className='col'>
                                                                <span className='h1 text-center d-block font-weight-bold mb-0'>
                                                                    {/* {this.state.completed_list} */}
                                                                    {
                                                                        this.state.outreach_create_count ?
                                                                        this.state.outreach_create_count : 
                                                                        JSON.parse(localStorage.getItem("outreach_create_count")!) ?
                                                                        JSON.parse(localStorage.getItem("outreach_create_count")!) :
                                                                        "0"
                                                                    }
                                                                </span>
                                                            </div>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                                <Card className='card-stats border-0 appointment-card mt-2 mt-md-0 ml-md-2'>
                                                    <CardTitle
                                                        tag='h5'
                                                        className='text-uppercase text-center mb-0'
                                                    >
                                                        New Appointments
                                                        <hr />
                                                    </CardTitle>
                                                    <CardBody>
                                                        <Row>
                                                            <div className='col'>
                                                                <span className='h1 text-center d-block font-weight-bold mb-0'>
                                                                    {
                                                                        this.state.weekAppointments ?
                                                                        this.state.weekAppointments.length : 
                                                                        JSON.parse(localStorage.getItem("weekAppointments")!) ?
                                                                        JSON.parse(localStorage.getItem("weekAppointments")!) :
                                                                        "0"
                                                                    }
                                                                </span>
                                                            </div>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            {/* Statistic Tables */}

                            <Col xs='12' className='pr-8 mb-5'>
                                {/* Patients Table */}

                                <div className='ld-ml-5 mt-5'>
                                    {this.state.patient_recent.length >= 0 ? (
                                        // var patie_short_list = this.state.patient_recent.splice(0,5)
                                        <div>
                                            <h3
                                                style={{
                                                    fontFamily: "Gothic A1",
                                                    fontWeight: 600,
                                                    fontSize: 18,
                                                    color: "#031F33",
                                                }}
                                            >
                                                Recent Patients
                                            </h3>

                                            <table className='table'>
                                                <tbody>
                                                    <>
                                                        {console.log(
                                                            this.state
                                                                .patient_recent
                                                        )}
                                                        {this.state.patient_recent
                                                            .splice(0, 5)
                                                            .map(
                                                                (
                                                                    patient: any
                                                                ) => {
                                                                    var date_only =
                                                                        patient.date_of_capture_id &&
                                                                        patient.date_of_capture_id.split(
                                                                            " "
                                                                        )[0];
                                                                    return (
                                                                        <tr>
                                                                            <td
                                                                                style={{
                                                                                    fontFamily:
                                                                                        "Gothic A1",
                                                                                    fontWeight: 600,
                                                                                    fontSize: 16,
                                                                                    color:
                                                                                        "#031F33",
                                                                                    textTransform:
                                                                                        "capitalize",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    patient.first_name_id
                                                                                }
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    fontFamily:
                                                                                        "Gothic A1",
                                                                                    fontSize: 16,
                                                                                    color:
                                                                                        "#031F33",
                                                                                    textTransform:
                                                                                        "capitalize",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    patient.surname_id
                                                                                }
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    fontFamily:
                                                                                        "Gothic A1",
                                                                                    fontSize: 16,
                                                                                    color:
                                                                                        "#031F33",
                                                                                    textTransform:
                                                                                        "capitalize",
                                                                                }}
                                                                            >
                                                                                {moment(
                                                                                    date_only
                                                                                ).format(
                                                                                    "DD MMM 'YY"
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                {this.getPatientStatus(
                                                                                    patient.client_id_complete
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                <a
                                                                                    className='btn btn-outline-primary'
                                                                                    onClick={() =>
                                                                                        this.getPatientAction(
                                                                                            patient
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Continue
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                    </>
                                                </tbody>
                                            </table>
                                            <p>
                                                <a
                                                    className='btn btn-block btn-lg btn-view'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.push(
                                                            "/patient"
                                                        );
                                                    }}
                                                    // style
                                                >
                                                    View All
                                                </a>
                                            </p>
                                        </div>
                                    ) : null}
                                </div>

                                {/* Outreach Table */}

                                <div className='ld-ml-5 mt-5'>
                                    {this.state.outreach_recent.length >= 0 ? (
                                        // var patie_short_list = this.state.patient_recent.splice(0,5)
                                        <div>
                                            <h3>Recent Outreach</h3>

                                            <table className='table'>
                                                <tbody>
                                                    <>
                                                        {this.state.outreach_recent
                                                            .splice(0, 5)
                                                            .map(
                                                                (
                                                                    patient: any
                                                                ) => {
                                                                    console.log(
                                                                        "outreach record, ",
                                                                        patient
                                                                    );
                                                                    var date_only = patient.date_of_capture_id
                                                                        ? patient.date_of_capture_id.split(
                                                                              " "
                                                                          )[0]
                                                                        : "";
                                                                    return (
                                                                        <tr>
                                                                            <td>
                                                                                {
                                                                                    patient.full_name_out
                                                                                }
                                                                            </td>
                                                                            {/* <td>{patient.surname_id}</td> */}
                                                                            <td>
                                                                                {moment(
                                                                                    date_only
                                                                                ).format(
                                                                                    "DD MMM 'YY"
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                {this.getPatientStatus(
                                                                                    patient.form_complete
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                <a
                                                                                    className='btn btn-outline-primary'
                                                                                    onClick={() =>
                                                                                        this.getPatientAction(
                                                                                            patient
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Continue
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                    </>
                                                </tbody>
                                            </table>
                                            <p>
                                                <a
                                                    className='btn btn-block btn-view'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.push(
                                                            "/outreach"
                                                        );
                                                    }}
                                                >
                                                    View All
                                                </a>
                                            </p>
                                        </div>
                                    ) : null}
                                </div>
                                <div>
                                    {this.state.appointment_recent?.length >
                                    0 ? (
                                        <div>
                                            <h3>
                                                Appointments scheduled for today
                                            </h3>

                                            <table className='table table-statistics'>
                                                <tbody>
                                                    <>
                                                        {this.state.appointment_recent.map(
                                                            (app_: any) => {
                                                                // var date_only =patient.date_of_capture_id.split(" ")[0]
                                                                console.log(
                                                                    app_
                                                                );
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            {
                                                                                app_.title
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {app_.patient
                                                                                ? app_
                                                                                      .patient
                                                                                      .first_name_id +
                                                                                  " " +
                                                                                  app_
                                                                                      .patient
                                                                                      .surname_id
                                                                                : ""}
                                                                        </td>
                                                                        <td>
                                                                            {moment(
                                                                                app_.start
                                                                            ).format(
                                                                                "DD MMM 'YY"
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {this.get_app_status(
                                                                                app_.status
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {this.get_app_action(
                                                                                app_
                                                                            )}
                                                                        </td>

                                                                        {/* <td>{this.getPatientStatus(app_.client_id_complete)}</td> */}
                                                                        {/* <td><a className="btn btn-outline-primary" onClick={()=>this.getPatientAction(patient)}>Continue</a></td>  */}
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                </tbody>
                                            </table>
                                            <p>
                                                <a
                                                    className='btn btn-block btn-view'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.push(
                                                            "/appointments"
                                                        );
                                                    }}
                                                >
                                                    View All
                                                </a>
                                            </p>

                                            {/* <p><a href="" className="btn btn-block btn-view">View All</a></p> */}
                                        </div>
                                    ) : null}
                                </div>
                            </Col>
                        </Col>

                        {/* Right Sidebar */}

                        <Col xs='3' className='py-5 py-md-0'>
                            <Row>
                                <Col>
                                    <div
                                        style={{
                                            position: "relative",
                                            height: 105,
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            this.props.history.push("/patient")
                                        }
                                    >
                                        <img
                                            className='mw-100'
                                            style={{
                                                minHeight: "100%",
                                            }}
                                            src={SidebarLeftImg}
                                        />
                                        <div
                                            className='mt-1 ml-3'
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                            }}
                                        >
                                            <h3 className='db-r-sb-h'>
                                                Patients
                                            </h3>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <p className='db-r-sb-t'>
                                                    See all patients <br />{" "}
                                                    records
                                                </p>
                                                <i className='fas fa-chevron-right text-white ml-2' />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div
                                        style={{
                                            position: "relative",
                                            height: 105,
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            this.props.history.push(
                                                "/appointments"
                                            )
                                        }
                                    >
                                        <img
                                            className='mw-100'
                                            style={{ minHeight: "100%" }}
                                            src={SidebarRightImg}
                                        />
                                        <div
                                            className='mt-1 ml-3'
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                            }}
                                        >
                                            <h3 className='db-r-sb-h'>
                                                Appointments
                                            </h3>
                                            <div className='d-flex justify-content-between, align-items-center'>
                                                <p className='db-r-sb-t'>
                                                    View and <br /> schedule
                                                </p>
                                                <i className='fas fa-chevron-right text-white ml-5 mt-1' />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={{
                                        height: 1300,
                                        overflowX: "hidden",
                                    }}
                                >
                                    {this.state.activities.length > 0 ? (
                                        <Card className='card-stats bg-white border-0'>
                                            <CardBody
                                                style={{
                                                    height: 1300,
                                                    overflowY: "hidden",
                                                }}
                                            >
                                                <h2>
                                                    Recent Activity-{" "}
                                                    {
                                                        this.state.activities ?
                                                        this.state.activities.length :
                                                        JSON.parse(localStorage.getItem("activities")!)?.length
                                                    }
                                                </h2>
                                                <TimeLine
                                                    activities={
                                                        this.state.activities ?
                                                        this.state.activities :
                                                        JSON.parse(localStorage.getItem("activities")!)
                                                    }
                                                />
                                            </CardBody>
                                        </Card>
                                    ) : null}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withOutreachState(
    withAppointmentState(
        withPrepState(
            withPatientState(withAuthState(withRouter(DashboardInfoPage)))
        )
    )
);
