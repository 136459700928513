import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import {
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    UncontrolledTooltip,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormText,
    Button,
    Modal,
    Card,
    CardBody,
    Collapse,
    CardHeader,
} from "reactstrap";
import withPatientState, { PatientProps } from "../Enhanchers/withPatientState";
import AsyncSelect from "react-select/async";
import withOutreachDD, { OutreachDDProps } from "../Enhanchers/withOutreachDD";
import AddPatientAddModal from "../Forms/PatientAddModal";
import { find_and_split } from "../utils/dd_util";
import withOutreachState, {
    OutreachProps,
} from "../Enhanchers/withOutreachState";
import { getOutreachFormFieldLabels, getGender } from "../constants";

interface IProps {
    patientMode: boolean;
    patientaddFunct: any;
    patientEdit?: boolean;
    EpatDetails?: any;
    NewPatientModal?: any;
    setAddPatientModal?: any;
    subLogic?: any;
}
//   interface IProps
type Props = PatientProps & IProps & OutreachDDProps & OutreachProps;

interface IPatientGroupActivity {
    patient_id: string;
    first_name_out: string;
    surname_out: string;
    preferred_name_out: string;
    date_of_birth_out: string;
    contact_number_out: string;
    gender_out: string;
    // hiv_status_out: string
    // counsellor_initials_out: string
    // full_name_out: string
    // test_conducted_out: string
    // referral_out: string
    // risk_assess_done_out: string
}
function PatientGroupActivityInit(props: Props) {
    const { NewPatientModal, setAddPatientModal } = props;

    const temp_pat = {
        outreach_indiv_id: "",
        patient_id: "",
        full_name_out: "",
        first_name_out: "",
        surname_out: "",
        preferred_name_out: "",
        date_of_birth_out: "",
        contact_number_out: "",
        gender_out: "",
        type_of_sex_work_out: "",
        signature_out: "",
    };
    console.log(props.patientMode, props.patientEdit, props.EpatDetails);
    const [patient, setTempPatient] = useState<any>(temp_pat);

    let temp: any = [];

    const [inputValuePatientSearch, setInputValue] = useState("");
    const [_pat_mod, setPatientMode] = useState(props.patientMode);
    const [addPatientClicked, setAddPatientClicked] = useState(false);

    const [testResult, setTestResult] = useState("");
    const [riskAssessmentDone, setRiskAssessmentDone] = useState("");
    const [hivTestDone, setHIVTestDone] = useState("");

    const [showPrep, setShowPrep] = useState(true);
    const [showArt, setShowArt] = useState(true);

    const [knownNegativeModalVisible, setKnownNegativeModalVisible] = useState(
        false
    );

    const [counselors, setCounselors] = useState<string[]>([]);

    useEffect(() => {
        // GET COUNSELORS
        props.outreach_dd.district_dd?.map((dictionary_object: any) => {
            let tempDistrictValue: any = props.subLogic;
            tempDistrictValue = new RegExp(
                "'" + tempDistrictValue + ".*?'",
                "g"
            );
            console.log(tempDistrictValue);
            if (
                // dictionary_object.meta.key &&
                dictionary_object.meta.counselor_key &&
                dictionary_object?.branching_logic &&
                tempDistrictValue.test(dictionary_object?.branching_logic)
            ) {
                console.log("made it");
                var values = find_and_split(
                    dictionary_object?.select_choices_or_calculations
                );

                values.map((value: any) => {
                    console.log(value);
                    temp.push(value);
                });
                setCounselors(temp);
            }
        });
        if (temp.length > 0) {
            setCounselors(temp);
        }
    }, [props.outreach_dd.district_dd, props.subLogic, props.patientMode]);

    // Patient Modal creating new Patient
    const {
        register,
        handleSubmit,
        watch,
        errors,
        formState,
        setValue,
        control,
        setError,
        clearError,
    } = useForm();
    //Patient Search Component functions and state variables
    const [selectOption, setSelectOption] = useState("");
    const getPatient = (newValue: any, actionMeta: any) => {
        if (actionMeta.action === "select-option") {
            setSelectOption(newValue.value);
            setPatientMode(false);
            var pat_new_rec = newValue.value;

            var outreach_previous = props.outreach.outreach_all.filter(
                (out_: any) => {
                    if (out_.uid === pat_new_rec.uid) {
                        return out_;
                    }
                }
            );

            setTempPatient({
                uid: pat_new_rec.uid,
                patient_id: pat_new_rec.uid || "",
                full_name_out:
                    pat_new_rec.first_name_id + " " + pat_new_rec.surname_id ||
                    "",
                first_name_out: pat_new_rec.first_name_id || "",
                surname_out: pat_new_rec.surname_id || "",
                preferred_name_out: pat_new_rec.preferred_name_id || "",
                date_of_birth_out: pat_new_rec.birthdate_id || "",
                contact_number_out: pat_new_rec.contact_number_id,
                gender_out: pat_new_rec.gender_id,
                type_of_sex_work_out: pat_new_rec.type_of_sex_work_out || "",
                // signature_out: pat_new_rec.signature_out || '',
            });
            setValue("first_name_out", pat_new_rec.first_name_id || "");
            setValue("surname_out", pat_new_rec.surname_id || "");
            setValue("preferred_name_out", pat_new_rec.preferred_name_id || "");
            setValue("date_of_birth_out", pat_new_rec.birthdate_id || "");
            setValue("contact_number_out", pat_new_rec.contact_number_id || "");
        } else {
            setTempPatient({
                uid: pat_new_rec.uid ? pat_new_rec.uid : "",
                outreach_lwazi_id: pat_new_rec ? pat_new_rec.uid : "",
                patient_id: pat_new_rec.record_id || "",
                full_name_out:
                    pat_new_rec.first_name_id + " " + pat_new_rec.surname_id ||
                    "",
                first_name_out: pat_new_rec.first_name_id || "",
                surname_out: pat_new_rec.surname_id || "",
                preferred_name_out: pat_new_rec.preferred_name_id || "",
                date_of_birth_out: pat_new_rec.birthdate_id || "",
                contact_number_out: pat_new_rec.cont,
                type_of_sex_work_out:
                    outreach_previous[0].type_of_sex_work_out || "",
                gender_out: pat_new_rec.gender_id,
            });
            setValue("gender_out", outreach_previous[0].gender_id);
            setValue("first_name_out", pat_new_rec.first_name_id);
            setValue("surname_out", pat_new_rec.surname_id);
            setValue("preferred_name_out", pat_new_rec.preferred_name_id);
            setValue("date_of_birth_out", pat_new_rec.birthdate_id);
            setValue("contact_number_out", pat_new_rec.contact_number_id);
            setValue("type_of_sex_work_out", pat_new_rec.type_of_sex_work_out);
        }
        console.groupEnd();
    };

    const patientValidate = (values: any) => {
        console.log(values, patient);

        if (props.patientEdit) {
            var t_p = {
                ...patient,
                ...props.EpatDetails,

                ...values,
                outreach_indiv_id: props.EpatDetails.outreach_indiv_id,
                full_name_out: patient.full_name_out,
                gender_out: patient.gender_out.toString(),
            };
        } else {
            var t_p = {
                ...patient,
                ...values,
                gender_out: patient.gender_out.toString(),
            };
        }
        setTimeout(() => {
            if (
                Object.keys(errors).length === 0 &&
                errors.constructor === Object
            ) {
                console.log("setting - ", t_p);
                props.patientaddFunct(t_p);
                setAddPatientClicked(false);
                // setTempPatient(t_p)
            }
        }, 1000);
    };

    const [patientEditInit, setPatientEditInit] = useState(true);
    // console.log(_pat_mod);
    // console.log(temp_pat);
    if (props.patientEdit && _pat_mod === true) {
        if (Object.keys(props.EpatDetails).length > 0 && patientEditInit) {
            setPatientMode(false);
            // console.log(props.EpatDetails);
            setPatientEditInit(false);
            setTempPatient({
                ...props.EpatDetails,
                uid: props.EpatDetails.uid,
                // outreach_lwazi_id: props.EpatDetails.uid,
                patient_id: props.EpatDetails.record_out || "",
                full_name_out:
                    props.EpatDetails.first_name_out +
                        " " +
                        props.EpatDetails.surname_out || "",
                first_name_out: props.EpatDetails.first_name_out || "",
                surname_out: props.EpatDetails.surname_out || "",
                preferred_name_out: props.EpatDetails.preferred_name_out,
                date_of_birth_out: props.EpatDetails.date_of_birth_out || "",
                contact_number_out: props.EpatDetails.contact_number_out,
                gender_out: props.EpatDetails.gender_out,
                type_of_sex_work_out: props.EpatDetails.type_of_sex_work_out,
            });

            setValue(
                "type_of_sex_work_out",
                props.EpatDetails.type_of_sex_work_out
            );
        } else if (patientEditInit) {
            // console.log("00");
            setPatientMode(true);
            // console.log(props.EpatDetails);
            setPatientEditInit(false);
            setTempPatient({
                uid: "",
                // outreach_lwazi_id: "",
                patient_id: "",
                full_name_out: "",
                first_name_out: "",
                surname_out: "",
                preferred_name_out: "",
                date_of_birth_out: "",
                contact_number_out: "",
                gender_out: "",
                type_of_sex_work_out: "",
            });
        }
    }

    const addPatientToTempList = (patient: any) => {
        setPatientMode(false);

        setTempPatient({
            ...patient,
            uid: patient.uid,
            patient_id: patient.record_id || "",
            full_name_out:
                patient.first_name_id + " " + patient.surname_id || "",
            first_name_out: patient.first_name_id || "",
            surname_out: patient.surname_id || "",
            preferred_name_out: patient.preferred_name_id,
            date_of_birth_out: patient.birthdate_id || "",
            contact_number_out: patient.contact_number_id,
            gender_out: patient.gender_id.toString(),
            type_of_sex_work_out: patient.type_of_sex_work_out,
        });
        console.groupEnd();
    };

    var temp_pat_list: any[] = [];
    props.patients.patients.map((patient: any) => {
        temp_pat_list.push({
            value: patient,
            label: patient.first_name_id + " " + patient.surname_id,
        });
    });

    const handleInputChange = (newValue: string) => {
        const inputValue = newValue.replace(/\W/g, "");
        setInputValue(inputValue);
        return newValue;
    };

    const filterPatientList = (inputValue: string) => {
        console.log(inputValue);
        var list = temp_pat_list.filter((i: any) =>
            JSON.stringify(i.label)
                .trim()
                .toLowerCase()
                .includes(inputValue.trim().toLowerCase())
        );
        list.sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
        var slice_list = list.slice(0, 10);
        console.log(slice_list);

        return slice_list;
    };

    const loadOptions = (inputValue: any, callback: any) => {
        setTimeout(() => {
            callback(filterPatientList(inputValue));
        }, 900);
    };

    const renderKnownNegativeModal = () => {
        return (
            <Modal
                className='modal-dialog-centered '
                style={{ width: "30%", height: "40%" }}
                isOpen={knownNegativeModalVisible}
                toggle={() =>
                    setKnownNegativeModalVisible(!knownNegativeModalVisible)
                }
            >
                <div className='modal-header text-center'>
                    <p>
                        Known Negative can only be selected if the patient has
                        been tested within the last 6 weeks.
                    </p>
                </div>
                <Button
                    // className='btn-icon btn-2'
                    style={{
                        width: "30%",
                        alignSelf: "center",
                        marginBottom: 20,
                    }}
                    color='info'
                    type='button'
                    onClick={() =>
                        setKnownNegativeModalVisible(!knownNegativeModalVisible)
                    }
                >
                    OK
                    {/* <span aria-hidden={true}>OK</span> */}
                </Button>
            </Modal>
        );
    };

    const returnMissingFields = () => {
        let fields = [];
        for (const key in errors) {
            console.log(key);
            fields.push(getOutreachFormFieldLabels(key));
        }
        console.log(fields);
        return fields;
    };

    const renderFormValidationModal = () => {
        let missingFields = returnMissingFields();
        return (
            <Modal
                className='modal-dialog-centered '
                size='xs'
                // contentClassName='bg-gradient-info'
                isOpen={
                    addPatientClicked &&
                    Object.keys(errors).length !== 0 &&
                    errors.constructor === Object
                }
                // toggle={() => props.togglefunct(false)}
            >
                {/* <div className='modal-header text-center'> */}
                <button
                    aria-label='Close'
                    className='close'
                    data-dismiss='modal'
                    type='button'
                    style={{ position: "absolute", right: 5, top: 5 }}
                    onClick={() => setAddPatientClicked(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
                <div style={{ padding: 50 }}>
                    <div>
                        <p style={{ paddingLeft: 40 }}>
                            {" "}
                            Please Ensure that The following Sections Are
                            Completed:
                        </p>
                    </div>

                    {missingFields.map((field) => (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: 50,
                                maxHeight: 30,
                            }}
                        >
                            <i className='text-danger fa fa-square fa-lg'></i>
                            <p
                                style={{
                                    paddingLeft: 30,
                                    paddingTop: 5,
                                    alignSelf: "center",
                                }}
                            >
                                {field}
                            </p>
                        </div>
                    ))}
                </div>

                {/* </div> */}
            </Modal>
        );
    };

    const { dirty, isSubmitting, touched, submitCount } = formState;
    console.log(dirty, isSubmitting, touched, submitCount, errors);
    return (
        <>
            {renderFormValidationModal()}
            {renderKnownNegativeModal()}

            {NewPatientModal ? (
                <AddPatientAddModal
                    isOpen={NewPatientModal}
                    togglefunct={setAddPatientModal}
                    setPatientToList={addPatientToTempList}
                />
            ) : null}
            <Card className='card-plain bg-secondary' style={{ width: "100%" }}>
                <CardHeader
                    role='tabpanel'
                    //   onClick={() =>toggle(indexV)}
                    //   aria-expanded={isOpen === indexV}
                >
                    <Row>
                        <Col xs='11'>
                            {!_pat_mod ? (
                                <h5 className='mb-0'>
                                    {" "}
                                    Patient Name: {patient.full_name_out}
                                </h5>
                            ) : (
                                <h5 className='mb-0'> Select Patient</h5>
                            )}
                        </Col>
                        {/* <i className={isOpen === indexV ? "text-right fa fa-chevron-up": "text-right fa fa-chevron-down"}></i> */}
                    </Row>
                </CardHeader>
                <Collapse role='tabpanel' isOpen={true}>
                    <CardBody>
                        <form onSubmit={handleSubmit(patientValidate)}>
                            <Row>
                                {_pat_mod ? (
                                    <>
                                        <Col xs='4' style={{ marginTop: "2%" }}>
                                            <AsyncSelect
                                                style={{
                                                    width: "97px !important",
                                                    marginTop: "7%",
                                                }}
                                                cacheOptions
                                                defaultOptions
                                                isClearable
                                                loadOptions={loadOptions}
                                                // value={selectOption}
                                                onChange={getPatient}
                                                //  options={temp_pat_list}
                                                onInputChange={
                                                    handleInputChange
                                                }
                                            />
                                        </Col>
                                        <Col xs='2' style={{ marginTop: "2%" }}>
                                            <Button
                                                className='btn-icon btn-2'
                                                color='info'
                                                type='button'
                                                onClick={() =>
                                                    setAddPatientModal(true)
                                                }
                                            >
                                                <span className='btn-inner--icon'>
                                                    <i className='fas fa-user-plus' />
                                                </span>
                                            </Button>
                                        </Col>
                                    </>
                                ) : (
                                    <Button
                                        color='warning'
                                        type='button'
                                        onClick={() => {
                                            var current_status = _pat_mod;
                                            console.log(
                                                "before change",
                                                patient
                                            );
                                            const temp_pat = {
                                                ...patient,
                                                patient_id: "",
                                                full_name_out: "",
                                                first_name_out: "",
                                                surname_out: "",
                                                preferred_name_out: "",
                                                date_of_birth_out: "",
                                                contact_number_out: "",
                                                gender_out: "",
                                                type_of_sex_work_out:
                                                    patient.type_of_sex_work_out,
                                            };
                                            console.log(
                                                "after change",
                                                temp_pat
                                            );
                                            setTempPatient(temp_pat);
                                            setPatientMode(!current_status);
                                        }}
                                    >
                                        Change Patient
                                    </Button>
                                )}
                                <Col xs='4'>
                                    <label className='form-control-label'>
                                        Legal First Name:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            // disabled
                                            innerRef={register({
                                                required: true,
                                            })}
                                            name='first_name_out'
                                            className='form-control-alternative'
                                            type='text'
                                            value={patient.first_name_out}
                                            defaultValue={
                                                patient.first_name_out
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs='4'>
                                    <label className='form-control-label'>
                                        Legal Surname:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            // disabled
                                            innerRef={register({
                                                required: true,
                                            })}
                                            name='surname_out'
                                            className='form-control-alternative'
                                            type='text'
                                            value={patient.surname_out}
                                            defaultValue={patient.surname_out}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs='6'>
                                    <label className='form-control-label'>
                                        Preferred Name:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            // disabled
                                            innerRef={register({
                                                required: false,
                                            })}
                                            name='preferred_name_out'
                                            className='form-control-alternative'
                                            type='text'
                                            value={patient.preferred_name_out}
                                            defaultValue={
                                                patient.preferred_name_out
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs='4'>
                                    <label className='form-control-label'>
                                        Date of Birth:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            innerRef={register({
                                                required: true,
                                            })}
                                            name='date_of_birth_out'
                                            // disabled
                                            className='form-control-alternative'
                                            type='text'
                                            value={patient.date_of_birth_out}
                                            defaultValue={
                                                patient.date_of_birth_out
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs='4'>
                                    <label className='form-control-label'>
                                        Contact Number:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            innerRef={register({
                                                required: true,
                                                pattern: /^0[0-9]{9}$/,
                                            })}
                                            // name="first_name_id"

                                            name='contact_number_out'
                                            className='form-control-alternative'
                                            type='text'
                                            // value={patient.contact_number_out}
                                            defaultValue={
                                                patient.contact_number_out
                                            }
                                        />
                                        {errors.contact_number_id ? (
                                            <a
                                                className='text-danger'
                                                style={{ fontSize: 8 }}
                                            >
                                                Please Enter a valid Contact
                                                Number
                                            </a>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs='6'>
                                    <label className='form-control-label'>
                                        Gender:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            // disabled
                                            type='select'
                                            name='gender_out'
                                            innerRef={register({
                                                required: true,
                                            })}
                                            className='form-control-alternative'
                                            value={patient.gender_out}
                                            onChange={(e) =>
                                                setTempPatient({
                                                    ...patient,
                                                    gender_out: e.target.value,
                                                })
                                            }
                                        >
                                            <option value=''>Gender</option>
                                            <option value='1'>Male </option>
                                            <option value='2'>Female </option>
                                            <option value='3'>
                                                Transgender Man{" "}
                                            </option>
                                            <option value='4'>
                                                Transgender Woman{" "}
                                            </option>
                                            <option value='5'>
                                                Gender Non-Conforming
                                            </option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                {patient.sex_at_birth_id && (
                                    <Col xs='6'>
                                        <FormGroup>
                                            <label className='form-control-label'>
                                                Sex At Birth:
                                            </label>
                                            <Input
                                                name='sex_at_birth_id'
                                                type='select'
                                                placeholder='Sex at Birth'
                                                className='form-control-alternative'
                                                value={patient.sex_at_birth_id}
                                                onChange={(e) =>
                                                    setTempPatient({
                                                        ...patient,
                                                        sex_at_birth_id:
                                                            e.target.value,
                                                    })
                                                }
                                            >
                                                <option value=''>
                                                    --Select--
                                                </option>
                                                <option value='2'>Male </option>
                                                <option value='1'>
                                                    Female{" "}
                                                </option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                )}
                            </Row>
                            <Row></Row>
                            <Row>
                                <Col xs='6'>
                                    <label className='form-control-label'>
                                        HIV Test Done:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            name='test_conducted_out'
                                            innerRef={register({
                                                required: true,
                                            })}
                                            className='form-control-alternative'
                                            type='select'
                                            defaultValue={
                                                patient.test_conducted_out
                                            }
                                            onChange={(e) => {
                                                setHIVTestDone(e.target.value);
                                            }}
                                        >
                                            <option value='1'>Yes</option>
                                            <option value='0'>No</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                {hivTestDone === "1" && (
                                    <Col xs='6'>
                                        <label className='form-control-label'>
                                            HIV Test Results:{" "}
                                        </label>
                                        <FormGroup>
                                            <Input
                                                name='test_results_out'
                                                innerRef={register({
                                                    required: false,
                                                })}
                                                className='form-control-alternative'
                                                type='select'
                                                onChange={(e) => {
                                                    setTestResult(
                                                        e.target.value
                                                    );
                                                }}
                                                defaultValue={
                                                    patient.test_results_out
                                                }
                                            >
                                                <option value=''>
                                                    --Select--
                                                </option>
                                                <option value='1'>
                                                    HIV Positive
                                                </option>
                                                <option value='2'>
                                                    HIV Negative
                                                </option>
                                                <option value='3'>
                                                    Indeterminate
                                                </option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                )}
                            </Row>
                            {/* //Status Change */}
                            {/* <Row> */}
                            {/* {showPrep ? (
                                    <Col xs='6'>
                                        <label className='form-control-label'>
                                            On Prep?:{" "}
                                        </label>
                                        <FormGroup>
                                            <Input
                                                name='on_prep_out'
                                                innerRef={register({
                                                    required: showPrep,
                                                })}
                                                className='form-control-alternative'
                                                type='select'
                                                defaultValue={
                                                    patient.on_prep_out
                                                }
                                            >
                                                <>
                                                    <option value=''>
                                                        --Select--
                                                    </option>
                                                    <option value='1'>
                                                        Yes
                                                    </option>
                                                    <option value='0'>
                                                        No
                                                    </option>
                                                </>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                ) : null} */}
                            {/* 
                                {showArt ? (
                                    <Col xs='6'>
                                        <label className='form-control-label'>
                                            On ART? :{" "}
                                        </label>
                                        <FormGroup>
                                            <Input
                                                name='on_art_out'
                                                innerRef={register({
                                                    required: showArt,
                                                })}
                                                className='form-control-alternative'
                                                type='select'
                                                defaultValue={
                                                    patient.on_art_out
                                                }
                                            >
                                                <option value=''>
                                                    --Select--
                                                </option>
                                                <option value='1'>Yes</option>
                                                <option value='0'>No</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                ) : null} */}
                            {/* </Row> */}
                            <Row>
                                {/* <Col xs='6'>
                                    <label className='form-control-label'>
                                        HIV Test Results:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            name='test_results_out'
                                            innerRef={register({
                                                required: false,
                                            })}
                                            className='form-control-alternative'
                                            type='select'
                                            onChange={(e) => {
                                                setTestResult(e.target.value);
                                            }}
                                            defaultValue={
                                                patient.test_results_out
                                            }
                                        >
                                            <
                                            <option value='1'>
                                                HIV Positive
                                            </option>
                                            <option value='2'>
                                                HIV Negative
                                            </option>
                                            <option value='3'>
                                                Indeterminate
                                            </option>
                                        </Input>
                                    </FormGroup>
                                </Col> */}
                                {/* RENDER IF HIV TEST RESULTS FILLED */}
                                {hivTestDone === "1" ||
                                riskAssessmentDone === "1" ? (
                                    <Col xs='6'>
                                        <label className='form-control-label'>
                                            Counsellor Responsible:{" "}
                                        </label>
                                        <FormGroup>
                                            <Input
                                                name='counselor_responsible'
                                                innerRef={register({
                                                    required: false,
                                                })}
                                                className='form-control-alternative'
                                                type='select'
                                                defaultValue={
                                                    patient.counselor_responsible
                                                }
                                            >
                                                <option value=''>
                                                    --Select--
                                                </option>
                                                {counselors &&
                                                    counselors.map(
                                                        (counselor: any) => (
                                                            <option
                                                                value={
                                                                    counselor.index
                                                                }
                                                            >
                                                                {
                                                                    counselor.value
                                                                }
                                                            </option>
                                                        )
                                                    )}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                ) : null}
                            </Row>
                            {/* <Row>
                                <Col xs='6'>
                                    <label className='form-control-label'>
                                        Referral for Additional Services Made:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            name='referral_out'
                                            innerRef={register({
                                                required: true,
                                            })}
                                            className='form-control-alternative'
                                            type='select'
                                            defaultValue={patient.referral_out}
                                        >
                                            <

                                            <option value='1'>Yes</option>
                                            <option value='0'>No</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row> */}

                            {patient.first_name_out.length > 0 ? (
                                <>
                                    <Col xs='6' className='text-right'>
                                        <Button
                                            color='secondary'
                                            type='button'
                                            onClick={() => {
                                                props.patientaddFunct(null);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color='success'
                                            type='submit'
                                            onClick={() => {
                                                handleSubmit(patientValidate);
                                                setTimeout(() => {
                                                    setAddPatientClicked(true);
                                                }, 1050);
                                            }}
                                        >
                                            Add Patient To Outreach
                                        </Button>
                                    </Col>
                                </>
                            ) : null}
                        </form>
                    </CardBody>
                </Collapse>
            </Card>
        </>
    );
}

export default withOutreachState(
    withOutreachDD(withPatientState(PatientGroupActivityInit))
);
