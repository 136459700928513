import { combineReducers } from 'redux';

import * as auth from './modules/auth';
import * as patients from './modules/patient';
import * as outreach from './modules/outreach';
import * as prep from './modules/prep';
import * as appointment from './modules/appointment';
import * as outreach_dd from './modules/outreach_translator';
import * as activity_log from './modules/activity_log';


const rootReducer = combineReducers({
  auth: auth.reducer,
  patients: patients.reducer,
  outreach: outreach.reducer,
  outreach_dd: outreach_dd.reducer,
  prep_records: prep.reducer,
  appointment_records: appointment.reducer,
  activity_log: activity_log.reducer

});

export default rootReducer;
