import React, { useState, Fragment } from "react";
import {
    createStyles,
    withStyles,
    WithStyles,
    ClickAwayListener,
    Toolbar,
    AppBar,
    fade,
    MenuItem,
    CssBaseline,
    Badge,
    Grid,
    Theme,
    Drawer,
    ListItem,
    ListItemIcon,
    ListItemText,
    List,
    Divider,
    Icon,
    Button,
} from "@material-ui/core";
import {
    NavLink,
    RouteComponentProps,
    RouteProps,
    withRouter,
} from "react-router-dom";
// import { loadCSS } from 'fg-loadcss';

// import home from '@material-ui/icons/Dashboard';
// import prep from '@material-ui/icons/AssignmentOutlined';
import outreach from "@material-ui/icons/AssignmentReturnOutlined";
import patient from "@material-ui/icons/AssignmentInd";
// import prepform_img from '../Assets/Navbar/nav-prep-form.png';
// import outreach_img from '../Assets/Navbar/nav-outreach-form.png';
import Home from "@material-ui/icons/Home";
import dashboard_img from "../Assets/Navbar/nav-dashboard-active.png";
import patient_img from "../Assets/Navbar/nav-patients.png";
import appointments_img from "../Assets/Navbar/nav-appointments.png";
import report_img from "../Assets/Navbar/nav-reports.png";
import notifications_img from "../Assets/Navbar/nav-notifications.png";
import prepform_img from "../Assets/Navbar/nav-prep-form.png";
// import outreach_img from '../Assets/Navbar/nav-outreach-form.png';
// import Home from '@material-ui/icons/Home';

import {
    Card,
    CardBody,
    CardTitle,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Table,
} from "reactstrap";

interface IProps {
    outreach_record: any;
}

type Props = WithStyles<typeof styles> & IProps & RouteComponentProps;

class PatientOutreachTab extends React.Component<Props> {
    // test_results:
    // beb_b_results: "0"
    // decide_to_initiate_results: "1"
    // hiv_test_results: "0"
    // meds_dispense: "0"
    // pregnancy_results: "0"
    // prep_counseling_conducted: "1"
    // proceed_screening: "0"
    // sti_results: "0"
    // weight_results: "123"

    render() {
        return (
            <>
                <CardBody>
                    <h6 className='heading-small text-muted mb-4'>
                        Previous Outreaches Attended
                    </h6>

                    <Table className='align-items-center' responsive>
                        <thead className='thead-light'>
                            <tr>
                                <th rowSpan={2}>Date</th>
                                <th rowSpan={2}>
                                    Risk Assessment
                                    <br />
                                    Done
                                </th>
                                <th
                                    style={{
                                        textAlign: "center",
                                        verticalAlign: "top",
                                    }}
                                    colSpan={3}
                                >
                                    HIV
                                </th>
                                <th rowSpan={2}>On Art?</th>
                                <th rowSpan={2}>On Prep?</th>
                                <th rowSpan={2}>
                                    Referral for <br />
                                    additional services
                                </th>
                                <th rowSpan={2}>Actions</th>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <th>Test Done</th>
                                <th>Result</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.outreach_record.map((record_: any) => {
                                const risk_asses: any = {
                                    1: "Yes",
                                    0: "No",
                                };

                                const status_out: any = {
                                    1: "Known Positive",
                                    2: "Known Negative",
                                    3: "Unknown",
                                };
                                const test_conducted_out: any = {
                                    1: "Yes",
                                    0: "No",
                                };
                                const test_result: any = {
                                    1: "HIV Positive",
                                    2: "HIV Negative",
                                    3: "Indeterminate",
                                };
                                const on_art: any = {
                                    1: "Yes",
                                    0: "No",
                                };
                                const on_prep: any = {
                                    1: "Yes",
                                    0: "No",
                                };
                                const referral_out: any = {
                                    1: "Yes",
                                    0: "No",
                                };

                                if (record_.activity_type_out === 1) {
                                    return (
                                        <tr>
                                            <td>
                                                {record_.activity_date_outreach}
                                            </td>
                                            <td>
                                                {
                                                    risk_asses[
                                                        record_
                                                            .risk_assess_done_out
                                                    ]
                                                }
                                            </td>
                                            <td>
                                                {
                                                    status_out[
                                                        record_.hiv_status_out
                                                    ]
                                                }
                                            </td>
                                            <td>
                                                {
                                                    test_conducted_out[
                                                        record_
                                                            .test_conducted_out
                                                    ]
                                                }
                                            </td>
                                            <td>
                                                {
                                                    test_result[
                                                        record_.test_results_out
                                                    ]
                                                }
                                            </td>
                                            <td>
                                                {on_art[record_.on_art_out]}
                                            </td>
                                            <td>
                                                {on_prep[record_.on_prep_out]}
                                            </td>
                                            <td>
                                                {
                                                    referral_out[
                                                        record_.referral_out
                                                    ]
                                                }
                                            </td>
                                            <td>
                                                <strong
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        var id =
                                                            record_.outreach_lwazi_id;
                                                        this.props.history.push(
                                                            "/outreach-view/" +
                                                                id
                                                        );
                                                    }}
                                                >
                                                    Details
                                                </strong>
                                            </td>
                                        </tr>
                                    );
                                }
                            })}
                        </tbody>
                    </Table>
                </CardBody>
                <CardBody style={{ marginTop: 10 }}>
                    <h6 className='heading-small text-muted mb-4'>
                        Previous Group Activities Attended
                    </h6>

                    <Table className='align-items-center' responsive>
                        <thead className='thead-light'>
                            <tr>
                                <th>Date</th>
                                {/* {/* <th> Amount Recieved</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.outreach_record.map((record_: any) => {
                                if (record_.activity_type_out === 2) {
                                    return (
                                        <tr>
                                            <td>
                                                {record_.activity_date_outreach}
                                            </td>
                                            <td>
                                                <strong
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        var id =
                                                            record_.outreach_lwazi_id;
                                                        this.props.history.push(
                                                            "/outreach-view/" +
                                                                id
                                                        );
                                                    }}
                                                >
                                                    Details
                                                </strong>
                                            </td>
                                            {/* {/* <td>{record_.hiv_status_out}</td> */}
                                        </tr>
                                    );
                                }
                            })}
                        </tbody>
                    </Table>
                </CardBody>
            </>
        );
    }
}
const styles = (theme: Theme) =>
    createStyles({
        drawer: {
            width: 90,
            flexShrink: 0,
        },
        icon_state: {
            active: "#fff",
            non_active: "#143840",
        },

        drawerPaper: {
            width: 90,
            marginTop: 50,
        },
        drawerContainer: {
            overflow: "auto",
            // marginTop: 40
        },
    });
export default withRouter(withStyles(styles)(PatientOutreachTab));
