import React, { useState, Fragment } from "react";
import {
    createStyles,
    withStyles,
    WithStyles,
    ClickAwayListener,
    Toolbar,
    AppBar,
    fade,
    MenuItem,
    CssBaseline,
    Badge,
    Grid,
    Theme,
    Drawer,
    ListItem,
    ListItemIcon,
    ListItemText,
    List,
    Divider,
    Icon,
} from "@material-ui/core";
import {
    NavLink as NavLinkRRD,
    RouteComponentProps,
    withRouter,
    Link,
} from "react-router-dom";
// import { loadCSS } from 'fg-loadcss';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Media,
    NavbarBrand,
    Navbar,
    NavItem,
    Nav,
    NavLink,
    Progress,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

// import home from '@material-ui/icons/Dashboard';
// import prep from '@material-ui/icons/AssignmentOutlined';
import outreach from "@material-ui/icons/AssignmentReturnOutlined";
import patient from "@material-ui/icons/AssignmentInd";
// import prepform_img from '../Assets/Navbar/nav-prep-form.png';
import outreach_img from "../Assets/Navbar/nav-outreach-form.svg";
import Home from "@material-ui/icons/Home";
import dashboard_img from "../Assets/Navbar/nav-dashboard.svg";
import patient_img from "../Assets/Navbar/nav-patients.svg";
import appointments_img from "../Assets/Navbar/nav-appointments.svg";
import report_img from "../Assets/Navbar/nav-reports.png";
import notifications_img from "../Assets/Navbar/nav-notifications.png";
import prepform_img from "../Assets/Navbar/nav-prep-form.svg";

// Active
import outreach_active_img from "../Assets/Navbar/nav-outreach-form-active.svg";

import dashboard_active_img from "../Assets/Navbar/nav-dashboard-active.svg";
import patient_active_img from "../Assets/Navbar/nav-patients-active.svg";
import appointments_active_img from "../Assets/Navbar/nav-appointments-active.svg";
import report_active_img from "../Assets/Navbar/nav-reports.png";
import notifications_active_img from "../Assets/Navbar/nav-notifications.png";
import prepform_active_img from "../Assets/Navbar/nav-prep-form-active.svg";

import nav_open from "../Assets/Navbar/nav-open.png";
import { BorderTop } from "@material-ui/icons";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
// import outreach_img from '../Assets/Navbar/nav-outreach-form.png';
// import Home from '@material-ui/icons/Home';

// import IRoutes from '../@types'
interface IRoutes {
    path: string;
    icon: string;
    name: string;
    active: boolean;
    image: string;
    image_active: string;
}
interface IProps {
    logo: any;
    //  logo: PropTypes.shape({
    //    // innerLink is for links that will direct the user within the app
    //    // it will be rendered as <Link to="...">...</Link> tag
    //    innerLink: PropTypes.string,
    //    // outterLink is for links that will direct the user outside the app
    //    // it will be rendered as simple <a href="...">...</a> tag
    //    outterLink: PropTypes.string,
    //    // the image src of the logo
    //    imgSrc: PropTypes.string.isRequired,
    //    // the alt for the img
    //    imgAlt: PropTypes.string.isRequired
}

type Props = WithStyles<typeof styles> &
    RouteComponentProps &
    IProps &
    AuthProps;
interface IState {
    collapseOpen: boolean;
    activeName: string;
}

class SideBar extends React.Component<Props, IState> {
    state = {
        collapseOpen: false,
        activeName: "",
    };

    constructor(props: any) {
        super(props);

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute = (routeName: string) => {
        return this.props.location.pathname
            .toLowerCase()
            .includes(routeName.toLowerCase())
            ? "active"
            : "";
    };
    // toggles collapse between opened and closed (true/false)
    toggleCollapse = () => {
        this.setState({
            collapseOpen: this.state.collapseOpen,
        });
        // console.log('collapse');
    };

    onMouseEnter() {
        this.setState({ collapseOpen: true });
    }

    onMouseLeave() {
        this.setState({ collapseOpen: false });
    }

    // closes the collapse
    closeCollapse = () => {
        this.setState({
            collapseOpen: false,
        });
    };

    setActive = (name: string) => {
        this.setState({
            activeName: name,
        });
    };

    createLinks = (routes: IRoutes[]) => {
        return routes.map((prop: IRoutes, key: number) => {
            if (prop.name === "Patients") {
                var active_icon =
                    this.state.activeName === prop.name ? true : false;

                // console.log(this.activeRoute(prop.path)=== 'active');
                // console.log(active_icon);

                return (
                    <NavItem
                        key={key}
                        active={
                            this.activeRoute(prop.path) === "active"
                                ? true
                                : false
                        }
                        onMouseOver={this.onMouseEnter}
                        onMouseLeave={this.onMouseLeave}
                    >
                        <NavLink
                            to={prop.path}
                            tag={NavLinkRRD}
                            onClick={() => this.setActive(prop.name)}
                            // onClick={this.closeCollapse}
                            // style={{borderLeftColor:  prop.path.includes(this.props.history.location.pathname)  ? 'bg-info':'#ffff', borderLeftWidth: 5, borderLeftStyle : 'solid'}}
                        >
                            {this.activeRoute(prop.path) === "active" ? (
                                <img src={prop.image_active} alt={prop.name} />
                            ) : (
                                <img src={prop.image} alt={prop.name} />
                            )}
                            {/* style={{ fontSize: 45 }} */}
                            {/* <i className={prop.icon} /> */}
                            {this.state.collapseOpen ? (
                                <span>{prop.name}</span>
                            ) : (
                                ""
                            )}
                        </NavLink>
                    </NavItem>
                );
            } else if (prop.name === "Outreach") {
                return (
                    //  style={{marginTop: 15}}
                    <NavItem
                        key={key}
                        active={
                            this.activeRoute(prop.path) === "active"
                                ? true
                                : false ||
                                  this.props.location.pathname
                                      .toLowerCase()
                                      .includes("group-activity-create")
                        }
                        onMouseOver={this.onMouseEnter}
                        onMouseLeave={this.onMouseLeave}
                    >
                        <NavLink
                            to={prop.path}
                            tag={NavLinkRRD}
                            onClick={() => this.setActive(prop.name)}
                            // onClick={this.closeCollapse}
                            // style={{borderLeftColor:  prop.path.includes(this.props.history.location.pathname) ? 'bg-success':'#ffff', borderLeftWidth: 5, borderLeftStyle : 'solid'}}
                            // activeClassName={this.state.activeName === prop.name ? "active" : null}
                        >
                            {/* style={{ fontSize: 45, marginLeft: 9 }} */}
                            {/* <i className={prop.icon} /> */}
                            {this.activeRoute(prop.path) === "active" ||
                            this.props.location.pathname
                                .toLowerCase()
                                .includes("group-activity-create") ? (
                                <img src={prop.image_active} alt={prop.name} />
                            ) : (
                                <img src={prop.image} alt={prop.name} />
                            )}
                            {this.state.collapseOpen ? (
                                <span>{prop.name}</span>
                            ) : (
                                ""
                            )}
                        </NavLink>
                    </NavItem>
                );
            } else if (prop.name === "Prep") {
                return (
                    //  style={{marginTop: 15}}
                    <NavItem
                        key={key}
                        active={
                            this.activeRoute(prop.path) === "active"
                                ? true
                                : false ||
                                  this.props.location.pathname
                                      .toLowerCase()
                                      .includes("prep-view")
                        }
                        onMouseOver={this.onMouseEnter}
                        onMouseLeave={this.onMouseLeave}
                    >
                        <NavLink
                            to={prop.path}
                            tag={NavLinkRRD}
                            onClick={() => this.setActive(prop.name)}
                            // onClick={this.closeCollapse}
                            // style={{borderLeftColor:  prop.path.includes(this.props.history.location.pathname) ? 'bg-success':'#ffff', borderLeftWidth: 5, borderLeftStyle : 'solid'}}
                            // activeClassName={this.state.activeName === prop.name ? "active" : null}
                        >
                            {/* style={{ fontSize: 45, marginLeft: 9 }} */}
                            {/* <i className={prop.icon} /> */}
                            {this.activeRoute(prop.path) === "active" ||
                            this.props.location.pathname
                                .toLowerCase()
                                .includes("prep-view") ? (
                                <img src={prop.image_active} alt={prop.name} />
                            ) : (
                                <img src={prop.image} alt={prop.name} />
                            )}
                            {this.state.collapseOpen ? (
                                <span>{prop.name}</span>
                            ) : (
                                ""
                            )}
                        </NavLink>
                    </NavItem>
                );
            } else {
                return (
                    // style={{marginTop: 20}}
                    <NavItem
                        key={key}
                        active={
                            this.state.activeName === prop.name ? true : false
                        }
                        onMouseOver={this.onMouseEnter}
                        onMouseLeave={this.onMouseLeave}
                    >
                        <NavLink
                            to={prop.path}
                            tag={NavLinkRRD}
                            onClick={() => this.setActive(prop.name)}
                            // onClick={this.closeCollapse}
                            // style={{borderLeftColor:  prop.path.includes(this.props.history.location.pathname)  ? 'bg-danger':'#ffff', borderLeftWidth: 5, borderLeftStyle : 'solid'}}

                            // activeClassName={this.state.activeName === prop.name ? "active" : null}
                        >
                            {/* style={{fontSize: 45, marginLeft : 5}} */}
                            {/* <i className={prop.icon} /> */}
                            {this.activeRoute(prop.path) === "active" ? (
                                <img src={prop.image_active} alt={prop.name} />
                            ) : (
                                <img src={prop.image} alt={prop.name} />
                            )}{" "}
                            {this.state.collapseOpen ? (
                                <span>{prop.name}</span>
                            ) : (
                                ""
                            )}
                        </NavLink>
                    </NavItem>
                );
            }
        });
    };

    render() {
        // console.log(this.props.history.location);

        const sidedrawerRoutes: IRoutes[] = [
            {
                name: "Dashboard",
                path: "/home",
                active: true,
                icon: "fa fa-users",
                image: dashboard_img,
                image_active: dashboard_active_img,
            },
            {
                name: "Patients",
                path: "/patient",
                active: false,
                icon: "fa fa-users",
                image: patient_img,
                image_active: patient_active_img,
            },
            {
                name: "Appointments",
                icon: "ni ni-calendar-grid-58",
                path: "/appointments",
                active: false,
                image: appointments_img,
                image_active: appointments_active_img,
            },
            {
                name: "Prep",
                icon: "fa fa-plus-square",
                path: "/prep",
                active: false,
                image: prepform_img,
                image_active: prepform_active_img,
            },
            {
                name: "Outreach",
                icon: "fa fa-clipboard",
                path: "/outreach",
                active: false,
                image: outreach_img,
                image_active: outreach_active_img,
            },
        ];

        return (
            <>
                <div
                    id='sidebar-container'
                    className={
                        this.state.collapseOpen
                            ? "d-none sidebar d-md-block sidebar-expanded"
                            : "d-none sidebar d-md-block  sidebar-collapsed"
                    }
                >
                    <Nav
                        style={{ marginTop: 80 }}
                        navbar
                        className='sidebar-sticky'
                    >
                        {this.createLinks(sidedrawerRoutes)}
                        {this.props.auth.user.super_admin ? (
                            <NavItem
                                key={"user-management"}
                                active={
                                    this.state.activeName === "user-management"
                                        ? true
                                        : false
                                }
                                onMouseOver={this.onMouseEnter}
                                onMouseLeave={this.onMouseLeave}
                            >
                                <NavLink
                                    onClick={() =>
                                        this.setActive("User Management")
                                    }
                                    to={"/user-management"}
                                    tag={NavLinkRRD}
                                    // activeClassName={this.state.activeName === "User Management" ? "active" : null}
                                >
                                    {/* <i className={"fa fa-id-card"} /> */}
                                    {this.activeRoute("/user-management") ===
                                    "active" ? (
                                        <img
                                            src={patient_active_img}
                                            alt='user-management'
                                        />
                                    ) : (
                                        <img
                                            src={patient_img}
                                            alt='user-management'
                                        />
                                    )}
                                    {this.state.collapseOpen ? (
                                        <span>User Management</span>
                                    ) : (
                                        ""
                                    )}
                                    {/* style={{ fontSize: 45 }}  */}
                                </NavLink>
                            </NavItem>
                        ) : null}
                        {/* </List>  */}
                        {/* </Collapse> */}
                        <List
                            className='mb-md-3'
                            style={{
                                position: "relative",
                                top: "auto",
                                bottom: 0,
                                boxSizing: "border-box",
                            }}
                        >
                            <NavItem
                                onMouseOver={this.onMouseEnter}
                                onMouseLeave={this.onMouseLeave}
                                key={10}
                            >
                                <button
                                    className='nav-link btn'
                                    onClick={this.toggleCollapse}
                                    style={{ margin: "auto" }}
                                >
                                    <img
                                        src={nav_open}
                                        alt='Open Menu'
                                        style={{ height: 30, width: 11 }}
                                    />
                                </button>
                            </NavItem>
                        </List>
                    </Nav>
                </div>
            </>
        );
    }
}
const styles = (theme: Theme) =>
    createStyles({
        drawer: {
            width: 50,
            flexShrink: 0,
        },
        icon: {
            active: "#fff",
            non_active: "#143840",
        },

        drawerPaper: {
            width: 50,
            marginTop: 50,
        },
        drawerContainer: {
            overflow: "hidden",
            // marginTop: 40
        },
        drawerFooter: {
            position: "relative",
            top: "auto",
            bottom: 0,

            boxSizing: "border-box",
            BorderTop: "5px solid gray",
        },
    });
export default withAuthState(withRouter(withStyles(styles)(SideBar)));
