import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import {
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    UncontrolledTooltip,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormText,
    Button,
    Modal,
    Card,
    CardBody,
    Collapse,
    CardHeader,
} from "reactstrap";
import withPatientState, { PatientProps } from "../Enhanchers/withPatientState";
import AsyncSelect from "react-select/async";
import withOutreachDD, { OutreachDDProps } from "../Enhanchers/withOutreachDD";
import AddPatientAddModal from "../Forms/PatientAddModal";
import { find_and_split } from "../utils/dd_util";
import withOutreachState, {
    OutreachProps,
} from "../Enhanchers/withOutreachState";
import {
    getOutreachFormFieldLabels,
    getDistrict,
    getSiteValue,
} from "../constants";
import ReactDatetime from "react-datetime";
import moment from "moment";


interface IProps {
    patientMode: boolean;
    patientaddFunct: any;
    patientEdit?: boolean;
    EpatDetails?: any;
    currentUserDistrict?: string;
    NewPatientModal?: any;
    setAddPatientModal?: any;
    subLogic?: any;
    DAG?: any;
}
//   interface IProps
type Props = PatientProps & IProps & OutreachDDProps & OutreachProps;

interface IPatientOutreach {
    patient_id: string;
    first_name_out: string;
    surname_out: string;
    preferred_name_out: string;
    date_of_birth_out: string;
    contact_number_out: string;
    gender_out: string;
    hiv_status_out?: string;
    counsellor_initials_out?: string;
    full_name_out?: string;
    test_conducted_out?: string;
    referral_out?: string;
    risk_assess_done_out?: string;
}
function PatientOutreachInit(props: Props) {
    const temp_pat: any = {
        outreach_indiv_id: "",
        patient_id: "",
        full_name_out: "",
        first_name_out: "",
        surname_out: "",
        preferred_name_out: "",
        date_of_birth_out: "",
        contact_number_out: "",
        gender_out: "",
        hiv_status_out: "",
        test_conducted_out: "",
        on_prep_out: "",
        on_art_out: "",
        referral_out: "",
        risk_assess_done_out: "",
        test_results_out: "",
        counsellor_initials_out: "",
    };
    const [counselors, setCounselors] = useState<string[]>([]);
    let temp: any = [];
    const [districtMapIndex, setDistrictMapIndex] = useState(0);
    const { NewPatientModal, setAddPatientModal } = props;
    console.log("test2");

    useEffect(() => {
        // GET COUNSELORS
        console.log("test");
        props.outreach_dd.district_dd?.map((dictionary_object: any) => {
            let tempDistrictValue: any = props.subLogic;
            tempDistrictValue = new RegExp(
                "'" + tempDistrictValue + ".*?'",
                "g"
            );
            console.log(tempDistrictValue);
            if (
                // dictionary_object.meta.key &&
                dictionary_object.meta.counselor_key &&
                dictionary_object?.branching_logic &&
                tempDistrictValue.test(dictionary_object?.branching_logic)
            ) {
                console.log("made it");
                var values = find_and_split(
                    dictionary_object?.select_choices_or_calculations
                );

                values.map((value: any) => {
                    console.log(value);
                    temp.push(value);
                });
                setCounselors(temp);
            }
        });
        if (temp.length > 0) {
            setCounselors(temp);
        }
    }, [props.outreach_dd.district_dd, props.subLogic, props.patientMode]);

    const [patient, setTempPatient] = useState<any>(temp_pat);
    const [inputValuePatientSearch, setInputValue] = useState("");
    const [_pat_mod, setPatientMode] = useState(true);
    const [testResult, setTestResult] = useState("");
    const [keypop, setKeypop] = useState("");
    const [hivTestDone, setHIVTestDone] = useState("");
    const [riskAssessmentDone, setRiskAssessmentDone] = useState("");
    const [addPatientClicked, setAddPatientClicked] = useState(false);

    const [knownNegativeModalVisible, setKnownNegativeModalVisible] = useState(
        false
    );

    // Hiv Status Logic
    // %5function <- cmd
    const [showPrep, setShowPrep] = useState(true);
    const [showArt, setShowArt] = useState(true);

    useEffect(() => {
        console.log(patient);
    }, [patient]);

    // Patient Modal creating new Patient
    const {
        register,
        handleSubmit,
        watch,
        errors,
        formState,
        setValue,
        control,
        setError,
        clearError,
    } = useForm();
    //Patient Search Component functions and state variables
    const [selectOption, setSelectOption] = useState("");
    const getPatient = (newValue: any, actionMeta: any) => {
        if (actionMeta.action === "select-option") {
            setSelectOption(newValue.value);
            setPatientMode(false);
            var pat_new_rec = newValue.value;

            var outreach_previous = props.outreach.outreach_all.filter(
                (out_: any) => {
                    if (out_.uid === pat_new_rec.uid) {
                        return out_;
                    }
                }
            );

            console.log(pat_new_rec);
            // console.log(outreach_previous);

            // if (outreach_previous.length === 0) {
            setTempPatient({
                uid: pat_new_rec.uid,
                // outreach_lwazi_id: pat_new_rec.uid,
                patient_id: pat_new_rec.uid || "",
                full_name_out:
                    pat_new_rec.first_name_id + " " + pat_new_rec.surname_id ||
                    "",
                first_name_out: pat_new_rec.first_name_id || "",
                surname_out: pat_new_rec.surname_id || "",
                preferred_name_out: pat_new_rec.preferred_name_id || "",
                date_of_birth_out: pat_new_rec.birthdate_id || "",
                contact_number_out: pat_new_rec.contact_number_id,
                gender_out: pat_new_rec.gender_id,
                hiv_status_out: "",
                test_conducted_out: "",
                referral_out: "",
                risk_assess_done_out: "",
                on_prep_out: "",
                on_art_out: "",
                test_results_out: "",
                counselor_responsible: "",
                key_population_id: pat_new_rec.key_population_id
                    ? pat_new_rec.key_population_id
                    : "",
            });
            setValue("first_name_out", pat_new_rec.first_name_id || "");
            setValue("surname_out", pat_new_rec.surname_id || "");
            setValue("preferred_name_out", pat_new_rec.preferred_name_id || "");
            setValue("date_of_birth_out", pat_new_rec.birthdate_id || "");
            setValue("contact_number_out", pat_new_rec.contact_number_id || "");
            // setValue("gender_out", pat_new_rec.gender_out);
        } else {
            setTempPatient({
                uid: pat_new_rec ? pat_new_rec.uid : "",
                outreach_lwazi_id: pat_new_rec ? pat_new_rec.uid : "",
                patient_id: pat_new_rec.record_id ? pat_new_rec.record_id : "",
                full_name_out:
                    pat_new_rec.first_name_id + " " + pat_new_rec.surname_id ||
                    "",
                first_name_out: pat_new_rec.first_name_id || "",
                surname_out: pat_new_rec.surname_id || "",
                preferred_name_out: pat_new_rec.preferred_name_id || "",
                date_of_birth_out: pat_new_rec.birthdate_id || "",
                contact_number_out: pat_new_rec.contact_number_id,
                gender_out: pat_new_rec.gender_id,
                hiv_status_out: outreach_previous[0].hiv_status_out,
                on_prep_out: outreach_previous[0].on_prep_out,
                test_conducted_out: outreach_previous[0].test_conducted_out,
                referral_out: outreach_previous[0].referral_out,
                risk_assess_done_out: outreach_previous[0].risk_assess_done_out,
                on_art_out: outreach_previous[0].on_art_out,
                test_results_out: outreach_previous[0].test_results_out,
                counselor_responsible: "",
                key_population_id: pat_new_rec.key_population_id
                    ? pat_new_rec.key_population_id
                    : "",
            });

            setValue("gender_out", outreach_previous[0].gender_id);
            setValue(
                "hiv_status_out",
                outreach_previous[0].hiv_status_out.toString()
            );
            setValue(
                "test_conducted_out",
                outreach_previous[0].test_conducted_out.toString()
            );
            setValue(
                "referral_out",
                outreach_previous[0].referral_out.toString()
            );
            setValue(
                "risk_assess_done_out",
                outreach_previous[0].risk_assess_done_out.toString()
            );
            setValue("on_art_out", outreach_previous[0].on_art_out.toString());
            setValue(
                "test_results_out",
                outreach_previous[0].test_results_out.toString()
            );
            setValue(
                "on_prep_out",
                outreach_previous[0].on_prep_out.toString()
            );

            setValue("first_name_out", pat_new_rec.first_name_id);
            setValue("surname_out", pat_new_rec.surname_id);
            setValue("preferred_name_out", pat_new_rec.preferred_name_id);
            setValue("date_of_birth_out", pat_new_rec.birthdate_id);
            setValue("contact_number_out", pat_new_rec.contact_number_id);
        }
        console.groupEnd();
    };

    const patientValidate = (values: any) => {
        console.log(values, patient);

        if (props.patientEdit) {
            var t_p = {
                ...patient,
                ...props.EpatDetails,
                ...values,
                outreach_indiv_id: props.EpatDetails.outreach_indiv_id,
                full_name_out: patient.full_name_out,
                gender_out: patient.gender_out.toString(),
            };
        } else {
            var t_p = {
                ...patient,
                ...values,
                gender_out: patient.gender_out.toString(),
            };
        }
        setTimeout(() => {
            if (
                Object.keys(errors).length === 0 &&
                errors.constructor === Object
            ) {
                console.log("setting - ", t_p);
                props.patientaddFunct(t_p);
                setTempPatient({
                    outreach_indiv_id: "",
                    patient_id: "",
                    full_name_out: "",
                    first_name_out: "",
                    surname_out: "",
                    preferred_name_out: "",
                    date_of_birth_out: "",
                    contact_number_out: "",
                    gender_out: "",
                    hiv_status_out: "",
                    test_conducted_out: "",
                    on_prep_out: "",
                    on_art_out: "",
                    referral_out: "",
                    risk_assess_done_out: "",
                    test_results_out: "",
                    counsellor_initials_out: "",
                });
                setAddPatientClicked(false);
                // setTempPatient(t_p)
            }
        }, 1000);
    };
    const [patientEditInit, setPatientEditInit] = useState(true);
    // console.log(_pat_mod);
    // console.log(temp_pat);
    if (props.patientEdit && _pat_mod === true) {
        if (Object.keys(props.EpatDetails).length > 0 && patientEditInit) {
            setPatientMode(false);
            // console.log(props.EpatDetails);
            setPatientEditInit(false);
            setTempPatient({
                ...props.EpatDetails,
                uid: props.EpatDetails.uid,
                // outreach_lwazi_id: props.EpatDetails.uid,
                patient_id: props.EpatDetails.record_out || "",
                full_name_out:
                    props.EpatDetails.first_name_out +
                        " " +
                        props.EpatDetails.surname_out || "",
                first_name_out: props.EpatDetails.first_name_out || "",
                surname_out: props.EpatDetails.surname_out || "",
                preferred_name_out: props.EpatDetails.preferred_name_id,
                key_population_id: props.EpatDetails.key_population_id || "",
                date_of_birth_out: props.EpatDetails.date_of_birth_out || "",
                contact_number_out: props.EpatDetails.contact_number_out,
                gender_out: props.EpatDetails.gender_out,
                hiv_status_out: props.EpatDetails.hiv_status_out,
                test_conducted_out: props.EpatDetails.test_conducted_out,
                referral_out: props.EpatDetails.referral_out,
                on_prep_out: props.EpatDetails.on_prep_out,
                risk_assess_done_out: props.EpatDetails.risk_assess_done_out,
                on_art_out: props.EpatDetails.on_art_out,
                test_results_out: props.EpatDetails.test_results_out,
                counselor_responsible: "",
            });

            setValue("hiv_status_out", props.EpatDetails.hiv_status_out);
            setValue(
                "test_conducted_out",
                props.EpatDetails.test_conducted_out
            );
            setValue("referral_out", props.EpatDetails.referral_out);
            setValue(
                "risk_assess_done_out",
                props.EpatDetails.risk_assess_done_out
            );
            setValue("on_art_out", props.EpatDetails.on_art_out);
            setValue("test_results_out", props.EpatDetails.test_results_out);
            setValue("on_prep_out", props.EpatDetails.on_prep_out);
        } else if (patientEditInit) {
            // console.log("00");
            setPatientMode(true);
            // console.log(props.EpatDetails);
            setPatientEditInit(false);

            setTempPatient({
                uid: "",
                // outreach_lwazi_id: "",
                patient_id: "",
                full_name_out: "",
                first_name_out: "",
                surname_out: "",
                preferred_name_out: "",
                date_of_birth_out: "",
                contact_number_out: "",
                gender_out: "",
                hiv_status_out: "",
                test_conducted_out: "",
                referral_out: "",
                on_prep_out: "",
                risk_assess_done_out: "",
                on_art_out: "",
                test_results_out: "",
                counselor_responsible: "",
                key_population_id: "",
            });
        }
    }

    const addPatientToTempList = (patient: any) => {
        setPatientMode(false);

        setTempPatient({
            ...patient,
            uid: patient.uid,
            // outreach_lwazi_id: patient.uid,
            patient_id: patient.record_id || "",
            full_name_out:
                patient.first_name_id + " " + patient.surname_id || "",
            first_name_out: patient.first_name_id || "",
            surname_out: patient.surname_id || "",
            preferred_name_out: patient.preferred_name_id,
            date_of_birth_out: patient.birthdate_id || "",
            contact_number_out: patient.contact_number_id,
            gender_out: patient.gender_id.toString(),
            hiv_status_out: "",
            test_conducted_out: "",
            referral_out: "",
            on_prep_out: "",
            risk_assess_done_out: "",
            on_art_out: "",
            test_results_out: "",
            key_population_id: patient.key_population_id || "",
        });
        console.groupEnd();
    };

    // var getGender = (value: any) => {
    //     var v_ = parseInt(value);
    //     if (v_ === 0) {
    //         return "none";
    //     }
    //     if (v_ === 1) {
    //         return "Male";
    //     }
    //     if (v_ === 2) {
    //         return "Female";
    //     }
    //     if (v_ === 3) {
    //         return "Transgender Man";
    //     }
    //     if (v_ === 4) {
    //         return "Transgender Woman";
    //     }
    //     if (v_ === 5) {
    //         return "Gender Non-Conforming";
    //     }
    // };
    var getYandN = (value: string) => {
        if (value === "0") {
            return "No";
        }
        if (value === "1") {
            return "Yes";
        }
    };

    var temp_pat_list: any[] = [];
    props.patients.patients.map((patient: any) => {
        temp_pat_list.push({
            value: patient,
            label: patient.first_name_id + " " + patient.surname_id,
        });
    });

    const handleInputChange = (newValue: string) => {
        const inputValue = newValue.replace(/\W/g, "");
        setInputValue(inputValue);
        return newValue;
    };

    const filterPatientList = (inputValue: string) => {
        console.log(inputValue);
        var list = temp_pat_list.filter((i: any) =>
            JSON.stringify(i.label)
                .trim()
                .toLowerCase()
                .includes(inputValue.trim().toLowerCase())
        );
        list.sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
        var slice_list = list.slice(0, 10);
        console.log(slice_list);

        return slice_list;
    };

    const loadOptions = (inputValue: any, callback: any) => {
        setTimeout(() => {
            callback(filterPatientList(inputValue));
        }, 900);
    };

    const renderKnownNegativeModal = () => {
        return (
            <Modal
                className='modal-dialog-centered '
                style={{ width: "30%", height: "40%" }}
                isOpen={knownNegativeModalVisible}
                toggle={() =>
                    setKnownNegativeModalVisible(!knownNegativeModalVisible)
                }
            >
                <div className='modal-header text-center'>
                    <p>
                        Known Negative can only be selected if the patient has
                        been tested within the last 6 weeks.
                    </p>
                </div>
                <Button
                    style={{
                        width: "30%",
                        alignSelf: "center",
                        marginBottom: 20,
                    }}
                    color='info'
                    type='button'
                    onClick={() =>
                        setKnownNegativeModalVisible(!knownNegativeModalVisible)
                    }
                >
                    OK
                </Button>
            </Modal>
        );
    };

    const { dirty, isSubmitting, touched, submitCount } = formState;
    console.log(dirty, isSubmitting, touched, submitCount, errors);

    const returnMissingFields = () => {
        let fields = [];
        for (const key in errors) {
            console.log(key);
            fields.push(getOutreachFormFieldLabels(key));
        }
        console.log(fields);
        return fields;
    };

    const renderFormValidationModal = () => {
        let missingFields = returnMissingFields();
        return (
            <Modal
                className='modal-dialog-centered '
                size='xs'
                // contentClassName='bg-gradient-info'
                isOpen={
                    addPatientClicked &&
                    Object.keys(errors).length !== 0 &&
                    errors.constructor === Object
                }
                // toggle={() => props.togglefunct(false)}
            >
                {/* <div className='modal-header text-center'> */}
                <button
                    aria-label='Close'
                    className='close'
                    data-dismiss='modal'
                    type='button'
                    style={{ position: "absolute", right: 5, top: 5 }}
                    onClick={() => setAddPatientClicked(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
                <div style={{ padding: 50 }}>
                    <div>
                        <p style={{ paddingLeft: 40 }}>
                            {" "}
                            Please Ensure that The following Sections Are
                            Completed:
                        </p>
                    </div>

                    {missingFields.map((field) => (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: 50,
                                maxHeight: 30,
                            }}
                        >
                            <i className='text-danger fa fa-square fa-lg'></i>
                            <p
                                style={{
                                    paddingLeft: 30,
                                    paddingTop: 5,
                                    alignSelf: "center",
                                }}
                            >
                                {field === "Contact Number" && patient.contact_number_out ?  "Please enter a 10 digital contact number in the following format: e.g. 082 000 0000" : field }
                            </p>
                        </div>
                    ))}
                </div>

                {/* </div> */}
            </Modal>
        );
    };

    return props.patientMode ? (
        <>
            {renderFormValidationModal()}
            {renderKnownNegativeModal()}
            {NewPatientModal ? (
                <AddPatientAddModal
                    isOpen={NewPatientModal}
                    togglefunct={setAddPatientModal}
                    setPatientToList={addPatientToTempList}
                />
            ) : null}
            <Card className='card-plain bg-secondary' style={{ width: "100%" }}>
                <CardHeader
                    role='tabpanel'
                    //   onClick={() =>toggle(indexV)}
                    //   aria-expanded={isOpen === indexV}
                >
                    <Row>
                        <Col xs='11'>
                            {!_pat_mod ? (
                                <h5 className='mb-0'>
                                    {" "}
                                    Patient Name:{" "}
                                    {patient.first_name_out +
                                        " " +
                                        patient.surname_out}
                                </h5>
                            ) : (
                                <h5 className='mb-0'> Select Patient</h5>
                            )}
                        </Col>
                        {/* <i className={isOpen === indexV ? "text-right fa fa-chevron-up": "text-right fa fa-chevron-down"}></i> */}
                    </Row>
                </CardHeader>
                <Collapse role='tabpanel' isOpen={true}>
                    <CardBody>
                        <form onSubmit={handleSubmit(patientValidate)}>
                            <Row>
                                {!patient.first_name_out ? (
                                    <>
                                        <Col xs='4' style={{ marginTop: "2%" }}>
                                            <AsyncSelect
                                                style={{
                                                    width: "97px !important",
                                                    marginTop: "7%",
                                                }}
                                                cacheOptions
                                                defaultOptions
                                                isClearable
                                                loadOptions={loadOptions}
                                                // value={selectOption}
                                                onChange={getPatient}
                                                //  options={temp_pat_list}
                                                onInputChange={
                                                    handleInputChange
                                                }
                                            />
                                        </Col>
                                        <Col xs='2' style={{ marginTop: "2%" }}>
                                            <Button
                                                className='btn-icon btn-2'
                                                color='info'
                                                type='button'
                                                onClick={() =>
                                                    setAddPatientModal(true)
                                                }
                                            >
                                                <span className='btn-inner--icon'>
                                                    <i className='fas fa-user-plus' />
                                                </span>
                                            </Button>
                                        </Col>
                                    </>
                                ) : (
                                    <Button
                                        color='warning'
                                        type='button'
                                        className='ml-1'
                                        onClick={() => {
                                            var current_status = _pat_mod;
                                            console.log(
                                                "before change",
                                                patient
                                            );
                                            const temp_pat = {
                                                ...patient,
                                                uid: "",
                                                patient_id: "",
                                                full_name_out: "",
                                                first_name_out: "",
                                                key_population_id: "",
                                                surname_out: "",
                                                preferred_name_out: "",
                                                date_of_birth_out: "",
                                                contact_number_out: "",
                                                gender_out: "",
                                                hiv_status_out:
                                                    patient.hiv_status_out ||
                                                    "",
                                                test_conducted_out:
                                                    patient.test_conducted_out ||
                                                    "",
                                                referral_out:
                                                    patient.referral_out || "",
                                                on_prep_out:
                                                    patient.on_prep_out || "",
                                                risk_assess_done_out:
                                                    patient.risk_assess_done_out ||
                                                    "",
                                                on_art_out:
                                                    patient.on_art_out || "",
                                                test_results_out:
                                                    patient.test_results_out ||
                                                    "",
                                            };
                                            console.log(
                                                "after change",
                                                temp_pat
                                            );
                                            setTempPatient(temp_pat);
                                            setPatientMode(!current_status);
                                        }}
                                    >
                                        Change Patient
                                    </Button>
                                )}
                            </Row>
                            <Row className='mt-3'>
                                <Col xs='4'>
                                    <label className='form-control-label'>
                                        Legal First Name:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            // disabled
                                            innerRef={register({
                                                required: true,
                                            })}
                                            name='first_name_out'
                                            className='form-control-alternative'
                                            type='text'
                                            value={patient.first_name_out}
                                            // defaultValue={
                                            //     patient.first_name_out
                                            // }
                                            onChange={(e) => {
                                                setTempPatient({
                                                    ...patient,
                                                    first_name_out:
                                                        e.target.value,
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs='4'>
                                    <label className='form-control-label'>
                                        Legal Surname:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            // disabled
                                            innerRef={register({
                                                required: true,
                                            })}
                                            name='surname_out'
                                            className='form-control-alternative'
                                            type='text'
                                            value={patient.surname_out}
                                            // defaultValue={patient.surname_out}
                                            onChange={(e) => {
                                                setTempPatient({
                                                    ...patient,
                                                    surname_out: e.target.value,
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs='6'>
                                    <label className='form-control-label'>
                                        Preferred Name:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            // disabled
                                            innerRef={register({
                                                required: false,
                                            })}
                                            name='preferred_name_out'
                                            className='form-control-alternative'
                                            type='text'
                                            value={patient.preferred_name_out}
                                            // defaultValue={
                                            //     patient.preferred_name_out
                                            // }
                                            onChange={(e) => {
                                                setTempPatient({
                                                    ...patient,
                                                    preferred_name_out:
                                                        e.target.value,
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs='4' className='ml-5'>
                                    <label className='form-control-label'>
                                        Key Population
                                    </label>
                                    <FormGroup>
                                        <Input
                                            innerRef={register({
                                                required: true,
                                                minLength: 1,
                                            })}
                                            name='key_population_id'
                                            type='select'
                                            className='form-control-alternative'
                                            placeholder='Key Population ID'
                                            value={
                                                keypop !== ""
                                                    ? keypop
                                                    : patient.key_population_id
                                            }
                                            // defaultValue={
                                            //     patient.key_population_id
                                            // }
                                            onChange={(e) => {
                                                e.preventDefault();
                                                setKeypop(e.target.value);
                                                setTempPatient({
                                                    ...patient,
                                                    key_population_id:
                                                        e.target.value,
                                                });
                                            }}
                                        >
                                            <option value='1'>Client</option>
                                            <option value='2'>Community</option>
                                            <option value='3'>FSW</option>
                                            <option value='4'>MSM</option>
                                            {/* <option value='5'>Transgender</option> */}
                                            <option value='7'>MSM-SW</option>
                                            <option value='10'>TGM</option>
                                            <option value='11'>TGM-SW</option>
                                            <option value='12'>TGNC</option>
                                            <option value='13'>TGNC-SW</option>
                                            <option value='8'>TGW</option>
                                            <option value='9'>TGW-SW</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                {/* <Col xs='4'>
                                    <label className='form-control-label'>
                                        Date of Birth:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            innerRef={register({
                                                required: true,
                                            })}
                                            name='date_of_birth_out'
                                            // disabled
                                            className='form-control-alternative'
                                            type='text'
                                            value={patient.date_of_birth_out}
                                            // defaultValue={
                                            //     patient.date_of_birth_out
                                            // }
                                            onChange={(e) => {
                                                setTempPatient({
                                                    ...patient,
                                                    date_of_birth_out:
                                                        e.target.value,
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                </Col> */}
                                <Col xs='4'>
                                    <label className='form-control-label'>
                                        Date of Birth
                                    </label>
                                    <FormGroup>
                                        <InputGroup className='input-group-alternative'>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <i className='ni ni-calendar-grid-58' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <ReactDatetime
                                                inputProps={{
                                                    // placeholder:,
                                                    value: patient.date_of_birth_out,
                                                    // name: "initiation_date",
                                                }}
                                                // isValidDate={valid}
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                onChange={(v) =>{
                                                    setTempPatient({
                                                        ...patient,
                                                        date_of_birth_out:moment(v).format("YYYY/MM/DD")
                                                    });
                                                }
                                                }
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col xs='4'>
                                    <label className='form-control-label'>
                                        Contact Number:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            innerRef={register({
                                                required: true,
                                                pattern: /^0[0-9]{9}$/,
                                            })}
                                            // name="first_name_id"

                                            name='contact_number_out'
                                            className='form-control-alternative'
                                            type='text'
                                            // disabled
                                            value={patient.contact_number_out}
                                            placeholder="e.g. 082 000 000"
                                            onChange={(e) => {
                                                setTempPatient({
                                                    ...patient,
                                                    contact_number_out:
                                                        e.target.value,
                                                });
                                            }}
                                        />
                                        {errors.contact_number_id ? (
                                            <a
                                                className='text-danger'
                                                style={{ fontSize: 8 }}
                                            >
                                                Please Enter a valid Contact
                                                Number
                                            </a>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs='6'>
                                    <label className='form-control-label'>
                                        Gender:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            // disabled
                                            type='select'
                                            name='gender_out'
                                            innerRef={register({
                                                required: true,
                                            })}
                                            className='form-control-alternative'
                                            value={patient.gender_out}
                                            onChange={(e) =>
                                                setTempPatient({
                                                    ...patient,
                                                    gender_out: e.target.value,
                                                })
                                            }
                                        >
                                            <option value=''>Gender</option>
                                            <option value='1'>Male </option>
                                            <option value='2'>Female </option>
                                            <option value='3'>
                                                Transgender Man{" "}
                                            </option>
                                            <option value='4'>
                                                Transgender Woman{" "}
                                            </option>
                                            <option value='5'>
                                                Gender Non-Conforming
                                            </option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                {patient.sex_at_birth_id && (
                                    <Col xs='6'>
                                        <FormGroup>
                                            <label className='form-control-label'>
                                                Sex At Birth:
                                            </label>
                                            <Input
                                                name='sex_at_birth_id'
                                                type='select'
                                                placeholder='Sex at Birth'
                                                className='form-control-alternative'
                                                value={patient.sex_at_birth_id}
                                                onChange={(e) =>
                                                    setTempPatient({
                                                        ...patient,
                                                        sex_at_birth_id:
                                                            e.target.value,
                                                    })
                                                }
                                            >
                                                <option value=''>
                                                    --Select--
                                                </option>
                                                <option value='2'>Male </option>
                                                <option value='1'>
                                                    Female{" "}
                                                </option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                )}
                            </Row>
                            <Row>
                                <Col xs='6'>
                                    <label className='form-control-label'>
                                        Risk Assessment Done:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            name='risk_assess_done_out'
                                            innerRef={register({
                                                required: true,
                                            })}
                                            className='form-control-alternative'
                                            type='select'
                                            defaultValue={
                                                patient.risk_assess_done_out
                                            }
                                            onChange={(e) => {
                                                setRiskAssessmentDone(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value=''>--Select--</option>

                                            <option value='1'>Yes</option>
                                            <option value='0'>No</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                {/* %5function */}
                                <Col xs='6'>
                                    <label className='form-control-label'>
                                        HIV Status:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            name='hiv_status_out'
                                            innerRef={register({
                                                required: true,
                                            })}
                                            className='form-control-alternative'
                                            type='select'
                                            defaultValue={
                                                patient.hiv_status_out
                                            }
                                            onChange={(e) => {
                                                e.preventDefault();
                                                if (
                                                    e.target.value.length !== 0
                                                ) {
                                                    if (e.target.value == "1") {
                                                        setShowPrep(false);
                                                        setShowArt(true);
                                                    } else if (
                                                        e.target.value === "2"
                                                    ) {
                                                        setShowPrep(true);
                                                        setShowArt(false);
                                                        setKnownNegativeModalVisible(
                                                            true
                                                        );
                                                    } else if (
                                                        e.target.value === "3"
                                                    ) {
                                                        setShowPrep(false);
                                                        setShowArt(false);
                                                    }
                                                } else {
                                                    setShowPrep(true);
                                                    setShowArt(true);
                                                }
                                            }}
                                        >
                                            <>
                                                <option value=''>
                                                    --Select--
                                                </option>
                                                <option value='1'>
                                                    Known Positive
                                                </option>
                                                <option value='2'>
                                                    Known Negative
                                                </option>
                                                <option value='3'>
                                                    Unknown
                                                </option>
                                            </>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col xs='6'>
                                    <label className='form-control-label'>
                                        HIV Test Done:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            name='test_conducted_out'
                                            innerRef={register({
                                                required: true,
                                            })}
                                            className='form-control-alternative'
                                            type='select'
                                            defaultValue={
                                                patient.test_conducted_out
                                            }
                                            onChange={(e) => {
                                                setHIVTestDone(e.target.value);
                                            }}
                                        >
                                            <option value=''>--Select--</option>

                                            <option value='1'>Yes</option>
                                            <option value='0'>No</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/* //Status Change */}
                            <Row>
                                {showPrep ? (
                                    <Col xs='6'>
                                        <label className='form-control-label'>
                                            On Prep?:{" "}
                                        </label>
                                        <FormGroup>
                                            <Input
                                                name='on_prep_out'
                                                innerRef={register({
                                                    required: showPrep,
                                                })}
                                                className='form-control-alternative'
                                                type='select'
                                                defaultValue={
                                                    patient.on_prep_out
                                                }
                                            >
                                                <>
                                                    <option value=''>
                                                        --Select--
                                                    </option>
                                                    <option value='1'>
                                                        Yes
                                                    </option>
                                                    <option value='0'>
                                                        No
                                                    </option>
                                                </>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                ) : null}

                                {showArt ? (
                                    <Col xs='6'>
                                        <label className='form-control-label'>
                                            On ART? :{" "}
                                        </label>
                                        <FormGroup>
                                            <Input
                                                name='on_art_out'
                                                innerRef={register({
                                                    required: showArt,
                                                })}
                                                className='form-control-alternative'
                                                type='select'
                                                defaultValue={
                                                    patient.on_art_out
                                                }
                                            >
                                                <option value=''>
                                                    --Select--
                                                </option>
                                                <option value='1'>Yes</option>
                                                <option value='0'>No</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                ) : null}
                            </Row>
                            <Row>
                                {hivTestDone === "1" && (
                                    <Col xs='6'>
                                        <label className='form-control-label'>
                                            HIV Test Results:{" "}
                                        </label>
                                        <FormGroup>
                                            <Input
                                                name='test_results_out'
                                                innerRef={register({
                                                    required: false,
                                                })}
                                                className='form-control-alternative'
                                                type='select'
                                                onChange={(e) => {
                                                    setTestResult(
                                                        e.target.value
                                                    );
                                                }}
                                                defaultValue={
                                                    patient.test_results_out
                                                }
                                            >
                                                <option value=''>
                                                    --Select--
                                                </option>
                                                <option value='1'>
                                                    HIV Positive
                                                </option>
                                                <option value='2'>
                                                    HIV Negative
                                                </option>
                                                <option value='3'>
                                                    Indeterminate
                                                </option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                )}

                                {/* RENDER IF HIV TEST RESULTS FILLED */}
                                {hivTestDone === "1" ||
                                riskAssessmentDone === "1" ? (
                                    <Col xs='6'>
                                        <label className='form-control-label'>
                                            Counsellor Responsible:{" "}
                                        </label>
                                        <FormGroup>
                                            <Input
                                                name='counselor_responsible'
                                                innerRef={register({
                                                    required: false,
                                                })}
                                                className='form-control-alternative'
                                                type='select'
                                                defaultValue={
                                                    patient.counselor_responsible
                                                }
                                            >
                                                <option value=''>
                                                    --Select--
                                                </option>
                                                {counselors.map(
                                                    (counselor: any) => (
                                                        <option
                                                            value={
                                                                counselor.index
                                                            }
                                                        >
                                                            {counselor.value}
                                                        </option>
                                                    )
                                                )}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                ) : null}
                            </Row>
                            <Row>
                                <Col xs='6'>
                                    <label className='form-control-label'>
                                        Referral for Additional Services Made:{" "}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            name='referral_out'
                                            innerRef={register({
                                                required: true,
                                            })}
                                            className='form-control-alternative'
                                            type='select'
                                            defaultValue={patient.referral_out}
                                        >
                                            <option value=''>--Select--</option>

                                            <option value='1'>Yes</option>
                                            <option value='0'>No</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {patient.first_name_out.length > 0 ? (
                                <>
                                    <Col xs='6' className='text-right'>
                                        <Button
                                            color='secondary'
                                            type='button'
                                            onClick={() => {
                                                if (
                                                    Object.keys(errors)
                                                        .length === 0 &&
                                                    errors.constructor ===
                                                        Object
                                                ) {
                                                    props.patientaddFunct(null);
                                                }
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color='success'
                                            type='submit'
                                            onClick={() => {
                                                handleSubmit(patientValidate);

                                                setTimeout(() => {
                                                    setAddPatientClicked(true);
                                                }, 1050);
                                            }}
                                        >
                                            Add Patient To Outreach
                                        </Button>
                                    </Col>
                                </>
                            ) : null}
                        </form>
                    </CardBody>
                </Collapse>
            </Card>
        </>
    ) : null;
}

export default withOutreachState(
    withOutreachDD(withPatientState(PatientOutreachInit))
);
