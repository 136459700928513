import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import * as actions from './actions';

export interface IPrepState {
  readonly prep_records: any;
}

export type PrepActions = ActionType<typeof actions>;

const initialState: IPrepState = {
  prep_records: []
};

export default combineReducers<IPrepState, PrepActions>({
  prep_records: (state = initialState.prep_records, action) => {
    switch (action.type) {
      case getType(actions.setPrep): {
        return action.payload.prep_records;
      }
      default: {
        return state;
      }
    }
  },

});
