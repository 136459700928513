import React from "react";
import logo from "../logo.svg";
import "../App.css";
import TopNavbarMain from "../Components/TopNavbarMain";
import {
    CssBaseline,
    WithStyles,
    Toolbar,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    withStyles,
    Theme,
    createStyles,
    Button,
} from "@material-ui/core";
import MainLayout from "../Layouts/MainLayout";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";
import {
    Badge,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
} from "reactstrap";
import { CardBody, CardTitle, Col, Card } from "reactstrap";
import AddIcon from "@material-ui/icons/Add";

import Header from "../Components/Header";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IPatient } from "../@types/patient";
import firebase from "../Services/firebase";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import PatientTable from "../Components/PatientTable";

import AsyncSelect from "react-select/async";
import OutreachForm from "../Forms/OutreachForm";
import withOutreachDD, { OutreachDDProps } from "../Enhanchers/withOutreachDD";
import OutreachEditForm from "../Forms/OutreachEditForm";
import GroupActivityEditForm from "../Forms/GroupActivityEditForm";

interface IState {
    outreachRecord: any;
    loading: boolean;
}

type Props = WithStyles<typeof styles> &
    RouteComponentProps<{ id: string }> &
    AuthProps;

class GroupActivityEdit extends React.Component<Props, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            outreachRecord: {},
            loading: true,
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            var ref = firebase.db
                .collection(
                    "main/" +
                        this.props.auth.user.data_access_group +
                        "/outreach_and_groupactivity"
                )
                .where("outreach_lwazi_id", "==", this.props.match.params.id)
                .get()
                .then((data: any) => {
                    var temp_list: any[] = [];
                    console.log(data);

                    data.docs.map((outreach_rec: any) => {
                        temp_list.push({
                            outreach_indiv_id: outreach_rec.id,
                            ...outreach_rec.data(),
                        });
                    });
                    var final_item = {
                        uid: temp_list[0].outreach_lwazi_id,
                        activity_date_outreach:
                            temp_list[0].activity_date_outreach,
                        activity_type_out: temp_list[0].activity_type_out,
                        sub_district_out: temp_list[0].sub_district_out,
                        peer_responsible_out: temp_list[0].peer_responsible_out,
                        form_complete: temp_list[0].form_complete,
                        // outreach_lwazi_id: temp_list[0].outreach_lwazi_id,
                        district: temp_list[0].kp_programme_site_out,
                        meta: temp_list[0].meta,
                        patients: temp_list,
                    };

                    console.log(final_item);
                    this.setState({
                        outreachRecord: final_item,
                        loading: false,
                    });
                });
        } else {
            window.alert("No outreach record id found");
            this.props.history.goBack();
        }
    }

    render() {
        console.log(this.state);
        if (!this.state.loading) {
            return (
                <MainLayout>
                    <div className='header bg-gradient-secondary pb-8 pt-5 pt-md-8'>
                        <Container fluid>
                            <div className='header-body'>
                                {/* Card stats */}
                                <Row>
                                    <Col xs='1'>
                                        <Button
                                            style={{
                                                backgroundColor: "transparent",
                                                marginTop: 8,
                                            }}
                                            onClick={() => {
                                                this.props.history.goBack();
                                            }}
                                        >
                                            <i
                                                className='fa fa-arrow-left'
                                                style={{ fontSize: 20 }}
                                            />
                                        </Button>
                                    </Col>
                                    <Col xs='4'>
                                        <h1>Edit</h1>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    {/* Page content */}
                    <Container className='mt--6' fluid>
                        {/* <h1>Patients</h1> */}

                        <Card className='bg-secondary shadow'>
                            <CardHeader className='bg-white border-0'>
                                <h3>
                                    LWAZI-ID:{" "}
                                    {
                                        this.state.outreachRecord
                                            .outreach_lwazi_id
                                    }
                                </h3>
                            </CardHeader>
                            <CardBody>
                                {Object.keys(this.state.outreachRecord).length >
                                0 ? (
                                    <GroupActivityEditForm
                                        outreach_record={
                                            this.state.outreachRecord
                                        }
                                    />
                                ) : null}
                            </CardBody>
                        </Card>
                    </Container>
                </MainLayout>
            );
        } else {
            return null;
        }
    }
}
const styles = (theme: Theme) =>
    createStyles({
        table: {
            minWidth: 650,
        },
        tableRow: {
            "&:hover": {
                borderLeft: "#17A3AB",
                borderLeftWidth: "10",
                borderLeftStyle: "solid",
                boxShadow: "0 3px 5px 2px rgba(0,0,0,0.1)",
            },
        },
    });
export default withOutreachDD(
    withAuthState(withRouter(withStyles(styles)(GroupActivityEdit)))
);
