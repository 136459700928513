import React from 'react'
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'

import { INITIAL_EVENTS, createEventId } from '../utils/event-utils'

// react component used to create sweet alerts
import ReactBSAlert from 'react-bootstrap-sweetalert'
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Modal,
  Row,
  Container,
  Col,
  TabContent,
  TabPane,
  NavItem,
  Nav,
} from 'reactstrap'

import { Button as MButton } from '@material-ui/core'
import MainLayout from '../Layouts/MainLayout'
import moment from 'moment'
import CalendarMain from './CalendarMain'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import AppointmentManagerModal from '../Forms/AppointmentManagerModal'
import { IPatient } from '../@types/patient'

// let calendar: Calendar;
interface IState {
  events?: IAppointment[]
  alert?: any
  currentDate: any
  weekendsVisible?: boolean
  currentEvents?: any
  showManager: boolean
  activeTab: string
}

export interface IAppointment {
  title: string
  start: any
  end: any
  className?: any
  id: any
  allDay?: any
  patient?: IPatient
  date?: any
  status?: any
  clinic?: any
  // appointment update
  // eventTitle?: string;
  // startDate?: any;
  // endDate?: any;
  // className?: any;
}

type Props = RouteComponentProps

// const calendarRef

class Appointments extends React.Component<Props, IState> {
  state = {
    currentDate: moment().format('YYYY-MM-DD'),
    showManager: false,
    activeTab: '1',
  }
  calendarRef: any
  constructor(props: any) {
    super(props)
    this.calendarRef = React.createRef() // this.fullCalendar.content =null
  }

  changeView = (newView: string) => {
    this.calendarRef.changeView(newView)
    this.setState({
      currentDate: this.calendarRef.view.title,
    })
  }

  componentDidMount() {
    this.calendarRef = React.createRef()
  }
  componentDidUpdate() {
    this.calendarRef = React.createRef()
  }

  updateShowManager = (state: boolean) => {
    this.setState({
      showManager: state,
    })
  }

  render() {
    this.calendarRef = React.createRef()

    // console.log(this.state);
    return (
      <>
        {this.state.showManager ? (
          <AppointmentManagerModal
            isOpen={this.state.showManager}
            togglefunct={this.updateShowManager}
            _isPrepCheck={false}
            _meds_dispense={{
              dispense: false,
              months: moment().format('YYYY/MM/DD'),
            }}
          />
        ) : null}
        <MainLayout>
          {/* Appointments Top Menu */}

          <Container fluid style={{ marginTop: 30 }}>
            <Row>
              <Col>
                <div
                  style={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <h1>Appointments</h1>
                  <div style={{ display: 'flex', marginRight: 20 }}>
                    <Button
                      className='menu-btn'
                      style={{
                        borderColor: '#17A3AB',
                        backgroundColor: '#17A3AB',
                      }}
                      type='button'
                      onClick={() => {
                        this.setState({
                          showManager: true,
                        })
                      }}
                    >
                      <span className='btn-inner--icon'>
                        <i className='far fa-plus-square fa-2x' />
                      </span>
                      <span className='btn-inner--text ml-1'>
                        Appointments Manager
                      </span>
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          {/* TableCalender */}
          <CalendarMain />
        </MainLayout>
      </>
    )
  }
}

export default withRouter(Appointments)
