import {
    Badge,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Col,
    Button,
    CardBody,
    Card,
    Modal,
    FormGroup,
    Input,
} from "reactstrap";
import React, { useState } from "react";
// import {Button} from "@material-ui/core" ;
import AddIcon from "@material-ui/icons/Add";
import { withRouter, RouteComponentProps } from "react-router-dom";

// eslint-disable-next-line import/first
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import _ from "lodash";
import ExportManager from "./ExportManager";
import moment from "moment";

// interface IProps{
//   list
// }
interface IState {
    prepCheck?: boolean;
    app_: any;
    app_show: boolean;
    filterClinic: string;
    filterAppointments: string;
    filterStatus: string;
    selected: any[];
    exportManager: boolean;
    filterDate: string;
}
interface IProps {
    events_: any;
    isFilter: string;
    _filterValue?: any;
    setList: any;
}

type Props = RouteComponentProps & AuthProps & IProps;

class AppointmentTable extends React.Component<Props, IState> {
    temp: any = {};
    state = {
        app_: this.temp,
        app_show: false,
        filterClinic: "",
        filterAppointments: "",
        filterStatus: "",
        selected: [],
        exportManager: false,
        filterDate: ""
    };

    // const [clinicVal, setClinicVal] = useState("")
    _setClinicVal = (v: any) => {
        console.log(v.target.value);
        this.setState({ filterClinic: v.target.value });
        this.props.setList({ filter: "clinic", value: v.target.value });
        return;
    };

    _setAppointmentDate = (v: any) =>{
        this.setState({ filterDate: v.target.value });
        this.props.setList({ filter: "start", value: v.target.value });
        return;
    }

    _setStatus = (v: any) => {
        console.log(v.target.value);
        this.setState({ filterStatus: v.target.value });
        this.props.setList({ filter: "status", value: v.target.value });
        return;
    };

    clinicOptions = () => {
        const selectOptions: any = {
            0: "Pretoria Park",
            1: "Sallisburg Clinic",
        };

        // _.uniq(this.props.events_,x,i =>{ return x.clinic}).map((op:any)=>{
        //   return (
        //     <option value="op">selectOptions[op]</option>
        //   )
        // })
    };

    toggleExportManager = (toggle?: boolean) => {
        if (toggle) {
            this.setState({
                exportManager: toggle,
            });
        } else {
            this.setState({
                exportManager: !this.state.exportManager,
            });
        }
    };

    handleOnSelect = (row: any, isSelect: any) => {
        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row.uid],
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter((x) => x !== row.uid),
            }));
        }
    };
    handleOnSelectAll = (isSelect: any, rows: any) => {
        // console.log(isSelect, rows);
        const ids = rows.map((r: any) => r.uid);
        if (isSelect) {
            this.setState(() => ({
                selected: ids,
            }));
        } else {
            this.setState(() => ({
                selected: [],
            }));
        }
    };
    exportCSV = () => {
        console.log("exporting csv");
        let csvContent = "data:text/csv;charset=utf-8,";
        var pat_k: any[] = [];
        if (this.state.selected.length > 0) {
            pat_k = this.state.selected;
            var csv_data = this.props.events_.filter((i: any) => {
                // console.log(i);
                return pat_k.includes(i.uid);
            });
            console.log(csv_data);
            var data: any, filename: any, link: any;
            var csv = this.convertArrayOfObjectsToCSV({
                data: csv_data,
            });
            if (csv == null) return;

            filename = "export.csv";

            if (!csv.match(/^data:text\/csv/i)) {
                csv = "data:text/csv;charset=utf-8," + csv;
            }
            data = encodeURI(csv);

            link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", filename);
            link.click();
        }
    };

    convertArrayOfObjectsToCSV = (args: any) => {
        var result: any,
            ctr: any,
            keys: any,
            columnDelimiter: any,
            lineDelimiter: any,
            data: any;

        data = args.data || null;
        if (data == null || !data.length) {
            return null;
        }

        columnDelimiter = args.columnDelimiter || ",";
        lineDelimiter = args.lineDelimiter || "\n";

        keys = Object.keys(data[0]);

        result = "";
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach((item: any) => {
            ctr = 0;
            keys.forEach((key: any) => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    showApp = (app_d: any) => {
        console.log(app_d);
        this.setState({
            app_: app_d,
            app_show: true,
        });
    };

    toggleModal = (b: any) => {
        this.setState({
            app_show: !this.state.app_show,
        });
    };

    render() {
        // const { ExportCSVButton } = CSVExport;
        const selectRow = {
            mode: "checkbox",
            clickToSelect: true,
        };

        const pagination = paginationFactory({
            page: 1,
            alwaysShowAllBtns: true,
            showTotal: false,
            withFirstAndLast: false,
            sizePerPageRenderer: ({ options, onSizePerPageChange }) => (
                <>
                    <section
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "brandon-grotesque",
                                fontWeight: 600,
                                fontSize: "0.813rem",
                                color: "#143840",
                                textTransform: "uppercase",
                                marginRight: 10,
                                marginTop: 15,
                            }}
                        >
                            Showing
                        </p>
                        <select
                            style={{
                                width: 80,
                                height: 40,
                                paddingLeft: 10,
                                paddingRight: 10,
                                borderRadius: 3,
                                color: "#143840",
                                fontSize: "0.75rem",
                            }}
                            name='datatable-basic_length'
                            aria-controls='datatable-basic'
                            className='form-control'
                            onChange={(e) =>
                                onSizePerPageChange(e.target.value)
                            }
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                        </select>
                    </section>
                </>
            ),
        });

        console.log(this.props.events_);

        const { SearchBar } = Search;

        const camelizeText = (str: string) => {
            return str.replace(/\W+(.)/g, function (match: any, chr: any) {
                return chr.toUpperCase();
            });
        };

        const columns = [
            // {
            //   dataField: 'patient',
            //   text: 'Patient',
            //   formatter: (cell: any, row: any) => {
            //     return cell.first_name_id + '  ' + cell.surname_id
            //   },
            //   // sort: true,
            // },
            {
                dataField: "prep_stage",
                text: "Appointment Type",
                formatter: (cell: any, row: any) => {
                    if (cell === 0) {
                        return "Prep Initiation";
                    } else {
                        return "Prep Follow-up " + cell;
                    }
                },
            },

            {
                dataField: "start",
                text: "Scheduled Date",
                sort: true,
                // formatter: (cell: number, row: any) => {
                //     // <option value='1'>Today</option>
                //     //             <option value='2'>This Week</option>
                //     //             <option value='3'>This Month</option>
                //     //             <option value='4'>Next Month</option>
                //     const selectOption: any = {
                //             1: "Today",
                //             2: "This Week",
                //             3: "This Month",
                //             4: "Next Month"
                //     };
                //     const selection = selectOption[cell];
                //     switch (selection) {
                //         case "Today":
                //             return new Date().toString();
                    
                //         default:
                //             break;
                //     }
                // },
            },
            {
                dataField: "clinic",
                text: "Clinic",
                sort: true,
                formatter: (cell: number, row: any) => {
                    const selectOptions: any = {
                        0: "Pretoria Park",
                        1: "Sallisburg Clinic",
                    };
                    console.log(selectOptions[cell])
                    return selectOptions[cell];
                },
            },
            // {
            //   dataField: "title",
            //  text: "Prep Appointment Type",
            //     sort: true,
            // },
            {
                dataField: "status",
                text: "Status",
                sort: true,
                formatter: (cell: any, row: any) => {
                    if (cell === "attended") {
                        return (
                            <>
                                <span className='badge badge-pill badge-complete'>
                                    Attended
                                </span>
                            </>
                        );
                    } else if (cell === "missed") {
                        return (
                            <>
                                <span className='badge badge-pill badge-incomplete'>
                                    Missed
                                </span>
                            </>
                        );
                    } else if (cell === "upcoming") {
                        return (
                            <>
                                <span className='badge badge-pill badge-review'>
                                    Upcoming
                                </span>
                            </>
                        );
                    }
                },
            },

            {
                dataField: "",
                text: "Actions",
                sort: true,
                formatter: (cell: any, row: any) => {
                    // console.log(row);
                    return (
                        // <div style={{marginLeft: -25}}>
                        <Button
                            className='btn-primary'
                            type='button'
                            onClick={() => this.showApp(row)}
                        >
                            View
                        </Button>
                        // </div>
                    );
                },
            },
        ];

        return (
            <>
                <ExportManager
                    isOpen={this.state.exportManager}
                    list={this.props.events_}
                    togglefunct={this.toggleExportManager}
                />
                <Modal
                    className='modal-dialog-centered'
                    contentClassName='bg-secondary'
                    isOpen={this.state.app_show}
                >
                    <div className='modal-header py-3'>
                        <h3 className='heading mt-4'>Appointment</h3>

                        <button
                            aria-label='Close'
                            className='close text-right'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleModal(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body py-3'>
                        <div className='text-center'>
                            <h2>{this.state.app_.title}</h2>
                        </div>
                        <Row>
                            <Col sm='3'>Date: </Col>
                            <Col sm='9'>{this.state.app_.start}</Col>
                        </Row>
                        <Row>
                            <Col sm='3'>Status: </Col>
                            <Col sm='9'>{this.state.app_.status}</Col>
                        </Row>
                        {/* {JSON.stringify(this.state.app_)} */}
                    </div>
                    <div className='modal-footer'>
                        <Row>
                            <Col xs='6'>
                                <Button
                                    className='btn-success'
                                    type='button'
                                    onClick={() => {
                                        this.props.history.push(
                                            "/prep-view?ref=prep_id&id=" +
                                                this.state.app_.patient_uid
                                        );
                                    }}
                                >
                                    View
                                </Button>
                            </Col>

                            <Col xs='4' className='text-right'>
                                <Button
                                    className='btn-primary'
                                    type='button'
                                    onClick={() => this.toggleModal(false)}
                                >
                                    Done
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Row>
                    <Col xs='3' style={{ marginTop: "20px" }}>
                        <label className='form-control-label'>Clinic:</label>
                        <FormGroup>
                            <Input
                                name='clinic'
                                type='select'
                                value={this.state.filterClinic}
                                className='form-control-alternative'
                                onChange={(v) => this._setClinicVal(v)}
                                // disabled={this.sclinicVal !== ""}
                            >
                                <option value=''>Select</option>
                                <option value='0'>Pretoria Park Clinic</option>
                                <option value='1'>Sallisburg Clinic</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col xs='3' style={{ marginTop: "20px" }}>
                        <label className='form-control-label'>
                            Appointment Date:
                        </label>
                        <FormGroup>
                            <Input type='select' name='months_dispensed' value={this.state.filterDate} onChange={(v) => this._setAppointmentDate(v)}>
                            <option value=''>Select</option>
                                <option value='1'>Today</option>
                                <option value='2'>This Week</option>
                                <option value='3'>This Month</option>
                                <option value='4'>Next Month</option>
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col xs='3' style={{ marginTop: "20px" }}>
                        <label className='form-control-label'>Status:</label>
                        <FormGroup>
                            <Input
                                type='select'
                                name='app_status'
                                value={this.state.filterStatus}
                                onChange={(v) => this._setStatus(v)}
                            >
                                <option value=''>Select</option>
                                <option value='attended'>Attended</option>
                                <option value='missed'>Missed</option>
                                <option value='upcoming'>Upcoming</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col xs='3' style={{ marginTop: 57 }}>
                        <Button
                            className='menu-btn'
                            style={{
                                borderColor: "#17A3AB",
                                backgroundColor: "#17A3AB",
                            }}
                            type='button'
                            onClick={() => this.toggleExportManager(true)}
                        >
                            <span className='btn-inner--icon'>
                                <i className='fas fa-print fa-2x' />
                            </span>
                            <span className='btn-inner--text ml-1'>
                                CSV Export
                            </span>
                        </Button>
                    </Col>
                </Row>
                <ToolkitProvider
                    data={this.props.events_}
                    keyField='uid'
                    columns={columns}
                    bootstrap4={true}
                    search={{ searchFormatted: true }}

                    // exportCSV
                >
                    {(props: any) => (
                        <div>
                            <Row>
                                {/* <SearchBar { ...props.searchProps }  delay={0}/> */}

                                {/* <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton> */}
                            </Row>
                            <BootstrapTable
                                style={{ marginBottom: 5 }}
                                {...props.baseProps}
                                // rowEvents={this.props.onRowSelect}
                                bordered={false}
                                pagination={pagination}
                                // selectRow={ selectRow }
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </>
        );
    }
}

export default withAuthState(withRouter(AppointmentTable));
