import { REDCAP_API_KEY } from "../constants";
const axios = require("axios");

const apiClientCSV = axios.create({
    baseURL: "https://redcap.core.wits.ac.za/redcap/",
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "text/csv; charset=utf-8",
    },
});

const apiClientJSON = axios.create({
    baseURL: "https://redcap.core.wits.ac.za/redcap/",
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
    },
});

export const generate_id = async () => {
    const request_params = `token=${REDCAP_API_KEY}&content=generateNextRecordName`;
    try {
        const response = await apiClientCSV.post("api/", request_params);
        const id_list = response.data;
        console.log(response);

        return id_list;
    } catch (err) {
        if (err && err.response) {
            const axiosError = err;
            return axiosError.response.data;
        }
        throw err;
    }
};

export const add_patient_toRedcap = async (patient_obj: string) => {
    const request_params = `token=${REDCAP_API_KEY}&content=record&format=json&type=flat&overwriteBehavior=normal&forceAutoNumber=false&data=[" +
        ${patient_obj} +
        "]&returnContent=count&returnFormat=json`;
    try {
        const response = await apiClientCSV.post(
            "api/",
            request_params

            //   {params:{
            //     "token": "0B07BAA4C0A1A8930EF5B16CD9115200",
            //     "content": "record",
            //     "format": "csv",
            //     "type": "flat",
            //     "csvDelimiter": ";",
            //     "forms[0]": "client_id",
            //     "rawOrLabel": "label",
            //     "rawOrLabelHeaders": "raw",
            //     "exportCheckboxLabel": "false",
            //     "exportSurveyFields": "false",
            //     "returnFormat": "csv"
            //   }}
        );
        const id_list = response.data;
        console.log(response);
        return id_list;
    } catch (err) {
        if (err && err.response) {
            const axiosError = err;
            return axiosError.response.data;
        }

        throw err;
    }
};

export const get_patientList = async () => {
    const request_params = `token=${REDCAP_API_KEY}&content=record&format=csv&type=flat&csvDelimiter=%3B&forms%5B0%5D=client_id&rawOrLabel=label&rawOrLabelHeaders=raw&exportCheckboxLabel=false&exportSurveyFields=false&exportDataAccessGroups=true&returnFormat=csv`;
    try {
        const response = await apiClientCSV.post(
            "api/",
            request_params

            //   {params:{
            //     "token": "0B07BAA4C0A1A8930EF5B16CD9115200",
            //     "content": "record",
            //     "format": "csv",
            //     "type": "flat",
            //     "csvDelimiter": ";",
            //     "forms[0]": "client_id",
            //     "rawOrLabel": "label",
            //     "rawOrLabelHeaders": "raw",
            //     "exportCheckboxLabel": "false",
            //     "exportSurveyFields": "false",
            //     "returnFormat": "csv"
            //   }}
        );
        const patient_list = response.data;
        return patient_list;
    } catch (err) {
        if (err && err.response) {
            const axiosError = err;
            return axiosError.response.data;
        }

        throw err;
    }
};

//   &records[0]=1429-0

export const get_record = async (patient_id: string) => {
    const request_params =
        `token=${REDCAP_API_KEY}&content=record&format=json&type=flat&records[0]=` +
        patient_id +
        "&forms[0]=client_id&forms[1]=intake&rawOrLabel=label&rawOrLabelHeaders=raw&exportCheckboxLabel=false&exportSurveyFields=true&exportDataAccessGroups=false&returnFormat=json";

    // 'token=0B07BAA4C0A1A8930EF5B16CD9115200'+
    // '&content=record&format=json&type=flat&csvDelimiter=%3B&forms%5B0%5D=client_id'+
    // '&rawOrLabel=label&rawOrLabelHeaders=raw&exportCheckboxLabel=false&exportSurveyFields=false&exportDataAccessGroups=true&'+
    // 'returnFormat=json'+
    // ' &records[0]='+patient_id
    try {
        const response = await apiClientJSON.post("api/", request_params);
        //console.log({response})
        const patient_list = response.data;
        return patient_list;
    } catch (err) {
        if (err && err.response) {
            const axiosError = err;
            return axiosError.response.data;
        }

        throw err;
    }
};

//  &forms[1]=phc_intake_form&
export const get_intake = async (patient_id: string) => {
    const request_params =
        `token=${REDCAP_API_KEY}&content=record&format=json&type=flat&records[0]=` +
        patient_id +
        "&events[0]=client_id_arm_1&forms[0]=phc_intake_form&events[0]=intake_open_chart_arm_1&rawOrLabel=label&rawOrLabelHeaders=raw&exportCheckboxLabel=false&exportSurveyFields=true&exportDataAccessGroups=false&returnFormat=json";

    // 'token=0B07BAA4C0A1A8930EF5B16CD9115200'+
    // '&content=record&format=json&type=flat&csvDelimiter=%3B&forms%5B0%5D=client_id'+
    // '&rawOrLabel=label&rawOrLabelHeaders=raw&exportCheckboxLabel=false&exportSurveyFields=false&exportDataAccessGroups=true&'+
    // 'returnFormat=json'+
    // ' &records[0]='+patient_id
    try {
        const response = await apiClientJSON.post("api/", request_params);
        //console.log({response})
        const patient_list = response.data;
        return patient_list;
    } catch (err) {
        if (err && err.response) {
            const axiosError = err;
            return axiosError.response.data;
        }

        throw err;
    }
};

//  export const get_intake = async(patient_id)

export const syncDataToRedcap = async (dataObj: any) => {
    delete dataObj.outreach_lwazi_id;
    delete dataObj.sub_district_out;
    delete dataObj.peer_responsible_out;
    delete dataObj.team_leader_out;
    delete dataObj.meta;
    delete dataObj.activity_peer;
    delete dataObj.date_of_capture_id;
    delete dataObj.facilitator_name_out;

    delete dataObj.areas_out;
    delete dataObj.uid;
    delete dataObj.patient_id;
    delete dataObj.counselor_responsible;
    dataObj.key_population_out = dataObj.key_population_id;
    delete dataObj.key_population_id;

    delete dataObj.key_poulation_out;
    delete dataObj.date_capture_id;
    dataObj.outreach_form_complete = dataObj.form_complete;
    delete dataObj.form_complete;

    const request_params = `token=${REDCAP_API_KEY}&content=record&format=json&type=flat&overwriteBehavior=normal&forceAutoNumber=false&data=[${JSON.stringify(
        dataObj
    )}]&returnContent=count&returnFormat=json`;
    try {
        const response = await apiClientCSV.post("api/", request_params);
        const id_list = response.data;
        console.log(response);

        return id_list;
    } catch (err) {
        if (err && err.response) {
            const axiosError = err;
            return axiosError.response.data;
        }
        throw err;
    }
};
