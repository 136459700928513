import React from "react";
import logo from "../logo.svg";
import "../App.css";
import TopNavbarMain from "../Components/TopNavbarMain";
import {
    CssBaseline,
    WithStyles,
    Toolbar,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    withStyles,
    Theme,
    createStyles,
} from "@material-ui/core";
import MainLayout from "../Layouts/MainLayout";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";
import {
    Badge,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    Button,
    UncontrolledTooltip,
} from "reactstrap";
import { CardBody, CardTitle, Col, Card } from "reactstrap";
import AddIcon from "@material-ui/icons/Add";

import Header from "../Components/Header";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IPatient } from "../@types/patient";
import firebase from "../Services/firebase";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import PatientTable from "../Components/PatientTable";

import AsyncSelect from "react-select/async";
import { IOutreach } from "../@types/outreach";
import _ from "lodash";
import OutreachTable from "../Components/OutreachTable";
import withOutreachState, {
    OutreachProps,
} from "../Enhanchers/withOutreachState";

interface IState {
    outreach_list: IOutreach[];
    last_visible: any;
    outreach_list_counter: any;
    group_activity_list: any;
    selectedTag: any;
    new_list: any;
}
type Props = WithStyles<typeof styles> &
    RouteComponentProps &
    AuthProps &
    OutreachProps;

class Outreach extends React.Component<Props, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            outreach_list: [],
            last_visible: 0,
            outreach_list_counter: 0,
            group_activity_list: 0,
            new_list: 0,
            selectedTag: [],
        };
    }

    handleOnChange = (tags: any) => {
        this.setState({
            selectedTag: [tags],
        });
    };

    componentDidMount() {
        console.log("called");
        if (
            this.props.auth.user !== null &&
            this.props.outreach.outreach.length > 0
        ) {
            var new_outreach_list: IOutreach[] = [];
            var outreach_counter = 0;
            var group_activity_counter = 0;
            var other_counter = 0;

            this.props.outreach.outreach.map((child: any) => {
                if (child.activity_type_out === 1) {
                    outreach_counter++;
                } else if (child.activity_type_out === 2) {
                    group_activity_counter++;
                } else {
                    other_counter++;
                }
            });

            console.log({
                // last_visible: lastVisible,
                outreach_list: this.props.outreach.outreach,
                outreach_list_counter: outreach_counter,
                group_activity_list: group_activity_counter,
            });
            if (
                this.state.outreach_list.length === 0 ||
                this.state.outreach_list_counter === 0 ||
                this.state.group_activity_list === 0
            ) {
                this.setState({
                    // last_visible: lastVisible,
                    outreach_list: this.props.outreach.outreach,
                    outreach_list_counter: outreach_counter,
                    group_activity_list: group_activity_counter,
                });
            }
        }
        // else {
        //     this.props.history.replace("/");
        // }
    }

    groupBy = (array: any[], key: string) => {
        // Return the end result
        return array.reduce((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;
        }, {}); // empty object is the initial value for result object
    };

    render() {
        const onRowSelect = (outreach: any) => {
            //console.log(outreach);

            // if(patient.client_id_complete !== 'New'){
            this.props.history.push({
                pathname: "/outreach-view/" + outreach.outreach_indiv_id,
                state: outreach,
            });
            // }else{
            //   console.log("review")
            // }
        };

        const onEdit = (r_: any) => {
            if (r_.activity_type_out === 1) {
                this.props.history.push(
                    "/outreach-edit/" + r_.outreach_indiv_id
                );
            } else if (r_.activity_type_out === 2) {
                this.props.history.push(
                    "/group-activity-edit/" + r_.outreach_indiv_id
                );
            }
        };

        // if (this.state.outreach_list.length > 0) {
        return (
            <>
                <MainLayout>
                    {/* <OutreachTable
                  onRowSelect={onRowSelect}
                  onEdit={onEdit}
                  list={this.state.outreach_list}
                />

              <Row xs='12'>
                <Col>
                  <hr />
                </Col>
              </Row> */}
                    {/* </Container> */}

                    {/* Outreach Table */}
                    <section
                        style={{
                            width: "95%",
                            marginLeft: 30,
                            paddingBottom: 20,
                        }}
                    >
                        <OutreachTable
                            onRowSelect={onRowSelect}
                            onEdit={onEdit}
                            list={this.state.outreach_list}
                        />
                    </section>

                    {/* Page content */}
                    {/* <Container className='mt--7'>
              <Row> */}
                    {/* <Card className="shadow">
              <CardHeader className="bg-transparent border-0"></CardHeader>
              <CardBody> */}
                    {/* </CardBody>
            </Card> */}
                    {/* </Row>
            </Container> */}
                </MainLayout>
            </>
        );
        // }
        // else {
        //     return (
        //         <MainLayout>
        //             <div
        //                 className='header bg-gradient-infos pb-8 pt-5 pt-md-8'
        //                 style={{ width: "100%" }}
        //             >
        //                 <Container fluid></Container>
        //             </div>
        //         </MainLayout>
        //     );
        // }
    }
}
const styles = (theme: Theme) =>
    createStyles({
        table: {
            minWidth: 650,
        },
        tableRow: {
            "&:hover": {
                borderLeft: "#17A3AB",
                borderLeftWidth: "10",
                borderLeftStyle: "solid",
                boxShadow: "0 3px 5px 2px rgba(0,0,0,0.1)",
            },
        },
    });
export default withOutreachState(
    withAuthState(withRouter(withStyles(styles)(Outreach)))
);
