import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { useForm, Controller } from "react-hook-form";

import {
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Button,
    Nav,
    NavItem,
    NavLink,
    Input,
    Modal,
    CardHeader,
    Container,
    Card,
    CardBody,
    Table,
} from "reactstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import "../App.css";
import withOutreachDD, { OutreachDDProps } from "../Enhanchers/withOutreachDD";
import withPatientState, { PatientProps } from "../Enhanchers/withPatientState";
import ReactDatetime from "react-datetime";
import { Moment } from "moment";
import moment from "moment";
import ReactQuill from "react-quill";
import firebase from "../Services/firebase";
import AppointmentManagerModal from "../Forms/AppointmentManagerModal";
import withPrepState, { PrepProps } from "../Enhanchers/withPrepState";
import MainLayout from "../Layouts/MainLayout";
import { AnyCnameRecord } from "dns";
import { getDifference } from "../utils/Object";
import PrepFollowUpManager from "../Components/PrepFollowUpManager";
import ReactBSAlert from "react-bootstrap-sweetalert";
import PrepInitiationManager from "./PrepInitiationManager";
import { getTransferClinic } from "../constants";
interface IProps {
    appointment?: any;
}

type Props = RouteComponentProps &
    AuthProps &
    OutreachDDProps &
    PatientProps &
    IProps &
    PrepProps;

function PrepMainViewForm(props: Props) {
    const [formActive, setFormActive] = useState("patient");

    const {
        register,
        errors,
        handleSubmit,
        formState,
        getValues: getPatientValues,
    } = useForm({
        mode: "onChange",
    });

    const {
        register: monitorRegister,
        errors: monitorErrors,
        handleSubmit: handleSubmitMonitor,
        formState: monitorFormState,
        getValues: getMonitorValues,
    } = useForm({
        mode: "onChange",
    });

    const [commentMultiLine, setCommentMultiLine] = useState("");
    const [followUpActive, setFollowUpActive] = useState(false);
    const [followUpItem, setFollowUpItem] = useState<any>({});

    const [activeMonitorIndex, setActiveMonitorIndex] = useState<number>(-1);

    const [monitorStay, setMonitorStay] = useState(false);
    const [monitorOutcome, setMonitorOutcome] = useState(false);

    var prep_ref = firebase.db.collection(
        "main/" + props.auth.user.data_access_group + "/prep_list"
    );

    const {
        register: initiationRegister,
        errors: initiationErrors,
        handleSubmit: handleSubmitInitiation,
        formState: initiationFormState,
        getValues: getInitiationValues,
        reset: resetInitition,
        setValue: setInitiationValue,
    } = useForm({
        mode: "onChange",
    });
    const [initActive, setInitActive] = useState(false);
    const [initActiveIndex, setInitActiveIndex] = useState(-1);

    // const [, setInitiationHistory] = useState<any[]>([])

    const [folReady, setFolReady] = useState(true);
    const setFollowUpDate = (date: Moment | string) => {
        setFollowupdate(moment(date).format("YYYY/MM/DD"));
        if (!monitorStay || monitorOutcome) {
            setFolReady(false);
        }

        return;
    };
    const [followupdate, setFollowupdate] = useState(
        moment().format("YYYY/MM/DD")
    );
    const [init_date, setInitDate] = useState(moment().format("YYYY/MM/DD"));
    const [initRec, setInitRec] = useState({
        creatine_results: "",
        decide_to_initiate_results: "",
        heb_b_results: "",
        hiv_test_results: "",
        init_date_prep: "",
        pregnancy_results: "",
        prep_counseling_conducted: "",
        proceed_screening: "",
        sti_results: "",
        weight_results: "",
    });

    var today = moment().subtract(1, "day").format("YYYY/MM/DD");

    const _setInitDate = (date: Moment | string) => {
        setInitDate(moment(date).format("YYYY/MM/DD"));

        return;
    };

    var valid = function (current: any) {
        return moment(current).isSameOrBefore(today);
    };

    var validFollowUp = function (current: any) {
        var followupCheck;
        if(Array.isArray(prepRecord.monitor_history)){
            followupCheck = moment(
                prepRecord.monitor_history[prepRecord.monitor_history.length - 1]
                    .next_visit_date
            ).add(28, "days");
        }else {
            followupCheck = moment(
                prepRecord.monitor_history.next_visit_date
            ).add(28, "days");
        }
       
        return current.isSameOrAfter(followupCheck);
    };

    const [activeTransfer, setActiveTransfer] = useState(false);
    const [transferDate, setTransferDate] = useState(
        moment().format("YYYY/MM/DD")
    );
    const _setTransferDate = (date: Moment | string) => {
        setTransferDate(moment(date).format("YYYY/MM/DD"));

        return;
    };
    const [transferClinic, setTransferClinic] = useState("");

    const [followUpManager, setFollowUpManager] = useState(false);
    const [followUpType, setFollowUpType] = useState(""); // type : "create", "edit"

    const createFollowUp = () => {
        var today = moment();

        // var followUpLegit = moment(
        //     prepRecord.monitor_history[prepRecord.monitor_history.length - 1]
        //         .next_visit_date
        // );
        // if (today.isBefore(followUpLegit)) {
        //     alert(
        //         "Error! Follow up can Be Scheduled from " +
        //             followUpLegit.format("DD MMM 'YY")
        //     );
        // } else {
        setFollowUpActive(true);
        setFollowUpType("create");

        // active follow up record data
        var prepCheck = moment(
            prepRecord.monitor_history[prepRecord.monitor_history.length - 1]
                ?.next_visit_date
        ).add(1, "month");

        //active Follow up Item
        if(Array.isArray(prepRecord.monitor_history)){
            setFollowUpItem(
                prepRecord.monitor_history[prepRecord.monitor_history.length - 1]
            );
        } else {
            setFollowUpItem(
                prepRecord.monitor_history
            );
        }
        
        console.log("Follow up item: "+ JSON .stringify(prepRecord.monitor_history))

        //setting the follow up date
        setFollowupdate(prepCheck.format("YYYY/MM/DD"));
        // }
    };

    const [monitorValues, setMonitorValues] = useState<any>({});

    const submitFollowUp = () => {
        var monitorInfo = getMonitorValues();
        console.log(monitorInfo);

        setMonitorValues(monitorInfo);
        setActiveDemoForm(true);
    };

    const updateFollowUp = () => {
        var monitorInfo = getMonitorValues();
        console.log(monitorInfo);

        setMonitorValues(monitorInfo);
    };
    console.log((!monitorStay || monitorOutcome) && folReady);

    const handleMultiLineChange = (value: any, multi_: string) => {
        // console.log(value)
        if (multi_ === "comments") {
            setCommentMultiLine(value);
        }
    };

    const [birthDate, _setBirthDate] = useState(today);

    var validTransfer = function (current: any) {
        return current.isSameOrAfter(today);
    };
    const temp_obj: any = {};
    const [initView, setInitView] = useState(false);

    const [activeDemoForm, setActiveDemoForm] = useState(false);

    const [prepRecord, setprepRecord] = useState(temp_obj);
    const [monitorHistory, setMonitorHistory] = useState<any>([]);

    const [initiationHistory, setInitiationHistory] = useState<any>([]);
    if (props.location.search.length > 0 && !initView) {
        var split_params = props.location.search.split("&");
        var reason = split_params[0].split("=")[1];
        var recordId = split_params[1].split("=")[1];
        //console.log(reason, recordId);

        if (reason === "prep_id") {
            // appointment

            var prep_record_: any;
            props.prep_records.prep_records.filter((prep_record: any) => {
                if (prep_record.uid === recordId) {
                    prep_record_ = prep_record;
                }
            });
            setprepRecord(prep_record_ ? prep_record_ : "");
            _setBirthDate(
                prep_record_ ? prep_record_.patient_info.birthdate_prep : ""
            );
            setInitView(true);
            setMonitorHistory(prep_record_ ? prep_record_.monitor_history : "");
            setInitiationHistory(
                prep_record_ ? prep_record_.initiation_history : ""
            );
        }
    }
    const [monitorActive, setMonitorActive] = useState(false);

    const pos_check: any = {
        1: "+",
        0: "-",
    };
    const test_res: any = {
        1: "+",
        0: "-",
        2: "N/A",
    };
    const yes_and_now: any = {
        1: "Y",
        0: "N",
    };
    const setSelectedDate = (date: Moment | string) => {
        _setBirthDate(moment(date).format("YYYY/MM/DD"));
        if (
            moment().diff(date, "years", false) < 18 &&
            String(date).length === 33
        ) {
            alert("Patient entered, is younger than 18");
        }
        return;
    };
    const { isValid, isSubmitting } = formState;
    const { isValid: monitorValid } = monitorFormState;

    const [initiationMode, setInitiationMode] = useState("none"); // none, initiation, re-initiation, edit

    const { isValid: initiationValid } = initiationFormState;
    const [initiationModel, setInitiationModel] = useState(false);

    if (Object.keys(prepRecord).length > 0) {
        return (
            <MainLayout>
                {initiationModel ? (
                    <PrepInitiationManager
                        isOpen={initiationModel}
                        togglefunct={setInitiationModel}
                    />
                ) : null}
                {activeDemoForm ? (
                    <PrepFollowUpManager
                        isOpen={activeDemoForm}
                        togglefunct={setActiveDemoForm}
                        deactivateFollowUp={setFollowUpActive}
                        currentMonitor={monitorValues}
                        monitorList={Array.isArray(monitorHistory) ? monitorHistory : [monitorHistory]}
                        followDate={followupdate}
                        setMonitor={setMonitorHistory}
                        errors={{
                            patient_errors: errors,
                            patient_valid: isSubmitting,
                            initiation_errors: initiationErrors,
                            initiation_valid:
                                initiationValid && initiationHistory.length > 0,
                            monitor_errors: monitorErrors,
                            monitor_valid: monitorValid,
                        }}
                        final_object={prepRecord}
                        save_follow_up_complete={setFollowUpActive}
                    />
                ) : null}
                <Container fluid style={{ marginTop: 30 }}>
                    <Row>
                        <Col xs='1'>
                            <Button
                                style={{
                                    backgroundColor: "transparent",
                                    marginTop: 13,
                                }}
                                onClick={() => {
                                    props.history.goBack();
                                }}
                            >
                                <i
                                    className='fa fa-arrow-left  ml-5'
                                    style={{ fontSize: 20 }}
                                />
                            </Button>
                        </Col>
                        <Col xs='11'>
                            <h1 className='pl-0'>Prep Record:</h1>
                        </Col>
                    </Row>

                    <Row xs='12'>
                        <Col>
                            <hr />
                        </Col>
                    </Row>
                </Container>

                {/* Page content */}
                <Container fluid>
                    <section>
                        {/* FormActive: Patient */}
                        {/* <form onSubmit={handleSubmit(checkPatientComplete)}> */}
                        <Table>
                            <thead>
                                <tr>
                                    <th colSpan={2} className='text-center'>
                                        Logo
                                    </th>
                                    <th colSpan={9} className='text-center'>
                                        Prep Clinical Form (Initiation)
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <>
                                    <tr>
                                        <th colSpan={2}>Name</th>
                                        <td colSpan={3}>
                                            <Input
                                                name='first_name_prep'
                                                //   className='form-control-alternative'
                                                type='text'
                                                disabled={!activeDemoForm}
                                                placeholder='First Name'
                                                defaultValue={
                                                    prepRecord.patient_info
                                                        .first_name_prep
                                                }
                                            />
                                        </td>
                                        <th colSpan={3}>Folder Number</th>
                                        <td colSpan={3}>
                                            <Input
                                                name='folder_number_prep'
                                                //   className='form-control-alternative'
                                                disabled={!activeDemoForm}
                                                type='text'
                                                placeholder='Clinic Folder Number'
                                                defaultValue={
                                                    prepRecord.patient_info
                                                        .folder_number_prep
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>Surname</th>
                                        <td colSpan={3}>
                                            <Input
                                                name='surname_prep'
                                                //   className='form-control-alternative'
                                                disabled={!activeDemoForm}
                                                type='text'
                                                placeholder='Surname'
                                                defaultValue={
                                                    prepRecord.patient_info
                                                        .surname_prep
                                                }
                                            />
                                        </td>
                                        <th colSpan={3}>Phone Number</th>
                                        <td colSpan={3}>
                                            <Input
                                                name='contact_number_prep'
                                                //   className='form-control-alternative'

                                                disabled={!activeDemoForm}
                                                type='text'
                                                placeholder='Contact Number'
                                                defaultValue={
                                                    prepRecord.patient_info
                                                        .contact_number_prep
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>DOB</th>
                                        <td colSpan={3}>
                                            <InputGroup className='input-group-alternative'>
                                                <InputGroupAddon addonType='prepend'>
                                                    <InputGroupText>
                                                        <i className='ni ni-calendar-grid-58' />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <ReactDatetime
                                                    inputProps={{
                                                        placeholder:
                                                            "Date of Birth",
                                                        value: birthDate,
                                                        name: "birthdate_prep",
                                                        disabled: !activeDemoForm,
                                                        // itemRef:
                                                    }}
                                                    isValidDate={valid}
                                                    timeFormat={false}
                                                    dateFormat={moment().format(
                                                        "YYYY/MM/DD"
                                                    )}
                                                    closeOnSelect={true}
                                                    // onChange={(v) => setSelectedDate(v)}
                                                />
                                            </InputGroup>
                                        </td>
                                        <th colSpan={3}>Address</th>
                                        <td colSpan={3}>
                                            {" "}
                                            <Input
                                                name='address_prep'
                                                disabled={!activeDemoForm}
                                                //   className='form-control-alternative'                        type='text'
                                                placeholder='address'
                                                defaultValue={
                                                    prepRecord.patient_info
                                                        .address_prep
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        {/* <th colSpan={2}>ID Number</th>
                    <td colSpan={3}>
                      <Input
                        name='id_number_prep'
                        //   className='form-control-alternative'
                        disabled
                        type='text'
                        placeholder='0000000000000'
                        defaultValue={prepRecord.patient_info.id_number_prep}

                      />
          
                    </td> */}
                                        <th colSpan={2}>Gender</th>
                                        <td colSpan={3}>
                                            {" "}
                                            <Input
                                                name='gender_prep'
                                                type='select'
                                                disabled={!activeDemoForm}
                                                className='form-control-alternative'
                                                defaultValue={
                                                    prepRecord.patient_info
                                                        .gender_prep
                                                }
                                            >
                                                <option value=''>
                                                    --Select--
                                                </option>
                                                <option value='1'>Male </option>
                                                <option value='2'>
                                                    Female{" "}
                                                </option>
                                                <option value='3'>
                                                    Transgender Man{" "}
                                                </option>
                                                <option value='4'>
                                                    Transgender Woman{" "}
                                                </option>
                                                <option value='5'>
                                                    Gender Non-Conforming
                                                </option>
                                            </Input>
                                        </td>
                                    </tr>
                                </>
                            </tbody>
                        </Table>
                        {/* </form> */}

                        <Table>
                            <tbody>
                                <tr>
                                    <td colSpan={11} className='breaking'>
                                        <strong>Instructions:</strong> Please
                                        use the below form to capture
                                        initiation, continuation,
                                        discontinuation, and re-initiation for
                                        PrEP. If a patient discontinues,
                                        continue the record with the
                                        corresponding date of discontinuation.
                                        Should a patient re-start PrEP,
                                        re-initiation and subsequent visits will
                                        be captured into this same form.
                                        Adherence support and other notes can be
                                        captured further below.
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table style={{ marginTop: 2 }} className='text-center'>
                            <thead>
                                <tr>
                                    <th colSpan={14}>
                                        Steps Prior to PrEP
                                        Initiation/Re-Initiation
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <th
                                        rowSpan={2}
                                        colSpan={2}
                                        className='small'
                                    >
                                        Date of Visit
                                    </th>
                                    <th
                                        rowSpan={2}
                                        colSpan={2}
                                        className='small'
                                    >
                                        HIV {"\n"}Test Result
                                    </th>
                                    <th
                                        rowSpan={2}
                                        colSpan={2}
                                        className='small'
                                    >
                                        PrEP Counselling Conducted?
                                    </th>
                                    <th
                                        rowSpan={2}
                                        colSpan={2}
                                        className='small'
                                    >
                                        Proceed with Screening?
                                    </th>
                                    <th colSpan={6} className='small'>
                                        Prep Screening
                                    </th>
                                </tr>
                                <tr>
                                    <th className='extra-small'>
                                        Creatinine (eGFR)
                                    </th>
                                    <th className='extra-small'>Pregnancy</th>
                                    <th className='extra-small'>
                                        Weight (Kgs)
                                    </th>
                                    <th className='extra-small'>
                                        Hep B Result
                                    </th>
                                    <th className='extra-small'>
                                        STI Screening
                                    </th>
                                    <th className='extra-small'>
                                        Decide to Initiate?
                                    </th>
                                </tr>
                            </tbody>
                        </Table>
                        <>
                            {initActive ? (
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td colSpan={2}>
                                                <ReactDatetime
                                                    inputProps={{
                                                        placeholder:
                                                            "Date of Visit",
                                                        value: moment(
                                                            init_date
                                                        ).format("YYYY/MM/DD"),
                                                        name: "init_date_prep",
                                                        disabled: false,
                                                    }}
                                                    isValidDate={valid}
                                                    timeFormat={false}
                                                    closeOnSelect={true}
                                                    dateFormat={moment().format(
                                                        "YYYY/MM/DD"
                                                    )}
                                                    onChange={(v) =>
                                                        _setInitDate(v)
                                                    }
                                                />
                                            </td>

                                            <td colSpan={2}>
                                                <Input
                                                    name='hiv_test_results'
                                                    type='select'
                                                    defaultValue={
                                                        initRec.hiv_test_results
                                                    }
                                                    className='form-control-alternative'
                                                    innerRef={initiationRegister(
                                                        {
                                                            required: true,
                                                            minLength: 1,
                                                        }
                                                    )}
                                                >
                                                    <option value=''></option>
                                                    <option value='1'>+</option>
                                                    <option value='0'>-</option>
                                                </Input>
                                            </td>
                                            <td colSpan={2}>
                                                <Input
                                                    name='prep_counseling_conducted'
                                                    defaultValue={
                                                        initRec.prep_counseling_conducted
                                                    }
                                                    type='select'
                                                    className='form-control-alternative'
                                                    innerRef={initiationRegister(
                                                        {
                                                            required: true,
                                                            minLength: 1,
                                                        }
                                                    )}
                                                >
                                                    <option value=''></option>
                                                    <option value='1'>Y</option>
                                                    <option value='2'>N</option>
                                                </Input>
                                            </td>
                                            <td colSpan={2}>
                                                <Input
                                                    name='proceed_screening'
                                                    type='select'
                                                    className='form-control-alternative'
                                                    defaultValue={
                                                        initRec.proceed_screening
                                                    }
                                                    innerRef={initiationRegister(
                                                        {
                                                            required: true,
                                                            minLength: 1,
                                                        }
                                                    )}
                                                >
                                                    <option value=''></option>
                                                    <option value='1'>Y</option>
                                                    <option value='0'>N</option>
                                                </Input>
                                            </td>
                                            <td>
                                                <Input
                                                    className='form-control-alternative'
                                                    type='text'
                                                    name='creatine_results'
                                                    defaultValue={
                                                        initRec.creatine_results
                                                    }
                                                    innerRef={initiationRegister(
                                                        {
                                                            required: false,
                                                            minLength: 1,
                                                        }
                                                    )}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Input
                                                    name='pregnancy_results'
                                                    type='select'
                                                    className='form-control-alternative'
                                                    defaultValue={
                                                        initRec.pregnancy_results
                                                    }
                                                    innerRef={initiationRegister(
                                                        {
                                                            required: true,
                                                            minLength: 1,
                                                        }
                                                    )}
                                                >
                                                    <option value=''></option>
                                                    <option value='1'>+</option>
                                                    <option value='0'>-</option>
                                                    <option value='2'>
                                                        N/A
                                                    </option>
                                                </Input>
                                            </td>
                                            <td>
                                                {" "}
                                                <Input
                                                    defaultValue={
                                                        initRec.weight_results
                                                    }
                                                    innerRef={initiationRegister(
                                                        {
                                                            required: true,
                                                            minLength: 1,
                                                        }
                                                    )}
                                                    className='form-control-alternative'
                                                    type='text'
                                                    name='weight_results'
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    name='heb_b_results'
                                                    type='select'
                                                    defaultValue={
                                                        initRec.heb_b_results
                                                    }
                                                    className='form-control-alternative'
                                                    innerRef={initiationRegister(
                                                        {
                                                            required: true,
                                                            minLength: 1,
                                                        }
                                                    )}
                                                >
                                                    <option value=''></option>
                                                    <option value='1'>+</option>
                                                    <option value='0'>-</option>
                                                </Input>
                                            </td>
                                            <td>
                                                <Input
                                                    name='sti_results'
                                                    type='select'
                                                    defaultValue={
                                                        initRec.sti_results
                                                    }
                                                    className='form-control-alternative'
                                                    innerRef={initiationRegister(
                                                        {
                                                            required: true,
                                                            minLength: 1,
                                                        }
                                                    )}
                                                >
                                                    <option value=''></option>
                                                    <option value='1'>+</option>
                                                    <option value='0'>-</option>
                                                </Input>
                                            </td>

                                            <td>
                                                <Input
                                                    name='decide_to_initiate_results'
                                                    type='select'
                                                    className='form-control-alternative'
                                                    innerRef={initiationRegister(
                                                        {
                                                            required: true,
                                                            minLength: 1,
                                                        }
                                                    )}
                                                    onChange={(e) => {
                                                        console.log(
                                                            e.target.value
                                                        );
                                                        if (
                                                            e.target.value ===
                                                            "1"
                                                        ) {
                                                            console.log(
                                                                initiationValid
                                                            );
                                                            var answers = getInitiationValues();
                                                            if (
                                                                Object.keys(
                                                                    answers
                                                                ).length > 0
                                                            ) {
                                                                console.log(
                                                                    answers
                                                                );
                                                                if (
                                                                    answers.decide_to_initiate_results ===
                                                                    "1"
                                                                ) {
                                                                    if (
                                                                        monitorHistory.length >
                                                                            0 &&
                                                                        initiationMode ===
                                                                            "re-initiation"
                                                                    ) {
                                                                        // confirmAlert();
                                                                        console.log(
                                                                            "checking"
                                                                        );
                                                                        // setInitiationModel(true);
                                                                        createFollowUp();
                                                                        setFormActive(
                                                                            "monitor"
                                                                        );
                                                                        var temp_list = initiationHistory;

                                                                        temp_list.push(
                                                                            {
                                                                                ...answers,
                                                                                init_date_prep: init_date,
                                                                            }
                                                                        );
                                                                        setInitActiveIndex(
                                                                            -1
                                                                        );
                                                                        console.log(
                                                                            temp_list
                                                                        );
                                                                        setprepRecord(
                                                                            {
                                                                                ...prepRecord,
                                                                                initiation_history: temp_list,
                                                                            }
                                                                        );
                                                                        setInitiationHistory(
                                                                            temp_list
                                                                        );
                                                                        setMonitorActive(
                                                                            true
                                                                        );
                                                                        setFollowUpActive(
                                                                            true
                                                                        );
                                                                        setMonitorStay(
                                                                            false
                                                                        );
                                                                        setMonitorOutcome(
                                                                            false
                                                                        );
                                                                        setInitActive(
                                                                            false
                                                                        );
                                                                        var temp_followup = moment(
                                                                            init_date
                                                                        ).add(
                                                                            1,
                                                                            "month"
                                                                        );
                                                                        setFollowupdate(
                                                                            temp_followup.format(
                                                                                "YYYY/MM/DD"
                                                                            )
                                                                        );
                                                                    } else if (
                                                                        monitorHistory.length >
                                                                            0 &&
                                                                        initiationMode ===
                                                                            "edit"
                                                                    ) {
                                                                        temp_list = initiationHistory;

                                                                        temp_list[
                                                                            initActiveIndex
                                                                        ] = {
                                                                            ...answers,
                                                                            init_date_prep: init_date,
                                                                        };
                                                                        setInitActiveIndex(
                                                                            -1
                                                                        );

                                                                        setInitiationHistory(
                                                                            temp_list
                                                                        );
                                                                        setMonitorActive(
                                                                            true
                                                                        );
                                                                        setFollowUpActive(
                                                                            true
                                                                        );
                                                                        setMonitorStay(
                                                                            false
                                                                        );
                                                                        setMonitorOutcome(
                                                                            false
                                                                        );
                                                                        setInitActive(
                                                                            false
                                                                        );
                                                                        var temp_followup = moment(
                                                                            init_date
                                                                        ).add(
                                                                            1,
                                                                            "month"
                                                                        );
                                                                        setFollowupdate(
                                                                            temp_followup.format(
                                                                                "YYYY/MM/DD"
                                                                            )
                                                                        );

                                                                        setFormActive(
                                                                            "monitor"
                                                                        );

                                                                        handleMultiLineChange(
                                                                            "",
                                                                            "comments"
                                                                        );
                                                                    } else {
                                                                        setFormActive(
                                                                            "monitor"
                                                                        );
                                                                        var temp_list = initiationHistory;
                                                                        if (
                                                                            initActiveIndex ===
                                                                            -1
                                                                        ) {
                                                                            temp_list.push(
                                                                                {
                                                                                    ...answers,
                                                                                    init_date_prep: init_date,
                                                                                }
                                                                            );
                                                                        } else {
                                                                            var temp_list = initiationHistory;

                                                                            temp_list[
                                                                                initActiveIndex
                                                                            ] = {
                                                                                ...answers,
                                                                                init_date_prep: init_date,
                                                                                outcome_monitor:
                                                                                    "",
                                                                            };
                                                                            setInitActiveIndex(
                                                                                -1
                                                                            );
                                                                        }
                                                                        setInitiationHistory(
                                                                            temp_list
                                                                        );
                                                                        setMonitorActive(
                                                                            true
                                                                        );
                                                                        setFollowUpActive(
                                                                            true
                                                                        );
                                                                        setMonitorStay(
                                                                            false
                                                                        );
                                                                        setMonitorOutcome(
                                                                            false
                                                                        );
                                                                        setInitActive(
                                                                            false
                                                                        );
                                                                        var temp_followup = moment(
                                                                            init_date
                                                                        ).add(
                                                                            1,
                                                                            "month"
                                                                        );
                                                                        setFollowupdate(
                                                                            temp_followup.format(
                                                                                "YYYY/MM/DD"
                                                                            )
                                                                        );
                                                                    }
                                                                }
                                                                handleMultiLineChange(
                                                                    "",
                                                                    "comments"
                                                                );
                                                            }
                                                        } else if (
                                                            e.target.value ===
                                                            "0"
                                                        ) {
                                                            console.log(
                                                                initiationValid
                                                            );
                                                            var answers = getInitiationValues();
                                                            if (
                                                                Object.keys(
                                                                    answers
                                                                ).length > 0
                                                            ) {
                                                                console.log(
                                                                    answers
                                                                );

                                                                if (
                                                                    answers.decide_to_initiate_results ===
                                                                    "0"
                                                                ) {
                                                                    setFormActive(
                                                                        "comment"
                                                                    );
                                                                    var temp_list = initiationHistory;

                                                                    if (
                                                                        initActiveIndex ===
                                                                        -1
                                                                    ) {
                                                                        temp_list.push(
                                                                            {
                                                                                ...answers,
                                                                                init_date_prep: init_date,
                                                                            }
                                                                        );
                                                                    } else {
                                                                        var temp_list = initiationHistory;

                                                                        temp_list[
                                                                            initActiveIndex
                                                                        ] = {
                                                                            ...answers,
                                                                            init_date_prep: init_date,
                                                                        };
                                                                        setInitActiveIndex(
                                                                            -1
                                                                        );
                                                                    }

                                                                    setInitiationHistory(
                                                                        temp_list
                                                                    );
                                                                    setInitActive(
                                                                        false
                                                                    );
                                                                    handleMultiLineChange(
                                                                        "<blockquote>Reason For DISCONTINUATION: </blockquote>",
                                                                        "comments"
                                                                    );
                                                                }
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <option value=''></option>
                                                    <option value='1'>Y</option>
                                                    <option value='0'>N</option>
                                                </Input>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            ) : (
                                <>
                                    {prepRecord.prep_status !== "1" ? (
                                        <>
                                            <Button
                                                xs='5'
                                                className='btn-outline-warning'
                                                type='button'
                                                disabled={!isValid}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setFormActive("initiation");
                                                    setInitActive(true);
                                                    setInitiationMode(
                                                        "re-initiation"
                                                    );

                                                    // get last date
                                                    if (
                                                        prepRecord
                                                            .monitor_history
                                                            .length > 0
                                                    ) {
                                                        // prepRecord.monitor_history[prepRecord.monitor_history.length -1].
                                                        _setInitDate(today);
                                                    }
                                                }}
                                            >
                                                RE-Initiate Patient
                                            </Button>
                                        </>
                                    ) : null}
                                </>
                            )}
                        </>

                        {initiationHistory.length !== 0 ? (
                            <>
                                {initiationHistory.map(
                                    (initItem: any, index: number) => {
                                        // console.log(initItem, index)

                                        return (
                                            <Table>
                                                <tbody>
                                                    <tr
                                                        key={index}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            console.log(index);
                                                            var temp_list =
                                                                prepRecord.initiation_history;
                                                            var itemEdit =
                                                                temp_list[
                                                                    index
                                                                ];
                                                            setInitActive(true);

                                                            // if (initActiveIndex === -1) {
                                                            //   setInitActiveIndex(0)
                                                            // } else {
                                                            setInitActiveIndex(
                                                                index
                                                            );
                                                            // }

                                                            setInitiationMode(
                                                                "edit"
                                                            );

                                                            temp_list = temp_list.splice(
                                                                index + 1,
                                                                1
                                                            );
                                                            console.log(
                                                                temp_list
                                                            );
                                                            setInitiationHistory(
                                                                temp_list
                                                            );

                                                            setInitiationHistory(
                                                                temp_list
                                                            );

                                                            console.log(
                                                                getInitiationValues()
                                                            );

                                                            Object.keys(
                                                                itemEdit
                                                            ).forEach(
                                                                (item: any) => {
                                                                    if (
                                                                        !item.includes(
                                                                            "init_date_prep"
                                                                        ) &&
                                                                        !item.includes(
                                                                            "decide_to_init_results"
                                                                        )
                                                                    ) {
                                                                        console.log(
                                                                            item,
                                                                            itemEdit[
                                                                                item
                                                                            ],
                                                                            true
                                                                        );
                                                                        setInitiationValue(
                                                                            item,
                                                                            itemEdit[
                                                                                item
                                                                            ],
                                                                            true
                                                                        );
                                                                    }
                                                                }
                                                            );
                                                            setInitRec(
                                                                itemEdit
                                                            );
                                                            setMonitorActive(
                                                                false
                                                            );
                                                        }}
                                                    >
                                                        <td colSpan={2}>
                                                            <ReactDatetime
                                                                inputProps={{
                                                                    placeholder:
                                                                        "Date of Visit",
                                                                    value: moment(
                                                                        initItem.init_date_prep
                                                                    ).format(
                                                                        "YYYY/MM/DD"
                                                                    ),
                                                                    name:
                                                                        "init_date_prep",
                                                                    disabled: true,
                                                                }}
                                                                isValidDate={
                                                                    valid
                                                                }
                                                                timeFormat={
                                                                    false
                                                                }
                                                                closeOnSelect={
                                                                    true
                                                                }
                                                                dateFormat={moment().format(
                                                                    "YYYY/MM/DD"
                                                                )}
                                                                onChange={(v) =>
                                                                    setSelectedDate(
                                                                        v
                                                                    )
                                                                }
                                                            />
                                                        </td>

                                                        <td>
                                                            {
                                                                pos_check[
                                                                    initItem
                                                                        .hiv_test_results
                                                                ]
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                yes_and_now[
                                                                    initItem
                                                                        .prep_counseling_conducted
                                                                ]
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                yes_and_now[
                                                                    initItem
                                                                        .proceed_screening
                                                                ]
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                initItem.creatine_results
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                test_res[
                                                                    initItem
                                                                        .pregnancy_results
                                                                ]
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                initItem.weight_results
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                pos_check[
                                                                    initItem
                                                                        .heb_b_results
                                                                ]
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                pos_check[
                                                                    initItem
                                                                        .sti_results
                                                                ]
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                yes_and_now[
                                                                    initItem
                                                                        .decide_to_initiate_results
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        );
                                    }
                                )}
                            </>
                        ) : null}

                        <Table>
                            <>
                                <thead>
                                    <tr>
                                        <th colSpan={13}>
                                            PrEP - Initiation/Re-Initiation and
                                            Monitoring
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={2} rowSpan={2}>
                                            Original Prep Initiation Date
                                        </td>
                                        <td colSpan={2} rowSpan={2}>
                                            {moment(
                                                prepRecord.initiation_date
                                            ).format("YYYY/MM/DD")}
                                        </td>
                                        <td rowSpan={2} colSpan={2}>
                                            {!activeTransfer ? (
                                                <Button
                                                    color='info'
                                                    className='pull-right'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        // submitPrepInitiation()
                                                        setActiveTransfer(true);
                                                    }}
                                                >
                                                    Create Transfer In
                                                </Button>
                                            ) : (
                                                <Col
                                                    style={{
                                                        justifyContent:
                                                            "space-between",
                                                        alignItems:
                                                            "flex-start",
                                                    }}
                                                >
                                                    <Row
                                                        style={{
                                                            marginBottom: 10,
                                                        }}
                                                    >
                                                        <Button
                                                            color='success'
                                                            className='pull-right'
                                                            disabled={
                                                                transferClinic.length ===
                                                                    0 &&
                                                                transferDate.length ===
                                                                    0
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                // submitPrepInitiation()
                                                                setActiveTransfer(
                                                                    false
                                                                );
                                                                var date_t = transferDate;
                                                                var clinic_t = transferClinic;
                                                                // var tempObj =
                                                                //     monitorHistory[0];
                                                                // tempObj.next_visit_date = date_t;
                                                                setprepRecord({
                                                                    ...prepRecord,
                                                                    prep_clinic: clinic_t,
                                                                    // init_date_prep: date_t,
                                                                });
                                                                // props.history.push(
                                                                //     "/prep-view-lwazi"
                                                                // );
                                                                // setMonitorHistory(
                                                                //     [
                                                                //         ...monitorHistory,
                                                                //         tempObj,
                                                                //     ]
                                                                // );
                                                            }}
                                                        >
                                                            Save Transfer In
                                                        </Button>
                                                    </Row>
                                                    <Row>
                                                        <Button
                                                            color='success'
                                                            outline
                                                            className='pull-right'
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                // submitPrepInitiation()
                                                                setActiveTransfer(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Row>
                                                </Col>
                                            )}
                                        </td>

                                        <td colSpan={2}>Date</td>
                                        <td colSpan={5}>From Clinic</td>
                                    </tr>
                                    {!activeTransfer ? (
                                        <tr>
                                            <td colSpan={2}>{transferDate}</td>
                                            <td colSpan={5}>
                                                {getTransferClinic(
                                                    transferClinic
                                                )}
                                            </td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td colSpan={2}>
                                                <FormGroup>
                                                    <InputGroup className='input-group-alternative'>
                                                        <InputGroupAddon addonType='prepend'>
                                                            <InputGroupText>
                                                                <i className='ni ni-calendar-grid-58' />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <ReactDatetime
                                                            inputProps={{
                                                                placeholder:
                                                                    "Select Date",
                                                                name: "date",
                                                            }}
                                                            defaultValue={
                                                                prepRecord.initiation_date
                                                            }
                                                            isValidDate={valid}
                                                            timeFormat={false}
                                                            closeOnSelect={true}
                                                            onChange={(v) =>
                                                                _setTransferDate(
                                                                    v
                                                                )
                                                            }
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </td>
                                            <td colSpan={5}>
                                                <FormGroup>
                                                    <Input
                                                        name='clinic'
                                                        type='select'
                                                        defaultValue={
                                                            prepRecord.prep_clinic
                                                        }
                                                        innerRef={monitorRegister(
                                                            {
                                                                required: true
                                                            }
                                                        )}
                                                        className='form-control-alternative'
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            setTransferClinic(
                                                                e.target.value
                                                            );
                                                        }}
                                                        // disabled={clinicVal !== ""}
                                                    >
                                                        <option value=''>
                                                            Select
                                                        </option>
                                                        <option value='0'>
                                                            Pretoria Park Clinic
                                                        </option>
                                                        <option value='1'>
                                                            Sallisburg Clinic
                                                        </option>
                                                    </Input>
                                                </FormGroup>
                                            </td>
                                        </tr>
                                    )}

                                    <tr>
                                        <th rowSpan={2} className='small'>
                                            # of months on PrEP
                                        </th>
                                        <th
                                            rowSpan={2}
                                            colSpan={2}
                                            className='small'
                                        >
                                            Next visit Date:
                                        </th>
                                        <th
                                            rowSpan={2}
                                            colSpan={2}
                                            className='small'
                                        >
                                            Actual visit:
                                        </th>
                                        <th rowSpan={2} className='small'>
                                            Staying on PrEP?
                                        </th>
                                        <th colSpan={7} className='small'>
                                            Test Results (if applicable)
                                        </th>
                                    </tr>
                                    <tr>
                                        <th className='small'>HIV Test</th>
                                        <th className='small'>
                                            Creatinine (eGFR)
                                        </th>
                                        <th className='small'>Weight (Kgs)</th>
                                        <th className='small'>Pregnancy</th>
                                        <th className='small'>STI Screening</th>
                                        <th className='small'>Outcome</th>
                                        <th className='small'>
                                            Month of Outcome
                                        </th>
                                    </tr>

                                    {monitorHistory !== null && Array.isArray(monitorHistory) && monitorHistory.length !== 0 ? (
                                        <>
                                            {monitorHistory && monitorHistory.length > 0 ?
                                                monitorHistory.map(
                                                    (
                                                        monitorItem: any,
                                                        index: number
                                                    ) => {
                                                        if (
                                                            activeMonitorIndex !==
                                                            index
                                                        ) {
                                                            return (
                                                                <tr
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        if (
                                                                            activeMonitorIndex ===
                                                                            -1
                                                                        ) {
                                                                            setActiveMonitorIndex(
                                                                                index
                                                                            );
                                                                        } else {
                                                                            setActiveMonitorIndex(
                                                                                -1
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <td>
                                                                        {index}
                                                                    </td>
                                                                    <td
                                                                        colSpan={
                                                                            2
                                                                        }
                                                                    >
                                                                        {!monitorItem.meds_dispense ? null : (
                                                                            <p className='text-red'>
                                                                                md!
                                                                            </p>
                                                                        )}
                                                                        {moment(
                                                                            monitorItem.next_visit_date
                                                                        ).format(
                                                                            "YYYY/MM/DD"
                                                                        )}
                                                                    </td>
                                                                    <td
                                                                        colSpan={
                                                                            2
                                                                        }
                                                                    >
                                                                        {
                                                                            monitorItem.actual_visit_date
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            yes_and_now[
                                                                                monitorItem
                                                                                    .staying_on_prep
                                                                            ]
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            test_res[
                                                                                monitorItem
                                                                                    .hiv_test_results_monitor
                                                                            ]
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            monitorItem.creatine_results_monitor
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            monitorItem.weight_results_monitor
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <td>
                                                                            {
                                                                                test_res[
                                                                                    monitorItem
                                                                                        .pregnancy_results_monitor
                                                                                ]
                                                                            }
                                                                        </td>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            pos_check[
                                                                                monitorItem
                                                                                    .sti_results_monitor
                                                                            ]
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {" "}
                                                                        <Input
                                                                            name='outcome_monitor'
                                                                            type='select'
                                                                            disabled
                                                                            className='form-control-alternative'
                                                                            defaultValue={
                                                                                monitorItem.outcome_monitor
                                                                            }
                                                                        >
                                                                            <option value=''></option>
                                                                            <option value='1'>
                                                                                Continue
                                                                            </option>
                                                                            <option value='2'>
                                                                                Disc1
                                                                                -
                                                                                Patient
                                                                                elected
                                                                                to
                                                                                discontinue
                                                                                PrEP
                                                                            </option>
                                                                            <option value='3'>
                                                                                Disc2
                                                                                -
                                                                                Clinician
                                                                                discontinued
                                                                                PrEP
                                                                            </option>
                                                                            <option value='4'>
                                                                                DNA
                                                                                -
                                                                                Patient
                                                                                did
                                                                                not
                                                                                attend
                                                                                scheduled
                                                                                visit
                                                                            </option>
                                                                            <option value='5'>
                                                                                LTFU
                                                                                -
                                                                                Lost
                                                                                to
                                                                                follow-up
                                                                                after
                                                                                60
                                                                                days
                                                                            </option>
                                                                            <option value='9'>
                                                                                Restart
                                                                                -
                                                                                Patient
                                                                                discontinued
                                                                                and
                                                                                restarted
                                                                                PrEP
                                                                            </option>
                                                                            <option value='6'>
                                                                                RIP
                                                                                -
                                                                                Patient
                                                                                died
                                                                            </option>
                                                                            <option value='7'>
                                                                                Sero
                                                                                -
                                                                                Patient
                                                                                seroconverted,
                                                                                HIV
                                                                                +
                                                                            </option>
                                                                            <option value='10'>
                                                                                TFI
                                                                                -
                                                                                Transferred
                                                                                in
                                                                            </option>
                                                                            <option value='8'>
                                                                                TFO
                                                                                -
                                                                                Transferred
                                                                                out
                                                                            </option>
                                                                        </Input>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            monitorItem.month_outcome_monitor
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        } else {
                                                            return (
                                                                <>
                                                                    <>
                                                                        <tr>
                                                                            <td>
                                                                                {
                                                                                    index
                                                                                }
                                                                            </td>
                                                                            <td
                                                                                colSpan={
                                                                                    2
                                                                                }
                                                                            >
                                                                                <InputGroup className='input-group-alternative'>
                                                                                
                                                                                    <ReactDatetime
                                                                                        inputProps={{
                                                                                            placeholder:
                                                                                                "Next Visit Date",
                                                                                            value: moment(
                                                                                                monitorItem.next_visit_date
                                                                                            ).format(
                                                                                                "YYYY/MM/DD"
                                                                                            ),
                                                                                            name:
                                                                                                "next_date_prep",
                                                                                            disabled:
                                                                                                (!monitorStay ||
                                                                                                    !monitorOutcome) &&
                                                                                                props
                                                                                                    .auth
                                                                                                    .user
                                                                                                    .user_role !==
                                                                                                    1,
                                                                                        }}
                                                                                        isValidDate={
                                                                                            validFollowUp
                                                                                        }
                                                                                        timeFormat={
                                                                                            false
                                                                                        }
                                                                                        closeOnSelect={
                                                                                            true
                                                                                        }
                                                                                        onChange={(
                                                                                            v
                                                                                        ) => {
                                                                                            setFollowUpDate(
                                                                                                v
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </InputGroup>
                                                                            </td>
                                                                            <td
                                                                                colSpan={
                                                                                    2
                                                                                }
                                                                            >
                                                                                {
                                                                                    today
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <Input
                                                                                    name='staying_on_prep'
                                                                                    type='select'
                                                                                    defaultValue={
                                                                                        monitorItem.staying_on_prep
                                                                                    }
                                                                                    className='form-control-alternative'
                                                                                    innerRef={monitorRegister(
                                                                                        {
                                                                                            required: true,
                                                                                            minLength: 1,
                                                                                        }
                                                                                    )}
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.preventDefault();
                                                                                        var answer =
                                                                                            e
                                                                                                .target
                                                                                                .value;
                                                                                        if (
                                                                                            answer.length !==
                                                                                            0
                                                                                        ) {
                                                                                            setMonitorStay(
                                                                                                true
                                                                                            );
                                                                                            // console.log('set MonitorStay', true)
                                                                                        } else {
                                                                                            setMonitorStay(
                                                                                                false
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <option value=''></option>
                                                                                    <option value='1'>
                                                                                        Y
                                                                                    </option>
                                                                                    <option value='0'>
                                                                                        N
                                                                                    </option>
                                                                                </Input>
                                                                            </td>
                                                                            <td>
                                                                                <Input
                                                                                    name='hiv_test_results_monitor'
                                                                                    defaultValue={
                                                                                        monitorItem.hiv_test_results_monitor
                                                                                    }
                                                                                    type='select'
                                                                                    className='form-control-alternative'
                                                                                    innerRef={monitorRegister(
                                                                                        {
                                                                                            required: true,
                                                                                            minLength: 1,
                                                                                        }
                                                                                    )}
                                                                                >
                                                                                    <option value=''></option>
                                                                                    <option value='1'>
                                                                                        +
                                                                                    </option>
                                                                                    <option value='0'>
                                                                                        -
                                                                                    </option>
                                                                                </Input>
                                                                            </td>
                                                                            <td>
                                                                                <Input
                                                                                    className='form-control-alternative'
                                                                                    type='text'
                                                                                    defaultValue={
                                                                                        monitorItem.creatine_results_monitor
                                                                                    }
                                                                                    name='creatine_results_monitor'
                                                                                    innerRef={monitorRegister(
                                                                                        {
                                                                                            required: false,
                                                                                            minLength: 1,
                                                                                        }
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input
                                                                                    className='form-control-alternative'
                                                                                    type='text'
                                                                                    defaultValue={
                                                                                        monitorItem._weight_results_monitor
                                                                                    }
                                                                                    name='weight_results_monitor'
                                                                                    innerRef={monitorRegister(
                                                                                        {
                                                                                            required: false,
                                                                                            minLength: 1,
                                                                                        }
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input
                                                                                    name='pregnancy_results_monitor'
                                                                                    type='select'
                                                                                    defaultValue={
                                                                                        monitorItem.pregnancy_results_monitor
                                                                                    }
                                                                                    className='form-control-alternative'
                                                                                    innerRef={monitorRegister(
                                                                                        {
                                                                                            required: true,
                                                                                            minLength: 1,
                                                                                        }
                                                                                    )}
                                                                                >
                                                                                    <option value=''></option>
                                                                                    <option value='1'>
                                                                                        +
                                                                                    </option>
                                                                                    <option value='0'>
                                                                                        -
                                                                                    </option>
                                                                                    <option value='2'>
                                                                                        N/A
                                                                                    </option>
                                                                                </Input>
                                                                            </td>
                                                                            <td>
                                                                                <Input
                                                                                    name='sti_results_monitor'
                                                                                    type='select'
                                                                                    defaultValue={
                                                                                        monitorItem.sti_results_monitor
                                                                                    }
                                                                                    className='form-control-alternative'
                                                                                    innerRef={monitorRegister(
                                                                                        {
                                                                                            required: true,
                                                                                            minLength: 1,
                                                                                        }
                                                                                    )}
                                                                                >
                                                                                    <option value=''></option>
                                                                                    <option value='1'>
                                                                                        +
                                                                                    </option>
                                                                                    <option value='0'>
                                                                                        -
                                                                                    </option>
                                                                                </Input>
                                                                            </td>
                                                                            <td>
                                                                                {" "}
                                                                                <Input
                                                                                    name='outcome_monitor'
                                                                                    type='select'
                                                                                    defaultValue={
                                                                                        monitorItem.outcome_monitor
                                                                                    }
                                                                                    disabled={
                                                                                        props
                                                                                            .auth
                                                                                            .user
                                                                                            .user_role ===
                                                                                        1
                                                                                    }
                                                                                    className='form-control-alternative'
                                                                                    innerRef={monitorRegister(
                                                                                        {
                                                                                            required: true,
                                                                                        }
                                                                                    )}
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.preventDefault();
                                                                                        if (
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                                .length !==
                                                                                            0
                                                                                        ) {
                                                                                            setMonitorOutcome(
                                                                                                true
                                                                                            );
                                                                                            var temp_list = monitorHistory;
                                                                                            var monitorInfo = getMonitorValues();

                                                                                            var temp_item = {
                                                                                                ...temp_list[
                                                                                                    temp_list.length -
                                                                                                        1
                                                                                                ],
                                                                                                ...monitorInfo,
                                                                                                month_outcome_monitor:
                                                                                                    moment(
                                                                                                        monitorHistory[
                                                                                                            monitorHistory.length -
                                                                                                                1
                                                                                                        ]
                                                                                                            .next_visit_date
                                                                                                    ).format(
                                                                                                        "MM"
                                                                                                    ) +
                                                                                                    "/" +
                                                                                                    moment(
                                                                                                        monitorHistory[
                                                                                                            monitorHistory.length -
                                                                                                                1
                                                                                                        ]
                                                                                                            .next_visit_date
                                                                                                    ).format(
                                                                                                        "YY"
                                                                                                    ),
                                                                                            };
                                                                                            // console.log(temp_list)
                                                                                            setMonitorHistory(
                                                                                                temp_list
                                                                                            );
                                                                                        } else {
                                                                                            setMonitorOutcome(
                                                                                                false
                                                                                            );
                                                                                            var temp_list = monitorHistory;
                                                                                            var monitorInfo = getMonitorValues();

                                                                                            var temp_item = {
                                                                                                ...temp_list[
                                                                                                    temp_list.length -
                                                                                                        1
                                                                                                ],
                                                                                                ...monitorInfo,
                                                                                                month_outcome_monitor:
                                                                                                    "",
                                                                                            };
                                                                                            // console.log(temp_list)
                                                                                            setMonitorHistory(
                                                                                                temp_list
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <option value=''></option>
                                                                                    <option value='1'>
                                                                                        Continue
                                                                                    </option>
                                                                                    <option value='2'>
                                                                                        Disc1
                                                                                        -
                                                                                        Patient
                                                                                        elected
                                                                                        to
                                                                                        discontinue
                                                                                        PrEP
                                                                                    </option>
                                                                                    <option value='3'>
                                                                                        Disc2
                                                                                        -
                                                                                        Clinician
                                                                                        discontinued
                                                                                        PrEP
                                                                                    </option>
                                                                                    <option value='4'>
                                                                                        DNA
                                                                                        -
                                                                                        Patient
                                                                                        did
                                                                                        not
                                                                                        attend
                                                                                        scheduled
                                                                                        visit
                                                                                    </option>
                                                                                    <option value='5'>
                                                                                        LTFU
                                                                                        -
                                                                                        Lost
                                                                                        to
                                                                                        follow-up
                                                                                        after
                                                                                        60
                                                                                        days
                                                                                    </option>
                                                                                    <option value='9'>
                                                                                        Restart
                                                                                        -
                                                                                        Patient
                                                                                        discontinued
                                                                                        and
                                                                                        restarted
                                                                                        PrEP
                                                                                    </option>
                                                                                    <option value='6'>
                                                                                        RIP
                                                                                        -
                                                                                        Patient
                                                                                        died
                                                                                    </option>
                                                                                    <option value='7'>
                                                                                        Sero
                                                                                        -
                                                                                        Patient
                                                                                        seroconverted,
                                                                                        HIV
                                                                                        +
                                                                                    </option>
                                                                                    <option value='10'>
                                                                                        TFI
                                                                                        -
                                                                                        Transferred
                                                                                        in
                                                                                    </option>
                                                                                    <option value='8'>
                                                                                        TFO
                                                                                        -
                                                                                        Transferred
                                                                                        out
                                                                                    </option>
                                                                                </Input>
                                                                            </td>
                                                                            <td>
                                                                                {moment(
                                                                                    monitorHistory[
                                                                                        monitorHistory.length -
                                                                                            1
                                                                                    ]
                                                                                        .next_visit_date
                                                                                ).format(
                                                                                    "MM"
                                                                                ) +
                                                                                    "/" +
                                                                                    moment(
                                                                                        monitorHistory[
                                                                                            monitorHistory.length -
                                                                                                1
                                                                                        ]
                                                                                            .next_visit_date
                                                                                    ).format(
                                                                                        "YY"
                                                                                    )}
                                                                            </td>
                                                                        </tr>
                                                                        <tr
                                                                            style={{
                                                                                justifyContent:
                                                                                    "space-between",
                                                                                alignItems:
                                                                                    "flex-en",
                                                                            }}
                                                                        >
                                                                            <td
                                                                                colSpan={
                                                                                    10
                                                                                }
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        "#f8f9fe",
                                                                                }}
                                                                            ></td>
                                                                            <td
                                                                                colSpan={
                                                                                    3
                                                                                }
                                                                            >
                                                                                <Button
                                                                                    color='info'
                                                                                    outlined
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.preventDefault();
                                                                                        setActiveMonitorIndex(
                                                                                            -1
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Cancel
                                                                                </Button>

                                                                                <Button
                                                                                    color='info'
                                                                                    className='pull-right'
                                                                                    disabled={
                                                                                        !monitorStay &&
                                                                                        monitorOutcome &&
                                                                                        !folReady
                                                                                    }
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.preventDefault();
                                                                                        updateFollowUp();
                                                                                    }}
                                                                                >
                                                                                    Save
                                                                                    Update
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                </>
                                                            );
                                                        }
                                                    }
                                                ) : null }
                                        </>
                                    ) : null}

                                    {followUpActive ? (
                                        <>
                                            {followUpItem && Object.keys(followUpItem).length > 0 ? (
                                                <>
                                                    <tr>
                                                        <td>
                                                            {
                                                                prepRecord
                                                                    .monitor_history
                                                                    .length
                                                            }
                                                        </td>
                                                        <td colSpan={2}>
                                                            <InputGroup className='input-group-alternative'>
                                                                {/* <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-calendar-grid-58' />
                        </InputGroupText>
                      </InputGroupAddon> */}
                                                                <ReactDatetime
                                                                    inputProps={{
                                                                        placeholder:
                                                                            "Next Visit Date",
                                                                        value: moment(
                                                                            followupdate
                                                                        ).format(
                                                                            "YYYY/MM/DD"
                                                                        ),
                                                                        name:
                                                                            "next_date_prep",
                                                                        disabled:
                                                                            (!monitorStay ||
                                                                                !monitorOutcome) &&
                                                                            props
                                                                                .auth
                                                                                .user
                                                                                .role_access !==
                                                                                1,
                                                                    }}
                                                                    isValidDate={
                                                                        validFollowUp
                                                                    }
                                                                    timeFormat={
                                                                        false
                                                                    }
                                                                    dateFormat={moment().format(
                                                                        "YYYY/MM/DD"
                                                                    )}
                                                                    closeOnSelect={
                                                                        true
                                                                    }
                                                                    onChange={(
                                                                        v
                                                                    ) => {
                                                                        setFollowUpDate(
                                                                            v
                                                                        );
                                                                    }}
                                                                />
                                                            </InputGroup>
                                                        </td>
                                                        <td colSpan={2}>
                                                            {today}
                                                        </td>
                                                        <td>
                                                            <Input
                                                                name='staying_on_prep'
                                                                type='select'
                                                                className='form-control-alternative'
                                                                innerRef={monitorRegister(
                                                                    {
                                                                        required: true,
                                                                        minLength: 1,
                                                                    }
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    var answer =
                                                                        e.target
                                                                            .value;
                                                                    if (
                                                                        answer.length !==
                                                                        0
                                                                    ) {
                                                                        setMonitorStay(
                                                                            true
                                                                        );
                                                                        // console.log('set MonitorStay', true)
                                                                    } else {
                                                                        setMonitorStay(
                                                                            false
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <option value=''></option>
                                                                <option value='1'>
                                                                    Y
                                                                </option>
                                                                <option value='0'>
                                                                    N
                                                                </option>
                                                            </Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                name='hiv_test_results_monitor'
                                                                defaultValue={
                                                                    followUpItem.hiv_test_results
                                                                }
                                                                type='select'
                                                                className='form-control-alternative'
                                                                innerRef={monitorRegister(
                                                                    {
                                                                        required: true,
                                                                        minLength: 1,
                                                                    }
                                                                )}
                                                            >
                                                                <option value=''></option>
                                                                <option value='1'>
                                                                    +
                                                                </option>
                                                                <option value='0'>
                                                                    -
                                                                </option>
                                                            </Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                className='form-control-alternative'
                                                                type='text'
                                                                defaultValue={
                                                                    followUpItem.creatine_results
                                                                }
                                                                name='creatine_results_monitor'
                                                                innerRef={monitorRegister(
                                                                    {
                                                                        required: false,
                                                                        minLength: 1,
                                                                    }
                                                                )}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                className='form-control-alternative'
                                                                type='text'
                                                                defaultValue={
                                                                    followUpItem.weight_results
                                                                }
                                                                name='weight_results_monitor'
                                                                innerRef={monitorRegister(
                                                                    {
                                                                        required: false,
                                                                        minLength: 1,
                                                                    }
                                                                )}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                name='pregnancy_results_monitor'
                                                                type='select'
                                                                defaultValue={
                                                                    followUpItem.pregnancy_results
                                                                }
                                                                className='form-control-alternative'
                                                                innerRef={monitorRegister(
                                                                    {
                                                                        required: true,
                                                                        minLength: 1,
                                                                    }
                                                                )}
                                                            >
                                                                <option value=''></option>
                                                                <option value='1'>
                                                                    +
                                                                </option>
                                                                <option value='0'>
                                                                    -
                                                                </option>
                                                                <option value='2'>
                                                                    N/A
                                                                </option>
                                                            </Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                name='sti_results_monitor'
                                                                type='select'
                                                                defaultValue={
                                                                    followUpItem.sti_results
                                                                }
                                                                className='form-control-alternative'
                                                                innerRef={monitorRegister(
                                                                    {
                                                                        required: true,
                                                                        minLength: 1,
                                                                    }
                                                                )}
                                                            >
                                                                <option value=''></option>
                                                                <option value='1'>
                                                                    +
                                                                </option>
                                                                <option value='0'>
                                                                    -
                                                                </option>
                                                            </Input>
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            <Input
                                                                name='outcome_monitor'
                                                                type='select'
                                                                className='form-control-alternative'
                                                                innerRef={monitorRegister(
                                                                    {
                                                                        required: true,
                                                                    }
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    if (
                                                                        e.target
                                                                            .value
                                                                            .length !==
                                                                        0
                                                                    ) {
                                                                        if (
                                                                            e
                                                                                .target
                                                                                .value ===
                                                                                "2" ||
                                                                            e
                                                                                .target
                                                                                .value ===
                                                                                "3"
                                                                        ) {
                                                                            console.log(
                                                                                "logged"
                                                                            );
                                                                            handleMultiLineChange(
                                                                                "<blockquote>Reason For DISCONTINUATION: </blockquote>",
                                                                                "comments"
                                                                            );
                                                                        } else {
                                                                            handleMultiLineChange(
                                                                                e,
                                                                                "comments"
                                                                            );
                                                                        }
                                                                        setMonitorOutcome(
                                                                            true
                                                                        );
                                                                        var temp_list = monitorHistory;
                                                                        var monitorInfo = getMonitorValues();

                                                                        var temp_item = {
                                                                            ...temp_list[
                                                                                temp_list.length -
                                                                                    1
                                                                            ],
                                                                            ...monitorInfo,
                                                                            month_outcome_monitor:
                                                                            Array.isArray(monitorHistory) ?
                                                                                moment(
                                                                                    monitorHistory[
                                                                                        monitorHistory.length -
                                                                                            1
                                                                                    ]
                                                                                        .next_visit_date
                                                                                ).format(
                                                                                    "MM"
                                                                                ) +
                                                                                "/" +
                                                                                moment(
                                                                                    monitorHistory[
                                                                                        monitorHistory.length -
                                                                                            1
                                                                                    ]
                                                                                        .next_visit_date
                                                                                ).format(
                                                                                    "YY"
                                                                                ) : 
                                                                                moment(
                                                                                    monitorHistory
                                                                                        .next_visit_date
                                                                                ).format(
                                                                                    "MM"
                                                                                ) +
                                                                                "/" +
                                                                                moment(
                                                                                    monitorHistory
                                                                                        .next_visit_date
                                                                                ).format(
                                                                                    "YY"
                                                                                )
                                                                                ,
                                                                        };
                                                                        // console.log(temp_list)
                                                                        setMonitorHistory(
                                                                            temp_list
                                                                        );
                                                                    } else {
                                                                        setMonitorOutcome(
                                                                            false
                                                                        );
                                                                        var temp_list = monitorHistory;
                                                                        var monitorInfo = getMonitorValues();

                                                                        var temp_item = {
                                                                            ...temp_list[
                                                                                temp_list.length -
                                                                                    1
                                                                            ],
                                                                            ...monitorInfo,
                                                                            month_outcome_monitor:
                                                                                "",
                                                                        };
                                                                        // console.log(temp_list)
                                                                        setMonitorHistory(
                                                                            temp_list
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <option value=''></option>
                                                                <option value='1'>
                                                                    Continue
                                                                </option>
                                                                <option value='2'>
                                                                    Disc1 -
                                                                    Patient
                                                                    elected to
                                                                    discontinue
                                                                    PrEP
                                                                </option>
                                                                <option value='3'>
                                                                    Disc2 -
                                                                    Clinician
                                                                    discontinued
                                                                    PrEP
                                                                </option>
                                                                <option value='4'>
                                                                    DNA -
                                                                    Patient did
                                                                    not attend
                                                                    scheduled
                                                                    visit
                                                                </option>
                                                                <option value='5'>
                                                                    LTFU - Lost
                                                                    to follow-up
                                                                    after 60
                                                                    days
                                                                </option>
                                                                <option value='9'>
                                                                    Restart -
                                                                    Patient
                                                                    discontinued
                                                                    and
                                                                    restarted
                                                                    PrEP
                                                                </option>
                                                                <option value='6'>
                                                                    RIP -
                                                                    Patient died
                                                                </option>
                                                                <option value='7'>
                                                                    Sero -
                                                                    Patient
                                                                    seroconverted,
                                                                    HIV +
                                                                </option>
                                                                <option value='10'>
                                                                    TFI -
                                                                    Transferred
                                                                    in
                                                                </option>
                                                                <option value='8'>
                                                                    TFO -
                                                                    Transferred
                                                                    out
                                                                </option>
                                                            </Input>
                                                        </td>
                                                        <td>
                                                            {Array.isArray(monitorHistory) ?
                                                             moment(
                                                                monitorHistory[
                                                                    monitorHistory.length -
                                                                        1
                                                                ]
                                                                    .next_visit_date
                                                            ).format("MM") +
                                                                "/" +
                                                                moment(
                                                                    monitorHistory[
                                                                        monitorHistory.length -
                                                                            1
                                                                    ]
                                                                        .next_visit_date
                                                                ).format("YY") : 
                                                                moment(
                                                                    monitorHistory
                                                                        .next_visit_date
                                                                ).format("MM") +
                                                                    "/" +
                                                                    moment(
                                                                        monitorHistory
                                                                            .next_visit_date
                                                                    ).format("YY")
                                                                }
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        className='test'
                                                        style={{
                                                            justifyContent:
                                                                "flex-end",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <td
                                                            className='test'
                                                            style={{
                                                                flexDirection:
                                                                    "row",
                                                                justifyContent:
                                                                    "flex-end",
                                                            }}
                                                            colSpan={13}
                                                        >
                                                            <div>
                                                                <Button
                                                                    color='success'
                                                                    className='btn-outline-success'
                                                                    outlined
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        setFollowUpActive(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    Cancel
                                                                </Button>

                                                                <Button
                                                                    color='success'
                                                                    className='pull-right'
                                                                    disabled={
                                                                        !monitorStay &&
                                                                        !monitorOutcome &&
                                                                        !folReady
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        submitFollowUp();
                                                                    }}
                                                                >
                                                                    Save
                                                                    FollowUp
                                                                </Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            ) : null}
                                        </>
                                    ) : (
                                        <>
                                            <tr>
                                                {" "}
                                                <th colSpan={13}>
                                                    <Button
                                                        block
                                                        color={'info'}
                                                        onClick={createFollowUp}
                                                        // disabled={
                                                        //     prepRecord.prep_status ===
                                                        //     "1"
                                                        // }
                                                    >
                                                        Create Follow-up
                                                    </Button>
                                                </th>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </>
                        </Table>

                        <Table>
                            <thead>
                                <tr>
                                    <th
                                        colSpan={11}
                                        className='wrapping text-left'
                                    >
                                        Notes: Reason for discontinuation /
                                        adherence support / allergies / medical
                                        history / hospitalisations / TB history.
                                        *Please state reason for discontinuation
                                        in detail (client's choice / change in{" "}
                                        <br /> risk profile / adverse effects,
                                        etc.)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={11}>
                                        <ReactQuill
                                            value={commentMultiLine}
                                            onChange={(v) =>
                                                handleMultiLineChange(
                                                    v,
                                                    "comments"
                                                )
                                            }
                                            // value={prepRecord.comment}
                                            // onChange={(v) =>
                                            //     handleMultiLineChange(
                                            //         v,
                                            //         "comments"
                                            //     )
                                            // }
                                            theme='snow'
                                            modules={{
                                                toolbar: [
                                                    ["bold", "italic"],
                                                    [
                                                        "link",
                                                        "blockquote",
                                                        "code",
                                                        "image",
                                                    ],
                                                    [
                                                        {
                                                            list: "ordered",
                                                        },
                                                        {
                                                            list: "bullet",
                                                        },
                                                    ],
                                                ],
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </section>
                </Container>
            </MainLayout>
        );
    } else {
        return null;
    }
}

export default withPrepState(
    withPatientState(
        withOutreachDD(withAuthState(withRouter(PrepMainViewForm)))
    )
);
