import { createStandardAction } from 'typesafe-actions';
import { User } from 'firebase';

const FETCH_USER = 'auth/FETCH_USER';
const SET_USER = 'auth/SET_USER';
const SET_ACTIVESTATE = 'auth/SET_ACTIVESTATE';
const SET_LOADINGMSG = 'auth/SET_LOADINGMSG';



//  USER ACTIONS
export const fetchUser = createStandardAction(FETCH_USER)();

export interface ISetUserPayload {
  user: any;
}
export const setUser = createStandardAction(SET_USER)<
  ISetUserPayload
>();


export interface ISetActiveStatePayload{
  active_state: any;
}

export const setActiveState = createStandardAction(SET_ACTIVESTATE)<
ISetActiveStatePayload>();

export interface ISetLoadingMsgPayload{
  loading_msg: any;
}

export const setLoadingMsgState = createStandardAction(SET_LOADINGMSG)<
ISetLoadingMsgPayload>();
