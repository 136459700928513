import React, { useState, Fragment } from 'react'
import {
  createStyles,
  withStyles,
  WithStyles,
  ClickAwayListener,
  Toolbar,
  AppBar,
  fade,
  MenuItem,
  CssBaseline,
  Badge,
  Grid,
  Theme,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Divider,
  Icon,
  Button,
} from '@material-ui/core'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'
// import { loadCSS } from 'fg-loadcss';

// import home from '@material-ui/icons/Dashboard';
// import prep from '@material-ui/icons/AssignmentOutlined';
import outreach from '@material-ui/icons/AssignmentReturnOutlined'
import patient from '@material-ui/icons/AssignmentInd'
// import prepform_img from '../Assets/Navbar/nav-prep-form.png';
// import outreach_img from '../Assets/Navbar/nav-outreach-form.png';
import Home from '@material-ui/icons/Home'
import dashboard_img from '../Assets/Navbar/nav-dashboard-active.png'
import patient_img from '../Assets/Navbar/nav-patients.png'
import appointments_img from '../Assets/Navbar/nav-appointments.png'
import report_img from '../Assets/Navbar/nav-reports.png'
import notifications_img from '../Assets/Navbar/nav-notifications.png'
import prepform_img from '../Assets/Navbar/nav-prep-form.png'
// import outreach_img from '../Assets/Navbar/nav-outreach-form.png';
// import Home from '@material-ui/icons/Home';

import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Table,
} from 'reactstrap'
import { IPatient } from '../@types/patient'

interface IProps {
  patient: IPatient
  appointment_records: any
}

type Props = WithStyles<typeof styles> & IProps

class PatientAppointmentTab extends React.Component<Props> {
  render() {
    const selectOptions: any = {
      0: 'Pretoria Park',
      1: 'Sallisburg Clinic',
    }
    return (
      <>
        <CardBody>
          <h6 className='heading-small text-muted mb-4'>
            Scheduled Appointments
          </h6>

          <Table className='align-items-center' responsive>
            <thead className='thead-light'>
              <tr>
                <th>Date</th>
                <th>Clinic</th>
                <th>Description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.appointment_records.map((record_: any) => {
                if (record_.status === 'upcoming') {
                  return (
                    <tr>
                      <td>{record_.start}</td>
                      <td>{selectOptions[record_.clinic]}</td>
                      <td>{record_.title}</td>
                      <td>{record_.status}</td>
                      {/* <td><a onClick={(e)=>{
                  e.preventDefault();
                  var id = record_.outreach_lwazi_id;
                  this.props.history.push("/outreach-view/"+id);}}
                  >Details</a></td> */}
                    </tr>
                  )
                }
              })}
            </tbody>
          </Table>

          {/* {JSON.stringify(this.props.prep_record[0])} */}
        </CardBody>

        <CardBody style={{ marginTop: 10 }}>
          <h6 className='heading-small text-muted mb-4'>Past Appointments</h6>

          <Table className='align-items-center' responsive>
            <thead className='thead-light'>
              <tr>
                <th>Date</th>
                <th>Clinic</th>
                <th>Description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.appointment_records.map((record_: any) => {
                if (record_.status !== 'upcoming') {
                  return (
                    <tr>
                      <td>{record_.start}</td>
                      <td>{selectOptions[record_.clinic]}</td>
                      <td>{record_.title}</td>
                      <td>{record_.status}</td>
                      {/* <td><a onClick={(e)=>{
                  e.preventDefault();
                  var id = record_.outreach_lwazi_id;
                  this.props.history.push("/outreach-view/"+id);}}
                  >Details</a></td> */}
                    </tr>
                  )
                }
              })}
            </tbody>
          </Table>
        </CardBody>
      </>
    )
  }
}
const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      width: 90,
      flexShrink: 0,
    },
    icon_state: {
      active: '#fff',
      non_active: '#143840',
    },

    drawerPaper: {
      width: 90,
      marginTop: 50,
    },
    drawerContainer: {
      overflow: 'auto',
      // marginTop: 40
    },
  })
export default withStyles(styles)(PatientAppointmentTab)
