import React from 'react'
import logo from '../logo.svg'
import TopNavbarMain from '../Components/TopNavbarMain'
import {
  CssBaseline,
  WithStyles,
  Toolbar,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  withStyles,
  Theme,
  createStyles,
  Button,
} from '@material-ui/core'
import MainLayout from '../Layouts/MainLayout'
import Paper from '@material-ui/core/Paper'
import MUIDataTable from 'mui-datatables'
import {
  Badge,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
} from 'reactstrap'
import { CardBody, CardTitle, Col, Card } from 'reactstrap'
import AddIcon from '@material-ui/icons/Add'

import Header from '../Components/Header'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { IPatient } from '../@types/patient'
import firebase from '../Services/firebase'
import withAuthState, { AuthProps } from '../Enhanchers/withAuthState'
import PatientTable from '../Components/PatientTable'

import AsyncSelect from 'react-select/async'
import PatientForm from '../Forms/patientForm'
import * as API from '../Services/API'

interface IState {
  patient?: any
  patientEdit?: boolean
}

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{ id: string }> &
  AuthProps

class PatientEdit extends React.Component<Props, IState> {
  //     state={
  // patientEdit: false,
  // patient: {}
  //      }

  //     constructor(props:any){
  // super(props);
  // this.state
  //     }
  state = {
    patient: {
      uid: '',
      birthdate_id: '',
      client_id_complete: 0,
      clinic_folder_number_id: '',
      comets_folder_no: '',
      contact_number_id: '',
      data_capturer_id: '',
      date_of_capture_id: '',
      first_name_id: '',
      gender_id: '',
      key_population_id: '',
      orbit_uid_id: '',
      passport_number: '',
      pref_name_id: '',
      race_id: '',
      record_id: '',
      redcap_data_access_group: '',
      redcap_event_name: '',
      redcap_repeat_instance: '',
      redcap_repeat_instrument: '',
      sa_id_number: '',
      surname_id: '',
      transgender_id: '',
    },
    patientEdit: false,
  }

  componentDidMount() {
    // if (this.props.location.state) {
    //   var temp_state: IPatient = this.props.location.state;
    //   console.log(temp_state);
    //   if (Object.keys(temp_state).length > 0) {
    //     this.setState({
    //       patient: temp_state,
    //       patientEdit: true,
    //     });
    //   }
    // } else {
    if (
      this.props.match.params.id &&
      this.props.location.state &&
      this.props.auth.user
    ) {
      var user_data_access_group = this.props.auth.user!.data_access_group.trim()

      var trim_string = 'main/' + user_data_access_group + '/patient_list'
      firebase.db
        .collection(trim_string)
        .doc(this.props.match.params.id)
        .get()
        .then((patient) => {
          //console.log((patient.data());
       
          var temp_pat: any = { uid: patient.id, ...patient.data() }
           if(this.state.patient !== temp_pat){ 
              this.setState({
            patient: temp_pat,
            patientEdit: true,
          })
        }
        return
        })
      // }
    }
  }

  render() {
    console.log(this.props)
    if (this.state.patientEdit) {
      return (
        <MainLayout>
          {/* Patient Edit Menu */}

          <Container fluid style={{ marginTop: 30 }}>
            <Row>
              <Col xs='1'>
                <Button
                  style={{ backgroundColor: 'transparent', marginTop: 13 }}
                  onClick={() => {
                    this.props.history.goBack()
                  }}
                >
                  <i
                    className='fa fa-arrow-left  ml-5'
                    style={{ fontSize: 20 }}
                  />
                </Button>
              </Col>
              <Col xs='11'>
                <h1 className='pl-0'>
                  Edit Patient{' '}
                  {this.state.patient ? this.state.patient.record_id : null}
                </h1>
              </Col>
            </Row>

            <Row xs='12'>
              <Col>
                <hr />
              </Col>
            </Row>
          </Container>

          {/* Page content */}
          <section
            style={{
              backgroundColor: '#fff',
              marginTop: '-2rem',
              paddingTop: '2.5rem',
              paddingBottom: '8.25rem',
              paddingLeft: '5rem',
              paddingRight: '5rem',
            }}
          >
            {this.state.patientEdit ? (
              <PatientForm
                patient={this.state.patient}
                isPatientEdit={this.state.patientEdit}
              />
            ) : (
              <PatientForm />
            )}
          </section>
        </MainLayout>
      )
    } else {
      return null
    }
  }
}
const styles = (theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    tableRow: {
      '&:hover': {
        borderLeft: '#17A3AB',
        borderLeftWidth: '10',
        borderLeftStyle: 'solid',
        boxShadow: '0 3px 5px 2px rgba(0,0,0,0.1)',
      },
    },
  })
export default withAuthState(withRouter(withStyles(styles)(PatientEdit)))
