import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import LaptopMacIcon from '@material-ui/icons/LaptopMac'
import HotelIcon from '@material-ui/icons/Hotel'
import RepeatIcon from '@material-ui/icons/Repeat'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { district_check_list } from '../utils/Object'
import withPatientState, { PatientProps } from '../Enhanchers/withPatientState'
import PatientInfo from './getPatient'
import { Col, Row } from 'reactstrap'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}))

interface IProps {
  activities: any[]
}

type Props = IProps & PatientProps

export function getDistrict(district_key: any) {
  var selected_district = ''
  district_check_list.forEach((district) => {
    if (district.district_act_val === district_key) {
      selected_district = district.label_value
    }
  })
  return selected_district
}

export function getStatus(_key: any) {
  if (_key === 2) return 'Completed'
  else if (_key === 0) return 'Review'
  if (_key === 3) return 'Amended'
  if (_key === 1) return 'Incomplete'
}

function TimeLine(props: Props) {
  const classes = useStyles()

  return (
    <div
      style={{ fontSize: 8 ,overflowY: "scroll",
        height: 1800}}
      className='timeline timeline-one-side'
      data-timeline-content='axis'
      data-timeline-axis-style='dashed'
    >
      {props.activities.map((activity) => {
        var time = activity.date_and_time.split(' ')[1]
        var date = activity.date_and_time.split(' ')[0]

        if (activity.activity === 'patient_create') {
          return (
            <div className='timeline-block'>
              <span className='timeline-step badge-success'>
                <i className='fa fa-users'></i>
              </span>
              <div className='timeline-content'>
                <Row>
                  <small className='text-muted font-weight-bold'>{date}</small>
                </Row>
                <Row>
                  <small className='text-muted font-weight-bold'>{time}</small>
                </Row>
                <h5 className='mt-3 mb-0'>Patient Created</h5>
                {/* <p className="text-sm mt-1 mb-0"> */}
                {/* Patient Info Here */}

                {/* {getPatient(activity.record_id)} */}
                {/* <PatientInfo
                 patient_id={activity}
                  /> */}
                {/* </p> */}
                <div className='mt-3'>
                  {/* <span className="badge badge-pill badge-success">design</span>{" "} */}
                  <span className='badge badge-pill badge-warning'>
                    Status: {getStatus(activity.form_status)}
                  </span>{' '}
                  <span className='badge badge-pill badge-danger'>
                    Capturer Role: {activity.role}
                  </span>{' '}
                  <span className='badge badge-pill badge-info'>
                    Captured By: {activity.capturer_name}
                  </span>{' '}
                  <span className='badge badge-pill badge-success'>
                    {getDistrict(activity.district)}
                  </span>
                </div>
              </div>
            </div>

            //   <TimelineItem>
            //     <TimelineOppositeContent>
            //       <Typography variant="body2" color="primary">
            //         {time}
            //       </Typography>
            //     </TimelineOppositeContent>
            //     <TimelineSeparator>
            //       <TimelineDot color="secondary">
            //       <PersonAddIcon />
            //       </TimelineDot>
            //       <TimelineConnector />
            //     </TimelineSeparator>
            //     <TimelineContent>
            //       <Paper elevation={3} className={classes.paper}>
            //         <Typography variant="h6" component="h1">
            //           Patient Edit:{"\n"}
            //           <ul>
            // <li>Patient ID: {activity.record_id}</li>
            // <li>District: {activity.district}</li>
            // <li>Form Status: {activity.form_status} </li>
            //           </ul>
            //           {/* Patient Create: {activity.value.first_name_id+"  "+activity.value.surname_id} */}
            //         </Typography>
            // <Typography>Created By: {activity.capturer_name}</Typography>
            //       </Paper>
            //     </TimelineContent>
            //   </TimelineItem>
          )
        } else if (activity.activity === 'patient_edit') {
          return (
            <div className='timeline-block'>
              <span className='timeline-step badge-info'>
                <i className='fa fa-users'></i>
              </span>
              <div className='timeline-content'>
                <Row>
                  <small className='text-muted font-weight-bold'>{date}</small>
                </Row>
                <Row>
                  <small className='text-muted font-weight-bold'>{time}</small>
                </Row>

                <h5 className='mt-3 mb-0'>Patient Edit</h5>
                {/* <p className="text-sm mt-1 mb-0"> */}
                {/* <PatientInfo
                 patient_id={activity.record_id}
                  />            */}
                {/* </p> */}
                <div className='mt-3'>
                  {/* <span className="badge badge-pill badge-success">design</span>{" "}
                <span className="badge badge-pill badge-success">system</span>{" "} */}
                  <span className='badge badge-pill badge-warning'>
                    Status: {getStatus(activity.form_status)}
                  </span>{' '}
                  <span className='badge badge-pill badge-danger'>
                    Capturer Role: {activity.role}
                  </span>{' '}
                  <span className='badge badge-pill badge-info'>
                    Captured By: {activity.capturer_name}
                  </span>{' '}
                  <span className='badge badge-pill badge-success'>
                    {getDistrict(activity.district)}
                  </span>
                </div>
              </div>
            </div>
          )
        } else if (activity.activity === 'outreach_create') {
          return (
            <div className='timeline-block'>
              <span className='timeline-step badge-success'>
                <i className='fa fa-clipboard'></i>
              </span>
              <div className='timeline-content'>
                <Row>
                  <small className='text-muted font-weight-bold'>{date}</small>
                </Row>
                <Row>
                  <small className='text-muted font-weight-bold'>{time}</small>
                </Row>
                <h5 className='mt-3 mb-0'>Outreach Create</h5>
                {/* <p className="text-sm mt-1 mb-0"> */}
                {/* <PatientInfo
                 patient_id={activity.record_id}
                  />            */}
                {/* </p> */}
                <div className='mt-3'>
                  <span className='badge badge-pill badge-warning'>
                    Status: {getStatus(activity.form_status)}
                  </span>{' '}
                  <span className='badge badge-pill badge-danger'>
                    Capturer Role: {activity.role}
                  </span>{' '}
                  <span className='badge badge-pill badge-info'>
                    Captured By: {activity.capturer_name}
                  </span>{' '}
                  <span className='badge badge-pill badge-success'>
                    {getDistrict(activity.district)}
                  </span>
                </div>
              </div>
            </div>
          )
        }
      })}
    </div>
  )
}
export default withPatientState(TimeLine)
