import * as React from 'react'
import firebase from './firebase'
import {
  useCollection,
  useCollectionData,
} from 'react-firebase-hooks/firestore'
import withAuthState, { AuthProps } from '../Enhanchers/withAuthState'
import withPatientState, { PatientProps } from '../Enhanchers/withPatientState'
import { IPatient } from '../@types/patient'
import { useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { getDifference } from '../utils/Object'

type Props = AuthProps & PatientProps & RouteComponentProps
class PatientListener extends React.Component<Props> {
  unsubscribes: Array<() => void> = []

  unsubscribe = () => {
    this.unsubscribes.forEach((unsubscribe) => unsubscribe())
    this.unsubscribes = []
  }

  componentDidMount() {
    var temp_pat_list: any[] = []
    var user = this.props.auth.user
    // if()conso
    var patient_ref = firebase.db.collection(
      'main/' + user.data_access_group + '/patient_list'
    )
    const patient_sub = patient_ref.onSnapshot((snapshot) => {
      var temp_pat_list: any[] = []
      snapshot.docs.map(patient => {
        temp_pat_list.push({
          uid: patient.id,
          ...patient.data(),
        })
      })

      // console.log(temp_pat_list);

      var dif_ = getDifference(this.props.patients.patients, temp_pat_list)

      console.log(dif_)

      if (Object.keys(dif_).length === 0 && temp_pat_list.length !== 0) {
        // alert("patient list created");
        this.props.setPatients({
          patients: temp_pat_list,
        })
        // Route checkins
        // console.log(this.props);
        if (this.props.location.pathname === '/') {
          this.props.history.replace('/home')
        }
      } else {
        //  /  alert("patient list update")
        this.props.setPatients({
          patients: temp_pat_list,
        })
      }
    })
    this.unsubscribes.push(patient_sub)

    // }
  }
  componentWillUnmount() {
    this.unsubscribe()
  }

  render() {
    return null
  }
}

export default withRouter(withPatientState(withAuthState(PatientListener)))
