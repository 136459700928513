import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import { useForm, Controller } from 'react-hook-form'

import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  Input,
  Modal,
  CardHeader,
  Container,
  Card,
  CardBody,
} from 'reactstrap'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import withAuthState, { AuthProps } from '../Enhanchers/withAuthState'
import '../App.css'
import withOutreachDD, { OutreachDDProps } from '../Enhanchers/withOutreachDD'
import withPatientState, { PatientProps } from '../Enhanchers/withPatientState'
import ReactDatetime from 'react-datetime'
import { Moment } from 'moment'
import moment from 'moment'
import ReactQuill from 'react-quill'
import firebase from '../Services/firebase'
import AppointmentManagerModal from '../Forms/AppointmentManagerModal'
import withPrepState, { PrepProps } from '../Enhanchers/withPrepState'
import MainLayout from '../Layouts/MainLayout'
import { AnyCnameRecord } from 'dns'
import { getDifference } from '../utils/Object'
// interface IState{
//   select

// }
interface IProps {
  appointment?: any
}

type Props = RouteComponentProps &
  AuthProps &
  OutreachDDProps &
  PatientProps &
  IProps &
  PrepProps

function Settings(props: Props) {
  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState,
    control,
    setError,
    clearError,
  } = useForm()

  return (
    <MainLayout>
      <div className='header bg-gradient-secondary pb-8 pt-5 pt-md-8'>
        <Container fluid>
          <div className='header-body'>
            {/* Card stats */}
            <Row>
              <Col xs='4'>
                <h1>Settings</h1>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Container className='mt--6' fluid>
        <Card className='bg-secondary shadow'>
          <CardHeader className='bg-white border-0'>
            <h3>Prep Record</h3>
          </CardHeader>
          <CardBody>
            <></>
          </CardBody>
        </Card>
      </Container>
    </MainLayout>
  )
}

export default withPrepState(
  withPatientState(withOutreachDD(withAuthState(withRouter(Settings))))
)
