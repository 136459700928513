import {
    Badge,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Col,
    Button,
} from "reactstrap";
import { IOutreach } from "../@types/outreach";
import withOutreachDD, { OutreachDDProps } from "../Enhanchers/withOutreachDD";
import { find_and_split } from "../utils/dd_util";
import ExportManager from "./ExportManager";
import withOutreachState, {
    OutreachProps,
} from "../Enhanchers/withOutreachState";
import React, { useState } from "react";
// import {Button} from "@material-ui/core" ;
import AddIcon from "@material-ui/icons/Add";
import { withRouter, RouteComponentProps } from "react-router-dom";
import view_arrow from "../Assets/img/icons/common/view-arrow.png";

// eslint-disable-next-line import/first
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

// import paginationFactory from "react-bootstrap-table2-paginator";
// eslint-disable-next-line import/first
import BootstrapTable from "react-bootstrap-table-next";
// eslint-disable-next-line import/first
import paginationFactory from "react-bootstrap-table2-paginator";
import { getActivity, getClientID } from "../constants";

// eslint-disable-next-line import/first
import ToolkitProvider, {
    Search,
    CSVExport,
} from "react-bootstrap-table2-toolkit";

// eslint-disable-next-line import/first
import moment from "moment";
import { ExportToCsv } from "export-to-csv";

// eslint-disable-next-line import/first

require("react-bootstrap-table-next/dist/react-bootstrap-table2.min.css");

interface IProps {
    list: any;
    onRowSelect?: any;
    onEdit?: any;
}

type Props = IProps & RouteComponentProps & OutreachDDProps & OutreachProps;

class OutreachTable extends React.Component<Props> {
    state = {
        exportManager: false,
        selected: [],
    };

    // exportCSV = () => {
    //     console.log("exporting csv");
    //     let csvContent = "data:text/csv;charset=utf-8,";
    //     var pat_k: any[] = [];
    //     if (this.state.selected.length > 0) {
    //         pat_k = this.state.selected;
    //         var csv_data = this.props.list.filter((i: any) => {
    //             // console.log(i);
    //             return pat_k.includes(i.uid);
    //         });
    //         console.log(csv_data);
    //         var data: any, filename: any, link: any;
    //         var csv = this.convertArrayOfObjectsToCSV({
    //             data: csv_data,
    //         });
    //         if (csv == null) return;

    //         filename = "export.csv";

    //         if (!csv.match(/^data:text\/csv/i)) {
    //             csv = "data:text/csv;charset=utf-8," + csv;
    //         }
    //         data = encodeURI(csv);

    //         link = document.createElement("a");
    //         link.setAttribute("href", data);
    //         link.setAttribute("download", filename);
    //         link.click();
    //     }
    // };
    toggleExportManager = (toggle?: boolean) => {
        if (toggle) {
            this.props.outreach.outreach.map((outreach: any) => {
                outreach.activity_type_out = getActivity(
                    outreach.activity_type_out
                )
                    ? getActivity(outreach.activity_type_out)
                    : outreach.activity_type_out;

                outreach.form_complete = getClientID(outreach.form_complete)
                    ? getClientID(outreach.form_complete)
                    : outreach.form_complete;
            });
            this.setState({
                exportManager: toggle,
            });
        } else {
            this.setState({
                exportManager: !this.state.exportManager,
            });
        }
    };

    convertArrayOfObjectsToCSV = (args: any) => {
        var result: any,
            ctr: any,
            keys: any,
            columnDelimiter: any,
            lineDelimiter: any,
            data: any;
        var formattedCSVData: any = [];
        data = args.data || null;
        if (data == null || !data.length) {
            return null;
        }
        data.map((item: any) => {
            const outreachItem = {
                record_id: item.record_id,
                activity: item.activity_type_out,
                peer_responsible: item.meta.peer_responsible_out,
                patient_count: item.patients.length,
                sub_district: item.meta.sub_district_out,
                status: item.form_complete,
                created_on: item.date_of_capture_id,
            };
            formattedCSVData.push(outreachItem);
        });
        return formattedCSVData;
    };

    getFormattedData() {
        var formattedCSVData: any = [];

        this.props.outreach.outreach.map((item: any) => {
            const outreachItem = {
                record_id: item.record_id,
                activity: item.activity_type_out,
                peer_responsible: item.meta.peer_responsible_out,
                patient_count: item.patients.length,
                sub_district: item.meta.sub_district_out,
                status: item.form_complete,
                created_on: item.date_of_capture_id,
            };
            formattedCSVData.push(outreachItem);
        });
        return formattedCSVData;
    }

    render() {
        // const { ExportCSVButton } = CSVExport;
        const selectRow = {
            mode: "checkbox",
            clickToExpand: true,
            clickToSelect: true,
        };
        const expandRow = {
            onlyOneExpanding: true,

            renderer: (row: any, rowIndex: any) => {
                console.log(row);
                return (
                    <div>
                        <h3 className='display-4'>RedCap Sync: </h3>
                        <Table className='align-items-center' responsive>
                            <thead className='thead-light'>
                                <tr>
                                    <th scope='col'>Patient</th>
                                    <th scope='col'>Outreach DD Check</th>
                                    <th scope='col'>Patient Record Sync</th>
                                </tr>
                            </thead>
                            <tbody>
                                <>
                                    {row.patients.map((_p: any) => {
                                        return (
                                            <tr>
                                                <th scope='row' colSpan={3}>
                                                    {/* <Media className="align-items-center">
                      <a
                        className="avatar rounded-circle mr-3"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <img
                          alt="..."
                          src={require("assets/img/theme/bootstrap.jpg")}
                        />
                      </a>
                      <Media> */}
                                                    <a className='mb-0'>
                                                        {_p.full_name_out}
                                                    </a>
                                                    {/* </Media>
                    </Media> */}
                                                </th>
                                                {/* <td>{checkPrepReady(_p.)}</td> */}
                                            </tr>
                                        );
                                    })}
                                </>
                            </tbody>
                        </Table>
                        {/* <p>{ `This Expand row is belong to rowKey ${rowIndex}` }</p>
          <p>You can render anything here, also you can add additional data on every row object</p>
          <p>expandRow.renderer callback will pass the origin row object to you</p> */}
                    </div>
                );
            },
        };
        const defaultSorted = [
            {
                dataField: "date_of_capture_id",
                order: "desc",
            },
        ];

        const peer_details = (p_: number) => {
            var peer_out_pat = /peer/g;
            var peer_data_dict = this.props.outreach_dd.district_dd.filter(
                (fields_: any) => {
                    if (peer_out_pat.test(fields_.field_name)) {
                        return true;
                    }
                }
            );
            // console.log(peer_data_dict)
            if (
                peer_data_dict[0] &&
                peer_data_dict[0].select_choices_or_calculations
            ) {
                var values = find_and_split(
                    peer_data_dict[0].select_choices_or_calculations
                );
                var correct_d = values.filter((d_: any) => {
                    return d_.index === p_;
                });
                // console.log(correct_d);
                if (correct_d.length > 0) {
                    return correct_d[0].value;
                } else {
                    return "peer not found";
                }
            }
        };

        const sub_district_find = (p_: string) => {
            console.log(p_);
            var peer_out_pat = /district/g;
            var peer_data_dict = this.props.outreach_dd.district_dd.filter(
                (fields_: any) => {
                    if (peer_out_pat.test(fields_.field_name)) {
                        return true;
                    }
                }
            );
            //  console.log(peer_data_dict,p_)
            if (
                peer_data_dict[0] &&
                peer_data_dict[0].select_choices_or_calculations
            ) {
                var values = find_and_split(
                    peer_data_dict[0].select_choices_or_calculations
                );
                // console.log(values);
                // var p_t = pa
                var correct_d = values.filter((d_: any) => {
                    return d_.index === parseInt(p_);
                });
                // console.log(correct_d);
                if (correct_d.length > 0) {
                    return correct_d[0].value;
                } else {
                    return "subdistrict not found";
                }
            }
        };

        const pagination = paginationFactory({
            page: 1,
            alwaysShowAllBtns: true,
            showTotal: false,
            withFirstAndLast: false,
            sizePerPageRenderer: ({ options, onSizePerPageChange }: any) => (
                <>
                    <section
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "brandon-grotesque",
                                fontWeight: 600,
                                fontSize: "0.813rem",
                                color: "#143840",
                                textTransform: "uppercase",
                                marginRight: 10,
                                marginTop: 15,
                            }}
                        >
                            Showing
                        </p>
                        <select
                            style={{
                                width: 80,
                                height: 40,
                                paddingLeft: 10,
                                paddingRight: 10,
                                borderRadius: 3,
                                color: "#143840",
                                fontSize: "0.75rem",
                            }}
                            name='datatable-basic_length'
                            aria-controls='datatable-basic'
                            className='form-control'
                            onChange={(e) =>
                                onSizePerPageChange(e.target.value)
                            }
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                        </select>
                    </section>
                </>
            ),
        });

        const { SearchBar } = Search;

        const columns = [
            {
                dataField: "activity_date_outreach",
                text: "Date",
                sort: true,
            },
            {
                dataField: "activity_type_out",
                text: "Activity",
                sort: true,
                formatter: (cell: any, row: any, rowIndex: number) => {
                    if (cell === 1 || cell === "Outreach") {
                        return <h3 className='text-success'>OUT</h3>;
                    } else {
                        return <h3 className='text-danger'>GA</h3>;
                    }
                },
            },
            {
                dataField: "meta",
                text: "Peer Responsible",
                sort: true,
                formatter: (cell: any, row: any, rowIndex: number) => {
                    return peer_details(parseInt(cell.peer_responsible_out));
                },
            },

            {
                dataField: "patients",
                text: "Patients",
                sort: true,
                formatter: (cell: any, row: IOutreach[], rowIndex: number) => {
                    return cell.length;
                },
            },
            {
                dataField: "meta",
                text: "Sub District",
                sort: true,
                formatter: (cell: any, row: IOutreach[], rowIndex: number) => {
                    return sub_district_find(cell.sub_district_out);
                },
            },

            {
                dataField: "form_complete",
                text: "Status",
                sort: true,
                formatter: (cell: any) => {
                    if (cell === 2) {
                        return (
                            <>
                                <span className='badge badge-pill badge-complete'>
                                    Complete
                                </span>
                            </>
                        );
                    } else if (cell === 1) {
                        return (
                            <>
                                <span className='badge badge-pill badge-incomplete'>
                                    Incomplete
                                </span>
                            </>
                        );
                    } else if (cell === 0) {
                        return (
                            <>
                                <span className='badge badge-pill badge-review'>
                                    Review
                                </span>
                            </>
                        );
                    } else if (cell === 3) {
                        return (
                            <>
                                <span className='badge badge-pill badge-incomplete'>
                                    Rejected
                                </span>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <span className='badge badge-pill badge-amended'>
                                    Amended
                                </span>
                            </>
                        );
                    }
                },
            },
            {
                dataField: "date_of_capture_id",
                text: "Created On",
                sort: true,
                formatter: (cell: any, row: any) => {
                    if (cell !== 0) {
                        // var split_date_and_time = cell.split(" ");
                        // var new_date = new Date(split_date_and_time[0]);
                        var _return = moment(cell).format("DD/MM/YYYY");
                        return <a>{_return}</a>;
                    } else {
                        return "no date saved";
                    }
                },
            },
            {
                dataField: "",
                text: "Actions",
                formatter: (cell: any, row: any) => {
                    return (
                        <Row>
                            <Col lg='6'>
                                <Button
                                    className='btn-primary'
                                    type='button'
                                    onClick={() => {
                                        this.props.onRowSelect(row);
                                    }}
                                >
                                    View
                                </Button>
                            </Col>
                            <Col lg='6'>
                                <Button
                                    className='btn-primary'
                                    type='button'
                                    onClick={() => {
                                        this.props.onEdit(row);
                                    }}
                                >
                                    Edit
                                </Button>
                            </Col>
                        </Row>
                    );
                },
            },
        ];

        return (
            <div>
                <ExportManager
                    isOpen={this.state.exportManager}
                    list={this.getFormattedData()}
                    togglefunct={this.toggleExportManager}
                />
                <ToolkitProvider
                    data={this.props.outreach.outreach}
                    keyField='id'
                    columns={columns}
                    bootstrap4={true}
                    search={{ searchFormatted: true }}
                    // exportCSV
                >
                    {(props: any) => (
                        <div>
                            <Container fluid style={{ marginTop: 30 }}>
                                <Row>
                                    <Col>
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <h1>Outreach </h1>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Button
                                                    className='menu-btn'
                                                    style={{
                                                        borderColor: "#17A3AB",
                                                        backgroundColor:
                                                            "#17A3AB",
                                                    }}
                                                    type='button'
                                                    onClick={() =>
                                                        this.toggleExportManager(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <span className='btn-inner--icon'>
                                                        <i className='fas fa-print fa-2x' />
                                                    </span>
                                                    <span className='btn-inner--text ml-1'>
                                                        CSV Export
                                                    </span>
                                                </Button>
                                                <Button
                                                    className='menu-btn'
                                                    color='warning'
                                                    type='button'
                                                    onClick={() => {
                                                        this.props.history.push(
                                                            {
                                                                pathname:
                                                                    "/outreach-create",
                                                                // state: patient,
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <span className='btn-inner--icon'>
                                                        <i className='far fa-plus-square fa-2x' />
                                                    </span>
                                                    <span className='btn-inner--text ml-1'>
                                                        Create Outreach
                                                    </span>
                                                </Button>
                                                <Button
                                                    className='menu-btn'
                                                    color='danger'
                                                    type='button'
                                                    onClick={() => {
                                                        this.props.history.push(
                                                            {
                                                                pathname:
                                                                    "/group-activity-create",
                                                                // state: patient,
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <span className='btn-inner--icon'>
                                                        <i className='far fa-plus-square fa-2x' />
                                                    </span>
                                                    <span className='btn-inner--text ml-1'>
                                                        Create Group Activity
                                                    </span>
                                                </Button>
                                                <SearchBar
                                                    className='menu-searchbar'
                                                    {...props.searchProps}
                                                    delay={0}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row xs='12'>
                                    <Col>
                                        <hr />
                                    </Col>
                                </Row>
                            </Container>
                            {this.props.outreach.outreach ? (
                                <BootstrapTable
                                    style={{ marginBottom: 5 }}
                                    {...props.baseProps}
                                    expandRow={expandRow}
                                    keyField='outreach_indiv_id'
                                    //  rowEvents={this.props.onRowSelect}
                                    bordered={false}
                                    pagination={pagination}
                                    // selectRow={ selectRow }

                                    defaultSorter={defaultSorted}
                                    // selectRow={ selectRow }
                                />
                            ) : null}
                        </div>
                    )}
                </ToolkitProvider>
            </div>
        );
    }
}

export default withOutreachState(withOutreachDD(withRouter(OutreachTable)));
