
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ISetPatientPayload,  setPatients } from '../Store/modules/patient/actions';
import { IPatientState as IPatientReduxState } from '../Store/modules/patient/reducer';
import { RootState } from '../@types/store';

const mapStateToProps = (state: RootState) => ({
  patients: state.patients,

});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setPatients,
      
    },
    dispatch
  );

interface IPatientState {
  patients: IPatientReduxState;
}

export interface IPatientDispatchProps {
  setPatients: (payload: ISetPatientPayload) => void;
}

export type PatientProps = IPatientState & IPatientDispatchProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
