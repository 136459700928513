
import React, { useState } from "react";
import firebase from '../Services/firebase';
import { useCollection, useCollectionData } from 'react-firebase-hooks/firestore';
import withAuthState, { AuthProps } from '../Enhanchers/withAuthState';
import withPatientState, { PatientProps } from '../Enhanchers/withPatientState';
import { IPatient } from '../@types/patient';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { UncontrolledAlert } from "reactstrap";

import { Alert } from "reactstrap";




function Notification(){


    const [_switch, setSwith_] = useState(false)


    // setTimeout(()=>{
 
  
    //     setSwith_(true);
    // },20000)



           return(
               null
           )
     

   
}

export default Notification