import React from "react";
import logo from "../logo.svg";
import "../App.css";
import TopNavbarMain from "../Components/TopNavbarMain";
import {
    CssBaseline,
    WithStyles,
    Toolbar,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    withStyles,
    Theme,
    createStyles,
    Button,
} from "@material-ui/core";
import MainLayout from "../Layouts/MainLayout";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";
import {
    Badge,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    FormGroup,
    Form,
    Input,
    CardTitle,
    Col,
    Card,
    CardBody,
    Collapse,
} from "reactstrap";
// import { CardBody, CardTitle, Col, Card } from "reactstrap";
import AddIcon from "@material-ui/icons/Add";

import Header from "../Components/Header";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IPatient } from "../@types/patient";
import firebase from "../Services/firebase";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import PatientTable from "../Components/PatientTable";

import AsyncSelect from "react-select/async";
import PatientForm from "../Forms/patientForm";
import withPatientState, { PatientProps } from "../Enhanchers/withPatientState";
import _, { last } from "lodash";
import PatientReviewForm from "../Forms/PatientReviewForm";
import ExistingPatientAccordion from "../Components/ExistingPatientAccordion";
import ReviewLayout from "../Layouts/ReviewFooter";
import ReviewFooter from "../Layouts/ReviewFooter";
interface IState {
    patient?: IPatient;
    patientEdit?: boolean;
    patient_matches?: IPatient[];
}

type Props = WithStyles<typeof styles> &
    RouteComponentProps<{ id: string }> &
    AuthProps &
    PatientProps;

class PatientReview extends React.Component<Props, IState> {
    state = {
        patient: {
            uid: "",
            birthdate_id: "",
            client_id_complete: 0,
            clinic_folder_number_id: "",
            comets_folder_no: "",
            contact_number_id: "",
            data_capturer_id: "",
            date_of_capture_id: "",
            first_name_id: "",
            gender_id: "",
            key_population_id: "",
            orbit_uid_id: "",
            passport_number: "",
            pref_name_id: "",
            race_id: "",
            record_id: "",
            redcap_data_access_group: "",
            redcap_event_name: "",
            redcap_repeat_instance: "",
            redcap_repeat_instrument: "",
            sa_id_number: "",
            surname_id: "",
            transgender_id: "",
        },
        patientEdit: false,
        patient_matches: [],
    };

    componentDidMount() {
        // if (this.props.location.state) {
        //   var temp_state: IPatient = this.props.location.state;
        //   console.log(temp_state);
        //   if (Object.keys(temp_state).length > 0) {
        //     this.setState({
        //       patient: temp_state,
        //       patientEdit: true,
        //     });
        //   }
        // } else {
        if (
            this.props.match.params.id &&
            this.props.location.state &&
            this.props.auth.user
        ) {
            var user_data_access_group = this.props.auth.user!.data_access_group.trim();

            var trim_string =
                "main/" + user_data_access_group + "/patient_list";
            firebase.db
                .collection(trim_string)
                .doc(this.props.match.params.id)
                .get()
                .then((patient_cb) => {
                    var last_pat: any = {
                        uid: patient_cb.id,
                        ...patient_cb.data(),
                    };
                    if (this.state.patient !== last_pat) {
                        console.log(last_pat);
                        this.setState({
                            patient: last_pat,
                            patientEdit: true,
                        });
                    }
                    return;
                });
        }
    }

    render() {
        if (this.state.patientEdit) {
            return (
                <>
                    <MainLayout>
                        {/* Patient Review Header */}

                        <Container fluid style={{ marginTop: 30 }}>
                            <Row>
                                <Col xs='1'>
                                    <Button
                                        style={{
                                            backgroundColor: "transparent",
                                            marginTop: 13,
                                        }}
                                        onClick={() => {
                                            this.props.history.goBack();
                                        }}
                                    >
                                        <i
                                            className='fa fa-arrow-left  ml-5'
                                            style={{ fontSize: 20 }}
                                        />
                                    </Button>
                                </Col>
                                <Col xs='11'>
                                    <h1 className='pl-0'>
                                        Patient Review{" "}
                                        {this.state.patient.record_id
                                            ? this.state.patient.record_id
                                            : ""}
                                    </h1>
                                </Col>
                            </Row>

                            <Row xs='12'>
                                <Col>
                                    <hr />
                                </Col>
                            </Row>
                        </Container>

                        {/* Page content */}

                        <section style={{ backgroundColor: "#fff" }}>
                            {this.state.patientEdit ? (
                                <PatientReviewForm
                                    patient={this.state.patient}
                                    isPatientEdit={this.state.patientEdit}
                                />
                            ) : null}
                        </section>

                        {/* <ReviewFooter /> */}
                    </MainLayout>
                </>
            );
        } else {
            return null;
        }
    }
}
const styles = (theme: Theme) =>
    createStyles({
        table: {
            minWidth: 650,
        },
        tableRow: {
            "&:hover": {
                borderLeft: "#17A3AB",
                borderLeftWidth: "10",
                borderLeftStyle: "solid",
                boxShadow: "0 3px 5px 2px rgba(0,0,0,0.1)",
            },
        },
    });

export default withPatientState(
    withAuthState(withRouter(withStyles(styles)(PatientReview)))
);
