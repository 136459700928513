import React, { useState } from 'react'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import withAuthState, { AuthProps } from '../Enhanchers/withAuthState'
import '../App.css'
import withOutreachDD, { OutreachDDProps } from '../Enhanchers/withOutreachDD'
import withPatientState, { PatientProps } from '../Enhanchers/withPatientState'
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
  FormGroup
} from 'reactstrap'
import firebase from 'firebase'
import { useForm } from 'react-hook-form'
// react plugin used to create datetimepicker
import ReactDatetime from 'react-datetime'
import moment, { Moment } from 'moment'
import ReactQuill from 'react-quill'
import PrepCreateManager from '../Components/PrepCreateManager'
import { getTransferClinic } from "../constants";

// interface IState{
//   select

// }
interface IProps {
  appointment?: any
}

type Props = RouteComponentProps &
  AuthProps &
  OutreachDDProps &
  PatientProps &
  IProps

function PrepInitiationCreateForm(props: Props) {
  const {
    register,
    errors,
    handleSubmit,
    formState,
    getValues: getPatientValues,
  } = useForm({
    mode: 'onChange',
  })
  const {
    register: initiationRegister,
    errors: initiationErrors,
    handleSubmit: handleSubmitInitiation,
    formState: initiationFormState,
    getValues: getInitiationValues,
    reset: resetInitition,
    setValue: setInitiationValue,
  } = useForm({
    mode: 'onChange',
  })

  const {
    register: monitorRegister,
    errors: monitorErrors,
    handleSubmit: handleSubmitMonitor,
    formState: monitorFormState,
    getValues: getMonitorValues,
  } = useForm({
    mode: 'onChange',
  })

  //   field Info
  const pos_check: any = {
    1: '+',
    0: '-',
  }
  const test_res: any = {
    1: '+',
    0: '-',
    2: 'N/A',
  }
  const yes_and_now: any = {
    1: 'Y',
    0: 'N',
  }
  const [commentMultiLine, setCommentMultiLine] = useState('')

  //   const {
  //     register: register2,
  //     errors: errors2,
  //     watch,
  //     on
  //     handleSubmit: handleSubmit2
  //   } = useForm({
  //     mode: "onBlur"
  //   });

  const [formActive, setFormActive] = useState('patient')

  const [initActive, setInitActive] = useState(false)
  const [initActiveIndex, setInitActiveIndex] = useState(-1)

  const [monitorActiveIndex, setMonitorActiveIndex] = useState(-1)
  const [initRec, setInitRec] = useState({
    creatine_results: '',
    decide_to_initiate_results: '',
    heb_b_results: '',
    hiv_test_results: '',
    init_date_prep: '',
    pregnancy_results: '',
    prep_counseling_conducted: '',
    proceed_screening: '',
    sti_results: '',
    weight_results: '',
  })

  const [initiationList, setInitList] = useState<any[]>([])

  const [monitorActive, setMonitorActive] = useState(false)

  const [monitorStay, setMonitorStay] = useState(false)
  const [monitorOutcome, setMonitorOutcome] = useState(false)

  const temp_obj: any = {}
  const [finalObject, setFinalObject] = useState(temp_obj)

  const [prepPatient, setPrepPatient] = useState(temp_obj)
  const [patientAdd, setPatientAdd] = useState(false)

  const [activeTransfer, setActiveTransfer] = useState(false);
  const [transferDate, setTransferDate] = useState(
      moment().format("YYYY/MM/DD")
  );
  const _setTransferDate = (date: Moment | string) => {
      setTransferDate(moment(date).format("YYYY/MM/DD"));

      return;
  };
  const [transferClinic, setTransferClinic] = useState("");

  var today = moment().subtract(1, 'day').format('YYYY/MM/DD')
  var next_date = today
  const [init_date, setInitDate] = useState(moment().format('YYYY/MM/DD'))
  const [followupdate, setFollowupdate] = useState(moment().format('YYYY/MM/DD'));
  var valid = function (current: any) {
    return current.isSameOrBefore(today)
  }
  var validFollowUp = function (current: any) {
    var followupCheck = moment(init_date).add(1, 'month')
    return current.isSameOrAfter(followupCheck)
  }

  const [birthDate, _setBirthDate] = useState(today)

  const _setInitDate = (date: Moment | string) => {
    setInitDate(moment(date).format('YYYY/MM/DD'))

    return
  }
  const setSelectedDate = (date: Moment | string) => {
    _setBirthDate(moment(date).format('YYYY/MM/DD'))
    if (moment().diff(date, 'years', false) < 18 &&
    String(date).length === 33) {
      alert('Patient entered, is younger than 18')
    }
    return
  }
  const setFollowUpDate = (date: Moment | string) => {
    setFollowupdate(moment(date).format('YYYY/MM/DD'))

    return
  }

  const submitPrepInitiation = () => {
    var patientInfo = getPatientValues()
    var initiationInfo = getInitiationValues()
    var monitorInfo = getMonitorValues()
    console.log('patient', patientInfo)
    console.log('initiation', initiationInfo)
    console.log('monitor ', monitorInfo)

    // Check 1 : Errors
    console.log(errors, initiationErrors, monitorErrors)
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(initiationErrors).length === 0 &&
      Object.keys(monitorErrors).length === 0
    ) {
      var monitor_his = []
      monitor_his.push({
        ...monitorInfo,
        month_outcome_monitor: initiationList[0] ? initiationList[0].month_outcome_monitor : "",
        actual_captured_date: today,

        actual_visit_date: init_date,
        meds_dispense: false,
        next_visit_date: followupdate,
      })

      var final_object = {
        // Current Activity Info
        form_complete: props.auth.user.role_access === 1 ? 2 : 0,
        prep_status: monitorInfo.outcome_monitor,
        // monitoring_status: 0,
        follow_up_status: 1,
        follow_up_date: moment(followupdate).format('YYYY/MM/DD'),
        date_capture_id: props.auth.user.uuid,
        date_of_capture_id: moment().format('YYYY/MM/DD HH:mm:ss'),

        // patient info
        patient_info: {
          ...patientInfo,
          birthdate_prep: birthDate,
          patient_uid: prepPatient.uid,
          record_id: prepPatient.record_id,
          patient_form_complete: prepPatient.client_id_complete,
        },
        initiation_date: init_date,
        initiation_status: monitorInfo.outcome_monitor,
        initiation_history: initiationList,
        monitor_history: monitor_his,
        comment: commentMultiLine ? JSON.stringify(commentMultiLine) : " ",
      }
      console.log(final_object)
      setFinalObject(final_object)
      setCreateManagerActive(true)
    }
  }

  if (props.location.search.length > 0 && !patientAdd) {
    var split_params = props.location.search.split('&')
    var reason = split_params[0].split('=')[1]
    var recordId = split_params[1].split('=')[1]
    console.log(reason, recordId)

    if (reason === 'patient' && recordId.length > 0) {
      // appointment

      var patientRecord = props.patients.patients.filter((pat_: any) => {
        return pat_.uid === recordId
      })

      if (patientRecord.length > 0) {
        setPatientAdd(true)
        setPrepPatient(patientRecord[0])
        setSelectedDate(moment(patientRecord[0].birthdate_id))
      }
    }
  }

  const handleMultiLineChange = (value: any, multi_: string) => {
    console.log(value)
    if (multi_ === 'comments') {
      setCommentMultiLine(value)
    }
  }

  const checkPatientComplete = (data: any) => {
    alert(JSON.stringify(data))
  }
  console.log(errors)
  const { isValid, isSubmitting } = formState
  const { isValid: initiationValid } = initiationFormState
  const { isValid: monitorValid } = monitorFormState

  console.log(isValid)

  const [createManagerActive, setCreateManagerActive] = useState(false)
  return (
    <Container fluid>
      {createManagerActive ? (
        <PrepCreateManager
        transferClinic={getTransferClinic(
          transferClinic
      )}
          isOpen={createManagerActive}
          togglefunct={setCreateManagerActive}
          errors={{
            patient_errors: errors,
            patient_valid: isSubmitting,
            initiation_errors: initiationErrors,
            initiation_valid: initiationValid && initiationList.length > 0,
            monitor_errors: monitorErrors,
            monitor_valid: monitorValid,
          }}
          final_object={finalObject}
        />
      ) : null}
      <section>
        {/* FormActive: Patient */}
        <form onSubmit={handleSubmit(checkPatientComplete)}>
          <Table>
            <thead>
              <tr>
                <th colSpan={2} className='text-center'>
                  Logo
                </th>
                <th colSpan={9} className='text-center'>
                  Prep Clinical Form (Initiation)
                </th>
              </tr>
            </thead>

            <tbody>
              {patientAdd ? (
                <>
                  <tr>
                    <th colSpan={2}>Name</th>
                    <td colSpan={3}>
                      <Input
                        innerRef={register({ required: true, minLength: 2 })}
                        name='first_name_prep'
                        //   className='form-control-alternative'
                        type='text'
                        disabled
                        placeholder='First Name'
                        defaultValue={prepPatient.first_name_id}
                      />
                      {errors.first_name_prep ? (
                        <a className='text-danger' style={{ fontSize: 8 }}>
                          Please Enter a valid name
                        </a>
                      ) : null}
                    </td>
                    <th colSpan={3}>Folder Number</th>
                    <td colSpan={3}>
                      <Input
                        innerRef={register({ required: true, minLength: 2 })}
                        name='folder_number_prep'
                        //   className='form-control-alternative'
                        disabled
                        type='text'
                        placeholder='Clinic Folder Number'
                        defaultValue={prepPatient.clinic_folder_number_id}
                      />
                      {errors.folder_number_prep ? (
                        <a className='text-danger' style={{ fontSize: 8 }}>
                          Please Enter a valid Folder Number
                        </a>
                      ) : null}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Surname</th>
                    <td colSpan={3}>
                      <Input
                        innerRef={register({ required: true, minLength: 2 })}
                        name='surname_prep'
                        //   className='form-control-alternative'
                        disabled
                        type='text'
                        placeholder='Surname'
                        defaultValue={prepPatient.surname_id}
                      />
                      {errors.folder_number_prep ? (
                        <a className='text-danger' style={{ fontSize: 8 }}>
                          Please Enter a valid Surname
                        </a>
                      ) : null}
                    </td>
                    <th colSpan={3}>Phone Number</th>
                    <td colSpan={3}>
                      <Input
                        innerRef={register({
                          required: true,
                          pattern: /(^0[0-9]{9})(\/)?/,
                          minLength: 1,
                        })}
                        name='contact_number_prep'
                        //   className='form-control-alternative'
                        type='text'
                        placeholder='Contact Number'
                        defaultValue={prepPatient.contact_number_id}
                      />
                      {errors.contact_number_prep ? (
                        <a className='text-danger' style={{ fontSize: 8 }}>
                          Please Enter a valid Contact Number
                        </a>
                      ) : null}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={2}>DOB</th>
                    <td colSpan={3}>
                      <InputGroup className='input-group-alternative'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='ni ni-calendar-grid-58' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'Date of Birth',
                            value: birthDate,
                            name: 'birthdate_prep',
                          }}
                          isValidDate={valid}
                          timeFormat={false}
                          dateFormat={moment().format("YYYY/MM/DD")}
                          closeOnSelect={true}
                          onChange={(v) => setSelectedDate(v)}
                        />
                      </InputGroup>
                    </td>
                    <th colSpan={3}>Address</th>
                    <td colSpan={3}>
                      {' '}
                      <Input
                        innerRef={register({ required: false, minLength: 2 })}
                        name='address_prep'
                        //   className='form-control-alternative'

                        type='text'
                        placeholder='address'
                      />
                      {errors.address_prep ? (
                        <a className='text-danger' style={{ fontSize: 8 }}>
                          Please Enter a valid Address
                        </a>
                      ) : null}
                    </td>
                  </tr>
                  <tr>
                    {/* <th colSpan={2}>ID Number</th>
                    <td colSpan={3}>
                      <Input
                        innerRef={register({ required: false, minLength: 13 })}
                        name='id_number_prep'
                        //   className='form-control-alternative'

                        type='text'
                        placeholder='0000000000000'
                      />
                      {errors.id_number_prep ? (
                        <a className='text-danger' style={{ fontSize: 8 }}>
                          Please Enter a valid ID Number
                        </a>
                      ) : null}
                    </td> */}
                    <th colSpan={2}>Gender</th>
                    <td colSpan={3}>
                      {' '}
                      <Input
                        innerRef={register({ required: true, minLength: 1 })}
                        name='gender_prep'
                        type='select'
                        className='form-control-alternative'
                        defaultValue={prepPatient.gender_id}
                      >
                        <option value='1'>Male </option>
                        <option value='2'>Female </option>
                        <option value='3'>Transgender Man </option>
                        <option value='4'>Transgender Woman </option>
                        <option value='5'>Gender Non-Conforming</option>
                      </Input>
                      {errors.gender_id ? (
                        <a className='text-danger' style={{ fontSize: 8 }}>
                          Please Select Gender
                        </a>
                      ) : null}
                    </td>
                  </tr>
                </>
              ) : null}
            </tbody>
          </Table>
        </form>

     
     
        <Table>
          <tbody>
            <tr>
              <td colSpan={11} className='breaking'>
                <strong>Instructions:</strong> Please use the below form to
                capture initiation, continuation, discontinuation, and
                re-initiation for PrEP. If a patient discontinues, continue the
                record with the corresponding date of discontinuation. Should a
                patient re-start PrEP, re-initiation and subsequent visits will
                be captured into this same form. Adherence support and other
                notes can be captured further below.
              </td>
            </tr>
          </tbody>
        </Table>
        {/* FormActive: Inition
================
Initiation Table */}

        <form key={0} onSubmit={handleSubmitInitiation(checkPatientComplete)}>
          <Table style={{ marginTop: 2 }} className='text-center'>
            <thead>
              <tr>
                <th colSpan={14}>
                  Steps Prior to PrEP Initiation/Re-Initiation
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th rowSpan={2} colSpan={2} className='small'>
                  Date of Visit
                </th>
                <th rowSpan={2} colSpan={2} className='small'>
                  HIV {'\n'}Test Result
                </th>
                <th rowSpan={2} colSpan={2} className='small'>
                  PrEP Counselling Conducted?
                </th>
                <th rowSpan={2} colSpan={2} className='small'>
                  Proceed with Screening?
                </th>
                <th colSpan={6} className='small'>
                  Prep Screening
                </th>
              </tr>
              <tr>
                <th className='extra-small'>Creatinine (eGFR)</th>
                <th className='extra-small'>Pregnancy</th>
                <th className='extra-small'>Weight (Kgs)</th>
                <th className='extra-small'>Hep B Result</th>
                <th className='extra-small'>STI Screening</th>
                <th className='extra-small'>Decide to Initiate?</th>
              </tr>
            </tbody>
          </Table>
          <Table style={{ marginTop: 2 }} className='text-center'>
            {initActive ? (
              <Table>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Date of Visit',
                          value: moment(init_date).format("YYYY/MM/DD"),
                          name: 'init_date_prep',
                        }}
                        isValidDate={valid}
                        timeFormat={false}
                        closeOnSelect={true}
                        onChange={(v) => _setInitDate(v)}
                      />
                    </td>

                    <td colSpan={2}>
                      <Input
                        name='hiv_test_results'
                        type='select'
                        defaultValue={initRec.hiv_test_results}
                        className='form-control-alternative'
                        innerRef={initiationRegister({
                          required: true,
                          minLength: 1,
                        })}
                      >
                        <option value=''></option>
                        <option value='1'>+</option>
                        <option value='0'>-</option>
                      </Input>
                    </td>
                    <td colSpan={2}>
                      <Input
                        name='prep_counseling_conducted'
                        defaultValue={initRec.prep_counseling_conducted}
                        type='select'
                        className='form-control-alternative'
                        innerRef={initiationRegister({
                          required: true,
                          minLength: 1,
                        })}
                      >
                        <option value=''></option>
                        <option value='1'>Y</option>
                        <option value='2'>N</option>
                      </Input>
                    </td>
                    <td colSpan={2}>
                      <Input
                        name='proceed_screening'
                        type='select'
                        className='form-control-alternative'
                        defaultValue={initRec.proceed_screening}
                        innerRef={initiationRegister({
                          required: true,
                          minLength: 1,
                        })}
                      >
                        <option value=''></option>
                        <option value='1'>Y</option>
                        <option value='0'>N</option>
                      </Input>
                    </td>
                    <td>
                      <Input
                        className='form-control-alternative'
                        type='text'
                        name='creatine_results'
                        defaultValue={initRec.creatine_results}
                        innerRef={initiationRegister({
                          required: false,
                          minLength: 1,
                        })}
                      />
                    </td>
                    <td>
                      {' '}
                      <Input
                        name='pregnancy_results'
                        type='select'
                        className='form-control-alternative'
                        defaultValue={initRec.pregnancy_results}
                        innerRef={initiationRegister({
                          required: true,
                          minLength: 1,
                        })}
                      >
                        <option value=''></option>
                        <option value='1'>+</option>
                        <option value='0'>-</option>
                        <option value='2'>N/A</option>
                      </Input>
                    </td>
                    <td>
                      {' '}
                      <Input
                        defaultValue={initRec.weight_results}
                        innerRef={initiationRegister({
                          required: true,
                          minLength: 1,
                        })}
                        className='form-control-alternative'
                        type='text'
                        name='weight_results'
                      />
                    </td>
                    <td>
                      <Input
                        name='heb_b_results'
                        type='select'
                        defaultValue={initRec.heb_b_results}
                        className='form-control-alternative'
                        innerRef={initiationRegister({
                          required: true,
                          minLength: 1,
                        })}
                      >
                        <option value=''></option>
                        <option value='1'>+</option>
                        <option value='0'>-</option>
                      </Input>
                    </td>
                    <td>
                      <Input
                        name='sti_results'
                        type='select'
                        defaultValue={initRec.sti_results}
                        className='form-control-alternative'
                        innerRef={initiationRegister({
                          required: true,
                          minLength: 1,
                        })}
                      >
                        <option value=''></option>
                        <option value='1'>+</option>
                        <option value='0'>-</option>
                      </Input>
                    </td>
                    <td>
                      <Input
                        name='decide_to_initiate_results'
                        type='select'
                        className='form-control-alternative'
                        innerRef={initiationRegister({
                          required: true,
                          minLength: 1,
                        })}
                        onChange={(e) => {
                          console.log(e.target.value)
                          if (e.target.value === '1') {
                            console.log(initiationValid)
                            var answers = getInitiationValues()
                            if (Object.keys(answers).length > 0) {
                              console.log(answers)
                              if (answers.decide_to_initiate_results === '1') {
                                setFormActive('monitor')
                                var temp_list = initiationList
                                if (initActiveIndex === -1) {
                                  temp_list.push({
                                    ...answers,
                                    init_date_prep: moment(init_date).format("YYYY/MM/DD"),
                                  })
                                } else {
                                  var temp_list = initiationList
                                  temp_list[initActiveIndex] = {
                                    ...answers,
                                    init_date_prep: moment(init_date).format("YYYY/MM/DD"),
                                    outcome_monitor: '',
                                  }
                                  setInitActiveIndex(-1)
                                }
                                setInitList(temp_list)
                                setMonitorActive(true)
                                setMonitorStay(false)
                                setMonitorOutcome(false)
                                setInitActive(false)
                                var temp_followup = moment(init_date).add(
                                  1,
                                  'month'
                                )
                                setFollowupdate(
                                  temp_followup.format('YYYY/MM/DD')
                                )
                              }
                            }
                          } else if (e.target.value === '0') {
                            console.log(initiationValid)
                            var answers = getInitiationValues()
                            if (Object.keys(answers).length > 0) {
                              console.log(answers)

                              if (answers.decide_to_initiate_results === '0') {
                                setFormActive('comment')
                                var temp_list = initiationList

                                if (initActiveIndex === -1) {
                                  temp_list.push({
                                    ...answers,
                                    init_date_prep: init_date,
                                  })
                                } else {
                                  var temp_list = initiationList

                                  temp_list[initActiveIndex] = {
                                    ...answers,
                                    init_date_prep: init_date,
                                  }
                                  setInitActiveIndex(-1)
                                }

                                setInitList(temp_list)
                                setInitActive(false)
                                handleMultiLineChange(
                                  '<blockquote>Reason For DISCONTINUATION: </blockquote>',
                                  'comments'
                                )
                              }
                            }
                          }
                        }}
                      >
                        <option value=''></option>
                        <option value='1'>Y</option>
                        <option value='0'>N</option>
                      </Input>
                    </td>
                  </tr>
                </tbody>
              </Table>
            ) : (
              <Col
                xs='12'
                style={{ justifyContent: 'center', alignItems: 'start' }}
              >
                {!isValid ? <p className="text-red">Please Complete Above Demographic Fields, to enable Initiation </p>: null }
                <Button
                  xs='5'
                  className='btn-outline-primary'
                  type='button'
                  disabled={!isValid}
                  onClick={(e) => {
                    e.preventDefault()
                    setFormActive('initiation')
                    setInitActive(true)
                  }}
                >
                  Initiate Patient
                </Button>
              </Col>
            )}
          </Table>
        </form>
        {initiationList.length !== 0 ? (
          <>
            {initiationList.map((initItem: any, index: number) => {
              console.log(initItem, index)

              return (
                <Table>
                  <tbody>
                    <tr
                      key={index}
                      onClick={(e) => {
                        e.preventDefault()
                        console.log(index)
                        var temp_list = initiationList
                        var itemEdit = temp_list[index]
                        setInitActive(true)
                        if (initActiveIndex === -1) {
                          setInitActiveIndex(0)
                        } else {
                          setInitActiveIndex(initActiveIndex)
                        }
                        temp_list = temp_list.splice(index + 1, 1)
                        console.log(temp_list)
                        setInitList(temp_list)
                        console.log(getInitiationValues())

                        Object.keys(itemEdit).forEach((item: any) => {
                          if (
                            !item.includes('init_date_prep') &&
                            !item.includes('decide_to_init_results')
                          ) {
                            console.log(item, itemEdit[item], true)
                            setInitiationValue(item, itemEdit[item], true)
                          }
                        })
                        setInitRec(itemEdit)
                        setMonitorActive(false)
                      }}
                    >
                      <td colSpan={2}>
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'Date of Visit',
                            value: moment(initItem.init_date_prep).format('YYYY/MM/DD'),
                            name: 'init_date_prep',
                            disabled: true,
                          }}
                          isValidDate={valid}
                          timeFormat={false}
                          dateFormat={moment().format("YYYY/MM/DD")}

                          closeOnSelect={true}
                          onChange={(v) => setSelectedDate(v)}
                        />
                      </td>

                      <td>{pos_check[initItem.hiv_test_results]}</td>
                      <td>{yes_and_now[initItem.prep_counseling_conducted]}</td>
                      <td>{yes_and_now[initItem.proceed_screening]}</td>
                      <td>{initItem.creatine_results}</td>
                      <td>{test_res[initItem.pregnancy_results]}</td>
                      <td>{initItem.weight_results}</td>
                      <td>{pos_check[initItem.heb_b_results]}</td>
                      <td>{pos_check[initItem.sti_results]}</td>
                      <td>
                        {yes_and_now[initItem.decide_to_initiate_results]}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )
            })}
          </>
        ) : null}

        {/* Form Active:  Monitoring  */}
        <Table>

          {monitorActive ? (
            <>
              <thead>
                <tr>
                  <th colSpan={13}>
                    PrEP - Initiation/Re-Initiation and Monitoring
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2} rowSpan={2}>
                    Original Prep Initiation Date
                  </td>
                  <td colSpan={2} rowSpan={2}>
                    {moment(initiationList[0].init_date_prep).format("YYYY/MM/DD")}
                  </td>
                  <td rowSpan={2} colSpan={2}>
                                            {!activeTransfer ? (
                                                <Button
                                                    color='info'
                                                    className='pull-right'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        // submitPrepInitiation()
                                                        setActiveTransfer(true);
                                                    }}
                                                >
                                                    Create Transfer In
                                                </Button>
                                            ) : (
                                                <Col
                                                    style={{
                                                        justifyContent:
                                                            "space-between",
                                                        alignItems:
                                                            "flex-start",
                                                    }}
                                                >
                                                    <Row
                                                        style={{
                                                            marginBottom: 10,
                                                        }}
                                                    >
                                                        <Button
                                                            color='success'
                                                            className='pull-right'
                                                            disabled={
                                                                transferClinic.length ===
                                                                    0 &&
                                                                transferDate.length ===
                                                                    0
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                // submitPrepInitiation()
                                                                setActiveTransfer(
                                                                    false
                                                                );
                                                                var date_t = transferDate;
                                                                var clinic_t = transferClinic;
                                                                // var tempObj =
                                                                //     monitorHistory[0];
                                                                // tempObj.next_visit_date = date_t;
                                                                // setprepRecord({
                                                                //     ...prepRecord,
                                                                //     prep_clinic: clinic_t,
                                                                //     // init_date_prep: date_t,
                                                                // });
                                                                // props.history.push(
                                                                //     "/prep-view-lwazi"
                                                                // );
                                                                // setMonitorHistory(
                                                                //     [
                                                                //         ...monitorHistory,
                                                                //         tempObj,
                                                                //     ]
                                                                // );
                                                            }}
                                                        >
                                                            Save Transfer In
                                                        </Button>
                                                    </Row>
                                                    <Row>
                                                        <Button
                                                            color='success'
                                                            outline
                                                            className='pull-right'
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                // submitPrepInitiation()
                                                                setActiveTransfer(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Row>
                                                </Col>
                                            )}
                                        </td>
                  <td colSpan={2}>Date</td>
                  <td colSpan={5}>From Clinic</td>
                </tr>
                {!activeTransfer ? (
                                        <tr>
                                            <td colSpan={2}>{transferDate}</td>
                                            <td colSpan={5}>
                                                {getTransferClinic(
                                                    transferClinic
                                                )}
                                            </td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td colSpan={2}>
                                                <FormGroup>
                                                    <InputGroup className='input-group-alternative'>
                                                        <InputGroupAddon addonType='prepend'>
                                                            <InputGroupText>
                                                                <i className='ni ni-calendar-grid-58' />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <ReactDatetime
                                                            inputProps={{
                                                                placeholder:
                                                                    "Select Date",
                                                                name: "date",
                                                            }}
                                                            // defaultValue={
                                                            //     prepRecord.initiation_date
                                                            // }
                                                            isValidDate={valid}
                                                            timeFormat={false}
                                                            closeOnSelect={true}
                                                            onChange={(v) =>
                                                                _setTransferDate(
                                                                    v
                                                                )
                                                            }
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </td>
                                            <td colSpan={5}>
                                                <FormGroup>
                                                    <Input
                                                        name='clinic'
                                                        type='select'
                                                        // defaultValue={
                                                        //     prepRecord.prep_clinic
                                                        // }
                                                        className='form-control-alternative'
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            setTransferClinic(
                                                                e.target.value
                                                            );
                                                        }}
                                                        // disabled={clinicVal !== ""}
                                                    >
                                                        <option value=''>
                                                            Select
                                                        </option>
                                                        <option value='0'>
                                                            Pretoria Park Clinic
                                                        </option>
                                                        <option value='1'>
                                                            Sallisburg Clinic
                                                        </option>
                                                    </Input>
                                                </FormGroup>
                                            </td>
                                        </tr>
                                    )}
                
                <tr>
                  <th rowSpan={2} className='small'>
                    # of months on PrEP
                  </th>
                  <th rowSpan={2} colSpan={2} className='small'>
                    Next visit Date:
                  </th>
                  <th rowSpan={2} colSpan={2} className='small'>
                    Actual visit:
                  </th>
                  <th rowSpan={2} className='small'>
                    Staying on PrEP?
                  </th>
                  <th colSpan={7} className='small'>
                    Test Results (if applicable)
                  </th>
                </tr>
                <tr>
                  <th className='small'>HIV Test</th>
                  <th className='small'>Creatinine (eGFR)</th>
                  <th className='small'>Weight (Kgs)</th>
                  <th className='small'>Pregnancy</th>
                  <th className='small'>STI Screening</th>
                  <th className='small'>
                    Outcome
                  </th>
                  <th className='small'>Month of Outcome</th>
                </tr>

                <tr>
                  <td>0</td>
                  <td colSpan={2}>
                    <InputGroup className='input-group-alternative'>
                      {/* <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-calendar-grid-58' />
                        </InputGroupText>
                      </InputGroupAddon> */}
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Next Visit Date',
                          value: moment(initiationList[0].init_date_prep).format("YYYY/MM/DD"),
                          name: 'next_date_prep',
                          disabled: (!monitorStay || !monitorOutcome) && props.auth.user.role_access !== 1,
                        }}
                        isValidDate={validFollowUp}
                        timeFormat={false}
                        closeOnSelect={true}
                        // dateFormat={moment().format("YYYY/MM/DD")}

                        onChange={setFollowUpDate}
                      />
                    </InputGroup>
                  </td>
                  <td colSpan={2}>{moment(initiationList[0].init_date_prep).format("YYYY/MM/DD")}</td>
                  <td>
                    <Input
                      name='staying_on_prep'
                      type='select'
                      className='form-control-alternative'
                      innerRef={monitorRegister({
                        required: true,
                        minLength: 1,
                      })}
                      onChange={(e) => {
                        e.preventDefault()
                        var answer = e.target.value
                        if (answer.length !== 0) {
                          setMonitorStay(true)
                          console.log('set MonitorStay', true)
                        } else {
                          setMonitorStay(false)
                        }
                      }}
                    >
                      <option value=''></option>
                      <option value='1'>Y</option>
                      <option value='0'>N</option>
                    </Input>
                  </td>
                  <td>
                    <Input
                      name='hiv_test_results_monitor'
                      defaultValue={initiationList[0].hiv_test_results}
                      disabled={true}
                      type='select'
                      className='form-control-alternative'
                      innerRef={monitorRegister({
                        required: true,
                        minLength: 1,
                      })}
                    >
                      <option value=''></option>
                      <option value='1'>+</option>
                      <option value='0'>-</option>
                    </Input>
                  </td>
                  <td>
                    <Input
                      className='form-control-alternative'
                      type='text'
                      defaultValue={initiationList[0].creatine_results}
                      disabled={true}
                      name='creatine_results_monitor'
                      innerRef={monitorRegister({
                        required: false,
                        minLength: 1,
                      })}
                    />
                  </td>
                  <td>
                    <Input
                      className='form-control-alternative'
                      type='text'
                      defaultValue={initiationList[0].weight_results}
                      disabled={true}
                      name='weight_results_monitor'
                      innerRef={monitorRegister({
                        required: false,
                        minLength: 1,
                      })}
                    />
                  </td>
                  <td>
                    <Input
                      name='pregnancy_results_monitor'
                      type='select'
                      defaultValue={initiationList[0].pregnancy_results}
                      disabled={true}
                      className='form-control-alternative'
                      innerRef={monitorRegister({
                        required: true,
                        minLength: 1,
                      })}
                    >
                      <option value=''></option>
                      <option value='1'>+</option>
                      <option value='0'>-</option>
                      <option value='2'>N</option>
                    </Input>
                  </td>
                  <td>
                    <Input
                      name='sti_results_monitor'
                      type='select'
                      defaultValue={initiationList[0].sti_results}
                      disabled={true}
                      className='form-control-alternative'
                      innerRef={monitorRegister({
                        required: true,
                        minLength: 1,
                      })}
                    >
                      <option value=''></option>
                      <option value='1'>+</option>
                      <option value='0'>-</option>
                    </Input>
                  </td>
                  <td>
                    {' '}
                    <Input
                      name='outcome_monitor'
                      type='select'
                      className='form-control-alternative'
                      innerRef={monitorRegister({
                        required: true,
                      })}
                      onChange={(e) => {
                        e.preventDefault()
                        if(e.target.value === "2" || e.target.value === "3"){
                          handleMultiLineChange(
                            "<blockquote>Reason For DISCONTINUATION: </blockquote>",
                            "comments"
                        );
                        }else{
                          handleMultiLineChange(e, 'comments')
                        }
                        if (e.target.value.length !== 0) {
                          setMonitorOutcome(true)
                          var temp_list = initiationList
                          temp_list[0] = {
                            ...temp_list[0],
                            month_outcome_monitor: moment(init_date).format('MM/YY'),
                          }
                          console.log(temp_list)
                          setInitList(temp_list)
                        } else {
                          setMonitorOutcome(false)
                          var temp_list = initiationList
                          temp_list[0] = {
                            ...temp_list[0],
                            month_outcome_monitor: '',
                          }
                          console.log(temp_list)
                          setInitList(temp_list)
                        }
                      }}
                    >
                      <option value=''></option>
                      <option value='1'>Continue</option>
                      <option value='2'>
                        Disc1 - Patient elected to discontinue PrEP
                      </option>
                      <option value='3'>
                        Disc2 - Clinician discontinued PrEP
                      </option>
                      <option value='4'>
                        DNA - Patient did not attend scheduled visit
                      </option>
                      <option value='5'>
                        LTFU - Lost to follow-up after 60 days
                      </option>
                      <option value='9'>
                        Restart - Patient discontinued and restarted PrEP
                      </option>
                      <option value='6'>RIP - Patient died</option>
                      <option value='7'>
                        Sero - Patient seroconverted, HIV +
                      </option>
                      <option value='10'>TFI - Transferred in</option>
                      <option value='8'>TFO - Transferred out</option>
                    </Input>
                  </td>
                  <td>{initiationList[0].month_outcome_monitor}</td>
                </tr>
            
              </tbody>
            </>
          ) : null}
        </Table>

        {/* Comments */}
        {formActive === 'comment' || !(!monitorStay || !monitorOutcome) ? (
          <Table>
            <thead>
              <tr>
                <th colSpan={11} className='wrapping text-left'>
                  Notes: Reason for discontinuation / adherence support /
                  allergies / medical history / hospitalisations / TB history.
                  *Please state reason for discontinuation in detail (client's
                  choice / change in <br /> risk profile / adverse effects,
                  etc.)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={11}>
                  <ReactQuill
                    value={commentMultiLine}
                    onChange={(v) => handleMultiLineChange(v, 'comments')}
                    theme='snow'
                    modules={{
                      toolbar: [
                        ['bold', 'italic'],
                        ['link', 'blockquote', 'code', 'image'],
                        [
                          {
                            list: 'ordered',
                          },
                          {
                            list: 'bullet',
                          },
                        ],
                      ],
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        ) : null}

        <Row>
          <Col xs='6'></Col>
          <Col xs='6' className='text-right'>
            <Button
              color='secondary'
              type='button'
              onClick={() => {
                props.history.goBack()
              }}
            >
              Cancel
            </Button>
            <Button
              color='info'
              onClick={(e) => {
                e.preventDefault()
                submitPrepInitiation()
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </section>
    </Container>
  )
}
export default withPatientState(
  withOutreachDD(withAuthState(withRouter(PrepInitiationCreateForm)))
)
