import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Datetime from "react-datetime";
import moment, { Moment } from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// reactstrap components
import "../App.css";

import {
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    UncontrolledTooltip,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    FormText,
    Modal,
} from "reactstrap";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { IPatient } from "../@types/patient";
import { clear } from "console";
import { RouteComponentProps, withRouter } from "react-router-dom";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import firebase from "../Services/firebase";
import ReviewComponent from "../Components/ReviewComponent";

import ReactQuill from "react-quill";
import { getDifference } from "../utils/Object";

interface IProps {
    patient: any;
    isPatientEdit: boolean;
}

type Props = RouteComponentProps & AuthProps & IProps;

function PatientReviewForm(props: Props) {
    const {
        register,
        handleSubmit,
        watch,
        errors,
        formState,
        control,
        setError,
        clearError,
    } = useForm();
    const [commentMultiLine, setcMultiLine] = useState("");
    const handleMultiLineChange = (value: any) => {
        setcMultiLine(value);
    };

    const today = moment().format("YYYY/MM/DD");
    var valid = function (current: any) {
        return current.isBefore(today);
    };
    const [_init_date, _setInitDate] = useState(today);

    const setSelectedDate = (date: Moment | string) => {
        _setInitDate(moment(date).format("YYYY/MM/DD"));
        if (
            moment().diff(date, "years", false) < 18 &&
            String(date).length === 33
        ) {
            alert("Patient entered, is younger than 18");
        }
        return;
    };

    if (moment(_init_date).isSame(today)) {
        var dob = moment(props.patient.birthdate_id).format("YYYY/MM/DD");
        _setInitDate(dob);
    }
    var modalForm = false;

    const key_pop: any = {
        "": "",
        "1": "Client",
        "2": "Community",
        "3": "FSW",
        "4": "MSM",
        "5": "Transgender",
        "7": "MSM-SW",
        "10": "TGM",
        "11": "TGM-SW",
        "12": "TGNC",
        "13": "TGNC-SW",
        "8": "TGW",
        "9": "TGW-SW",
    };

    const [defaultModal, setPatientModal] = useState(false);

    const onSubmit = (data: any) => {
        ////console.log("-------------------");
        ////console.log("submitted value:");
        //console.log(formState);

        console.log(data);

        // if (data.contact_number_id.length !== 10) {
        //   alert('Please enter valid contact number')
        // }

        var _yearsTop = moment().subtract("years", 18);
        console.log(_yearsTop);
        console.log(moment(_init_date).isAfter(_yearsTop));

        // if(moment(_init_date).isAfter(_yearsTop)){
        //   alert("Patient is younger than 18");
        //   // return
        // }
        //console.log("-------------------");
        if (!modalForm) {
            setPatientModal(!defaultModal);
        }
        //console.log("-------------------");
        if (!modalForm) {
            setPatientModal(!defaultModal);
        } else if (modalForm) {
            console.log("submitting");
            acceptPatient(data);
        }
    };
    const [sexAtBirth, setSexAtBirth] = useState(false);

    const acceptPatient = (values: any) => {
        console.log("reviewing patient", errors);
        console.log(values);
        var date_now = new Date();
        var new_patient_object = {
            ...props.patient,
            first_name_id: values.first_name_id,
            surname_id: values.surname_id,
            pref_name_id: values.pref_name_id,
            key_population_id: values.key_population_id,
            gender_id: values.gender_id,
            birthdate_id: moment(_init_date).format("YYYY/MM/DD"),
            race_id: values.race_id,
            contact_number_id: values.contact_number_id,
            clinic_folder_number_id: props.patient?.clinic_folder_number_id,
            record_id: "null",
            date_capture_id: props.auth.user.uuid,
            last_of_capture_id: moment().format("YYYY/MM/DD HH:mm:ss"),
            client_id_complete: props.auth.user.role_access === 1 ? 2 : 0,
            lwazi_status: props.auth.user.role_access === 1 ? "new" : "review",
        };

        var user_data_access_group = props.auth.user!.data_access_group.trim();
        // var trim_string = ("patients/"+ user_data_access_group + "/patients");
        var ref = firebase.db.collection(
            "main/" + props.auth.user!.data_access_group + "/patient_list"
        );

        ref.doc(props.patient?.uid)
            .update(new_patient_object)
            .then((data) => {
                console.log("Successfully Added: ");
                console.log("Document written with ID: ", props.patient?.uid);
                if (props.patient?.uid) {
                    var difference_ = getDifference(
                        new_patient_object,
                        props.patient
                    );
                    var activity_ = {
                        record_id: props.patient?.uid,
                        district: props.auth.user.data_access_group,
                        role: props.auth.user.role,
                        activity: "patient_review_approve",
                        capturer_id: props.auth.user.uuid,
                        capturer_name: props.auth.user.name,
                        date_and_time: moment().format("YYYY/MM/DD HH:mm:ss"),
                        form_status: props.auth.user.role_access === 1 ? 2 : 0,
                        update: difference_,
                    };
                    var activit_ref = firebase.db.collection("activity_log");
                    activit_ref
                        .add(activity_)
                        .then((data__) => {
                            // var doc_activity_ref = "main/"+props.auth.user!.data_access_group+"/patient_list/"+data.id+"/activity_log";
                            // var main_log =  firebase.db.collection("activity_log");
                            // ref.add()

                            props.history.goBack();
                        })
                        .catch((error) => {});
                }
            })
            .catch((error) => {
                console.log("error");
            });
    };

    const rejectPatient = (values: any) => {
        console.log("reviewing patient", errors);
        console.log(values);
        var date_now = new Date();
        var new_patient_object = {
            ...props.patient,
            first_name_id: values.first_name_id,
            surname_id: values.surname_id,
            pref_name_id: values.pref_name_id,
            key_population_id: values.key_population_id,
            gender_id: values.gender_id,
            birthdate_id: moment(_init_date).format("YYYY/MM/DD"),
            race_id: values.race_id,
            contact_number_id: values.contact_number_id,
            clinic_folder_number_id: props.patient?.clinic_folder_number_id,
            date_capture_id: props.auth.user.uuid,
            date_of_capture_id: moment().format("YYYY/MM/DD HH:mm:ss"),
            client_id_complete: 1,
        };

        var user_data_access_group = props.auth.user!.data_access_group.trim();
        // var trim_string = ("patients/"+ user_data_access_group + "/patients");
        var ref = firebase.db.collection(
            "main/" + props.auth.user!.data_access_group + "/patient_list"
        );

        ref.doc(props.patient?.uid)
            .update(new_patient_object)
            .then((data) => {
                console.log("Successfully Added: ");
                console.log("Document written with ID: ", props.patient?.uid);
                if (props.patient?.uid) {
                    var difference_ = getDifference(
                        new_patient_object,
                        props.patient
                    );
                    var activity_ = {
                        record_id: props.patient?.uid,
                        district: props.auth.user.data_access_group,
                        role: props.auth.user.role,
                        activity: "patient_review_reject",
                        capturer_id: props.auth.user.uuid,
                        capturer_name: props.auth.user.name,
                        date_and_time: moment().format("YYYY/MM/DD HH:mm:ss"),
                        form_status: 1,
                        update: difference_,
                    };
                    var activit_ref = firebase.db.collection("activity_log");
                    activit_ref
                        .add(activity_)
                        .then((data__) => {
                            // var doc_activity_ref = "main/"+props.auth.user!.data_access_group+"/patient_list/"+data.id+"/activity_log";
                            // var main_log =  firebase.db.collection("activity_log");
                            // ref.add()

                            props.history.goBack();
                        })
                        .catch((error) => {});
                }
            })
            .catch((error) => {
                console.log("error");
            });
    };

    // const onSubmit = (data: any) => {
    //   ////console.log("-------------------");
    //   ////console.log("submitted value:");
    //   //console.log(formState);
    //   ////console.log(data);
    //   //console.log("-------------------");
    //   // setPatientModal(!defaultModal);
    // }
    const { dirty, isSubmitting, touched, submitCount } = formState;
    console.log({ dirty, isSubmitting, touched, submitCount });
    console.log(errors);
    if (props.auth.user.role_access !== 1) {
        alert(
            "you do not have the necessary access rights to access this page"
        );
        props.history.goBack();
    }

    const ModalFu = () => {
        return (
            <Modal
                className='modal-dialog-centered modal-info'
                contentClassName='bg-gradient-info'
                isOpen={defaultModal}
                toggle={() => setPatientModal(false)}
            >
                <div className='modal-header'>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => setPatientModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body'>
                    <div className='py-3 text-center'>
                        <i className='ni ni-paper-diploma ni-3x' />
                        <h4 className='heading mt-4'>Please Confirm!</h4>
                        <p>
                            Hey {props.auth.user.name}, The following form will
                            be sent for review. This will be made available,
                            once reviewed and approved by Data Capturer.
                        </p>
                    </div>
                </div>
                <div className='modal-footer'>
                    <Button
                        className='btn-white'
                        color='default'
                        type='button'
                        onClick={handleSubmit(acceptPatient)}
                    >
                        Confirm!
                    </Button>
                    <Button
                        className='text-white ml-auto'
                        color='link'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => {
                            setPatientModal(!defaultModal);
                        }}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        );
    };

    return (
        <>
            <section
                style={{
                    width: "100%",
                    height: "133vh",
                    marginTop: -32.5,
                    padding: "60px 0",
                }}
            >
                {defaultModal ? <>{ModalFu()}</> : null}

                <Form onSubmit={handleSubmit(onSubmit)}>
                    {/* Chadd Edits Starts */}

                    <section className='ml-5'>
                        <Row className='ml-5'>
                            <Col xs='4' className='ml-5'>
                                <label className='form-control-label'>
                                    Legal First Name
                                </label>
                                <FormGroup
                                    className={
                                        "" +
                                        ((errors.first_name_id !== true ||
                                            errors.first_name_id ===
                                                undefined) &&
                                        !(
                                            !formState.dirty &&
                                            formState.submitCount !== 0
                                        ) &&
                                        formState.submitCount > 0
                                            ? "has-success"
                                            : "") +
                                        " " +
                                        (errors.first_name_id
                                            ? "has-danger"
                                            : "")
                                    }
                                >
                                    <Input
                                        innerRef={register({
                                            required: true,
                                            minLength: 2,
                                        })}
                                        name='first_name_id'
                                        className='form-control-alternative'
                                        id='input-username'
                                        type='text'
                                        defaultValue={
                                            props.patient?.first_name_id
                                        }
                                    />
                                    {errors.first_name_id ? (
                                        <a
                                            className='text-danger'
                                            style={{ fontSize: 8 }}
                                        >
                                            Please Enter a valid name
                                        </a>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col xs='4' className='ml-5'>
                                <label className='form-control-label'>
                                    Legal Surname
                                </label>
                                <FormGroup
                                    className={
                                        "" +
                                        ((errors.surname_id !== true ||
                                            errors.surname_id === undefined) &&
                                        !(
                                            !formState.dirty &&
                                            formState.submitCount !== 0
                                        ) &&
                                        formState.submitCount > 0
                                            ? "has-success"
                                            : "") +
                                        " " +
                                        (errors.surname_id ? "has-danger" : "")
                                    }
                                >
                                    <Input
                                        innerRef={register({
                                            required: true,
                                            minLength: 2,
                                        })}
                                        name='surname_id'
                                        className='form-control-alternative '
                                        type='text'
                                        defaultValue={props.patient?.surname_id}
                                    />
                                    {errors.surname_id ? (
                                        <a
                                            className='text-danger'
                                            style={{ fontSize: 8 }}
                                        >
                                            Please Enter a valid Surname
                                        </a>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col xs='4' className='ml-5'>
                                <label className='form-control-label'>
                                    Preferred Name{" "}
                                    <span style={{ fontSize: 8 }}>
                                        (optional)
                                    </span>
                                </label>
                                <FormGroup
                                    className={
                                        "" +
                                        ((errors.pref_name_id !== true ||
                                            errors.pref_name_id ===
                                                undefined) &&
                                        !(
                                            !formState.dirty &&
                                            formState.submitCount !== 0
                                        ) &&
                                        formState.submitCount > 0
                                            ? "has-success"
                                            : "") +
                                        " " +
                                        (errors.pref_name_id
                                            ? "has-danger"
                                            : "")
                                    }
                                >
                                    <Input
                                        innerRef={register({
                                            minLength: 1,
                                        })}
                                        name='pref_name_id'
                                        className='form-control-alternative '
                                        type='text'
                                        defaultValue={
                                            props.patient?.pref_name_id
                                        }
                                    />
                                </FormGroup>
                            </Col>

                            <Col xs='4' className='ml-5'>
                                <FormGroup
                                    className={
                                        "" +
                                        ((errors.gender_id !== true ||
                                            errors.gender_id === undefined) &&
                                        !(
                                            !formState.dirty &&
                                            formState.submitCount !== 0
                                        ) &&
                                        formState.submitCount > 0
                                            ? "has-success"
                                            : "") +
                                        " " +
                                        (errors.gender_id ? "has-danger" : "")
                                    }
                                >
                                    <label className='form-control-label'>
                                        Gender
                                    </label>

                                    <Input
                                        innerRef={register({
                                            required: true,
                                            minLength: 1,
                                        })}
                                        name='gender_id'
                                        type='select'
                                        defaultValue={props.patient?.gender_id}
                                        className='form-control-alternative'
                                    >
                                        <option value=''>Gender</option>
                                        <option value='1'>Male </option>
                                        <option value='2'>Female </option>
                                        <option value='3'>
                                            Transgender Man{" "}
                                        </option>
                                        <option value='4'>
                                            Transgender Woman{" "}
                                        </option>
                                        <option value='5'>
                                            Gender Non-Conforming
                                        </option>
                                    </Input>
                                    {errors.gender_id ? (
                                        <a
                                            className='text-danger'
                                            style={{ fontSize: 8 }}
                                        >
                                            Please Select Gender
                                        </a>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col xs='4' className='ml-5'>
                                <label className='form-control-label'>
                                    Key Population Group
                                </label>
                                <FormGroup
                                    className={
                                        "" +
                                        ((errors.key_population_id !== true ||
                                            errors.key_population_id ===
                                                undefined) &&
                                        !(
                                            !formState.dirty &&
                                            formState.submitCount !== 0
                                        ) &&
                                        formState.submitCount > 0
                                            ? "has-success"
                                            : "") +
                                        " " +
                                        (errors.key_population_id
                                            ? "has-danger"
                                            : "")
                                    }
                                >
                                    <Input
                                        innerRef={register({
                                            required: true,
                                            minLength: 1,
                                        })}
                                        name='key_population_id'
                                        type='select'
                                        className='form-control-alternative'
                                        defaultValue={
                                            props.patient?.key_population_id
                                        }
                                        onChange={(e) => {
                                            e.preventDefault();
                                            var ans = e.target.value;
                                            var ans_val = key_pop[ans];
                                            if (ans_val.includes("TG")) {
                                                setSexAtBirth(true);
                                            } else {
                                                setSexAtBirth(false);
                                            }
                                        }}
                                    >
                                        <option value='1'>Client</option>
                                        <option value='2'>Community</option>
                                        <option value='3'>FSW</option>
                                        <option value='4'>MSM</option>
                                        <option value='5'>Transgender</option>
                                        <option value='7'>MSM-SW</option>
                                        <option value='10'>TGM</option>
                                        <option value='11'>TGM-SW</option>
                                        <option value='12'>TGNC</option>
                                        <option value='13'>TGNC-SW</option>
                                        <option value='8'>TGW</option>
                                        <option value='9'>TGW-SW</option>
                                    </Input>
                                    {errors.key_population_id ? (
                                        <a
                                            className='text-danger'
                                            style={{ fontSize: 8 }}
                                        >
                                            Please Select Key Population Group
                                        </a>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            {sexAtBirth ? (
                                <Col xs='4' className='ml-5'>
                                    <label className='form-control-label'>
                                        Sex At Birth:
                                    </label>
                                    <FormGroup
                                        className={
                                            "" +
                                            ((errors.gender_id !== true ||
                                                errors.gender_id ===
                                                    undefined) &&
                                            !(
                                                !formState.dirty &&
                                                formState.submitCount !== 0
                                            ) &&
                                            formState.submitCount > 0
                                                ? "has-success"
                                                : "") +
                                            " " +
                                            (errors.gender_id
                                                ? "has-danger"
                                                : "")
                                        }
                                    >
                                        <Input
                                            innerRef={register({
                                                required: sexAtBirth,
                                                minLength: 1,
                                            })}
                                            name='sex_at_birth_id'
                                            type='select'
                                            className='form-control-alternative'
                                            defaultValue={
                                                props.patient?.gender_id
                                            }
                                        >
                                            <option value='1'>Female </option>
                                            <option value='2'>Male </option>
                                        </Input>
                                        {errors.gender_id ? (
                                            <a
                                                className='text-danger'
                                                style={{ fontSize: 8 }}
                                            >
                                                Please Select Gender
                                            </a>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            ) : null}
                            <Col xs='4' className='ml-5'>
                                <label className='form-control-label'>
                                    Date of Birth
                                </label>
                                <FormGroup>
                                    <InputGroup className='input-group-alternative'>
                                        <InputGroupAddon addonType='prepend'>
                                            <InputGroupText>
                                                <i className='ni ni-calendar-grid-58' />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <ReactDatetime
                                            inputProps={{
                                                placeholder: "Select Date",
                                                value: _init_date,
                                                name: "initiation_date",
                                            }}
                                            isValidDate={valid}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={(v) => setSelectedDate(v)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col xs='4' className='ml-5'>
                                <label className='form-control-label'>
                                    Race
                                </label>
                                <FormGroup
                                    className={
                                        "" +
                                        ((errors.race_id !== true ||
                                            errors.race_id === undefined) &&
                                        !(
                                            !formState.dirty &&
                                            formState.submitCount !== 0
                                        ) &&
                                        formState.submitCount > 0
                                            ? "has-success"
                                            : "") +
                                        " " +
                                        (errors.race_id ? "has-danger" : "")
                                    }
                                >
                                    <Input
                                        innerRef={register({ required: true })}
                                        name='race_id'
                                        type='select'
                                        className='form-control-alternative'
                                        defaultValue={props.patient?.race_id}
                                    >
                                        <option value='1'>Asian</option>
                                        <option value='2'>Black</option>
                                        <option value='3'>Coloured</option>
                                        <option value='4'>Mixed Race</option>
                                        <option value='5'>White</option>
                                        <option value='6'>Other</option>
                                    </Input>
                                    {errors.race_id ? (
                                        <a
                                            className='text-danger'
                                            style={{ fontSize: 8 }}
                                        >
                                            Please Select Race
                                        </a>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col xs='4' className='ml-5'>
                                <label className='form-control-label'>
                                    Contact Number
                                </label>
                                <FormGroup
                                    className={
                                        "" +
                                        ((errors.contact_number_id !== true ||
                                            errors.contact_number_id ===
                                                undefined) &&
                                        !(
                                            !formState.dirty &&
                                            formState.submitCount !== 0
                                        ) &&
                                        formState.submitCount > 0
                                            ? "has-success"
                                            : "") +
                                        " " +
                                        (errors.contact_number_id
                                            ? "has-danger"
                                            : "")
                                    }
                                >
                                    <Input
                                        innerRef={register({
                                            required: true,
                                            pattern: /(^0[0-9]{9})(\/)?/,
                                            minLength: 1,
                                        })}
                                        name='contact_number_id'
                                        className='form-control-alternative'
                                        placeholder='076533456'
                                        type='text'
                                        defaultValue={
                                            props.patient?.contact_number_id
                                        }
                                    />
                                    {errors.contact_number_id ? (
                                        <a
                                            className='text-danger'
                                            style={{ fontSize: 8 }}
                                        >
                                            Please Enter a valid Contact Number
                                        </a>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>
                    </section>
                    <footer className='footer bg-white'>
                        <Row className='align-items-center justify-content-xl-between'>
                            <Col xs='7'>
                                {/* <div className="bg-white">
      <label className="form-control-label">Review Comment: </label>
      <ReactQuill
          value={commentMultiLine}
          onChange={handleMultiLineChange}
          theme="snow"
          modules={{
            toolbar: [
              ["bold", "italic"],
              ["link", "blockquote", "code", "image"],
              [
                {
                  list: "ordered"
                },
                {
                  list: "bullet"
                }
              ]
            ]
          }}
        />  
         </div> */}
                            </Col>
                            <Col xs='5'>
                                <Row xs='12 mr-5'>
                                    <Col xs='3' className='text-right'>
                                        <Button
                                            outline
                                            color='secondary'
                                            type='button'
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col xs='5' className='text-right'>
                                        <Button
                                            color='success'
                                            type='submit'
                                            disabled={
                                                commentMultiLine.length !== 0
                                            }
                                            onClick={handleSubmit(
                                                acceptPatient
                                            )}
                                        >
                                            Approve Patient
                                        </Button>
                                    </Col>
                                    <Col xs='3' className='text-right'>
                                        <Button
                                            color='danger'
                                            onClick={handleSubmit(
                                                rejectPatient
                                            )}
                                        >
                                            Reject Patient
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </footer>
                </Form>
            </section>
        </>
    );
}

export default withAuthState(withRouter(PatientReviewForm));
