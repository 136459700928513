import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Datetime from "react-datetime";
import moment, { Moment } from "moment";
import ReactDatePicker from "react-datepicker";
import {
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    UncontrolledTooltip,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormText,
    Button,
    Card,
    CardBody,
    Collapse,
    CardHeader,
    Modal,
} from "reactstrap";
// import "react-datepicker/dist/react-datepicker.css";
// reactstrap components
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { IPatient } from "../@types/patient";
import { clear } from "console";
import { RouteComponentProps, withRouter } from "react-router-dom";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import firebase from "../Services/firebase";

import "../App.css";
import Select from "react-select";
import { IOutreach } from "../@types/outreach";

import * as API from "../Services/API";
import withOutreachDD, { OutreachDDProps } from "../Enhanchers/withOutreachDD";
import { find_and_split, createVerifier } from "../utils/dd_util";
import withPatientState, { PatientProps } from "../Enhanchers/withPatientState";

import SubdistrictFields from "../Components/SubdistrictFields";
import PatientOutreachInit from "../Components/PatientOutreachInit";
import PatientGroupActivityInit from "../Components/PatientGroupActivityInit";
import { syncDataToRedcap, generate_id } from "../Services/API";
import { getDistrict, getSiteValue } from "../constants";

type Props = RouteComponentProps & AuthProps & OutreachDDProps & PatientProps;

interface Dropdown {
    label: string;
    values: string[];
}

function GroupActivityForm(props: Props) {
    var empty_arr: any[] = [];
    var hotspot_options: any[] = [];

    const [selectOption, setSelectOption] = useState("");
    const [inputValuePatientSearch, setInputValue] = useState("");
    const [PatientMode, setPatientMode] = useState(false);
    const [patientOutreachList, setPatientOutreachList] = useState(empty_arr);
    const [NewPatientModal, setAddPatientModal] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const [ssanswers, _setSSAnswers] = useState<any[]>([]);
    const [ssrequired, _setSSRequired] = useState(0);

    const [metaList, setMetaList] = useState(empty_arr);
    const [activeEdit, setActiveEdit] = useState(-1);

    const [kp_sites, setKPSites] = useState<any[]>([]);
    const [kpSelected, setKpSelected] = useState(false);
    const [subLogic, setSubLogic] = useState(
        String(getSiteValue(getDistrict(props.auth.user.data_access_group)))
    );
    const [pageInit, setPageInit] = useState(false);

    var subdistrict_options_obj: any = "";
    const [subdistrict_options, setSubdistrict_options] = useState(
        subdistrict_options_obj
    );
    const [subdistrict_selected, setSubdistrict_selected] = useState(false);
    const [outreach_record, setOutreachRecord] = useState({
        kp_program_site_out: props.auth.user!.data_access_group.trim(),
        subdistrict_out: "",
        peer_responsible_out: "",
        team_leader_out: "",
    });

    const [subDistrictFieldName, setSubDistrictFieldName] = useState("");

    const [subDistrictSelection, setSubDistrictSelection] = useState("");

    const {
        register,
        handleSubmit,
        // handleChange,
        watch,
        errors,
        formState,
        control,
        setError,
        clearError,
        getValues,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            activity_date_outreach: "",
            activity_type_out: "",
            age_out: "",
            team_leader_out: "",
            contact_number_out: "",
            sub_district_out: "",
            peer_responsible_out: "",
            area_out: "",
            location_out: "",
            data_capturer_out: "",
            date_of_birth_out: "",
            date_of_capture_out: "",
            first_name_out: "",
            full_name_out: "",
            gender_out: "",
            key_population_out: "",
            kp_programme_site_out: props.auth.user!.data_access_group.trim(),
            name_surname_dob_out: "",
            outreach_form_complete: "",
            preferred_name_out: "",
            redcap_data_access_group: "",
            surname_out: "",
            type_out: "",
            type_of_sex_work_out: "",
        },
    });

    const { dirty, isSubmitting, touched, submitCount } = formState;

    useEffect(() => {
        console.log(kpSelected + " " + subLogic);
        if (subLogic) {
            setKpSelected(true);
        }
        if (!pageInit) {
            console.log(JSON.stringify(props.outreach_dd.all_dd));
            const district_choices = props.outreach_dd.all_dd.filter(
                (d_: any) => {
                    // HERE
                    return d_.id === "kp_programme_site_out";
                }
            );

            if (district_choices.length > 0) {
                var k_sites = find_and_split(
                    district_choices[0].select_choices_or_calculations
                );
                console.log(district_choices);
                console.log(k_sites);
                setKPSites(k_sites);
                setPageInit(true);
            }

            var ans = createVerifier(props.outreach_dd.all_dd, "1");
            console.log(ans);
        }
    }, [
        props.auth.user.role_access,
        props.outreach_dd.all_dd,
        pageInit,
        subLogic,
        subDistrictSelection,
        kpSelected,
    ]);

    const checkValue = (values: any) => {
        console.log(values, patientOutreachList, subdistrict_options);
        console.log(
            Object.keys(values).length > 3,
            patientOutreachList.length > 0
        );
        if (Object.keys(values).length > 3 && patientOutreachList.length > 0) {
            setPatientModal(true);
        }
    };

    //Modal State
    const [defaultModal, setPatientModal] = useState(false);

    var temp_pat_list: any[] = [];
    props.patients.patients.map((patient: any) => {
        temp_pat_list.push({
            value: patient,
            label: patient.first_name_id + " " + patient.surname_id,
        });
    });

    const handleInputChange = (newValue: string) => {
        const inputValue = newValue.replace(/\W/g, "");
        setInputValue(inputValue);
        return inputValue;
    };

    const filterPatientList = (inputValue: string) => {
        var list = temp_pat_list.filter((i: any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        list.sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
        list.slice(0, 20);
        return list;
    };

    const loadOptions = (inputValue: any, callback: any) => {
        setTimeout(() => {
            callback(filterPatientList(inputValue));
        }, 900);
    };

    const add_newPatient = (record: any) => {
        var current_status = PatientMode;
        if (record) {
            const check_patient = patientOutreachList.some(
                (el) => el.patient_id === record.patient_id
            );
            if (!check_patient) {
                var temp_list_pat = patientOutreachList;
                temp_list_pat.push(record);
                setPatientOutreachList(temp_list_pat);
            } else {
                alert("Patient Exist Already, Please Select Other");
            }
        }
        setPatientMode(!current_status);
    };

    const edit_existingPatient = (record: any, index: any) => {
        if (record) {
            var temp_list_pat = patientOutreachList;
            temp_list_pat[index] = record;
            setPatientOutreachList(temp_list_pat);
            editPat(index);
        }
    };

    const removePatient = (indexV: number) => {
        // var temp_list_pat = patientOutreachList;
        // console.log("before deleting ", temp_list_pat);
        // console.log("index to delete", index);
        // temp_list_pat = temp_list_pat.splice(index,1);
        // console.log("after delete", temp_list_pat)
        // setPatientOutreachList(temp_list_pat)
        var temp_list_pat = patientOutreachList;
        // console.log(indexV);
        var temp_arry = _.remove(temp_list_pat, (crrObj, index) => {
            // console.log(crrObj, index);
            return index !== indexV;
        });
        // console.log(temp_arry);
        setPatientOutreachList(temp_arry);
    };

    const [isOpen, setIsOpen] = useState(-1);

    const toggle = (index: number) => {
        if (index !== isOpen) {
            setIsOpen(index);
        } else {
            setIsOpen(-1);
        }
    };

    const _today = moment().format("YYYY/MM/DD");

    const [date_visit, _setDateVisit] = useState("Invalid date");

    const setSelectedDate = (date: Moment | string) => {
        console.log(date);
        _setDateVisit(moment(date).format("YYYY/MM/DD"));
        // setAttended(true);
        return;
    };
    var valid = function (current: moment.MomentInput) {
        return moment(current).isSameOrBefore(_today);
    };

    const editPat = (index: any) => {
        console.log(index);
        if (index !== activeEdit) {
            setActiveEdit(index);
        } else {
            setActiveEdit(-1);
        }
    };

    const createNewOutreach = (values: any) => {
        //console.log(('saving new patient form');
        //console.log((values);
        console.log(ssanswers, ssrequired);
        if (ssanswers.length < ssrequired) {
            alert("Please Specify Sub district Location and Area");
        }

        var date_now = new Date();
        var new_outreach_object = {
            record_id: "",
            activity_peer: values.peer_responsible_out,
            activity_date_outreach: moment(date_visit).format("YYYY-MM-DD"),
            patient_count: patientOutreachList.length,
            activity_type_out: 2,
            meta: {
                ...values,
                sub_dist_logic: ssanswers,
            },
            patients: patientOutreachList,
            date_capture_id: props.auth.user.uuid,
            date_of_capture_id: moment().format("YYYY-MM-DD HH:mm:ss"),
            form_complete: props.auth.user.role_access === 1 ? 2 : 0,
        };

        new_outreach_object = {
            ...new_outreach_object,
            record_id:
                new_outreach_object.activity_type_out +
                "-" +
                new_outreach_object.activity_date_outreach +
                "-" +
                new_outreach_object.activity_peer +
                "-" +
                props.auth.user.data_access_group +
                "-" +
                new_outreach_object.meta.sub_district_out,
        };
        var ref = firebase.db.collection(
            "main/" + props.auth.user.data_access_group + "/outreach_grouped"
        );
        var indiv_rec = firebase.db.collection(
            "main/" +
                props.auth.user.data_access_group +
                "/outreach_and_groupactivity"
        );
        console.log(new_outreach_object);
        ref.add(new_outreach_object)
            .then((data) => {
                console.log("Successfully Added: ");
                console.log("Document written with ID: ", data.id);
                if (data.id) {
                    setPatientModal(false);
                    var batch = firebase.db.batch();
                    new_outreach_object.patients.forEach((p_: any) => {
                        var new_temp_outreach_object = {
                            outreach_lwazi_id: data.id,
                            // ------------------------
                            date_capture_id: props.auth.user.uuid,
                            date_of_capture_id: moment().format(
                                "YYYY-MM-DD HH:mm:ss"
                            ),
                            form_complete:
                                props.auth.user.role_access === 1 ? 2 : 0,
                            // ------------------------
                            ...p_,
                            ...values,
                            meta: {
                                ...values,
                                sub_dist_logic: ssanswers,
                            },
                            activity_type_out: 2,
                            //  record_id: new_outreach_object.record_id,
                            activity_peer: new_outreach_object.activity_peer,
                            activity_date_outreach: moment(date_visit).format(
                                "YYYY-MM-DD"
                            ),
                        };
                        console.log(new_temp_outreach_object);
                        // batch.

                        const dataToSync = new_temp_outreach_object;

                        delete dataToSync.type_of_sex_work_out;
                        generate_id().then((id: any) => {
                            dataToSync.record_id = id;
                            dataToSync.redcap_event_name = "outreach_arm_1";
                            dataToSync.redcap_repeat_instance = "1";
                            dataToSync.redcap_repeat_instrument =
                                "outreach_form";
                            console.log(dataToSync);
                            syncDataToRedcap(dataToSync);
                        });

                        batch.set(indiv_rec.doc(), new_temp_outreach_object);
                        // indiv_rec.add(new_temp_outreach_object)
                    });
                    batch.commit().then((d_) => {
                        console.log(d_);
                        props.history.goBack();

                        var activity_ = {
                            record_id: data.id,
                            district: props.auth.user.data_access_group,
                            role: props.auth.user.role,
                            activity_type_out:
                                new_outreach_object.activity_type_out,
                            activity: "group_activity_create",
                            capturer_id: props.auth.user.uuid,
                            capturer_name: props.auth.user.name,
                            date_and_time: moment().format(
                                "YYYY/MM/DD HH:mm:ss"
                            ),
                            form_status:
                                props.auth.user.role_access === 1 ? 2 : 0,
                        };
                        console.log(activity_);
                        var activit_ref = firebase.db.collection(
                            "activity_log"
                        );
                        activit_ref
                            .add(activity_)
                            .then((data__) => {})
                            .catch((error) => {
                                console.log(error);
                            });
                    });
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    const setSubDistrictAnswers = (ar: any[]) => {
        console.log(ar);
        _setSSAnswers(ar);
    };

    console.log(errors);

    const ModalFu = () => {
        return (
            <Modal
                className='modal-dialog-centered modal-success'
                contentClassName='bg-gradient-success'
                isOpen={defaultModal}
                toggle={() => setPatientModal(false)}
            >
                <div className='modal-header'>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => setPatientModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body'>
                    <div className='py-3 text-center'>
                        <i className='ni ni-paper-diploma ni-3x' />
                        <h4 className='heading mt-4'>Please Confirm!</h4>
                        {props.auth.user.role_access === 0 ? (
                            <p>
                                Hey {props.auth.user.name}, The following form
                                will be send for review. This will be made
                                available, once reviewed and approved by Data
                                Capture.
                            </p>
                        ) : (
                            <p>
                                Hey {props.auth.user.name}, Confirm Hereby, That
                                The following details Is Correct and Checked.{" "}
                                {"\n"}
                                Click Confirm below to Submit Record.
                            </p>
                        )}
                    </div>
                </div>
                <div className='modal-footer'>
                    <Button
                        className='btn-white'
                        color='default'
                        type='button'
                        onPress={() => {
                            setFormSubmitted(false);
                        }}
                        onClick={handleSubmit(createNewOutreach)}
                    >
                        Confirm!
                    </Button>
                    <Button
                        className='text-white ml-auto'
                        color='link'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => {
                            setPatientModal(!defaultModal);
                            setFormSubmitted(false);
                        }}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        );
    };

    const getHotspots = () => {
        let object: Dropdown = {
            label: "",
            values: [],
        };
        console.log(JSON.stringify(props.outreach_dd.district_dd));
        props.outreach_dd.all_dd.map((dictionary_object: any) => {
            var dict_obj = dictionary_object;
            var places_pat = /places_out/g;
            // if(subLogic)
            let tempDistrictValue = new RegExp(
                "'" + subDistrictSelection + ".*?'",
                "g"
            );
            var part = String(dict_obj?.branching_logic).substring(
                String(dict_obj?.branching_logic).lastIndexOf("[") + 1,
                String(dict_obj?.branching_logic).lastIndexOf("]")
            );

            if (
                tempDistrictValue.test(dictionary_object.branching_logic) &&
                part === subDistrictFieldName &&
                dictionary_object.select_choices_or_calculations &&
                places_pat.test(dict_obj.field_name)
            ) {
                console.log(dict_obj);
                object.label = dict_obj.field_label;
                var obj_temp_select_value = find_and_split(
                    dict_obj.select_choices_or_calculations,
                    "hotspots"
                );
                obj_temp_select_value.map((select_option: any) => {
                    object.values.push(select_option);
                });
            }
        });
        return object;
    };

    const getTypes = () => {
        const hotspot_type = props.outreach_dd.all_dd.filter((_: any) => {
            return _.field_name === "type_out";
        });

        if (hotspot_type.length > 0) {
            console.log("Hotspots found");
            const _hOptions = hotspot_type[0].select_choices_or_calculations;
            hotspot_options = find_and_split(_hOptions, "types");
            console.log(hotspot_options);
        }
        return hotspot_options;
    };

    const getSubdistricts = () => {
        let object: Dropdown = {
            label: "",
            values: [],
        };
        let fieldName = "";
        console.log(JSON.stringify(props.outreach_dd.district_dd));

        props.outreach_dd.all_dd.map((dictionary_object: any) => {
            var dict_obj = dictionary_object;
            var subdistrict_pat = /sub_districts/g;
            var subdistrict_pat2 = /sub_district/g;
            let test =
                subdistrict_pat.test(dict_obj.field_name) ||
                subdistrict_pat2.test(dict_obj.field_name);
            let tempDistrictValue = new RegExp("'" + subLogic + "'", "g");
            var part = String(dict_obj?.branching_logic).substring(
                String(dict_obj?.branching_logic).lastIndexOf("[") + 1,
                String(dict_obj?.branching_logic).lastIndexOf("]")
            );
            if (
                test &&
                subLogic &&
                dict_obj?.branching_logic &&
                part === "kp_programme_site_out" &&
                dict_obj.select_choices_or_calculations &&
                tempDistrictValue.test(dictionary_object?.branching_logic)
            ) {
                console.log(tempDistrictValue);
                console.log(dict_obj);
                var obj_temp_select_value = find_and_split(
                    dict_obj.select_choices_or_calculations,
                    "SubDistricts"
                );

                if (object.label !== dict_obj.field_label) {
                    fieldName = dict_obj.field_name;
                    console.log(
                        "🚀 ~ file: OutreachForm.tsx ~ line 494 ~ props.outreach_dd.all_dd.map ~ fieldName",
                        fieldName
                    );
                    object.label = dict_obj.field_label;
                }

                obj_temp_select_value.map((select_option: any) => {
                    object.values.push(select_option);
                });
            }
        });
        if (subDistrictFieldName !== fieldName) {
            setSubDistrictFieldName(fieldName);
        }
        return object;
    };

    const getPeerResponsbile = () => {
        let object: Dropdown = {
            label: "",
            values: [],
        };
        props.outreach_dd.district_dd.map((dictionary_object: any) => {
            var dict_obj = dictionary_object;
            var peer_out_pat = /peer/g;
            let tempDistrictValue = new RegExp("'" + subLogic + "'", "g");

            if (
                peer_out_pat.test(dict_obj.field_name) &&
                subLogic &&
                dict_obj?.branching_logic &&
                tempDistrictValue.test(dict_obj?.branching_logic)
            ) {
                object.label = dict_obj.field_label;
                console.log(dict_obj.field_label);
                var obj_temp_select_value = find_and_split(
                    dict_obj.select_choices_or_calculations,
                    "peer"
                );
                obj_temp_select_value.map((select_option: any) => {
                    object.values.push(select_option);
                });
            }
        });
        return object;
    };

    const getTeamLeaderResponsible = () => {
        let object: Dropdown = {
            label: "",
            values: [],
        };
        props.outreach_dd.district_dd.map((dictionary_object: any) => {
            var dict_obj = dictionary_object;
            var team_leader_pat = /team_leader/g;
            let tempDistrictValue = new RegExp("'" + subLogic + "'", "g");

            if (
                team_leader_pat.test(dict_obj.field_name) &&
                subLogic &&
                dict_obj?.branching_logic &&
                tempDistrictValue.test(dict_obj?.branching_logic)
            ) {
                object.label = dict_obj.field_label;
                var obj_temp_select_value = find_and_split(
                    dict_obj.select_choices_or_calculations,
                    "team"
                );
                obj_temp_select_value.map((select_option: any) => {
                    object.values.push(select_option);
                });
            }
        });
        return object;
    };

    const getWards = () => {
        let object: Dropdown = {
            label: "",
            values: [],
        };
        props.outreach_dd.all_dd.map((dictionary_object: any) => {
            var dict_obj = dictionary_object;
            var ward_pat = /wards_out/g;
            var ward_pat_2 = /ward_out/g;

            let tempDistrictValue = new RegExp(
                "'" + subDistrictSelection + ".*?'",
                "g"
            );
            let test =
                ward_pat.test(dict_obj.field_name) ||
                ward_pat_2.test(dict_obj.field_name);

            var part = String(dictionary_object?.branching_logic).substring(
                String(dictionary_object?.branching_logic).lastIndexOf("[") + 1,
                String(dictionary_object?.branching_logic).lastIndexOf("]")
            );

            if (test) {
                if (
                    tempDistrictValue.test(dictionary_object.branching_logic) &&
                    part === subDistrictFieldName &&
                    dictionary_object.select_choices_or_calculations
                ) {
                    object.label = dict_obj.field_label;

                    var obj_temp_select_value = find_and_split(
                        dict_obj.select_choices_or_calculations,
                        "wards"
                    );
                    obj_temp_select_value.map((select_option: any) => {
                        object.values.push(select_option);
                    });
                }
            }
        });
        console.log(object);
        return object;
    };

    const getAreas = () => {
        let object: Dropdown = {
            label: "",
            values: [],
        };
        props.outreach_dd.all_dd.map((dictionary_object: any) => {
            var dict_obj = dictionary_object;
            var area_pat = /areas_out/g;
            var area_pat2 = /area_out/g;
            var area_pat3 = /sites_out/g;
            var area_pat4 = /site_out/g;

            let tempDistrictValue = new RegExp(
                "'" + subDistrictSelection + ".*?'",
                "g"
            );

            var part = String(dict_obj?.branching_logic).substring(
                String(dict_obj?.branching_logic).lastIndexOf("[") + 1,
                String(dict_obj?.branching_logic).lastIndexOf("]")
            );

            if (
                (area_pat.test(dict_obj.field_name) ||
                    area_pat2.test(dict_obj.field_name) ||
                    area_pat3.test(dict_obj.field_name) ||
                    area_pat4.test(dict_obj.field_name)) &&
                subLogic &&
                String(part) === String(subDistrictFieldName) &&
                dict_obj?.branching_logic &&
                tempDistrictValue.test(dict_obj?.branching_logic) &&
                dict_obj.select_choices_or_calculations
            ) {
                object.label = dict_obj.field_label;
                var obj_temp_select_value = find_and_split(
                    dict_obj.select_choices_or_calculations,
                    "area " + dict_obj.field_label
                );
                console.log(dict_obj);
                obj_temp_select_value.map((select_option: any) => {
                    object.values.push(select_option);
                });
            }
        });
        return object;
    };

    const getLocations = () => {
        let object: Dropdown = {
            label: "",
            values: [],
        };
        console.log(JSON.stringify(props.outreach_dd.district_dd));
        props.outreach_dd.all_dd.map((dictionary_object: any) => {
            var dict_obj = dictionary_object;
            var location_out_pat = /location/g;

            console.log(subDistrictFieldName); //cpt_sub_district_out
            var part = String(dict_obj?.branching_logic).substring(
                String(dict_obj?.branching_logic).lastIndexOf("[") + 1,
                String(dict_obj?.branching_logic).lastIndexOf("]")
            );
            let tempDistrictValue = new RegExp(
                "'" + subDistrictSelection + ".*?'",
                "g"
            );

            console.log(part);
            if (
                location_out_pat.test(dict_obj.field_name) &&
                part === subDistrictFieldName &&
                subLogic &&
                tempDistrictValue.test(dict_obj?.branching_logic) &&
                dict_obj.select_choices_or_calculations
            ) {
                object.label = dict_obj.field_label;
                var obj_temp_select_value = find_and_split(
                    dict_obj.select_choices_or_calculations,
                    "locations"
                );
                obj_temp_select_value.map((select_option: any) => {
                    object.values.push(select_option);
                });
            }
        });
        return object;
    };

    return (
        <>
            <Form onSubmit={handleSubmit(checkValue)}>
                {defaultModal && formSubmitted && !NewPatientModal ? (
                    <>{ModalFu()}</>
                ) : null}
                <div className='pl-lg-4'>
                    <Row>
                        <Col xs='4'>
                            <label className='form-control-label'>
                                Date of Visit
                            </label>
                            <FormGroup>
                                <InputGroup className='input-group-alternative'>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <i className='ni ni-calendar-grid-58' />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <ReactDatetime
                                        inputProps={{
                                            placeholder: "Select Date",
                                            value: date_visit,
                                            name: "date_of_visit_out",
                                        }}
                                        isValidDate={valid}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        onChange={(v) => setSelectedDate(v)}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>

                    {/* kp_programme_site_out */}

                    <Row>
                        <Col xs='4'>
                            <label className='form-control-label'>
                                District
                            </label>
                            {props.auth.user.role_access === 1 ? (
                                <FormGroup>
                                    <Input
                                        innerRef={register({ required: true })}
                                        name='kp_programme_site_out'
                                        className='form-control-alternative'
                                        type={
                                            props.auth.user.role_access !== 1
                                                ? "text"
                                                : "select"
                                        }
                                        defaultValue={getDistrict(
                                            props.auth.user.data_access_group
                                        )}
                                        disabled={
                                            props.auth.user.role_access !== 1
                                        }
                                        onChange={(e) => {
                                            e.preventDefault();
                                            // setFieldsHidden(true);
                                            var selct = e.target.value;
                                            console.log(selct);
                                            setSubLogic(selct);
                                            setKpSelected(true);
                                            // setTimeout(() => {
                                            //     setFieldsHidden(false);
                                            // }, 3000);
                                        }}
                                    >
                                        <>
                                            <option></option>
                                            {kp_sites.map(
                                                (sites: any, index: number) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={sites.index}
                                                        >
                                                            {sites.value}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </>
                                    </Input>
                                </FormGroup>
                            ) : (
                                <FormGroup>
                                    <Input
                                        innerRef={register({ required: true })}
                                        name='kp_programme_site_out'
                                        className='form-control-alternative'
                                        type='text'
                                        value={getDistrict(
                                            props.auth.user.data_access_group
                                        )}
                                        disabled={true}
                                    ></Input>
                                </FormGroup>
                            )}
                        </Col>

                        {/* HERE */}
                        {/* BEGIN */}
                    </Row>
                    {kpSelected && (
                        <>
                            {subLogic && (
                                <Col xs='4' style={{ padding: 0 }}>
                                    <label className='form-control-label'>
                                        {getSubdistricts().label
                                            ? getSubdistricts().label
                                            : "Sub Districts"}
                                    </label>
                                    <FormGroup>
                                        <Input
                                            innerRef={register({
                                                required: true,
                                            })}
                                            name='sub_district_out'
                                            className='form-control-alternative'
                                            type='select'
                                            onChange={(e: any) =>
                                                setSubDistrictSelection(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <>
                                                <option value=''></option>
                                                {getSubdistricts().values.map(
                                                    (subdistrict: any) => (
                                                        <option
                                                            value={
                                                                subdistrict.index
                                                            }
                                                        >
                                                            {subdistrict.value}
                                                        </option>
                                                    )
                                                )}
                                            </>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            )}
                            {subLogic && (
                                <Row>
                                    {getWards().values.length !== 0 &&
                                        subDistrictSelection && (
                                            <Col xs='4'>
                                                <label className='form-control-label'>
                                                    {getWards().label
                                                        ? getWards().label
                                                        : "Ward"}
                                                </label>
                                                <FormGroup>
                                                    <Input
                                                        innerRef={register({
                                                            required: true,
                                                        })}
                                                        name='wards_out'
                                                        className='form-control-alternative'
                                                        type='select'
                                                    >
                                                        <>
                                                            <option value=''></option>
                                                            {getWards().values.map(
                                                                (ward: any) => (
                                                                    <option
                                                                        value={
                                                                            ward.index
                                                                        }
                                                                    >
                                                                        {
                                                                            ward.value
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        )}

                                    {getAreas().values.length !== 0 &&
                                        subDistrictSelection && (
                                            <Col xs='4'>
                                                <label className='form-control-label'>
                                                    {getAreas().label !== ""
                                                        ? getAreas().label
                                                        : "Area"}
                                                </label>
                                                <FormGroup>
                                                    <Input
                                                        innerRef={register({
                                                            required: true,
                                                        })}
                                                        name='areas_out'
                                                        className='form-control-alternative'
                                                        type='select'
                                                    >
                                                        <>
                                                            <option value=''></option>
                                                            {getAreas().values.map(
                                                                (area: any) => (
                                                                    <option
                                                                        value={
                                                                            area.index
                                                                        }
                                                                    >
                                                                        {
                                                                            area.value
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        )}

                                    {getLocations().values.length !== 0 &&
                                        subDistrictSelection && (
                                            <Col xs='4'>
                                                <label className='form-control-label'>
                                                    {getLocations().label
                                                        ? getLocations().label
                                                        : "Location"}
                                                </label>
                                                <FormGroup>
                                                    <Input
                                                        innerRef={register({
                                                            required: true,
                                                        })}
                                                        name='location_out'
                                                        className='form-control-alternative'
                                                        type='select'
                                                    >
                                                        {getLocations().values.map(
                                                            (location: any) => (
                                                                <option
                                                                    value={
                                                                        location.index
                                                                    }
                                                                >
                                                                    {
                                                                        location.value
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        )}

                                    {getHotspots().values.length !== 0 &&
                                        subDistrictSelection && (
                                            <Col xs='4'>
                                                <label className='form-control-label'>
                                                    {getHotspots().label
                                                        ? getHotspots().label
                                                        : "Hotspot"}
                                                </label>
                                                <FormGroup>
                                                    <Input
                                                        innerRef={register({
                                                            required: true,
                                                        })}
                                                        name='places_out'
                                                        className='form-control-alternative'
                                                        type='select'
                                                    >
                                                        <>
                                                            {getHotspots().values.map(
                                                                (
                                                                    select_option: any
                                                                ) => {
                                                                    return (
                                                                        <>
                                                                            <option value=''></option>
                                                                            <option
                                                                                value={
                                                                                    select_option.index
                                                                                }
                                                                            >
                                                                                {
                                                                                    select_option.value
                                                                                }
                                                                            </option>
                                                                        </>
                                                                    );
                                                                }
                                                            )}
                                                        </>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        )}

                                    {getTypes().length !== 0 && (
                                        <Col xs='4'>
                                            <label className='form-control-label'>
                                                {/* {
                                                        hotspot_type[0]
                                                            .field_label
                                                    } */}
                                                Location/Hotspot Type
                                            </label>
                                            <FormGroup>
                                                <Input
                                                    innerRef={register({
                                                        required: true,
                                                    })}
                                                    name='type_out'
                                                    className='form-control-alternative'
                                                    type='select'
                                                >
                                                    <>
                                                        {getTypes().map(
                                                            (
                                                                select_option: any
                                                            ) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            select_option.index
                                                                        }
                                                                    >
                                                                        {
                                                                            select_option.value
                                                                        }
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    )}

                                    <Col xs='4'>
                                        <label className='form-control-label'>
                                            {getTeamLeaderResponsible().label
                                                ? getTeamLeaderResponsible()
                                                      .label
                                                : "Team Leader Responsible"}
                                        </label>
                                        <FormGroup>
                                            <Input
                                                innerRef={register({
                                                    required: true,
                                                })}
                                                name='team_leader_out'
                                                className='form-control-alternative'
                                                type='select'
                                            >
                                                <>
                                                    <option value=''></option>
                                                    {getTeamLeaderResponsible().values.map(
                                                        (teamLeader: any) => (
                                                            <option
                                                                value={
                                                                    teamLeader.index
                                                                }
                                                            >
                                                                {
                                                                    teamLeader.value
                                                                }
                                                            </option>
                                                        )
                                                    )}
                                                </>
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col xs='4'>
                                        <label className='form-control-label'>
                                            {getPeerResponsbile().label
                                                ? getPeerResponsbile().label
                                                : "Peer Responsible"}
                                        </label>
                                        <FormGroup>
                                            <Input
                                                innerRef={register({
                                                    required: true,
                                                })}
                                                name='peer_responsible_out'
                                                className='form-control-alternative'
                                                type='select'
                                            >
                                                <>
                                                    <option value=''></option>
                                                    {getPeerResponsbile().values.map(
                                                        (peer: any) => (
                                                            <option
                                                                value={
                                                                    peer.index
                                                                }
                                                            >
                                                                {peer.value}
                                                            </option>
                                                        )
                                                    )}
                                                </>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )}
                            {/* end */}
                            {subLogic && (
                                <Row>
                                    <Col xs='4'>
                                        <label className='form-control-label'>
                                            Group Activity
                                        </label>
                                        <FormGroup>
                                            <Input
                                                innerRef={register({
                                                    required: true,
                                                })}
                                                name='group_activity_out'
                                                type='select'
                                                className='form-control-alternative'
                                                //  value={outreach_record.kp_program_site_out}
                                            >
                                                <option value=''></option>
                                                <option value='1'>
                                                    Creative Space
                                                </option>
                                                <option value='2'>
                                                    Support Group
                                                </option>
                                                <option value='2'>
                                                    Training
                                                </option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col xs='4'>
                                        <label className='form-control-label'>
                                            Theme
                                        </label>

                                        <FormGroup>
                                            <Input
                                                innerRef={register({
                                                    required: true,
                                                })}
                                                name='theme_out'
                                                type='text'
                                                className='form-control-alternative'
                                                //  value={outreach_record.kp_program_site_out}
                                            ></Input>
                                        </FormGroup>
                                    </Col>
                                    <Col xs='4'>
                                        <label className='form-control-label'>
                                            Facilitators Name
                                        </label>

                                        <FormGroup>
                                            <Input
                                                innerRef={register({
                                                    required: true,
                                                })}
                                                name='facilitator_name_out'
                                                type='text'
                                                className='form-control-alternative'
                                                //  value={outreach_record.kp_program_site_out}
                                            ></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}

                    {/* HERE */}

                    <Row>
                        {PatientMode ? (
                            <PatientGroupActivityInit
                                NewPatientModal={NewPatientModal}
                                setAddPatientModal={setAddPatientModal}
                                subLogic={subLogic}
                                patientMode={PatientMode}
                                patientaddFunct={add_newPatient}
                            />
                        ) : (
                            <Button
                                color='info'
                                type='button'
                                className='ml-3'
                                onClick={() => {
                                    var current_status = PatientMode;
                                    setPatientMode(!current_status);
                                }}
                            >
                                Add A Patient
                            </Button>
                        )}
                    </Row>

                    <Row>
                        {patientOutreachList.length > 0 ? (
                            <Col style={{ marginTop: 10 }}>
                                {patientOutreachList
                                    // .reverse()
                                    .map((patient: any, indexV: number) => {
                                        return (
                                            <>
                                                <Row xs='12'>
                                                    <div
                                                        className='accordion'
                                                        style={{
                                                            width: "99%",
                                                        }}
                                                    >
                                                        <Card className='card-plain'>
                                                            <CardHeader
                                                                role='tabpanel'
                                                                onClick={() =>
                                                                    toggle(
                                                                        indexV
                                                                    )
                                                                }
                                                                aria-expanded={
                                                                    isOpen ===
                                                                    indexV
                                                                }
                                                            >
                                                                <Row>
                                                                    <Col xs='11'>
                                                                        <h5 className='mb-0'>
                                                                            {" "}
                                                                            Patient
                                                                            Name:{" "}
                                                                            {
                                                                                patient.full_name_out
                                                                            }
                                                                        </h5>
                                                                    </Col>
                                                                    <i
                                                                        className={
                                                                            isOpen ===
                                                                            indexV
                                                                                ? "text-right fa fa-chevron-up"
                                                                                : "text-right fa fa-chevron-down"
                                                                        }
                                                                    ></i>
                                                                    <a
                                                                        onClick={() => {
                                                                            removePatient(
                                                                                indexV
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            marginLeft:
                                                                                "2%",
                                                                        }}
                                                                    >
                                                                        <i className='text-right text-danger fa fa-window-close'></i>
                                                                    </a>
                                                                </Row>
                                                            </CardHeader>
                                                            <Collapse
                                                                role='tabpanel'
                                                                isOpen={
                                                                    isOpen ===
                                                                    indexV
                                                                }
                                                            >
                                                                <CardBody>
                                                                    <Row
                                                                        xs='12'
                                                                        className='pull-right'
                                                                    >
                                                                        <Col xs='2'>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    editPat(
                                                                                        indexV
                                                                                    );
                                                                                }}
                                                                                className='btn-icon btn-info'
                                                                                style={{
                                                                                    marginBottom:
                                                                                        "2%",
                                                                                }}
                                                                            >
                                                                                <span className='text-right text-white fa fa-pencil-square'></span>
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs='6'>
                                                                            <label className='form-control-label'>
                                                                                Legal
                                                                                First
                                                                                Name:{" "}
                                                                            </label>
                                                                            <FormGroup>
                                                                                <Input
                                                                                    disabled={
                                                                                        activeEdit !==
                                                                                        indexV
                                                                                    }
                                                                                    name='first_name_out'
                                                                                    className='form-control-alternative'
                                                                                    type='text'
                                                                                    defaultValue={
                                                                                        patient.first_name_out
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col xs='6'>
                                                                            <label className='form-control-label'>
                                                                                Legal
                                                                                Surname:{" "}
                                                                            </label>
                                                                            <FormGroup>
                                                                                <Input
                                                                                    disabled={
                                                                                        activeEdit !==
                                                                                        indexV
                                                                                    }
                                                                                    name='surname_out'
                                                                                    className='form-control-alternative'
                                                                                    type='text'
                                                                                    defaultValue={
                                                                                        patient.surname_out
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col xs='6'>
                                                                            <label className='form-control-label'>
                                                                                Preferred
                                                                                Name:{" "}
                                                                            </label>
                                                                            <FormGroup>
                                                                                <Input
                                                                                    disabled={
                                                                                        activeEdit !==
                                                                                        indexV
                                                                                    }
                                                                                    name='preferred_name_out'
                                                                                    className='form-control-alternative'
                                                                                    type='text'
                                                                                    defaultValue={
                                                                                        patient.preferred_name_out
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs='4'>
                                                                            <label className='form-control-label'>
                                                                                Date
                                                                                of
                                                                                Birth:{" "}
                                                                            </label>
                                                                            <FormGroup>
                                                                                <Input
                                                                                    name='date_of_birth_out'
                                                                                    disabled={
                                                                                        activeEdit !==
                                                                                        indexV
                                                                                    }
                                                                                    className='form-control-alternative'
                                                                                    type='text'
                                                                                    defaultValue={
                                                                                        patient.date_of_birth_out
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col xs='4'>
                                                                            <label className='form-control-label'>
                                                                                Contact
                                                                                Number:{" "}
                                                                            </label>
                                                                            <FormGroup>
                                                                                <Input
                                                                                    disabled={
                                                                                        activeEdit !==
                                                                                        indexV
                                                                                    }
                                                                                    name='contact_number_out'
                                                                                    className='form-control-alternative'
                                                                                    type='text'
                                                                                    defaultValue={
                                                                                        patient.contact_number_out
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs='6'>
                                                                            <label className='form-control-label'>
                                                                                Gender:{" "}
                                                                            </label>
                                                                            <FormGroup>
                                                                                <Input
                                                                                    name='gender_out'
                                                                                    className='form-control-alternative'
                                                                                    disabled={
                                                                                        activeEdit !==
                                                                                        indexV
                                                                                    }
                                                                                    // value={patient.gender_out}
                                                                                    defaultValue={
                                                                                        patient.gender_out
                                                                                    }
                                                                                    type='select'
                                                                                >
                                                                                    <option value=''>
                                                                                        --Select--
                                                                                    </option>
                                                                                    <option value='1'>
                                                                                        Male{" "}
                                                                                    </option>
                                                                                    <option value='2'>
                                                                                        Female{" "}
                                                                                    </option>
                                                                                    <option value='3'>
                                                                                        Transgender
                                                                                        Man{" "}
                                                                                    </option>
                                                                                    <option value='4'>
                                                                                        Transgender
                                                                                        Woman{" "}
                                                                                    </option>
                                                                                    <option value='5'>
                                                                                        Gender
                                                                                        Non-Conforming
                                                                                    </option>
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    {activeEdit ===
                                                                    indexV ? (
                                                                        <>
                                                                            <Col
                                                                                xs='6'
                                                                                className='text-right'
                                                                            >
                                                                                <Button
                                                                                    color='secondary'
                                                                                    type='button'
                                                                                    onClick={() => {
                                                                                        editPat(
                                                                                            indexV
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Cancel
                                                                                </Button>
                                                                                <Button
                                                                                    color='success'
                                                                                    onClick={() => {
                                                                                        edit_existingPatient(
                                                                                            patient,
                                                                                            indexV
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Save
                                                                                    Update
                                                                                </Button>
                                                                            </Col>
                                                                        </>
                                                                    ) : null}
                                                                </CardBody>
                                                            </Collapse>
                                                        </Card>
                                                    </div>
                                                </Row>
                                            </>
                                        );
                                    })}
                            </Col>
                        ) : null}
                    </Row>

                    <Row>
                        <Col xs='6'></Col>
                        <Col xs='6' className='text-right'>
                            <Button
                                color='secondary'
                                type='button'
                                onClick={() => {
                                    props.history.goBack();
                                    setFormSubmitted(true);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                color='info'
                                type='submit'
                                onClick={() => {
                                    setFormSubmitted(true);
                                }}
                                onPress={() => {
                                    handleSubmit(checkValue);
                                }}
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    );
}
export default withPatientState(
    withOutreachDD(withAuthState(withRouter(GroupActivityForm)))
);
