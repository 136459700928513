import React from "react";
import logo from "../logo.svg";
import "../App.css";
import TopNavbarMain from "../Components/TopNavbarMain";

import {
    CssBaseline,
    WithStyles,
    Toolbar,
    withStyles,
    Theme,
    createStyles,
} from "@material-ui/core";
import MainLayout from "../Layouts/MainLayout";
import Paper from "@material-ui/core/Paper";
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardFooter,
    FormGroup,
    Form,
    Input,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    CardText,
} from "reactstrap";
import { CardBody, CardTitle, Col } from "reactstrap";
import AddIcon from "@material-ui/icons/Add";
import { RouteComponentProps, withRouter, NavLink } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import { IPatient } from "../@types/patient";
import PatientInfoTab from "../Components/PatientInfoTab";
import classnames from "classnames";
import PatientOutreachTab from "../Components/PatientOutreachTab";
import PatientAppointmentTab from "../Components/PatientAppointmentTab";
import editBtn from "../Assets/img/icons/common/edit-btn.svg";
import * as API from "../Services/API";
import moment from "moment";
import { BorderBottom } from "@material-ui/icons";
import withPrepState, { PrepProps } from "../Enhanchers/withPrepState";
import withAppointmentState, {
    AppointmentProps,
} from "../Enhanchers/withAppointmentState";
import withOutreachState, {
    OutreachProps,
} from "../Enhanchers/withOutreachState";
import PatientPrepTab from "../Components/PatientPrepTab";
import PatientPrintView from "../Pages/PatientPrintView";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import domtoimage from "dom-to-image";
// import {firebase} from '../Services/firebase';
interface IState {
    patient: any;
    prep_record: any;
    intake_info: any;
    activeTab: string;
    loading: boolean;
    appointments: any;
    outreach: any;
}
type Props = WithStyles<typeof styles> &
    RouteComponentProps<{ id: string }> &
    PrepProps &
    AppointmentProps &
    OutreachProps;
class PatientView extends React.Component<Props, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            patient: this.props.location.state,
            prep_record: [],
            intake_info: {},
            activeTab: "1",
            loading: true,
            appointments: [],
            outreach: [],
        };
    }

    componentDidMount() {
        if (this.props.match.params.id && this.props.location.state) {
            console.log(this.props.location.state);
            if (this.props.location.state) {
                var pat_ = this.props.location.state;
                var prep_record = this.getPatientPrepInfo(pat_);
                var appointments_ = this.getPatientAppointmentInfo(pat_);
                var outreach_ = this.getPatientOutreachInfo(pat_);
                console.log({
                    patient: this.props.location.state,
                    loading: true,
                    prep_record: prep_record,
                    appointments: appointments_,
                    outreach: outreach_,
                });
                this.setState({
                    patient: this.props.location.state,
                    loading: true,
                    prep_record: prep_record,
                    appointments: appointments_,
                    outreach: outreach_,
                });
            }
        }
    }

    printDownload = () => {
        const input = document.getElementById("patient-info-div");
        const pdf = new jsPDF({ orientation: "portrait" });
        if (pdf && input) {
            domtoimage.toPng(input).then((imgData: any) => {
                pdf.addImage(imgData, "PNG", 0, 0, 180, 150);
                pdf.save("download.pdf");
            });
        }
    };

    getPatientPrepInfo = (patient: any) => {
        var p_ = this.props.prep_records.prep_records.filter(
            (prep_rec: any) => prep_rec.patient_id === patient.uid
        );
        console.log("prep record found", p_);

        return p_;
    };
    getPatientOutreachInfo = (patient: any) => {
        var p_ = this.props.outreach.outreach_all.filter(
            (out_rec: any) => out_rec.uid === patient.uid
        );
        console.log("prep record found", p_);

        return p_;
    };
    getPatientAppointmentInfo = (patient: any) => {
        var p_ = this.props.appointment_records.appointment_records.filter(
            (app_rec: any) => app_rec.patient_id === patient.uid
        );
        console.log("prep record found", p_);

        return p_;
    };
    getPatientAge = (date_string: string) => {
        var moment_date = new Date(date_string);
        //console.log(moment_date)
        var a = moment(moment_date);
        var b = moment();
        var years = b.diff(a, "years", false);
        var months = b.diff(a, "months", false);
        //console.log(years+" "+months);
        return years;
    };

    toggle = (tab: string) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    getGender = (value: any) => {
        if (value == 0) {
            return "none";
        }
        if (value == 1) {
            return "Male";
        }
        if (value == 2) {
            return "Female";
        }
        if (value == 3) {
            return "Transgender Man";
        }
        if (value == 4) {
            return "Transgender Woman";
        }
        if (value == 5) {
            return "Gender Non-Conforming";
        }
    };

    patientName_ = (patient: any) => {
        var pref_name = patient.pref_name_id;
        if (pref_name !== undefined) {
            if (pref_name.length > 0) {
                return (
                    patient.first_name_id +
                    " (" +
                    patient.pref_name_id +
                    ") " +
                    patient.surname_id
                );
            } else {
                return patient.first_name_id + " " + patient.surname_id;
            }
        } else {
            return patient.first_name_id + " " + patient.surname_id;
        }
    };

    render() {
        // var var patient_birthday  moment(this.state.patient.birthdate_id).format()

        // console.log((this.state.patient));
        if (this.state.loading) {
            return (
                <MainLayout>
                    {/* Patiet View Menu */}
                    <div id='patient-info-div'>
                        <Container
                            fluid
                            style={{ marginTop: 30 }}
                            id='patient-info-div'
                        >
                            <Row className='mb-3'>
                                <Col xs='1'>
                                    <Button
                                        style={{
                                            backgroundColor: "transparent",
                                            marginTop: 13,
                                        }}
                                        onClick={() => {
                                            this.props.history.goBack();
                                        }}
                                    >
                                        <i
                                            className='fa fa-arrow-left  ml-4'
                                            style={{ fontSize: 20 }}
                                        />
                                    </Button>
                                </Col>
                                <Col xs='11'>
                                    <h1 className='pl-0 ml-2'>Patient</h1>
                                </Col>
                            </Row>

                            {/* Info Box */}

                            <Row className='bg-white p-5'>
                                <Col xs='12'>
                                    <Row>
                                        <Col lg='5'>
                                            <h2
                                                style={{ fontSize: "2.5rem" }}
                                                className='sea-green pl-0'
                                            >
                                                {this.patientName_(
                                                    this.state.patient
                                                )}
                                            </h2>
                                            <p className='patient-metadata'>
                                                <span className='sea-green'>
                                                    |
                                                </span>
                                                <span className='mb-0 pl-2 pr-2'>
                                                    {this.getPatientAge(
                                                        this.state.patient
                                                            .birthdate_id
                                                    ) + " Years Old"}
                                                </span>
                                                <span className='sea-green'>
                                                    |
                                                </span>
                                                <span className='mb-0 pl-2 pr-2'>
                                                    {this.getGender(
                                                        this.state.patient
                                                            .gender_id
                                                    ) + " "}
                                                </span>
                                                <span className='sea-green'>
                                                    |
                                                </span>
                                                <span className='pl-2 mb-0'>
                                                    {this.state.patient
                                                        .contact_number_id
                                                        ? this.state.patient
                                                              .contact_number_id
                                                        : "// "}
                                                </span>
                                            </p>
                                            {/* {this.state.completed_list} */}
                                        </Col>
                                        <Col lg='7'>
                                            <Row>
                                                <Col xs='3'>
                                                    <Card className='border-1 card-outline hidden-appointment'>
                                                        <CardTitle
                                                            style={{
                                                                fontSize:
                                                                    "0.7rem",
                                                            }}
                                                            tag='h3'
                                                            className='text-center mb-0'
                                                        >
                                                            Date First Seen{" "}
                                                            <br />
                                                            <br />
                                                            <hr />
                                                        </CardTitle>
                                                        <CardBody>
                                                            <p className='small'>
                                                                {
                                                                    this.state.patient.date_of_capture_id.split(
                                                                        " "
                                                                    )[0]
                                                                }
                                                                {/* {this.state.completed_list} */}
                                                            </p>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col xs='3'>
                                                    <Card className='border-1 card-outline'>
                                                        <CardTitle
                                                            style={{
                                                                fontSize:
                                                                    "0.7rem",
                                                            }}
                                                            tag='h3'
                                                            className='mb-0 text-center'
                                                        >
                                                            Seen for Outreach/
                                                            Group Activity
                                                            <hr />
                                                        </CardTitle>
                                                        <CardBody>
                                                            <p>
                                                                {
                                                                    this.state
                                                                        .outreach
                                                                        .length
                                                                }
                                                            </p>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col xs='3'>
                                                    <Card className='border-1 card-outline hidden-appointment'>
                                                        <CardTitle
                                                            style={{
                                                                fontSize:
                                                                    "0.7rem",
                                                            }}
                                                            tag='h2'
                                                            className='text-center mb-0'
                                                        >
                                                            on Prep Visit <br />
                                                            <br />
                                                            <hr />
                                                        </CardTitle>
                                                        {Object.keys(
                                                            this.state
                                                                .prep_record
                                                        ).length > 0 ? (
                                                            <CardBody>
                                                                <p>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .prep_record[0]
                                                                            .prep_by_month
                                                                            .length
                                                                    }
                                                                </p>
                                                            </CardBody>
                                                        ) : (
                                                            <CardBody>
                                                                <p>--</p>
                                                            </CardBody>
                                                        )}
                                                    </Card>
                                                </Col>
                                                <Col xs='3'>
                                                    <Card className='border-1 card-active hidden-appointment'>
                                                        <CardTitle
                                                            style={{
                                                                fontSize:
                                                                    "0.7rem",
                                                            }}
                                                            tag='h2'
                                                            className='text-center mb-0'
                                                        >
                                                            Total Times Seen{" "}
                                                            <br />
                                                            <br />
                                                            <hr />
                                                        </CardTitle>
                                                        {Object.keys(
                                                            this.state
                                                                .prep_record
                                                        ).length > 0 ? (
                                                            <CardBody>
                                                                <p>
                                                                    {this.state
                                                                        .prep_record[0]
                                                                        .prep_by_month
                                                                        .length +
                                                                        this
                                                                            .state
                                                                            .outreach
                                                                            .length}
                                                                </p>
                                                            </CardBody>
                                                        ) : (
                                                            <CardBody>
                                                                <p>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .outreach
                                                                            .length
                                                                    }
                                                                </p>
                                                            </CardBody>
                                                        )}
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>

                        {/* Page content */}

                        <Container fluid>
                            <Row>
                                <Col>
                                    <h1 className='patient-tab-header'>
                                        Personal
                                    </h1>
                                </Col>

                                <Col lg='6'>
                                    <div
                                        className='float-right icon mt-5 bg-edit text-white'
                                        onClick={() => {
                                            this.props.history.push({
                                                pathname:
                                                    "/patient-edit/" +
                                                    this.state.patient.uid,
                                                state: this.state.patient,
                                            });
                                        }}
                                    >
                                        <img src={editBtn} />
                                    </div>
                                    <div
                                        className='float-right icon mt-5 mr-5 bg-edit text-white'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.printDownload();
                                        }}
                                    >
                                        <i className='text-white fa-lg fa fa-file-text' />
                                    </div>
                                </Col>
                            </Row>

                            <Card className=' ml-5 mb-5'>
                                {/* Header container */}
                                <TabContent>
                                    <TabPane>
                                        <PatientInfoTab
                                            patientInfo={this.state.patient}
                                            intakeInfo={this.state.intake_info}
                                        />
                                    </TabPane>
                                </TabContent>
                            </Card>
                            <h1 className='patient-tab-header'>Outreach</h1>

                            <Card className=' ml-5 mb-5'>
                                {/* Header container */}
                                <TabContent>
                                    <TabPane>
                                        <PatientOutreachTab
                                            outreach_record={
                                                this.state.outreach
                                            }
                                        />
                                    </TabPane>
                                </TabContent>
                            </Card>
                            <h1 className='patient-tab-header'>Appointments</h1>

                            <Card className=' ml-5 mb-5'>
                                {/* Header container */}
                                <TabContent>
                                    <TabPane>
                                        <PatientAppointmentTab
                                            patient={this.state.patient}
                                            // prep_record={this.state.prep_record}
                                            appointment_records={
                                                this.state.appointments
                                            }
                                        />
                                    </TabPane>
                                </TabContent>
                            </Card>
                            <h1 className='patient-tab-header'>Prep</h1>

                            <Card className=' ml-5 mb-5'>
                                {/* Header container */}
                                <TabContent>
                                    <TabPane>
                                        <PatientPrepTab
                                            patient={this.state.patient}
                                            prep_records={
                                                this.state.prep_record
                                            }
                                        />
                                    </TabPane>
                                </TabContent>
                            </Card>
                        </Container>
                    </div>
                </MainLayout>
            );
        } else {
            return (
                <MainLayout>
                    <div
                        className='header pb-6'
                        style={{
                            minHeight: "600px",
                            backgroundImage:
                                "url(" +
                                require("../Assets/img/splash.jpg") +
                                ")",
                            backgroundSize: "cover",
                            backgroundPosition: "center top",
                        }}
                    >
                        {/* <div className={this.props.classes.loader}> */}
                        {/* </div> */}
                    </div>
                    <span className='mask bg-gradient-info opacity-8' />
                </MainLayout>
            );
        }
    }
}
const styles = (theme: Theme) =>
    createStyles({
        table: {
            minWidth: 650,
        },

        tableRow: {
            "&:hover": {
                borderLeft: "#17A3AB",
                borderLeftWidth: "10",
                borderLeftStyle: "solid",
                boxShadow: "0 3px 5px 2px rgba(0,0,0,0.1)",
            },
        },
    });
export default withOutreachState(
    withAppointmentState(
        withPrepState(withRouter(withStyles(styles)(PatientView)))
    )
);
