import React, { useEffect } from "react";

import AccountCircle from "@material-ui/icons/AccountCircle";

import {
    CssBaseline,
    WithStyles,
    Toolbar,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    withStyles,
    Theme,
    createStyles,
} from "@material-ui/core";
import MainLayout from "../Layouts/MainLayout";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";
import {
    Badge,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Button,
    Container,
    Row,
    Input,
    Form,
} from "reactstrap";
import { CardBody, CardTitle, Col, Card } from "reactstrap";
import AddIcon from "@material-ui/icons/Add";

import Header from "../Components/Header";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IPatient } from "../@types/patient";
import firebase from "../Services/firebase";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import PatientTable from "../Components/PatientTable";

import AsyncSelect from "react-select/async";
import PatientForm from "../Forms/patientForm";
import * as API from "../Services/API";
import { useState } from "react";
import { useForm } from "react-hook-form";

type Props = WithStyles<typeof styles> &
    RouteComponentProps<{ id: string }> &
    AuthProps;

function UserProfile(props: Props) {
    const {
        register,
        handleSubmit,
        watch,
        errors,
        formState,
        control,
        setError,
        clearError,
    } = useForm();

    const [editMode, changeEditMode] = useState(false);
    const [selectedUser, setSelectedUser] = useState<any>();

    useEffect(() => {
        var ref = firebase.db.collection("Users").doc(props.match.params.id);
        ref.get().then((doc) => {
            if (doc.data()) {
                setSelectedUser(doc.data());
            }
        });
    }, [editMode]);

    const editProfile = () => {
        changeEditMode(!editMode);
    };

    const onSubmit = (values: any) => {
        console.log(values);
        // props.history.goBack();
        var ref = firebase.db.collection("Users").doc(props.match.params.id);
        ref.update(values).then(() => {
            changeEditMode(false);
        });
    };

    if (selectedUser) {
        return (
            <MainLayout>
                {/* Prep View Lwazi Header */}

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Container fluid style={{ marginTop: 30 }}>
                        <Row>
                            <Col xs='1'>
                                <Button
                                    style={{
                                        backgroundColor: "transparent",
                                        marginTop: 13,
                                    }}
                                    onClick={() => {
                                        props.history.goBack();
                                    }}
                                >
                                    <i
                                        className='fa fa-arrow-left ml-5'
                                        style={{ fontSize: 20 }}
                                    />
                                </Button>
                            </Col>
                            <Col
                                xs='11'
                                className='d-flex justify-content-between align-items-center'
                            >
                                <h1 className='pl-0'>Profile</h1>

                                {!editMode ? (
                                    <Button
                                        className='menu-btn'
                                        style={{
                                            borderColor: "#17A3AB",
                                            backgroundColor: "#FFF",
                                            height: 40,
                                            fontSize: 12,
                                        }}
                                        onClick={() => editProfile()}
                                    >
                                        <span className='btn-inner--icon'>
                                            <i
                                                className='fas fa-pencil-alt'
                                                style={{ color: "#17A3AB" }}
                                            />
                                        </span>
                                        <span
                                            style={{ color: "#17A3AB" }}
                                            className='btn-inner--text ml-1'
                                        >
                                            Edit Profile
                                        </span>
                                    </Button>
                                ) : (
                                    <Row
                                        xs='6'
                                        className='text-right'
                                        style={{ marginRight: 8 }}
                                    >
                                        <Col xs='12'>
                                            <Button
                                                style={{
                                                    fontSize: 12,
                                                }}
                                                className='btn-outline-primary'
                                                type='button'
                                                onClick={() => {
                                                    editProfile();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                style={{
                                                    fontSize: 12,
                                                }}
                                                className='btn-primary-alternative'
                                                type='submit'
                                                onClick={handleSubmit(onSubmit)}
                                            >
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </Container>

                    {/* Main Section */}

                    <section
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginLeft: -3,
                        }}
                    >
                        <aside
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: 340,
                                height: 710,
                            }}
                        >
                            <AccountCircle
                                style={{
                                    color: "#83437D",
                                    width: 160,
                                    height: 160,
                                    marginTop: 50,
                                    marginBottom: 10,
                                }}
                            />
                            {/* <button
              style={{
                background: '#fff',
                border: '1px solid #17A3AB',
                borderRadius: 4,
                padding: 12,
              }}
            >
              <span
                style={{
                  fontFamily: 'brandon-grotesque',
                  fontWeight: 600,
                  fontSize: 12,
                  color: '#17A3AB',
                  textTransform: 'uppercase',
                }}
              >
                Change Image
              </span>
            </button> */}
                        </aside>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                            }}
                        >
                            <div style={{ marginTop: 60, marginLeft: 100 }}>
                                <ul style={{ listStyle: "none" }}>
                                    <li>
                                        <h2
                                            style={{
                                                fontFamily: "brandon-grotesque",
                                                fontWeight: 600,
                                                fontSize: 13,
                                                color: "#B3B8BF",
                                            }}
                                        >
                                            General
                                        </h2>
                                    </li>
                                    <li>Full Name</li>
                                    {/* <li>ID Number</li> */}
                                    <li>District</li>
                                    <li>Role</li>
                                    {/* <li>Supervisor</li> */}
                                </ul>
                                <ul
                                    style={{ listStyle: "none", marginTop: 60 }}
                                >
                                    <li>
                                        <h2
                                            style={{
                                                fontFamily: "brandon-grotesque",
                                                fontWeight: 600,
                                                fontSize: 13,
                                                color: "#B3B8BF",
                                            }}
                                        >
                                            Contact
                                        </h2>
                                    </li>
                                    <li>Email Address</li>
                                    {/* <li>Mobile Number</li> */}
                                </ul>
                                <ul
                                    style={{ listStyle: "none", marginTop: 60 }}
                                >
                                    <li>
                                        <h2
                                            style={{
                                                fontFamily: "brandon-grotesque",
                                                fontWeight: 600,
                                                fontSize: 13,
                                                color: "#B3B8BF",
                                            }}
                                        >
                                            Account
                                        </h2>
                                    </li>
                                    <li>Password</li>
                                </ul>
                            </div>
                            <div style={{ marginTop: 60 }}>
                                <ul
                                    style={{
                                        listStyle: "none",
                                        fontWeight: 600,
                                    }}
                                >
                                    <li>
                                        <h2
                                            style={{
                                                fontFamily: "brandon-grotesque",
                                                fontWeight: 600,
                                                fontSize: 13,
                                                color: "#B3B8BF",
                                                visibility: "hidden",
                                            }}
                                        >
                                            General
                                        </h2>
                                    </li>
                                    {!editMode ? (
                                        <li>{selectedUser.name}</li>
                                    ) : (
                                        <li>
                                            <Input
                                                innerRef={register({
                                                    required: true,
                                                })}
                                                name='name'
                                                className='form-control-alternative '
                                                type='text'
                                                defaultValue={selectedUser.name}
                                                // onChange={(e) => {
                                                //     setSelectedUser(
                                                //         (
                                                //             selectedUser: any
                                                //         ) => ({
                                                //             ...selectedUser,
                                                //             name:
                                                //                 e.target.value,
                                                //         })
                                                //     );
                                                // }}
                                            />
                                        </li>
                                    )}
                                    {/* <li>9011040250081</li> */}
                                    {!editMode ? (
                                        <li>
                                            {selectedUser.data_access_group}
                                        </li>
                                    ) : (
                                        <Input
                                            innerRef={register({
                                                required: true,
                                            })}
                                            placeholder='sm'
                                            bsSize='sm'
                                            name='data_access_group'
                                            type='select'
                                            defaultValue={
                                                selectedUser.data_access_group
                                            }
                                            className='form-control-alternative'
                                            // onChange={(e) => {
                                            //     setSelectedUser(
                                            //         (selectedUser: any) => ({
                                            //             ...selectedUser,
                                            //             data_access_group:
                                            //                 e.target.value,
                                            //         })
                                            //     );
                                            // }}
                                        >
                                            <option value=''>
                                                --Select District--
                                            </option>
                                            <option value='tg_buffalo_city'>
                                                Buffalo City Metropolitan
                                                Municipality
                                            </option>
                                            <option value='tg_cpt'>
                                                City of Cape Town Metropolitan
                                                Municipality
                                            </option>
                                            <option value='johannesburg'>
                                                City of Johannesburg
                                                Metropolitan Municipality
                                            </option>
                                            <option value='oasis_johannesburg'>
                                                City of Johannesburg
                                                Metropolitan Municipality OASIS
                                            </option>
                                            <option value='phru_johannesburg'>
                                                City of Johannesburg
                                                Metropolitan Municipality PHRU
                                            </option>
                                            <option value='tshwane'>
                                                City of Tshwane Metropolitan
                                                Municipality
                                            </option>
                                            <option value='ekurhuleni'>
                                                Ekurhuleni Metropolitan
                                                Municipality
                                            </option>
                                            {/* <option value="10">Ekurhuleni Metropolitan Municipality CPC  */}
                                            <option value='tg_mandelabay'>
                                                Nelson Mandela Bay Municipality
                                            </option>
                                            <option value='vhembe'>
                                                Vhembe District Municipality
                                            </option>
                                        </Input>
                                    )}
                                    {!editMode ? (
                                        <li>{selectedUser.role}</li>
                                    ) : (
                                        <Input
                                            placeholder='sm'
                                            bsSize='sm'
                                            name='role'
                                            type='select'
                                            defaultValue={selectedUser.role}
                                            innerRef={register({
                                                required: true,
                                            })}
                                            className='form-control-alternative'
                                            // onChange={(e) => {
                                            //     setSelectedUser(
                                            //         (selectedUser: any) => ({
                                            //             ...selectedUser,
                                            //             role: e.target.value,
                                            //         })
                                            //     );
                                            // }}
                                        >
                                            <option value=''>
                                                --SElect your Role--
                                            </option>
                                            <option value='peer_educator'>
                                                Peer Educator
                                            </option>
                                            <option value='nurse'>Nurse</option>
                                            <option value='data_capturer'>
                                                Data Capturer
                                            </option>
                                            <option value='data_quality_officer'>
                                                Data Quality Officer
                                            </option>
                                            <option value='site_administrator'>
                                                Site Administrator
                                            </option>
                                        </Input>
                                    )}
                                    {/* <li>Tim Room</li> */}
                                </ul>
                                <ul
                                    style={{
                                        listStyle: "none",
                                        fontWeight: 600,
                                        marginTop: 60,
                                    }}
                                >
                                    <li>
                                        <h2
                                            style={{
                                                fontFamily: "brandon-grotesque",
                                                fontWeight: 600,
                                                fontSize: 13,
                                                color: "#B3B8BF",
                                                visibility: "hidden",
                                            }}
                                        >
                                            Contact
                                        </h2>
                                    </li>
                                    {!editMode ? (
                                        <li>{selectedUser.email}</li>
                                    ) : (
                                        <li>
                                            <Input
                                                innerRef={register({
                                                    required: true,
                                                })}
                                                name='email'
                                                className='form-control-alternative '
                                                type='text'
                                                defaultValue={
                                                    selectedUser.email
                                                }
                                                // onChange={(e) => {
                                                //     setSelectedUser(
                                                //         (
                                                //             selectedUser: any
                                                //         ) => ({
                                                //             ...selectedUser,
                                                //             email:
                                                //                 e.target.value,
                                                //         })
                                                //     );
                                                // }}
                                            />
                                        </li>
                                    )}
                                    {/* <li>072 348 9867</li> */}
                                </ul>
                                {/* <ul style={{ listStyle: 'none', fontWeight: 600, marginTop: 60 }}>
                <li>
                  <h2
                    style={{
                      fontFamily: 'brandon-grotesque',
                      fontWeight: 600,
                      fontSize: 13,
                      color: '#B3B8BF',
                      visibility: 'hidden',
                    }}
                  >
                    Account
                  </h2>
                </li>
                <li>******</li>
              </ul> */}
                            </div>
                        </div>
                    </section>
                </Form>
            </MainLayout>
        );
    } else {
        return null;
    }
}
const styles = (theme: Theme) =>
    createStyles({
        table: {
            minWidth: 650,
        },
        tableRow: {
            "&:hover": {
                borderLeft: "#17A3AB",
                borderLeftWidth: "10",
                borderLeftStyle: "solid",
                boxShadow: "0 3px 5px 2px rgba(0,0,0,0.1)",
            },
        },
    });
export default withAuthState(withRouter(withStyles(styles)(UserProfile)));
