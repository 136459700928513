import React from "react";
import "../App.css";
import TopNavbarMain from "../Components/TopNavbarMain";
import { WithStyles, withStyles, Theme, createStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import logo from "../Assets/IMG_main/logo-prepi.png";
import firebase from "../Services/firebase";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import right_container_img from '../Assets/img/img-login-right.png'
import lwazi_large_text from '../Assets/img/lwazi-large-text.png'
import {
  // Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

type Props = WithStyles<typeof styles> & RouteComponentProps;

interface IState {
  username: string;
}
class Login extends React.Component<Props, IState> {
  state = {
    username: "",
  };
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: any) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event: any, key: string) {
    if (key === "username") {
      this.setState({ username: event.target.value });
    }
  }

  handleSubmit(event: any) {
    //
    event.preventDefault();
    console.log(this.state.username);
    console.log(event);
    console.log(event.target.value)
    firebase.auth.sendPasswordResetEmail(this.state.username).then((user: any) => {
        console.log(user);
 
          alert("Successfully send password reset link");

           this.props.history.replace("/login");
        
      }).catch((e) => {
        console.log("error", e);
        alert(e.message)
      })

  }

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row full-height">
            <div className="col-lg-7 col-md-10 col-sm-12 login-view">
              <div className="form-container aligin-middle p-5">
                <img
                  src={logo}
                  className="img-fluid mt-5 mb-5"
                  id="login-logo"
                  alt=""
                />
                <Row>
                 <Col xs='1'>
                    <a
                      style={{ backgroundColor: 'transparent', marginTop: 13 }}
                      onClick={() => {
                        this.props.history.goBack()
                      }}
                      className='btn'
                    >
                      <i
                        className='fa fa-arrow-left text-white  ml-5'
                        style={{ fontSize: 20 }}
                      />
                    </a>
                  </Col>
                  <Col xs="8" style={{marginLeft: 88}}>
                <h1 className="mb-4 login-h1">Forgot Password</h1>
                </Col>
                </Row>
                <form
                  className={this.props.classes.form}
                  noValidate
                  onClick={this.handleSubmit}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={this.state.username}
                    onChange={(event) => {
                      this.handleChange(event, "username");
                    }}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={this.props.classes.submit}
                    // on
                  >
                    Forgot Password
                  </Button>
                </form>
              </div>
            </div>
            <div className='col-lg-5 col-md-10 col-sm-12 extra-view'>
              <div className='content'>
                <img src={right_container_img} alt='' />
                <img src={lwazi_large_text} className='largetext' alt='' />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const styles = (theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  });
export default withRouter(withStyles(styles)(Login));
