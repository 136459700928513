import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'bootstrap/dist/css/bootstrap.min.css'

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import DictionaryTranslater from './Services/DictionaryTranslater'
import PatientListener from './Services/PatientListener'

import './Assets/plugins/nucleo/css/nucleo.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './Assets/scss/argon-dashboard-react.scss'
import './Assets/vendor/fullcalendar.min.css'
import './Assets/vendor/timeline.min.css'
import './App.css'
import { Provider } from 'react-redux'
import store from './Store'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

// import "assets/vendor/select2/dist/css/select2.min.css";
// import "assets/vendor/quill/dist/quill.core.css";
import './Assets/vendor/quill.core.css'
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
