import React, { useState, Fragment } from 'react'
import {
  createStyles,
  withStyles,
  WithStyles,
  ClickAwayListener,
  Toolbar,
  AppBar,
  fade,
  MenuItem,
  CssBaseline,
  Badge,
  Grid,
  Theme,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Divider,
} from '@material-ui/core'
import { Row, Col, Button } from 'reactstrap'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import logo from '../Assets/IMG_main/logo-prepi.png'
import logo2 from '../Assets/IMG_main/logo-wits.png'
import AccountCircle from '@material-ui/icons/AccountCircle'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Menu from '@material-ui/core/Menu'
import moment from 'moment'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import MailIcon from '@material-ui/icons/Mail'
import withAuthState, { AuthProps } from '../Enhanchers/withAuthState'
import firebase from '../Services/firebase'
import { FormGroup, Input } from 'reactstrap'
import { getDifference } from '../utils/Object'

type Props = WithStyles<typeof styles> & AuthProps & RouteComponentProps

interface IState {
  anchorEl: null | HTMLElement
  selected_role: string
  selected_district: string
}
class TopNavbarMain extends React.Component<Props, IState> {
  state = {
    anchorEl: null,
    selected_role: '',
    selected_district: '',
  }

  constructor(props: any) {
    super(props)
  }

  componentDidMount() {}

  componentDidUpdate(prevProps: any) {
    // console.log(prevProps);
    if (
      this.props.auth.user.role_access !== prevProps.auth.user.role_access ||
      this.props.auth.user.role !== prevProps.auth.user.role ||
      this.props.auth.user.role_access !== prevProps.auth.user.role_access ||
      this.props.auth.user.super_admin !== prevProps.auth.user.super_admin
    ) {
      var dif_user = getDifference(prevProps.auth.user, this.props.auth.user)
      // console.log(dif_user);
    }
  }

  handClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorEl: event.currentTarget,
    })
  }
  logout = () => {
    this.props.setUser({ user: {} })
    firebase.auth.signOut()
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    var selected_role = this.props.auth.user.role
    var selected_district = this.props.auth.user.data_access_group
    return (
      <header
        style={{
          width: '100%',
          backgroundColor: '#fff',
          position: 'fixed',
          zIndex: 1000,
          top: 0,
          left: 0,
          paddingTop: 10,
        }}
      >
        <Row xs='12' className='w-100'>
          <Col xs='6'>
            {/* Section 1 */}

            <section
              style={{
                display: 'flex',
              }}
            >
              {/* IWAZI logo */}
              <div className='mr-5'>
                <img
                onClick={() => this.props.history.push('/home')}
                  src={logo}
                  alt='home'
                  style={{
                    height: 27.53,
                    width: 70,
                    marginLeft: 25,
                    marginTop: 8,
                    cursor:"pointer"
                  }}
                />
              </div>

              {/* Not in design document */}

              <div>
                <FormGroup style={{ marginTop: 16 }}>
                  <Input
                    placeholder='sm'
                    bsSize='sm'
                    name='district_select'
                    type='select'
                    value={selected_district}
                    className='form-control-alternative'
                    onChange={(v) => {
                      // console.log(v.target.value);
                      // this.props.setUser({
                      //   user:{...this.props.auth.user,
                      //   data_access_group: v.target.value
                      //   }
                      // })
                      selected_district = v.target.value
                      if(selected_district.length > 0){
                      firebase.db
                        .collection('/Users')
                        .doc(this.props.auth.user.uuid)
                        .update({
                          ...this.props.auth.user,
                          data_access_group: v.target.value,
                        })
                        .then((data) => {
                          // eslint-disable-next-line no-restricted-globals
                          location.reload()
                        })
                      }
                    }}
                  >
                    <option value=''>--Select District--</option>
                    <option value='tg_buffalo_city'>
                      Buffalo City Metropolitan Municipality
                    </option>
                    <option value='tg_buffalo_city_she'>
                      Buffalo City Metropolitan Municipality SHE
                    </option>
                    <option value='tg_cpt'>
                      City of Cape Town Metropolitan Municipality
                    </option>
                    <option value='sw_cpt'>
                    City of Cape Town Metropolitan Municipality GDX
                    </option>
                    <option value='johannesburg'>
                      City of Johannesburg Metropolitan Municipality
                    </option>
                    <option value='oasis_johannesburg'>
                      City of Johannesburg Metropolitan Municipality OASIS
                    </option>
                    <option value='phru_johannesburg'>
                      City of Johannesburg Metropolitan Municipality PHRU
                    </option>
                    <option value='tshwane'>
                      City of Tshwane Metropolitan Municipality
                    </option>
                    <option value='ekurhuleni'>
                      Ekurhuleni Metropolitan Municipality
                    </option>
                    <option value='ek'>
                    Ekurhuleni Metropolitan Municipality CPC
                    </option>
                    {/* <option value="10">Ekurhuleni Metropolitan Municipality CPC  */}
                    <option value='tg_mandelabay'>
                      Nelson Mandela Bay Municipality
                    </option>
                    <option value='she_mandelabay'>
                    Nelson Mandela Bay Municipality SHE
                    </option>
                    <option value='vhembe'>Vhembe District Municipality</option>
                    <option value='vhembe_tvep'>Vhembe District Municipality TVEP</option>
                  </Input>
                </FormGroup>
              </div>

              <div>
                <FormGroup style={{ marginTop: 16, marginLeft: 8 }}>
                  <Input
                    placeholder='sm'
                    bsSize='sm'
                    name='role_select'
                    type='select'
                    value={selected_role}
                    className='form-control-alternative'
                    onChange={(v) => {
                      // console.log(v.target.value);
                      selected_role = v.target.value
                      if (selected_role.length > 0) {
                        firebase.db
                          .collection('/Users')
                          .doc(this.props.auth.user.uuid)
                          .update({
                            ...this.props.auth.user,
                            role_access:
                              selected_role === 'peer_educator' ||
                              selected_role === 'nurse'
                                ? 0
                                : 1,
                            role: selected_role,
                          })
                          .then((data) => {
                            // eslint-disable-next-line no-restricted-globals
                            location.reload()
                          })
                      }
                    }}
                  >
                    <option value=''>--Select your Role--</option>
                    <option value='peer_educator'>Peer Educator</option>
                    <option value='nurse'>Nurse</option>
                    <option value='data_capturer'>Data Capturer</option>
                    <option value='data_quality_officer'>
                      Data Quality Officer
                    </option>
                    <option value='site_administrator'>
                      Site Administrator
                    </option>
                  </Input>
                </FormGroup>
              </div>
            </section>
          </Col>
          {/* Section 2 */}
          <Col xs='6'>
            <section
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {/* time top navbar */}
              <div
                style={{
                  color: '#B3B8BF',
                  marginRight: 40,
                  display: 'inline',
                  fontFamily: 'Gothic A1',
                  fontWeight: 500,
                }}
              >
                {moment().format('DD MMM YYYY, HH:mm:ss')}
              </div>
              {/* Notification menu */}
              {/* Not in design document */}

              {/* <div style={{ marginTop: 20 }}>
          <IconButton aria-label='show 11 new notifications'>
            <Badge badgeContent={11} color='secondary'>
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </div> */}
              {/* <p
                style={{
                  color: '#031f33',
                  fontSize: 12,
                  marginBottom: -5,
                  //  fontWeight: 500,
                  textAlign: 'right',
                }}
              >
                Welcome,
              </p> */}
              <div
                style={{
                  color: '#031f33',
                  fontSize: 16,
                  fontWeight: 550,
                  fontFamily: 'Gothic A1',
                  textAlign: 'right',
                  marginBottom: '0rem !important',
                }}
              >
                {this.props.auth.user.name}
              </div>

              {/* Not in design document */}

              {/* <div
                className='text-danger'
                style={{
                  color: '#031f33',
                  fontSize: 13,
                  fontWeight: 300,
                  textAlign: 'right',
                }}
              >
                <>
                  {this.props.auth.user.super_admin
                    ? ' Super Admin: '
                    : null}
                </>
                <>
                  {this.props.auth.user.role === 'peer_educator'
                    ? ' Peer Educator'
                    : null}
                </>
                <>
                  {this.props.auth.user.role === 'nurse' ? ' Nurse' : null}
                </>
                <>
                  {this.props.auth.user.role === 'data_capturer'
                    ? 'Data Capturer '
                    : null}
                </>
                <>
                  {this.props.auth.user.role === 'data_quality_officer'
                    ? ' Data Quality Officer'
                    : null}
                </>
                <>
                  {this.props.auth.user.role === 'site_administrator'
                    ? ' Site Administrator'
                    : null}
                </>
                {/* <>{this.props.auth.user.super_admin ? " Super Admin: ": null}</> */}
              {/*</div> */}
              <Button
                style={{
                  border: 'none',
                  background: 'transparent',
                  padding: 0,
                  margin: 0,
                }}
                onClick={this.handClick}
              >
                <AccountCircle
                  style={{
                    color: '#83437D',
                    marginLeft: '4px',
                    width: '52px',
                    height: '52px',
                    marginRight: '50px',
                  }}
                />
              </Button>
              <Menu
                id='simple-menu'
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem onClick={() => this.props.history.push('/settings')}>
                  Settings
                </MenuItem>
                <MenuItem onClick={() => this.props.history.push('/profile')}>
                  My Profile
                </MenuItem>
                <MenuItem onClick={this.logout}>Logout</MenuItem>
              </Menu>
              {/* Wits Logo */}
              <img
                src={logo2}
                alt='home2'
                style={{
                  height: 28,
                  width: 60,
                  marginRight: 25,
                }}
              />
            </section>
          </Col>
        </Row>
      </header>
    )
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      // width: '100%',
      // height: '100%',
      // backgroundColor: 'red'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#ffff',
      boxShadow: 'none',
      height: 80,
      //  paddingTop: 10
    },
    drawer: {
      width: 240,
      flexShrink: 0,
    },
    drawerPaper: {
      width: 240,
      marginTop: 50,
    },
    drawerContainer: {
      overflow: 'auto',
      marginTop: 40,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
export default withRouter(withAuthState(withStyles(styles)(TopNavbarMain)))
