import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import * as actions from './actions';

export interface IPatientState {
  readonly patients: any;
}

export type PatientActions = ActionType<typeof actions>;

const initialState: IPatientState = {
  patients: []
};

export default combineReducers<IPatientState, PatientActions>({
  patients: (state = initialState.patients, action) => {
    switch (action.type) {
      case getType(actions.setPatients): {
        return action.payload.patients;
      }
      default: {
        return state;
      }
    }
  },

});
