import React from 'react'

import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'

import { INITIAL_EVENTS, createEventId } from '../utils/event-utils'
import firebase from '../Services/firebase'
// react component used to create sweet alerts
import ReactBSAlert from 'react-bootstrap-sweetalert'
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Modal,
  Row,
  Container,
  Col,
  CardTitle,
} from 'reactstrap'

import { Button as MButton } from '@material-ui/core'
import MainLayout from '../Layouts/MainLayout'
import moment from 'moment'
import CalendarMain from './CalendarMain'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import CountBoxNav from '../Components/CountBoxNav'
import UserTable from '../Components/UserTable'
import UserForm from '../Forms/UserForm'

type Props = RouteComponentProps

interface IState {
  users: any
}

interface IState {
  users: any
  newUser: any
}

// const calendarRef

class UserManagement extends React.Component<Props, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      newUser: false,
      users: [],
    }
  }

  toggleCreateUser = (bol: boolean) => {
    this.setState({
      newUser: bol,
    })
  }

  createNewUser = (value: any) => {
    console.log(value)
  }

  componentDidMount() {
    firebase.db
      .collection('Users')
      .get()
      .then((querySnapshot) => {
        var allUsers: any[] = []
        querySnapshot.forEach((doc) => {
          allUsers.push(doc.data())
        })
        this.setState({ users: allUsers })
      })
      .catch((error) => {
        //   console.log("Error", error);
      })
  }

  render() {
    const onRowSelect = (user: any) => {
      //  console.log(user);
      // if (patient.client_id_complete !== 0) {
      //   this.props.history.push({
      //     pathname: "/patient/" + patient.uid,
      //     state: patient,
      //   })
      // } else {
      //   console.log("review")
      // }
    }

    const onEdit = (user: any) => {
      // if (patient.client_id_complete === 0 || patient.client_id_complete === 1) {
      //   this.props.history.push({
      //     pathname: "/patient-edit/" + patient.uid,
      //     state: patient,
      //   })
      // } else if (patient.client_id_complete === 0) {
      //   console.log("review");
      //   this.props.history.push({
      //     pathname: "/patient-review/" + patient.uid,
      //     state: patient
      //   });
      // }
    }

    if (this.state.users.length > 0) {
      console.log(this.state.users)
    }

    return (
      <MainLayout>
        <Container fluid style={{ marginTop: 30 }}>
          <Modal
            className='modal-dialog-centered'
            contentClassName='bg-secondary'
            isOpen={this.state.newUser}
          >
            <div className='modal-header py-3'>
              <h3 className='heading mt-4'>Create New User</h3>
              <button
                aria-label='Close'
                className='close text-right'
                data-dismiss='modal'
                type='button'
                onClick={() => this.toggleCreateUser(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className='modal-body py-3'>
              <UserForm
                closeModal_={this.toggleCreateUser}
                userMode={'create'}
              />
            </div>
            <div className='modal-footer'>
              {/* <Button className="btn-white" color="default" type="button"
                                  onClick={() =>props.history.push("/prep")}>
                 Sure
                </Button> */}
            </div>
          </Modal>
          <Row>
            <Col>
              <div
                style={{
                  width: '100%',
                  height: 'auto',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h1>
                  User Management
                  {this.state.users.length > 0 ? (
                    <small style={{ marginLeft: 10 }} className='text-muted'>
                      ({this.state.users.length} Users)
                    </small>
                  ) : null}
                </h1>
                <div style={{ display: 'flex', marginRight: 20 }}>
                  <Button
                    className='menu-btn'
                    color='success'
                    onClick={() => {
                      this.toggleCreateUser(true)
                    }}
                  >
                    <span className='btn-inner--icon'>
                      <i className='far fa-plus-square fa-2x' />
                    </span>
                    <span className='btn-inner--text ml-1'>Add User</span>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          <Row xs='12'>
            <Col>
              <hr />
            </Col>
          </Row>
        </Container>

        {/* User Table */}

        <section style={{ width: '95%', marginLeft: 30, paddingBottom: 20 }}>
          <UserTable
            onRowSelect={onRowSelect}
            onEdit={onEdit}
            list={this.state.users}
          />
        </section>
      </MainLayout>
    )
  }
}

export default withRouter(UserManagement)
