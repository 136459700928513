import React, { Component } from "react";
// import { localStorage } from 'react'
// import { Alert, AsyncStorage } from 'react;
// import DeviceInfo from 'react-native-device-info';
import firebase from "../Services/firebase";
import { RouteComponentProps, withRouter } from "react-router";
import { useFormContext } from "react-hook-form";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import withOutreachDD, { OutreachDDProps } from "../Enhanchers/withOutreachDD";
import _ from "lodash";
import { find_and_split } from "./dd_util";
// import { getDistrict } from "../Components/TimeLine";
import { getDistrict, getSiteValue } from "../constants";

interface IProps {
    list_name: string;
    selected_district: string;
}

type Props = OutreachDDProps & IProps & AuthProps;
type MyState = { currentDistrict: string };
class DistrictSubDistrictTranslator extends Component<Props, MyState> {
    notificationListener: any;
    notificationOpenedListener: any;

    constructor(props: any) {
        super(props);
        this.state = {
            currentDistrict: "",
        };
    }

    componentDidMount() {
        console.log("mounted: " + this.props.outreach_dd);
        console.log(this.props.outreach_dd);
        this.setState({
            currentDistrict: this.props.selected_district,
        });
        this.translate(this.props);
    }
    async componentWillUpdate(nextProps: any) {
        console.log(
            "updated: " + JSON.stringify(this.props.outreach_dd.district_dd)
        );

        if (nextProps.selected_district !== this.state.currentDistrict) {
            this.setState({
                currentDistrict: nextProps.selected_district,
            });
            this.translate(nextProps);
        }
    }

    async translate(nextProps: any) {
        console.log(nextProps, this.props);
        // if (this.state.currentDistrict !== this.props.selected_district) {
        //get district from users
        var selected_district_val = nextProps.selected_district
            ? nextProps.selected_district
            : getSiteValue(getDistrict(nextProps.auth.user.data_access_group));
        console.log("test2");

        console.log(selected_district_val);

        var district_check_list: any = [];

        nextProps.outreach_dd.district_dd.map((dictionary_object: any) => {
            var dict_obj = dictionary_object;
            var subdistrict_pat = /sub_/g;

            if (subdistrict_pat.test(dict_obj.field_name)) {
                var obj_temp_select_value = find_and_split(
                    dict_obj.select_choices_or_calculations
                );
                obj_temp_select_value.map((select_option: any) => {
                    district_check_list.push(select_option);
                });
            }
        });

        if (
            nextProps.list_name === "outreach" &&
            selected_district_val !== undefined &&
            nextProps.outreach_dd.all_dd !== undefined
        ) {
            var dictionary_list: any[] = [];
            var all_list: any[] = [];
            firebase.db
                .collection("Dictionary/outreach/outreach_lwazi_output_dd")
                // .orderBy("field_name")
                .get()
                .then((data_: any) => {
                    // var item_main = data_.data();
                    data_.forEach((item_: any) => {
                        // console.log(item_)

                        // var selected_district_found = "";
                        //
                        // var district_selected = /'2'/g;
                        var district_selected;

                        // if (this.props.auth.user.role_access === 1) {
                        district_selected = new RegExp(
                            "'" + selected_district_val + ".*?'",
                            "g"
                        );

                        console.log(district_selected);
                        // } else {
                        //     //find district key
                        //     var selected_district_found;
                        //     district_check_list.map((district: any) => {
                        //         if (
                        //             String(district.value)
                        //                 .toLowerCase()
                        //                 .includes(
                        //                     getDistrict(
                        //                         this.props.auth.user
                        //                             .data_access_group
                        //                     ).toLowerCase()
                        //                 )
                        //         ) {
                        //             //   console.log("selected district of user ",district)
                        //             selected_district_found =
                        //                 "" + district.key_value;
                        //         }
                        //     });
                        //     district_selected = new RegExp(
                        //         "'" + selected_district_found + ".*?'",
                        //         "g"
                        //     );
                        // }
                        console.log(district_selected);
                        var subdistrict_pat = /sub_/g;
                        var subdistrict_pat_2 = /_sub_/g;

                        var kp_program = /kp_programme_site_out/g;
                        var temp_obj = {
                            ...item_.data(),
                            id: item_.id,
                            field_name: item_.id,
                        };
                        all_list.push(temp_obj);

                        // if (temp_obj.branching_logic !== 0) {
                        if (
                            kp_program.test(temp_obj?.branching_logic) &&
                            district_selected.test(temp_obj?.branching_logic)
                        ) {
                            var string_to_test = temp_obj.field_name;
                            console.log(temp_obj);

                            console.log(string_to_test);
                            var district_out: any;
                            var sub_district_out: any;
                            var areas_out: any;
                            var wards_out: any;
                            var location_out: any;
                            var places_out: any;
                            var sites_out: any;
                            var team_leader_out: any;
                            var peer_out: any;
                            var counselor_out: any;
                            var hotspot_out: any;

                            //         Object.keys(temp_obj).forEach(object_key =>{
                            console.log("success");
                            //  console.log(object_key);
                            var district_pat = /district/g;
                            var areas_out_pat = /area/g;

                            var ward_pat = /wards_out/g;
                            var ward_pat_2 = /_wards_out/g;

                            var location_out_pat = /location/g;
                            var places_out_pat = /place/g;
                            var sites_out_pat = /site/g;
                            var team_leader_out_pat = /team_leader/g;
                            var peer_out_pat = /peer/g;
                            var counselor_out_pat = /counselor/g;
                            var hotspot_out_pat = /hotspot/g;

                            var value = temp_obj;
                            // console.log(district_pat.test(district_pat), value.length);

                            //TEST
                            var cpt_counselor = /cpt_chw_out/g;
                            var chw_out_coj = /chw_out_coj/g;
                            var chw_out_coj_oasis = /chw_out_coj_oasis/g;
                            var chw_out_phru = /chw_out_phru/g;
                            var chw_cot = /chw_cot/g;
                            var chw_out_ek = /chw_out_ek/g;
                            var chw_out_ek_cpc = /chw_out_ek_cpc/g;
                            var chw_out_vh = /chw_out_vh/g;

                            //TEST

                            //district
                            if (
                                hotspot_out_pat.test(string_to_test) &&
                                string_to_test.length > 0
                            ) {
                                //   console.log( object_key +": "+string_to_test[object_key]);
                                hotspot_out = {
                                    hotspot_key: string_to_test,
                                    hotspot_value: value,
                                    key: "Hotspot",
                                };
                            }

                            //district
                            if (
                                district_pat.test(string_to_test) &&
                                string_to_test.length > 0
                            ) {
                                //   console.log( object_key +": "+string_to_test[object_key]);
                                district_out = {
                                    district_key: string_to_test,
                                    district_value: value,
                                    key: "District",
                                };
                            }

                            if (
                                (subdistrict_pat.test(string_to_test) ||
                                    subdistrict_pat_2.test(string_to_test)) &&
                                string_to_test.length > 0
                            ) {
                                //   console.log( string_to_test +": "+value[string_to_test]);
                                sub_district_out = {
                                    sub_district_key: string_to_test,
                                    sub_district_value: value,
                                    key: "Sub District",
                                };
                            }

                            //area
                            if (
                                areas_out_pat.test(string_to_test) &&
                                string_to_test.length > 0
                            ) {
                                //   console.log( string_to_test +": "+value[string_to_test]);
                                areas_out = {
                                    area_key: string_to_test,
                                    area_value: value,
                                    key: "Area",
                                };
                            }

                            //wards
                            if (
                                (ward_pat.test(string_to_test) ||
                                    ward_pat_2.test(string_to_test)) &&
                                string_to_test.length > 0
                            ) {
                                wards_out = {
                                    ward_key: string_to_test,
                                    ward_value: value,
                                    key: "Ward",
                                };
                            }

                            // location_out
                            if (
                                location_out_pat.test(string_to_test) &&
                                string_to_test.length > 0
                            ) {
                                location_out = {
                                    location_key: string_to_test,
                                    location_value: value,
                                    key: "Location",
                                };
                            }

                            // places_out
                            if (
                                places_out_pat.test(string_to_test) &&
                                string_to_test.length > 0
                            ) {
                                places_out = {
                                    place_key: string_to_test,
                                    place_value: value,
                                    key: "Place",
                                };
                            }

                            // sites_out
                            if (
                                sites_out_pat.test(string_to_test) &&
                                string_to_test.length > 0
                            ) {
                                sites_out = {
                                    site_key: string_to_test,
                                    sites_value: value,
                                    key: "Site",
                                };
                            }

                            // team_leader_out
                            if (
                                team_leader_out_pat.test(string_to_test) &&
                                string_to_test.length > 0
                            ) {
                                console.log("team_leader_out");
                                team_leader_out = {
                                    team_leader_key: string_to_test,
                                    team_leader_value: value,
                                    key: "Team Leader",
                                };
                            }

                            // peer_out
                            if (
                                peer_out_pat.test(string_to_test) &&
                                string_to_test.length > 0
                            ) {
                                console.log("peer_out_pat");
                                peer_out = {
                                    peer_key: string_to_test,
                                    peer_value: value,
                                    key: "Peer Responsible",
                                };
                            }

                            // counselor_out
                            if (string_to_test.length > 0) {
                                if (
                                    cpt_counselor.test(string_to_test) ||
                                    chw_out_coj.test(string_to_test) ||
                                    chw_out_coj_oasis.test(string_to_test) ||
                                    chw_out_phru.test(string_to_test) ||
                                    chw_cot.test(string_to_test) ||
                                    chw_out_ek.test(string_to_test) ||
                                    chw_out_ek_cpc.test(string_to_test) ||
                                    chw_out_vh.test(string_to_test) ||
                                    counselor_out_pat.test(string_to_test)
                                ) {
                                    counselor_out = {
                                        counselor_key: string_to_test,
                                        counselor_value: value,
                                        key: "Counselor: ",
                                    };
                                }
                            }

                            // })

                            var final = {
                                ...temp_obj,
                                id: temp_obj.id,
                                // date: date_out,
                                // ...district_out,
                                meta: {
                                    ...sub_district_out,
                                    ...areas_out,
                                    ...wards_out,
                                    ...location_out,
                                    ...places_out,
                                    ...sites_out,
                                    ...team_leader_out,
                                    ...peer_out,
                                    ...counselor_out,
                                    ...hotspot_out,
                                },
                            };
                            console.log("dsdt");
                            console.log(final);
                            if (Object.keys(final.meta).length > 0) {
                                console.log("Called here");
                                dictionary_list.push(final);
                            }
                            // console.log();
                        }
                        // }
                    });

                    nextProps.setOutreachDistrictDD({
                        district_dd: dictionary_list,
                    });
                    console.log(dictionary_list);
                    // this.props.setOutreachDD({all_dd:all_list})
                    // this.props.setOutreachDD({outreach_dd:{
                    //     outreach_dd:{ district_dd: ,
                    //     all_dd: all_list
                    // }
                    // }});
                });
        }
        // }
        // else {
        //     nextSelectedDistrict = "1";
        //     this.translate(nextProps);
        // }
    }

    render() {
        return null;
    }
}

export default withAuthState(withOutreachDD(DistrictSubDistrictTranslator));
