import React from "react";
import logo from "../logo.svg";
import "../App.css";
import TopNavbarMain from "../Components/TopNavbarMain";
import {
    CssBaseline,
    WithStyles,
    Toolbar,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    withStyles,
    Theme,
    createStyles,
    Button,
} from "@material-ui/core";
import MainLayout from "../Layouts/MainLayout";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";
import {
    Badge,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
} from "reactstrap";
import { CardBody, CardTitle, Col, Card } from "reactstrap";
import AddIcon from "@material-ui/icons/Add";

import Header from "../Components/Header";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IPatient } from "../@types/patient";
import firebase from "../Services/firebase";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import PatientTable from "../Components/PatientTable";

import AsyncSelect from "react-select/async";
import PatientForm from "../Forms/patientForm";
import OutreachViewForm from "../Forms/OutreachViewForm";

interface IState {
    outreachRecord: any;
    loading: boolean;
}

type Props = WithStyles<typeof styles> &
    RouteComponentProps<{ id: string }> &
    AuthProps;

class OutreachLwaziView extends React.Component<Props, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            outreachRecord: {},
            loading: true,
        };
    }

    componentDidMount() {
        console.log(this.props.match.params.id);
        if (this.props.match.params.id) {
            var ref = firebase.db
                .collection(
                    "main/" +
                        this.props.auth.user.data_access_group +
                        "/outreach_and_groupactivity"
                )
                .where("outreach_lwazi_id", "==", this.props.match.params.id)
                .get()
                .then((data: any) => {
                    var temp_list: any[] = [];
                    console.log(data);

                    data.docs.map((outreach_rec: any) => {
                        temp_list.push({
                            uid: outreach_rec.id,
                            ...outreach_rec.data(),
                        });
                    });

                    var final_item = {
                        activity_date_outreach:
                            temp_list[0] && temp_list[0].activity_date_outreach,
                        activity_type_out:
                            temp_list[0].activity_type_out &&
                            temp_list[0].activity_type_out,
                        sub_district_out:
                            temp_list[0].sub_district_out &&
                            temp_list[0].sub_district_out,
                        peer_responsible_out:
                            temp_list[0].peer_responsible_out &&
                            temp_list[0].peer_responsible_out,
                        form_complete:
                            temp_list[0].form_complete &&
                            temp_list[0].form_complete,
                        outreach_lwazi_id:
                            temp_list[0].outreach_lwazi_id &&
                            temp_list[0].outreach_lwazi_id,
                        district:
                            temp_list[0].kp_programme_site_out &&
                            temp_list[0].kp_programme_site_out,
                        meta: temp_list[0].meta && temp_list[0].meta,
                        patients: temp_list && temp_list,
                    };
                    console.log(final_item);
                    this.setState({
                        outreachRecord: final_item,
                        loading: false,
                    });
                });
        } else {
            window.alert("No outreach record id found");
            this.props.history.goBack();
        }
    }

    render() {
        console.log(this.state);
        if (!this.state.loading) {
            return (
                <>
                    <OutreachViewForm
                        outreachRecord={this.state.outreachRecord}
                    />
                </>
            );
        } else {
            return null;
        }
    }
}
const styles = (theme: Theme) =>
    createStyles({
        table: {
            minWidth: 650,
        },
        tableRow: {
            "&:hover": {
                borderLeft: "#17A3AB",
                borderLeftWidth: "10",
                borderLeftStyle: "solid",
                boxShadow: "0 3px 5px 2px rgba(0,0,0,0.1)",
            },
        },
    });
export default withAuthState(withRouter(withStyles(styles)(OutreachLwaziView)));
