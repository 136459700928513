
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ISetUserPayload,  setUser } from '../Store/modules/auth/actions';
import { ISetActiveStatePayload, setActiveState} from '../Store/modules/auth/actions';
import { ISetLoadingMsgPayload, setLoadingMsgState} from '../Store/modules/auth/actions';

import { IAuthState as IAuthReduxState } from '../Store/modules/auth/reducer';
import { RootState } from '../@types/store';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUser,
      setActiveState,
      setLoadingMsgState
      
    },
    dispatch
  );

interface IAuthState {
  auth: IAuthReduxState;
}

export interface IAuthDispatchProps {
  setUser: (payload: ISetUserPayload) => void;
  setActiveState: (payload: ISetActiveStatePayload) => void;
  setLoadingMsgState: (payload: ISetLoadingMsgPayload) => void;
}

export type AuthProps = IAuthState & IAuthDispatchProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
