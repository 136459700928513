import { createStandardAction } from 'typesafe-actions';
import { User } from 'firebase';
import { IPatient } from '../../../@types/patient';
import { AnyMxRecord } from 'dns';

const FETCH_PREP = 'prep/FETCH_PREP';
const SET_PREP = 'prep/SET_PREP';



export const fetchPrep = createStandardAction(FETCH_PREP)();

export interface ISetPrepPayload {
  prep_records: any[];
}
export const setPrep = createStandardAction(SET_PREP)<
  ISetPrepPayload
>();
