import { createStandardAction } from 'typesafe-actions';
import { User } from 'firebase';
import { IPatient } from '../../../@types/patient';

const FETCH_PATIENT = 'patient/FETCH_PATIENT';
const SET_PATIENT = 'patient/SET_PATIENT';



export const fetchPatient = createStandardAction(FETCH_PATIENT)();

export interface ISetPatientPayload {
  patients: IPatient[];
}
export const setPatients = createStandardAction(SET_PATIENT)<
  ISetPatientPayload
>();
