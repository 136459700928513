import { createStandardAction } from 'typesafe-actions';
import { User } from 'firebase';

const FETCH_APPOINTMENT = 'appointment/FETCH_APPOINTMENT';
const SET_APPOINTMENT = 'appointment/SET_APPOINTMENT';



export const fetchAppointment = createStandardAction(FETCH_APPOINTMENT)();

export interface ISetAppointmentPayload {
  appointment_records: any[];
}
export const setAppointment = createStandardAction(SET_APPOINTMENT)<ISetAppointmentPayload>();
