import React, { useState } from "react";
import "../App.css";
import TopNavbarMain from "../Components/TopNavbarMain";
import {
    WithStyles,
    withStyles,
    Theme,
    createStyles,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    Radio,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import logo from "../Assets/IMG_main/logo-lwazi-login.svg";
import right_container_img from "../Assets/img/img-login-right.png";
import lwazi_large_text from "../Assets/img/lwazi-large-text.png";
import firebase from "../Services/firebase";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import {
    // Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";
import { useForm } from "react-hook-form";
import moment from "moment";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";

type Props = WithStyles<typeof styles> & RouteComponentProps & AuthProps;

function Register(props: Props) {
    const [state, setState] = React.useState({
        email: "",
        password: "",
        role: "",
        data_access_group: "",
    });
    const {
        register,
        handleSubmit,
        watch,
        errors,
        formState,
        control,
        setError,
        clearError,
    } = useForm();

    const handleChange = (event: any) => {
        // console.log(event)
        setState({ ...state, [event.target.id]: event.target.value });
        // console.log(state);
    };

    const registerUser = (e: any) => {
        e.preventDefault();
        const { email, password, role, data_access_group } = state;
        console.log(email, password, role, data_access_group);
        if (password.length < 5) {
            alert("Please Enter Valid Password");
        } else if (role.length === 0 || data_access_group.length === 0) {
            alert("Please Select Role and Data Access Group");
        } else if (
            role.length > 0 &&
            data_access_group.length > 0 &&
            password.length > 0
        ) {
            var previous_data = userToRegister;
            console.log("previous_data", previous_data);
            firebase.db.collection("Users").doc(userToRegister.uid).delete();

            firebase.auth
                .createUserWithEmailAndPassword(email, password)
                .then((data: any) => {
                    console.log(data);
                    var new_record_copy = {
                        ...previous_data,
                        uuid: data.user.uid,
                        status: "registered",
                        register_by_datetime: moment().format(
                            "YYYY/MM/DD | HH:mm:ss"
                        ),
                    };

                    //  .then((data:any)=>{
                    //    console.log("record deleted", data)
                    props.setUser({ user: new_record_copy });
                    //  })
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            console.log("lost");
            return;
        }
    };

    var temp_obj: any;
    const [userToRegister, setUserToRegiser] = useState(temp_obj);
    // console.log(temp_obj);
    if (userToRegister === undefined) {
        if (props.location.search.length > 0) {
            console.log(props);
            var split_params = props.location.search.split("&");
            var reason = split_params[0].split("=");
            var recordId = split_params[1].split("=");
            console.log(reason, recordId);

            if (reason[0] === "?id" && reason[1].length > 0) {
                // appointment
                firebase.db
                    .collection("Users")
                    .doc(reason[1])
                    .get()
                    .then((data) => {
                        var temp_ = { ...data.data(), uid: data.id };

                        console.log({ ...data.data(), uid: data.id });
                        var doc_data = data.data();
                        var apt_: any = { ...doc_data, uid: data.id };

                        console.log(apt_);
                        if (apt_.status === "registration") {
                            setUserToRegiser(apt_);
                            setState({
                                email: apt_.email,
                                password: "",
                                role: apt_.role,
                                data_access_group: apt_.data_access_group,
                            });
                            // .then(data=>{
                        } else if (
                            recordId[0] === "invite" &&
                            recordId[1] !== apt_.status
                        ) {
                            alert("expired token");
                            props.history.replace("/login");
                        } else {
                            alert("intruder alert");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        alert("expired token");
                        props.history.replace("/login");
                    });
            }
        }
    }

    // const onSubmit = (data: any) => {
    //   console.log(data);
    // };

    return (
        <>
            <div className='container-fluid'>
                <div className='row full-height'>
                    <div className='col-lg-6 col-md-10 col-sm-12 login-view'>
                        <div className='form-container aligin-middle p-5'>
                            <img
                                src={logo}
                                className='img-fluid mt-5 mb-5'
                                id='login-logo'
                                alt=''
                            />
                            <h1 className='login-h1'>Registration </h1>
                            {/* <h1 className="mb-4 login-h1">Assistant</h1> */}
                            {userToRegister ? (
                                <Form>
                                    <>
                                        <TextField
                                            variant='outlined'
                                            margin='normal'
                                            fullWidth
                                            label='Full Name'
                                            name='name'
                                            disabled
                                            defaultValue={userToRegister.name}
                                        />
                                        <TextField
                                            variant='outlined'
                                            margin='normal'
                                            fullWidth
                                            disabled
                                            name='email'
                                            label='Email Address'
                                            type='email'
                                            defaultValue={userToRegister.email}
                                        />
                                        <TextField
                                            variant='outlined'
                                            margin='normal'
                                            autoFocus
                                            fullWidth
                                            id='password'
                                            name='password'
                                            label='Password'
                                            type='password'
                                            autoComplete='current-password'
                                            onChange={handleChange}
                                        />
                                        <>
                                            {userToRegister.super_admin ? (
                                                <>
                                                    {/* <Row xs="12"> */}

                                                    <FormControlLabel
                                                        checked={
                                                            userToRegister.super_admin
                                                        }
                                                        disabled={
                                                            userToRegister.super_admin
                                                        }
                                                        control={<Radio />}
                                                        label='SuperAdmin'
                                                    />

                                                    <FormControl
                                                        variant='outlined'
                                                        margin='normal'
                                                        className={
                                                            props.classes
                                                                .formControl
                                                        }
                                                        fullWidth
                                                    >
                                                        <InputLabel
                                                            style={{
                                                                backgroundColor:
                                                                    "#17A3AB",
                                                            }}
                                                        >
                                                            Data Access Group
                                                        </InputLabel>
                                                        <Select
                                                            // className="form-control-alternative"
                                                            // type="select"
                                                            defaultValue={
                                                                userToRegister.data_access_group
                                                            }
                                                            name='data_access_group'
                                                        >
                                                            <MenuItem value=''>
                                                                --Select Access
                                                                Group--
                                                            </MenuItem>
                                                            <MenuItem value='tg_buffalo_city'>
                                                                Buffalo City
                                                                Metropolitan
                                                                Municipality{" "}
                                                            </MenuItem>
                                                            <option value='tg_buffalo_city_she'>
                                                                Buffalo City
                                                                Metropolitan
                                                                Municipality SHE
                                                            </option>
                                                            <MenuItem value='tg_cpt'>
                                                                City of Cape
                                                                Town
                                                                Metropolitan
                                                                Municipality{" "}
                                                            </MenuItem>
                                                            <MenuItem value='sw_cpt'>
                                                                City of Cape
                                                                Town
                                                                Metropolitan
                                                                Municipality GDX
                                                            </MenuItem>
                                                            <MenuItem value='johannesburg'>
                                                                City of
                                                                Johannesburg
                                                                Metropolitan
                                                                Municipality{" "}
                                                            </MenuItem>
                                                            <MenuItem value='oasis_johannesburg'>
                                                                City of
                                                                Johannesburg
                                                                Metropolitan
                                                                Municipality
                                                                OASIS{" "}
                                                            </MenuItem>
                                                            <MenuItem value='phru_johannesburg'>
                                                                City of
                                                                Johannesburg
                                                                Metropolitan
                                                                Municipality
                                                                PHRU{" "}
                                                            </MenuItem>
                                                            <MenuItem value='tshwane'>
                                                                City of Tshwane
                                                                Metropolitan
                                                                Municipality{" "}
                                                            </MenuItem>
                                                            <MenuItem value='ekurhuleni'>
                                                                Ekurhuleni
                                                                Metropolitan
                                                                Municipality{" "}
                                                            </MenuItem>
                                                            <MenuItem value='ek'>
                                                                Ekurhuleni
                                                                Metropolitan
                                                                Municipality CPC
                                                            </MenuItem>
                                                            <MenuItem value='tg_mandelabay'>
                                                                Nelson Mandela
                                                                Bay Municipality{" "}
                                                            </MenuItem>
                                                            <MenuItem value='she_mandelabay'>
                                                                Nelson Mandela
                                                                Bay Municipality
                                                                SHE
                                                            </MenuItem>
                                                            <MenuItem value='vhembe'>
                                                                Vhembe District
                                                                Municipality
                                                            </MenuItem>
                                                            <MenuItem value='vhembe_tvep'>
                                                                Vhembe District
                                                                Municipality
                                                                TVEP
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {/* </Row>
          <Row xs="12"> */}

                                                    <FormControl
                                                        variant='outlined'
                                                        margin='normal'
                                                        className={
                                                            props.classes
                                                                .formControl
                                                        }
                                                        fullWidth
                                                    >
                                                        <InputLabel
                                                            style={{
                                                                backgroundColor:
                                                                    "#17A3AB",
                                                            }}
                                                        >
                                                            Role
                                                        </InputLabel>
                                                        <Select
                                                            // type="select"
                                                            name='role'
                                                            defaultValue={
                                                                userToRegister.role
                                                            }
                                                        >
                                                            <MenuItem value=''>
                                                                --SElect your
                                                                Role--
                                                            </MenuItem>
                                                            <MenuItem value='peer_educator'>
                                                                Peer Educator
                                                            </MenuItem>
                                                            <MenuItem value='nurse'>
                                                                Nurse
                                                            </MenuItem>
                                                            <MenuItem value='data_capturer'>
                                                                Data Capturer
                                                            </MenuItem>
                                                            <MenuItem value='data_quality_officer'>
                                                                Data Quality
                                                                Officer
                                                            </MenuItem>
                                                            <MenuItem value='site_administrator'>
                                                                {" "}
                                                                Site
                                                                Administrator
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {/* </Row> */}
                                                </>
                                            ) : (
                                                <>
                                                    <FormControl
                                                        variant='outlined'
                                                        margin='normal'
                                                        className={
                                                            props.classes
                                                                .formControl
                                                        }
                                                        fullWidth
                                                    >
                                                        <InputLabel
                                                            style={{
                                                                backgroundColor:
                                                                    "#17A3AB",
                                                            }}
                                                        >
                                                            Data Access Group
                                                        </InputLabel>
                                                        <Select
                                                            // className="form-control-alternative"
                                                            // type="select"
                                                            disabled
                                                            onChange={
                                                                handleChange
                                                            }
                                                            defaultValue={
                                                                userToRegister.data_access_group
                                                            }
                                                            name='data_access_group'
                                                        >
                                                            <MenuItem value=''>
                                                                --Select Access
                                                                Group--
                                                            </MenuItem>
                                                            <MenuItem value='tg_buffalo_city'>
                                                                Buffalo City
                                                                Metropolitan
                                                                Municipality{" "}
                                                            </MenuItem>
                                                            <MenuItem value='tg_cpt'>
                                                                City of Cape
                                                                Town
                                                                Metropolitan
                                                                Municipality{" "}
                                                            </MenuItem>
                                                            <MenuItem value='johannesburg'>
                                                                City of
                                                                Johannesburg
                                                                Metropolitan
                                                                Municipality{" "}
                                                            </MenuItem>
                                                            <MenuItem value='oasis_johannesburg'>
                                                                City of
                                                                Johannesburg
                                                                Metropolitan
                                                                Municipality
                                                                OASIS{" "}
                                                            </MenuItem>
                                                            <MenuItem value='phru_johannesburg'>
                                                                City of
                                                                Johannesburg
                                                                Metropolitan
                                                                Municipality
                                                                PHRU{" "}
                                                            </MenuItem>
                                                            <MenuItem value='tshwane'>
                                                                City of Tshwane
                                                                Metropolitan
                                                                Municipality{" "}
                                                            </MenuItem>
                                                            <MenuItem value='ekurhuleni'>
                                                                Ekurhuleni
                                                                Metropolitan
                                                                Municipality{" "}
                                                            </MenuItem>
                                                            {/* <MenuItem value="10">Ekurhuleni Metropolitan Municipality CPC  */}
                                                            <MenuItem value='tg_mandelabay'>
                                                                Nelson Mandela
                                                                Bay Municipality{" "}
                                                            </MenuItem>
                                                            <MenuItem value='vhembe'>
                                                                Vhembe District
                                                                Municipality
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {/* </Row>
          <Row xs="12"> */}

                                                    <FormControl
                                                        variant='outlined'
                                                        margin='normal'
                                                        onChange={handleChange}
                                                        className={
                                                            props.classes
                                                                .formControl
                                                        }
                                                        fullWidth
                                                    >
                                                        <InputLabel
                                                            style={{
                                                                backgroundColor:
                                                                    "#17A3AB",
                                                            }}
                                                        >
                                                            Role
                                                        </InputLabel>
                                                        <Select
                                                            // type="select"
                                                            disabled
                                                            name='role'
                                                            defaultValue={
                                                                userToRegister.role
                                                            }
                                                        >
                                                            <MenuItem value=''>
                                                                --SElect your
                                                                Role--
                                                            </MenuItem>
                                                            <MenuItem value='peer_educator'>
                                                                Peer Educator
                                                            </MenuItem>
                                                            <MenuItem value='nurse'>
                                                                Nurse
                                                            </MenuItem>
                                                            <MenuItem value='data_capturer'>
                                                                Data Capturer
                                                            </MenuItem>
                                                            <MenuItem value='data_quality_officer'>
                                                                Data Quality
                                                                Officer
                                                            </MenuItem>
                                                            <MenuItem value='site_administrator'>
                                                                {" "}
                                                                Site
                                                                Administrator
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </>
                                            )}
                                        </>

                                        <Button
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            // disabled={state.password.length < 5 }
                                            className={props.classes.submit}
                                            onClick={registerUser}
                                            // on
                                        >
                                            Register
                                        </Button>
                                    </>
                                </Form>
                            ) : null}
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-10 col-sm-12 extra-view'>
                        <div className='content'>
                            <img src={right_container_img} alt='' />
                            <img
                                src={lwazi_large_text}
                                className='largetext'
                                alt=''
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
const styles = (theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(8),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    });
export default withAuthState(withRouter(withStyles(styles)(Register)));
