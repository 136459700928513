import { createStandardAction } from 'typesafe-actions';
import { User } from 'firebase';
// import { IPatient } from '../../../@types/patient';

const FETCH_OUTREACH = 'outreach/FETCH_OUTREACH';
const SET_OUTREACH = 'outreach/SET_OUTREACH';
const SET_OUTREACH_ALL = 'outreach/SET_OUTREACH_ALL';




export const fetchOutreach = createStandardAction(FETCH_OUTREACH)();

export interface ISetOutreachPayload {
  outreach: any;
}
export const setOutreach = createStandardAction(SET_OUTREACH)<
  ISetOutreachPayload
>();


export interface ISetOutreachAllPayload {
  outreach_all: any;
}
export const setOutreachAll = createStandardAction(SET_OUTREACH_ALL)<
  ISetOutreachAllPayload
>();