


export function getDifference (a: any, b: any) {
return Object.fromEntries(Object.entries(b).filter(([key, val]) => key in a && a[key] !== val));
} 

export const district_check_list = 
[{
district_act_val: "tg_buffalo_city",
key_value: 1,
label_value: "Buffalo City Metropolitan Municipality"
},
{
district_act_val: "tg_cpt",
key_value: 2,
label_value: "City of Cape Town Metropolitan Municipality"
},
{
district_act_val: "johannesburg",
key_value: 3,
label_value: "City of Johannesburg Metropolitan Municipality" 
},
{
district_act_val: "oasis_johannesburg",
key_value: 9,
label_value: "City of Johannesburg Metropolitan Municipality OASIS" 
},
{
district_act_val: "phru_johannesburg",
key_value: 8,
label_value: "City of Johannesburg Metropolitan Municipality PHRU" 
},
{
district_act_val: "tshwane",
key_value: 4,
label_value: "City of Tshwane Metropolitan Municipality"
},
{
district_act_val: "ekurhuleni",
key_value: 5,
label_value: "Ekurhuleni"
},
// {
// district_act_val: "ek",
// key_value: 10,
// label_value: "Ekurhuleni Metropolitan Municipality CPC"
// },
{
district_act_val: "tg_mandelabay",
key_value: 6,
label_value: "Nelson Mandela Bay Municipality"
},
{
district_act_val: "vhembe",
key_value: 7,
label_value: "Vhembe District Municipality"
}
];