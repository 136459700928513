import {
    Badge,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Col,
    Button,
} from "reactstrap";
import React from "react";
import ExportManager from "./ExportManager";
import PrepCheck from "./PrepCheck";
import withPrepState, { PrepProps } from "../Enhanchers/withPrepState";

// import {Button} from "@material-ui/core" ;
import AddIcon from "@material-ui/icons/Add";
import { withRouter, RouteComponentProps } from "react-router-dom";

require("react-bootstrap-table-next/dist/react-bootstrap-table2.min.css");
// eslint-disable-next-line import/first
import PrepManagerModal from "./PrepManagerModal";

// eslint-disable-next-line import/first
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

// import paginationFactory from "react-bootstrap-table2-paginator";
// eslint-disable-next-line import/first
import BootstrapTable from "react-bootstrap-table-next";
// eslint-disable-next-line import/first
import paginationFactory from "react-bootstrap-table2-paginator";

// eslint-disable-next-line import/first
import ToolkitProvider, {
    Search,
    CSVExport,
} from "react-bootstrap-table2-toolkit";

// eslint-disable-next-line import/first
import moment from "moment";
// eslint-disable-next-line import/first
import { IOutreach } from "../@types/outreach";

interface IProps {
    list: any;
    onRowSelect?: any;
    onEdit?: any;
}

interface IState {
    selected: any[];
    exportManager: boolean;
}
type Props = IProps & RouteComponentProps & PrepProps;

class PrepTable extends React.Component<Props> {
    state = {
        prepCheck: false,
        selected: [],
        exportManager: false,
    };

    togglePrepCheck = () => {
        this.setState({
            prepCheck: !this.state.prepCheck,
        });
    };

    toggleExportManager = (toggle?: boolean) => {
        if (toggle) {
            this.setState({
                exportManager: toggle,
            });
        } else {
            this.setState({
                exportManager: !this.state.exportManager,
            });
        }
    };

    handleOnSelect = (row: any, isSelect: any) => {
        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row.uid],
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter((x) => x !== row.uid),
            }));
        }
    };
    handleOnSelectAll = (isSelect: any, rows: any) => {
        // console.log(isSelect, rows);
        const ids = rows.map((r: any) => r.uid);
        if (isSelect) {
            this.setState(() => ({
                selected: ids,
            }));
        } else {
            this.setState(() => ({
                selected: [],
            }));
        }
    };
    exportCSV = () => {
        console.log("exporting csv");
        let csvContent = "data:text/csv;charset=utf-8,";
        var pat_k: any[] = [];
        if (this.state.selected.length > 0) {
            pat_k = this.state.selected;
            var csv_data = this.props.list.filter((i: any) => {
                // console.log(i);
                return pat_k.includes(i.uid);
            });
            console.log(csv_data);
            var data: any, filename: any, link: any;
            var csv = this.convertArrayOfObjectsToCSV({
                data: csv_data,
            });
            if (csv == null) return;

            filename = "export.csv";

            if (!csv.match(/^data:text\/csv/i)) {
                csv = "data:text/csv;charset=utf-8," + csv;
            }
            data = encodeURI(csv);

            link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", filename);
            link.click();
        }
    };

    convertArrayOfObjectsToCSV = (args: any) => {
        var result: any,
            ctr: any,
            keys: any,
            columnDelimiter: any,
            lineDelimiter: any,
            data: any;

        data = args.data || null;
        if (data == null || !data.length) {
            return null;
        }
        console.log(data);

        columnDelimiter = args.columnDelimiter || ",";
        lineDelimiter = args.lineDelimiter || "\n";

        keys = Object.keys(data[0]);

        result = "";
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach((item: any) => {
            ctr = 0;
            keys.forEach((key: any) => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    getFormattedData() {
        var formattedCSVData: any = [];

        this.props.list.map((item: any, index: any) => {
            console.log(item);
            console.log(item.monitor_history);
            let prepItem: any;
            item.initiation_history.map(
                (initiation_item: any, initiation_index: any) => {
                    if (item.monitor_history[0]) {
                        item.monitor_history.map(
                            (monitor_item: any, monitor_index: any) => {
                                prepItem = {
                                    uid: item.uid,
                                    address_prep:
                                        item.patient_info.address_prep,
                                    birthdate_prep:
                                        item.patient_info.birthdate_prep,
                                    contact_number_prep:
                                        item.patient_info.contact_number_prep,
                                    first_name_prep:
                                        item.patient_info.first_name_prep,
                                    folder_number_prep:
                                        item.patient_info.folder_number_prep,
                                    gender_prep: item.patient_info.gender_prep,
                                    patient_form_complete:
                                        item.patient_info.patient_form_complete,
                                    patient_uid: item.patient_info.patient_uid,
                                    record_id: item.patient_info.record_id,
                                    surname_prep:
                                        item.patient_info.surname_prep,
                                    comment: item.comment,
                                    date_capture_id: item.date_capture_id,
                                    date_of_capture_id: item.date_of_capture_id,
                                    follow_up_date: item.follow_up_date,
                                    follow_up_status: item.follow_up_status,
                                    form_complete: item.form_complete,
                                    initiation_date: item.initiation_date,
                                    initiation_status: item.initiation_status,
                                    prep_clinic: item.prep_clinic,
                                    prep_status: item.prep_status,
                                    initiation_record_number:
                                        initiation_index + 1,
                                    init_date_prep:
                                        initiation_item.init_date_prep,
                                    hiv_test_results:
                                        initiation_item.hiv_test_results,
                                    weight_results:
                                        initiation_item.weight_results,
                                    pregnancy_results:
                                        initiation_item.pregnancy_results,
                                    heb_b_results:
                                        initiation_item.heb_b_results,
                                    decide_to_initiate_results:
                                        initiation_item.decide_to_initiate_results,
                                    proceed_screening:
                                        initiation_item.proceed_screening,
                                    prep_counseling_conducted:
                                        initiation_item.prep_counseling_conducted,
                                    creatine_results:
                                        initiation_item.creatine_results,
                                    month_outcome_initiation:
                                        initiation_item.month_outcome_monitor,
                                    sti_results: initiation_item.sti_results,

                                    monitor_record_number: monitor_index + 1,
                                    actual_captured_date_monitor: item
                                        .monitor_history[monitor_index]
                                        ? item.monitor_history[monitor_index]
                                              .actual_captured_date
                                        : "",
                                    actual_visit_date_monitor: item
                                        .monitor_history[monitor_index]
                                        ? item.monitor_history[monitor_index]
                                              .actual_visit_date
                                        : "",
                                    creatine_results_monitor: item
                                        .monitor_history[monitor_index]
                                        ? item.monitor_history[monitor_index]
                                              .creatine_results_monitor
                                        : "",
                                    hiv_test_results_monitor: item
                                        .monitor_history[monitor_index]
                                        ? item.monitor_history[monitor_index]
                                              .hiv_test_results_monitor
                                        : "",
                                    meds_dispense: item.monitor_history[
                                        monitor_index
                                    ]
                                        ? item.monitor_history[monitor_index]
                                              .meds_dispense
                                        : "",
                                    month_outcome_monitor: item.monitor_history[
                                        monitor_index
                                    ]
                                        ? item.monitor_history[monitor_index]
                                              .month_outcome_monitor
                                        : "",
                                    next_visit_date: item.monitor_history[
                                        monitor_index
                                    ]
                                        ? item.monitor_history.next_visit_date
                                        : "",
                                    outcome_monitor: item.monitor_history[
                                        monitor_index
                                    ]
                                        ? item.monitor_history[monitor_index]
                                              .outcome_monitor
                                        : "",
                                    pregnancy_results_monitor: item
                                        .monitor_history[monitor_index]
                                        ? item.monitor_history[monitor_index]
                                              .pregnancy_results_monitor
                                        : "",
                                    staying_on_prep: item.monitor_history[
                                        monitor_index
                                    ]
                                        ? item.monitor_history[monitor_index]
                                              .staying_on_prep
                                        : "",
                                    sti_results_monitor: item.monitor_history[
                                        monitor_index
                                    ]
                                        ? item.monitor_history[monitor_index]
                                              .sti_results_monitor
                                        : "",
                                    weight_results_monitor: item
                                        .monitor_history[monitor_index]
                                        ? item.monitor_history[monitor_index]
                                              .weight_results_monitor
                                        : "",
                                };
                            }
                        );
                    } else {
                        prepItem = {
                            uid: item.uid,
                            address_prep: item.patient_info.address_prep,
                            birthdate_prep: item.patient_info.birthdate_prep,
                            contact_number_prep:
                                item.patient_info.contact_number_prep,
                            first_name_prep: item.patient_info.first_name_prep,
                            folder_number_prep:
                                item.patient_info.folder_number_prep,
                            gender_prep: item.patient_info.gender_prep,
                            patient_form_complete:
                                item.patient_info.patient_form_complete,
                            patient_uid: item.patient_info.patient_uid,
                            record_id: item.patient_info.record_id,
                            surname_prep: item.patient_info.surname_prep,
                            comment: item.comment,
                            date_capture_id: item.date_capture_id,
                            date_of_capture_id: item.date_of_capture_id,
                            follow_up_date: item.follow_up_date,
                            follow_up_status: item.follow_up_status,
                            form_complete: item.form_complete,
                            initiation_date: item.initiation_date,
                            initiation_status: item.initiation_status,
                            prep_clinic: item.prep_clinic,
                            prep_status: item.prep_status,
                            initiation_record_number: initiation_index + 1,
                            init_date_prep: initiation_item.init_date_prep,
                            hiv_test_results: initiation_item.hiv_test_results,
                            weight_results: initiation_item.weight_results,
                            pregnancy_results:
                                initiation_item.pregnancy_results,
                            heb_b_results: initiation_item.heb_b_results,
                            decide_to_initiate_results:
                                initiation_item.decide_to_initiate_results,
                            proceed_screening:
                                initiation_item.proceed_screening,
                            prep_counseling_conducted:
                                initiation_item.prep_counseling_conducted,
                            creatine_results: initiation_item.creatine_results,
                            month_outcome_initiation:
                                initiation_item.month_outcome_monitor,
                            sti_results: initiation_item.sti_results,
                        };
                    }

                    formattedCSVData.push(prepItem);
                }
            );
        });
        return formattedCSVData;
    }

    render() {
        const selectRow = {
            mode: "checkbox",
            clickToSelect: true,
        };
        console.log(this.props.list);

        const pagination = paginationFactory({
            page: 1,
            alwaysShowAllBtns: true,
            showTotal: false,
            withFirstAndLast: false,
            sizePerPageRenderer: ({ options, onSizePerPageChange }: any) => (
                <>
                    <section
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "brandon-grotesque",
                                fontWeight: 600,
                                fontSize: "0.813rem",
                                color: "#143840",
                                textTransform: "uppercase",
                                marginRight: 10,
                                marginTop: 15,
                            }}
                        >
                            Showing
                        </p>
                        <select
                            style={{
                                width: 80,
                                height: 40,
                                paddingLeft: 10,
                                paddingRight: 10,
                                borderRadius: 3,
                                color: "#143840",
                                fontSize: "0.75rem",
                            }}
                            name='datatable-basic_length'
                            aria-controls='datatable-basic'
                            className='form-control'
                            onChange={(e) =>
                                onSizePerPageChange(e.target.value)
                            }
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                        </select>
                    </section>
                </>
            ),
        });

        const { SearchBar } = Search;
        const outcome_d: any = {
            "1": "Continue",
            "2": "Disc1",

            "3": "Disc2",

            "4": "DNA",

            "5": "LTFU",

            "9": "Restart",

            "6": "RIP",
            "7": "Sero",

            "10": "TFI",
            "8": "TFO",
        };

        const columns = [
            {
                dataField: "follow_up_status",
                text: "Current Prep Status",
                sort: true,
                formatter: (cell: any, row: any, rowIndex: number) => {
                    if (cell === 1) {
                        return "Initiation";
                    } else if (cell > 1) {
                        return "Prep Follow Up " + cell;
                    } else {
                        return "none";
                    }
                },
            },
            {
                dataField: "prep_status",
                text: "Outcome Status",
                sort: true,
                formatter: (cell: any, row: any, rowIndex: number) => {
                    if (cell.length > 0) {
                        return (
                            <span className='badge badge-pill badge-prep-outcome'>
                                {" "}
                                {outcome_d[cell]}
                            </span>
                        );
                    } else {
                        return "DNE";
                    }
                },
            },
            {
                dataField: "patient_info",
                text: "Patient",
                sort: true,
                formatter: (cell: any, row: any, rowIndex: number) => {
                    return cell.first_name_prep + " " + cell.surname_prep;
                },
            },
            {
                dataField: "initiation_date",
                text: "Initiation Date",
                sort: true,
            },
            {
                dataField: "follow_up_date",
                text: "Follow-Up Date",
                sort: true,
                formatter: (cell: any, row: any, rowIndex: number) => {
                    if (cell.length > 0) {
                        return moment(cell).format("YYYY-MM-DD");
                    } else {
                        return "no date";
                    }
                },
            },
            {
                dataField: "form_complete",
                text: "Form Status",
                sort: true,
                formatter: (cell: any, row: any, rowIndex: number) => {
                    if (cell === 2) {
                        return (
                            <>
                                <span className='badge badge-pill badge-complete'>
                                    Complete
                                </span>
                            </>
                        );
                    } else if (cell === 1) {
                        return (
                            <>
                                <span className='badge badge-pill badge-incomplete'>
                                    Incomplete
                                </span>
                            </>
                        );
                    } else if (cell === 0) {
                        return (
                            <>
                                <span className='badge badge-pill badge-review'>
                                    Review
                                </span>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <span className='badge badge-pill badge-amended'>
                                    Amended
                                </span>
                            </>
                        );
                    }
                },
            },
            {
                dataField: "",
                text: "Actions",
                formatter: (cell: any, row: any) => {
                    // console.log(cell, row)
                    return (
                        <Button
                            className='btn-primary'
                            type='button'
                            onClick={() => {
                                this.props.history.push(
                                    "/prep-view?ref=prep_id&id=" + row.uid
                                );
                            }}
                        >
                            View / Edit
                        </Button>
                    );
                },
            },
        ];

        return (
            <>
                <ExportManager
                    isOpen={this.state.exportManager}
                    list={this.getFormattedData()}
                    togglefunct={this.toggleExportManager}
                />
                <ToolkitProvider
                    data={this.props.list}
                    keyField='id'
                    columns={columns}
                    bootstrap4={true}
                    search={{ searchFormatted: true }}
                    // exportCSV
                >
                    {(props: any) => (
                        <div>
                            <Container fluid style={{ marginTop: 30 }}>
                                {this.state.prepCheck ? (
                                    <PrepCheck
                                        isOpen={this.state.prepCheck}
                                        togglefunct={this.togglePrepCheck}
                                        _isPrepCheck={false}
                                    />
                                ) : null}
                                <Row>
                                    <Col>
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <h1>PrEP</h1>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Button
                                                    className='menu-btn'
                                                    style={{
                                                        borderColor: "#178CCC",
                                                        backgroundColor:
                                                            "#178CCC",
                                                    }}
                                                    type='button'
                                                    onClick={() => {
                                                        this.togglePrepCheck();
                                                    }}
                                                >
                                                    <span className='btn-inner--icon'>
                                                        <i className='far fa-plus-square fa-2x' />
                                                    </span>
                                                    <span className='btn-inner--text ml-1'>
                                                        Prep Check
                                                    </span>
                                                </Button>
                                                <Button
                                                    className='menu-btn'
                                                    style={{
                                                        borderColor: "#17A3AB",
                                                        backgroundColor:
                                                            "#17A3AB",
                                                    }}
                                                    type='button'
                                                    onClick={() =>
                                                        this.toggleExportManager(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <span className='btn-inner--icon'>
                                                        <i className='fas fa-print fa-2x' />
                                                    </span>
                                                    <span className='btn-inner--text ml-1'>
                                                        CSV Export
                                                    </span>
                                                </Button>
                                                <SearchBar
                                                    className='menu-searchbar'
                                                    {...props.searchProps}
                                                    delay={0}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row xs='12'>
                                    <Col>
                                        <hr />
                                    </Col>
                                </Row>
                            </Container>
                            {/* <Container fluid style={{ marginTop: 30 }}>
          {this.state.prepCheck ? (
            <PrepManagerModal
              isOpen={this.state.prepCheck}
              togglefunct={this.togglePrepCheck}
              _isPrepCheck={false}
            />
          ) : null}
          <Row>
            <Col>
              <div
                style={{
                  width: '100%',
                  height: 'auto',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h1>Prep</h1>
                <div style={{ display: 'flex', marginRight: 60 }}>
                  <Button
                    className='menu-btn'
                    style={{
                      borderColor: '#178CCC',
                      backgroundColor: '#178CCC',
                    }}
                    onClick={() => {
                      this.togglePrepCheck()
                    }}
                  >
                    <span className='btn-inner--icon'>
                      <i className='far fa-plus-square fa-2x' />
                    </span>
                    <span className='btn-inner--text'>Prep Check</span>
                  </Button>

                <SearchBar style={{
                      width: 330,
                      height: 50,
                      marginRight: 30,
                      backgroundColor: '#fff',
                      border: '1px solid #B3B8BF',
                      borderRadius: 3,
                    }}
                   {...props.searchProps} delay={0} />

              </div>
              </div>
            </Col>
          </Row>

          <Row xs='12'>
            <Col>
              <hr />
            </Col>
          </Row>
        </Container> */}
                            <Row className='text-right'>
                                <Col xs='12'>
                                    <div className='float-right  col-md-6'></div>
                                    {/* <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton> */}
                                </Col>
                            </Row>
                            <BootstrapTable
                                style={{ marginBottom: 5 }}
                                {...props.baseProps}
                                //  rowEvents={this.props.onRowSelect}
                                bordered={false}
                                pagination={pagination}
                                // selectRow={ selectRow }
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </>
        );
    }
}

export default withPrepState(withRouter(PrepTable));
