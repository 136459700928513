import {
    Badge,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Col,
    Button,
} from "reactstrap";
import React, { useState } from "react";
// import {Button} from "@material-ui/core" ;
import AddIcon from "@material-ui/icons/Add";
import { withRouter, RouteComponentProps } from "react-router-dom";

// eslint-disable-next-line import/first
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import view_arrow from "../Assets/img/icons/common/view-arrow.png";

// import paginationFactory from "react-bootstrap-table2-paginator";
// eslint-disable-next-line import/first
import BootstrapTable from "react-bootstrap-table-next";
// eslint-disable-next-line import/first
import paginationFactory from "react-bootstrap-table2-paginator";

// eslint-disable-next-line import/first
import ToolkitProvider, {
    Search,
    CSVExport,
} from "react-bootstrap-table2-toolkit";

// eslint-disable-next-line import/first
import moment from "moment";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import { lowerCase } from "lodash";
import ExportManager from "./ExportManager";
import { toCamelCaseString } from "../utils/text_util";
import withPatientState, { PatientProps } from "../Enhanchers/withPatientState";
// require('react-bootstrap-table-next/dist/react-bootstrap-table2.min.css');

interface IProps {
    list: any;
    onRowSelect?: any;
    onEdit?: any;
}

interface IState {
    selected: any[];
    exportManager: boolean;
}

type Props = IProps & RouteComponentProps & AuthProps & PatientProps;

class ViewPatientTable extends React.Component<Props, IState> {
    state = {
        selected: [],
        exportManager: false,
    };

    toggleExportManager = (toggle?: boolean) => {
        if (toggle) {
            this.setState({
                exportManager: toggle,
            });
        } else {
            this.setState({
                exportManager: !this.state.exportManager,
            });
        }
    };

    handleOnSelect = (row: any, isSelect: any) => {
        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row.uid],
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter((x) => x !== row.uid),
            }));
        }
    };
    handleOnSelectAll = (isSelect: any, rows: any) => {
        // console.log(isSelect, rows);
        const ids = rows.map((r: any) => r.uid);
        if (isSelect) {
            this.setState(() => ({
                selected: ids,
            }));
        } else {
            this.setState(() => ({
                selected: [],
            }));
        }
    };

    onRowSelect = (patient: any) => {
        //console.log(patient, index);

        if (patient.client_id_complete !== 0) {
            this.props.history.push({
                pathname: "/patient/" + patient.uid,
                state: patient,
            });
        } else {
            //  console.log("review")
        }
    };

    onEdit = (patient: any) => {
        //console.log(patient, index);

        if (this.props.auth.user.role_access !== 1) {
            this.props.history.push({
                pathname: "/patient-edit/" + patient.uid,
                state: patient,
            });
        } else {
            //  console.log("review");
            if (
                patient.client_id_complete === 0 ||
                patient.client_id_complete === 1
            ) {
                this.props.history.push({
                    pathname: "/patient-review/" + patient.uid,
                    state: patient,
                });
            } else {
                this.props.history.push({
                    pathname: "/patient-edit/" + patient.uid,
                    state: patient,
                });
            }
        }
    };
    exportCSV = () => {
        console.log("exporting csv");
        let csvContent = "data:text/csv;charset=utf-8,";
        var pat_k: any[] = [];
        if (this.state.selected.length > 0) {
            pat_k = this.state.selected;
            var csv_data = this.props.patients.patients.filter((i: any) => {
                // console.log(i);
                return pat_k.includes(i.uid);
            });
            console.log(csv_data);
            var data: any, filename: any, link: any;
            var csv = this.convertArrayOfObjectsToCSV({
                data: csv_data,
            });
            if (csv == null) return;

            filename = "export.csv";

            if (!csv.match(/^data:text\/csv/i)) {
                csv = "data:text/csv;charset=utf-8," + csv;
            }
            data = encodeURI(csv);

            link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", filename);
            link.click();
        }
    };

    convertArrayOfObjectsToCSV = (args: any) => {
        var result: any,
            ctr: any,
            keys: any,
            columnDelimiter: any,
            lineDelimiter: any,
            data: any;

        data = args.data || null;
        if (data == null || !data.length) {
            return null;
        }

        columnDelimiter = args.columnDelimiter || ",";
        lineDelimiter = args.lineDelimiter || "\n";

        keys = Object.keys(data[0]);

        result = "";
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach((item: any) => {
            ctr = 0;
            keys.forEach((key: any) => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    render() {
        const defaultSorted = [
            {
                dataField: "date_of_capture_id",
                order: "desc",
            },
        ];
        // const { ExportCSVButton } = CSVExport;
        const selectRow = {
            mode: "checkbox",
            clickToSelect: true,
            selected: this.state.selected,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll,
        };

        const pagination = paginationFactory({
            page: 1,
            alwaysShowAllBtns: true,
            showTotal: false,
            withFirstAndLast: false,
            sizePerPageRenderer: ({ options, onSizePerPageChange }) => (
                <>
                    <section
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "brandon-grotesque",
                                fontWeight: 600,
                                fontSize: "0.813rem",
                                color: "#143840",
                                textTransform: "uppercase",
                                marginRight: 10,
                                marginTop: 15,
                            }}
                        >
                            Showing
                        </p>
                        <select
                            style={{
                                width: 80,
                                height: 40,
                                paddingLeft: 10,
                                paddingRight: 10,
                                borderRadius: 3,
                                color: "#143840",
                                fontSize: "0.75rem",
                            }}
                            name='datatable-basic_length'
                            aria-controls='datatable-basic'
                            className='form-control'
                            onChange={(e) =>
                                onSizePerPageChange(e.target.value)
                            }
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                        </select>
                    </section>
                </>
            ),
        });

        const { SearchBar } = Search;

        const columns = [
            {
                dataField: "record_id",
                text: "Table",
                formatter: (cell: any, row: any) => {
                    if (row.form !== "patient: outreach/group-activity") {
                        return <a className='text-success '>Patient</a>;
                    } else {
                        return (
                            <a className='text-warning '>
                                Patient: outreach/group-activity
                            </a>
                        );
                    }
                },
            },
            // {
            //   dataField: 'record_id',
            //   text: 'Record ID',
            //   sort: true,
            //   formatter: (cell: any, row: any) => {
            //     if (cell !== undefined) {
            //       return cell
            //     } else {
            //       return 'NRR'
            //     }
            //   },
            // },
            // {
            //   dataField: 'lwazi_status',
            //   text: 'RedCap Status',
            //   formatter: (cell: any, row: any) => {
            //     var cell_ = '' + cell
            //     //  console.log(cell_);
            //     if (cell_.includes('done')) {
            //       return (
            //         <i className='fa text-success fa-check-circle fa-lg little-text'></i>
            //       )
            //     }
            //     if (cell_.includes('new')) {
            //       return (
            //         <i className='fa text-info fa-external-link-square fa-lg little-text'></i>
            //       )
            //     }
            //     if (cell_.includes('review')) {
            //       return (
            //         <i className='fa text-warning fa-circle-o-notch fa-lg little-text'></i>
            //       )
            //     }
            //     if (cell_.includes('error')) {
            //       return <i className='fa text-danger fa-times fa-lg little-text'></i>
            //     }
            //     if (cell_.includes('updated')) {
            //       return (
            //         <i className='fa text-warning fa-external-link-square fa-lg little-text'></i>
            //       )
            //     } else {
            //       return <i className='fa fa-ban fa-lg text-default'></i>
            //     }

            //     // return "i"
            //   },
            // },
            // {
            //   dataField: 'clinic_folder_number_id',
            //   text: 'Clinic Folder ID',
            //   sort: true,
            // },

            {
                dataField: "first_name",
                text: "Full Name ",
                sort: true,
                formatter: (cell: any, row: any) => {
                    // console.log(cell);
                    if (cell !== null) {
                        if (row.pref_name_id !== undefined) {
                            return cell + "  " + row.pref_name_id;
                        } else {
                            return cell;
                        }
                    } else {
                        return "no name";
                    }
                },
            },
            // {
            //   dataField: "pref_name_id",
            //  text: "Preferred Name",
            //     sort: true,
            //     formatter: (cell:any, row:any) => camelizeText(lowerCase(cell)),

            // },
            {
                dataField: "surname",
                text: "Legal Surname",
                sort: true,
                formatter: (cell: any, row: any) => {
                    if (cell !== null) {
                        return cell;
                    } else {
                        return "no surname";
                    }
                },
            },
            // {
            //   dataField: 'birthdate_id',
            //   text: 'D.O.B',
            //   sort: true,
            //   formatter: (cell:any) =>{
            //     if (cell !== 0) {
            //       // var split_date_and_time = cell.split(" ");
            //       // var new_date = new Date(split_date_and_time[0]);
            //       var _return = moment(cell).format('DD/MM/YYYY')
            //       return <a>{_return}</a>
            //     } else {
            //       return 'no date saved'
            //     }
            //   },
            // },

            // {
            //   dataField: 'contact_number_id',
            //   text: 'Contact #',
            //   sort: true,
            //   formatter: (cell: any) => {
            //     if (cell != 'undefined') {
            //       var c_ = '' + cell
            //       if (c_.includes('/')) {
            //         return (
            //           <a>
            //             {cell.split('/')[0]}
            //             <span className='text-red'>+</span>
            //           </a>
            //         )
            //       } else {
            //         return <a>{cell}</a>
            //       }
            //     } else {
            //       return <a>-number not found-</a>
            //     }
            //   },
            // },
            // {
            //   dataField: 'date_of_capture_id',
            //   text: 'First Seen',
            //   sort: true,
            //   formatter: (cell: any) => {
            //     if (cell !== 0) {
            //       // var split_date_and_time = cell.split(" ");
            //       // var new_date = new Date(split_date_and_time[0]);
            //       var _return = moment(cell).format('DD/MM/YYYY')
            //       return <a>{_return}</a>
            //     } else {
            //       return 'no date saved'
            //     }
            //   },
            // },
            {
                dataField: "form_status",
                text: "Status",
                sort: true,
                formatter: (cell: any) => {
                    if (cell === 2) {
                        return (
                            <>
                                <span className='badge badge-pill badge-complete'>
                                    Complete
                                </span>
                            </>
                        );
                    } else if (cell === 1) {
                        return (
                            <>
                                <span className='badge badge-pill badge-incomplete'>
                                    Incomplete
                                </span>
                            </>
                        );
                    } else if (cell === 0) {
                        return (
                            <>
                                <span className='badge badge-pill badge-review'>
                                    Review
                                </span>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <span className='badge badge-pill badge-amended'>
                                    Amended
                                </span>
                            </>
                        );
                    }
                },
            },
            {
                dataField: "",
                text: "Actions",
                sort: false,
                formatter: (cell: any, row: any) => {
                    return (
                        <>
                            {row.client_id_complete === 2 ||
                            row.client_id_complete === 3 ? (
                                <>
                                    {this.props.auth.user.role_access === 0 ? (
                                        // nurse / peer educator

                                        <Row>
                                            <UncontrolledDropdown nav inNavbar>
                                                <DropdownToggle nav caret>
                                                    <i
                                                        className=' fa fa-arrow-circle-right fa-lg'
                                                        style={{
                                                            color: "#17a4ab",
                                                        }}
                                                    ></i>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <DropdownItem>
                                                        <Col lg='6'>
                                                            <Button
                                                                className='btn-primary'
                                                                type='button'
                                                                onClick={() => {
                                                                    this.onRowSelect(
                                                                        row
                                                                    );
                                                                }}
                                                            >
                                                                View
                                                            </Button>
                                                        </Col>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Col lg='6'>
                                                            <div
                                                                className='pull-right view-arrow'
                                                                onClick={() => {
                                                                    this.onEdit(
                                                                        row
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        view_arrow
                                                                    }
                                                                    alt='view'
                                                                />
                                                            </div>
                                                        </Col>
                                                    </DropdownItem>
                                                    {/* <DropdownItem divider />
                  <DropdownItem>
                    Reset
                  </DropdownItem> */}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </Row>
                                    ) : (
                                        // DC/SA /SM/DQO
                                        <Row>
                                            <UncontrolledDropdown nav inNavbar>
                                                <DropdownToggle nav caret>
                                                    <i
                                                        className=' fa fa-arrow-circle-right fa-lg'
                                                        style={{
                                                            color: "#17a4ab",
                                                        }}
                                                    ></i>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <DropdownItem>
                                                        <Col lg='6'>
                                                            <Button
                                                                className='btn-primary'
                                                                type='button'
                                                                onClick={() => {
                                                                    this.onRowSelect(
                                                                        row
                                                                    );
                                                                }}
                                                            >
                                                                View
                                                            </Button>
                                                        </Col>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Col lg='6'>
                                                            <div
                                                                className='pull-right view-arrow'
                                                                onClick={() => {
                                                                    this.onEdit(
                                                                        row
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        view_arrow
                                                                    }
                                                                    alt='view'
                                                                />
                                                            </div>
                                                        </Col>
                                                    </DropdownItem>
                                                    {/* <DropdownItem divider />
                  <DropdownItem>
                    Reset
                  </DropdownItem> */}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </Row>
                                    )}
                                </>
                            ) : (
                                // null
                                //Complete
                                <>
                                    {this.props.auth.user.role_access === 1 ? (
                                        <div>
                                            <Button
                                                className='btn-primary'
                                                type='button'
                                                onClick={() => {
                                                    this.onEdit(row);
                                                }}
                                            >
                                                Review
                                            </Button>
                                            {/* <div className="icon icon-shape bg-info text-white rounded-circle shadow"   onClick={()=>{this.props.onEdit(row)}}>
                <i className="fas fa-pencil-square"  />
            </div> */}
                                        </div>
                                    ) : (
                                        <div>
                                            <Button
                                                className='btn-primary'
                                                type='button'
                                                onClick={() => {
                                                    this.onEdit(row);
                                                }}
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    );
                },
            },

            // <i className="fas fa-check-square text-success" />
            //               ) : (
            //                 <i className="fas fa-window-close text-danger " />
        ];

        return (
            <>
                {this.props.patients.patients.length > 0 ? (
                    <>
                        <ExportManager
                            isOpen={this.state.exportManager}
                            list={this.props.list}
                            togglefunct={this.toggleExportManager}
                        />
                        <ToolkitProvider
                            data={this.props.list}
                            keyField='uid'
                            columns={columns}
                            bootstrap4={true}
                            search={{ searchFormatted: true }}
                            // exportCSV
                        >
                            {(props: any) => (
                                <div>
                                    <Row>
                                        <Col>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "auto",
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center",
                                                    marginLeft: 15,
                                                }}
                                            >
                                                <h1>Patients </h1>
                                            </div>
                                        </Col>
                                    </Row>
                                    <BootstrapTable
                                        style={{
                                            marginBottom: 5,
                                            marginTop: 5,
                                        }}
                                        {...props.baseProps}
                                        // rowEvents={this.props.onRowSelect}
                                        bordered={false}
                                        pagination={pagination}
                                        // selectRow={selectRow}
                                        defaultSorted={defaultSorted}
                                        noDataIndication={() => {
                                            return (
                                                <h1 className='display-3'>
                                                    {" "}
                                                    No Results Found
                                                </h1>
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    </>
                ) : null}
            </>
        );
    }
}

export default withPatientState(withAuthState(withRouter(ViewPatientTable)));
