
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ISetOutreachPayload,  setOutreach } from '../Store/modules/outreach/actions';
import { ISetOutreachAllPayload,  setOutreachAll } from '../Store/modules/outreach/actions';

import { IOutreachState as IOutreachReduxState } from '../Store/modules/outreach/reducer';
// import { IOutreachAllState as IOutreachAllReduxState } from '../Store/modules/outreach/reducer';

import { RootState } from '../@types/store';

const mapStateToProps = (state: RootState) => ({
  outreach: state.outreach,

});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setOutreach,
      setOutreachAll
      
    },
    dispatch
  );

interface IOutreachState {
  outreach: IOutreachReduxState;
}

export interface IOutreachDispatchProps {
  setOutreach: (payload: ISetOutreachPayload) => void;
  setOutreachAll: (payload: ISetOutreachAllPayload) => void;
}

export type OutreachProps = IOutreachState & IOutreachDispatchProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
