import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import * as actions from './actions';

export interface IOutDDState {
  readonly all_dd: any;
  readonly district_dd:any;
}

export type OutDDActions = ActionType<typeof actions>;

const initialState: IOutDDState = {
    all_dd:   [],
    district_dd: []
};

export default combineReducers<IOutDDState, OutDDActions>({
  all_dd: (state = initialState.all_dd, action) => {
    switch (action.type) {
      case getType(actions.setOutreachDD): {
        return action.payload.all_dd;
      }
      default: {
        return state;
      }
    }
  },
  district_dd: (state = initialState.district_dd, action) => {
    switch (action.type) {
      case getType(actions.setOutreachDistrictDD): {
        return action.payload.district_dd;
      }
      default: {
        return state;
      }
    }
  },

});
