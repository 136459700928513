import React from 'react'
import logo from '../logo.svg'
import '../App.css'
import TopNavbarMain from '../Components/TopNavbarMain'
import {
  CssBaseline,
  WithStyles,
  Toolbar,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  withStyles,
  Theme,
  createStyles,
  Button,
} from '@material-ui/core'
import MainLayout from '../Layouts/MainLayout'
import Paper from '@material-ui/core/Paper'
import MUIDataTable from 'mui-datatables'
import {
  Badge,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
} from 'reactstrap'
import { CardBody, CardTitle, Col, Card } from 'reactstrap'
import AddIcon from '@material-ui/icons/Add'

import Header from '../Components/Header'
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom'
import { IPatient } from '../@types/patient'
import firebase from '../Services/firebase'
import withAuthState, { AuthProps } from '../Enhanchers/withAuthState'
import PatientTable from '../Components/PatientTable'

import AsyncSelect from 'react-select/async'
import OutreachForm from '../Forms/OutreachForm'
import withOutreachDD, { OutreachDDProps } from '../Enhanchers/withOutreachDD'
import PrepCreateForm from '../Forms/PrepCreateForm'
import { IAppointment } from '../Pages/Appointments'
import PrepInitiationCreateForm from '../Forms/PrepInitiationCreateForm'

interface IState {
  loading: boolean
  apnt_: any
}

type Props = WithStyles<typeof styles> &
  RouteComponentProps &
  AuthProps &
  OutreachDDProps

class PrepInitiationCreate extends React.Component<Props, IState> {
  state = {
    loading: true,
    apnt_: {},
  }
  constructor(props: any) {
    super(props)
  }
  componentDidMount() {}

  render() {
    return (
      <MainLayout>
        {/* Prep Initiation Create Menu */}

        <Container fluid className='mt-4 mb-5'>
          <Row>
            <Col xs='1'>
              <Button
                style={{ backgroundColor: 'transparent', marginTop: 13 }}
                onClick={() => {
                  this.props.history.goBack()
                }}
              >
                <i
                  className='fa fa-arrow-left  ml-5'
                  style={{ fontSize: 20 }}
                />
              </Button>
            </Col>
            <Col xs='11'>
              <h1 className='pl-0'>Prep Initiation / Prep</h1>
            </Col>
          </Row>

          <Row xs='12'>
            <Col>
              <hr />
            </Col>
          </Row>

          {/* Page content */}
          <Container fluid>
            {/* <h1>Patients</h1> */}
            {/* <PrepInitiationCreateForm /> */}

            <Card className='bg-secondary shadow w-100'>
              <CardHeader className='bg-white border-0'>
                <h3>New Preceding PrEP Form</h3>
              </CardHeader>
              <CardBody>
                <PrepInitiationCreateForm />
              </CardBody>
            </Card>
          </Container>
        </Container>
      </MainLayout>
    )
  }
}
const styles = (theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    tableRow: {
      '&:hover': {
        borderLeft: '#17A3AB',
        borderLeftWidth: '10',
        borderLeftStyle: 'solid',
        boxShadow: '0 3px 5px 2px rgba(0,0,0,0.1)',
      },
    },
  })
export default withOutreachDD(
  withAuthState(withRouter(withStyles(styles)(PrepInitiationCreate)))
)
