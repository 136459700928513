import React from "react";
import "./App.css";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import Profile from "./Pages/Profile";
import Prep from "./Pages/Prep";
import prepCreate from "./Components/prepCreate";
import PushMessaging from "./Services/PushMessaging";
import { AuthProps } from "./Enhanchers/withAuthState";
import Dashboard from "./Pages/Dashboard";
import PrepMain from "./Pages/PrepMain";
import PrepView from "./Pages/PrepView";
import GroupActivityCreate from "./Pages/GroupActivityCreate";
import Landing from "./Pages/Landing";
import Outreach from "./Pages/Outreach";
import PatientView from "./Pages/PatientView";
import PatientCreate from "./Pages/PatientCreate";
import PatientEdit from "./Pages/PatientEdit";
import OutreachCreate from "./Pages/OutreachCreate";
import OutreachView from "./Pages/OutreachView";
import PatientReview from "./Pages/PatientReview";
import Appointments from "./Pages/Appointments";
import UserManagement from "./Pages/UserManagement";
import ActivityLog from "./Pages/ActivityLog";
import Home from "./Pages/Home";
import OutreachLwaziView from "./Pages/OutreachLwaziView";
import Settings from "./Pages/Settings";
import PatientPrintView from "./Pages/PatientPrintView";
import OutreachEdit from "./Pages/OutreachEdit";
import GroupActivityEdit from "./Pages/GroupActivityEdit";
import PrepViewLwazi from "./Pages/PrepViewLwazi";
import PrepInitiationCreate from "./Pages/PrepInitiationCreate";
import PrepMainViewForm from "./Pages/PrepMainViewForm";
import UserProfile from "./Pages/UserProfile";

function ProtectedRoute() {
    return (
        <>
            <Route exact path='/home' component={Dashboard} />
            <Route exact path='/patient' component={Home} />
            <Route exact path='/outreach' component={Outreach} />
            <Route exact path='/patient/:id' component={PatientView} />
            <Route exact path='/patient-create' component={PatientCreate} />
            <Route exact path='/patient-edit/:id' component={PatientEdit} />
            <Route exact path='/patient-review/:id' component={PatientReview} />
            <Route
                exact
                path='/patient-print/:id'
                component={PatientPrintView}
            />

            {/* Outreach */}
            <Route exact path='/outreach-create' component={OutreachCreate} />
            <Route
                exact
                path='/group-activity-create'
                component={GroupActivityCreate}
            />

            <Route
                exact
                path='/outreach-view/:id'
                component={OutreachLwaziView}
            />
            <Route exact path='/outreach-edit/:id' component={OutreachEdit} />
            <Route
                exact
                path='/group-activity-edit/:id'
                component={GroupActivityEdit}
            />

            {/* Appointments  */}
            <Route exact path='/appointments' component={Appointments} />

            {/* PREP */}
            <Route exact path='/prep' component={Prep} />
            <Route exact path='/prep-create' component={PrepInitiationCreate} />
            <Route exact path='/prep-view' component={PrepMainViewForm} />
            <Route exact path='/prep-view-lwazi' component={PrepViewLwazi} />
            <Route exact path='/prep-main' component={PrepMain} />

            {/* Auth Users */}
            <Route exact path='/user-management' component={UserManagement} />
            <Route
                exact
                path='/user-management/profile/:id'
                component={UserProfile}
            />
            <Route exact path='/profile' component={Profile} />
            <Route exact path='/settings' component={Settings} />
            <Route exact path='/activity-log' component={ActivityLog} />
        </>
    );
}
export default ProtectedRoute;
