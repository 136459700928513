import FullCalendar from "@fullcalendar/react";
import React, { useRef, useEffect, useState } from "react";
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Modal,
    Row,
    Container,
    Col,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    InputGroupAddon,
    InputGroup,
    InputGroupText,
} from "reactstrap";
import editBtn from "../Assets/img/icons/common/edit-btn.svg";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment, { Moment } from "moment";
import { IAppointment } from "./Appointments";
import firebase from "../Services/firebase";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import AppointmentTable from "../Components/AppointmentTable";
import withAppointmentState, {
    AppointmentProps,
} from "../Enhanchers/withAppointmentState";
import ReactDatetime from "react-datetime";
import _ from "lodash";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "../Assets/vendor/fullcalender.css";
// import Notification, { notify } from 'react-notify-bootstrap';

// const calendarRef:any;

// let events: IAppointment[]  = [
//     {
//         id: 'a',
//         title: 'Prep',
//         start: '2020-07-01',
//         end: '2020-07-02',
//         allDay: true,
//         className: "bg-info"
//     },
//     {
//         id: 'b',
//         title: 'Main Meet 2 Prepi',
//         start: '2020-07-20',
//         end: '2020-07-20',
//         allDay: true,
//         className: "bg-danger"
//     }
//     ];

type Props = AuthProps & AppointmentProps & RouteComponentProps;

function CalendarMain(props: Props) {
    const [app_, update_app] = useState(tem3_);

    const clinic_: any = {
        "0": "Pretoria Park Clinic",
        "1": "Sallisburg Clinic",
    };
    const toggleModal = (v: boolean) => {
        updateApp_show(!app_show);
    };
    const [clinicVal, setClinicVal] = useState("");

    const [isFilter, setIsFilter] = useState("");
    const [filterString, setFilterString] = useState("");
    const [todayList, _setTodayList] = useState<any[]>([]);

    const [updated, setUpdated] = useState(false);

    var temp_: any[] = [];
    const [events, setEvents] = useState(temp_);
    const [groupedEvents, setGroupedEvents] = useState(temp_);

    const [listEvents, setListEvents] = useState(temp_);

    const [activeTab, setActiveTab] = useState("1");
    const [error, setError] = useState("");
    var today = moment().format("YYYY/MM/DD");

    const [_showapp, updateShowApp] = useState(false);

    const getStatus = (status: string) => {
        // if(status === "missed")
        //   return "bg-danger"
        // else if(status === 'upcoming')
        //   return "bg-info"
        // else if(status)
    };

    const toggle = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const _setClinicVal = (v: any) => {
        // console.log(v.target.value);
        setClinicVal(v.target.value);
        setListEvents_({ filter: "clinic", value: v.target.value });
    };
    const showApp = (app_d: any) => {
        // console.log(app_d);
        update_app(app_d);
        toggleModal(true);
    };

    if (!updated && events.length === 0) {
        const updated_app = props.appointment_records.appointment_records.map(
            (doc_: any) => {
                // console.log(doc_)
                var status;
                var className;
                if (doc_.status !== "attended") {
                    status = doc_.start < today ? "missed" : "upcoming";
                    className =
                        doc_.start < today
                            ? "badge-incomplete"
                            : "badge-review";
                } else {
                    //  console.log(doc_);
                    status = "attended";
                    className = "badge-complete";
                }

                return {
                    ...doc_,
                    status: status,
                    className: className,
                };
            }
        );

        var today_list = updated_app.filter((_ap: any) => {
            return moment(_ap.start).isSameOrAfter(today);
        });

        var grouped = _.mapValues(_.groupBy(updated_app, "start"));
        const newList = Object.keys(grouped)
            .map((i) => grouped[i])
            .map((app) => {
                return {
                    start: app[0].start,

                    app: app,
                };
            });
        // console.log(grouped)
        // console.log(newList);
        // console.log(today_list)
        //console.log(updated_app);
        _setTodayList(today_list);
        setEvents(updated_app);
        setGroupedEvents(newList);
        setListEvents(updated_app);
        setUpdated(true);
    }

    const setListEvents_ = (ets_: any) => {
        if (ets_.filter === "clinic") {
            var updated_app = events.filter((doc_: any) => {
                // console.log(doc_)
                console.log("filter date", ets_);

                if (ets_.value === doc_.clinic) {
                    return doc_;
                }
            });
            //console.log(updated_app);
            //  setEvents(updated_app);
            var today_list = updated_app.filter((_ap: any) => {
                return moment(_ap.start).isSameOrAfter(today);
            });

            console.log(today_list);
            //console.log(updated_app);
            _setTodayList(today_list);

            setListEvents(updated_app);
            setIsFilter("clinic");
        } else if (ets_.filter === "status") {
            console.log("filter status", ets_);
            var updated_app = events.filter((doc_: any) => {
                // console.log(doc_)

                if (ets_.value === doc_.status) {
                    return doc_;
                }
            });
            //console.log(updated_app);
            //  setEvents(updated_app);
            var today_list = updated_app.filter((_ap: any) => {
                return moment(_ap.start).isSameOrAfter(today);
            });

            console.log(today_list);
            //console.log(updated_app);
            _setTodayList(today_list);

            setListEvents(updated_app);
            setIsFilter("status");
        }
        else if (ets_.filter === "start") {
             // <option value='1'>Today</option>
        // <option value='2'>This Week</option>
        //                         <option value='3'>This Month</option>
        //                         <option value='4'>Next Month</option>
            //  setEvents(updated_app);
            var today_list = events.filter((_ap: any) => {
                console.log(_ap)
                switch (ets_.value) {
                    case '1':
                        return moment(_ap.start).isSame(today);
                    case '2':
                        return moment(_ap.start).isSame(today,'week');
                    case '3':
                        return moment(_ap.start).isSame(today,'month');
                    case '4':
                        var temp = new Date()
                        temp.setMonth(new Date().getMonth() + 1);
                        const nextMonth = moment(temp).format("YYYY/MM/DD");
                        return moment(_ap.start).isSame(nextMonth,'month');
                    default:
                        return moment(_ap.start).isSameOrAfter(today);
                }
            });

            console.log(today_list);
            //console.log(updated_app);
            _setTodayList(today_list);

            setListEvents(today_list);
            setIsFilter("start");
        }
    };
    // firebase.useFires
    const calendarRef: any = React.createRef();
    // calendarRef.eve
    const [currentDate, setCurrentDate] = useState(moment().format("MMM YYYY"));
    // const  currentDate =

    const changeView = (newView: string) => {
        //  console.log("calendar ref ",calendarRef);

        let calendarApi = calendarRef.current.getApi();
        //   console.log("calendar api", calendarApi );
        calendarApi.changeView(newView);

        setCurrentDate(calendarApi.view.title);
        return;
    };

    const go2Today = () => {
        let calendarApi = calendarRef.current.getApi();
        calendarApi.today();
        setCurrentDate(calendarApi.view.title);
    };

    const [viewEvent, setViewEvent] = useState(false);
    var tem3_: any;
    const [eventV_det, setEventV_det] = useState(tem3_);

    const [app_show, updateApp_show] = useState(false);
    const [appEdit, _updateAppEdit] = useState(false);

    const _today = moment().format("YYYY/MM/DD");

    const [date_edit, _setDate_edit] = useState(_today);
    const [app_status, _setApp_status] = useState("");
    var isValidDate = function (current: moment.MomentInput) {
        return moment(current).isSameOrAfter(today);
    };

    const setSelectedDate = (date: Moment | string) => {
        console.log(date);
        _setDate_edit(moment(date).format("YYYY/MM/DD"));
        // setAttended(true);
        return;
    };

    // console.log(eventV_det);
    return (
        <>
            <Modal
                className='modal-dialog-centered'
                contentClassName='bg-secondary'
                isOpen={app_show}
            >
                <div className='modal-header py-3'>
                    {/* <h3 className="heading mt-4">Appointment</h3> */}

                    <button
                        aria-label='Close'
                        className='close text-right'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body py-3'>
                    {app_show ? (
                        <>
                            <div className='text-center'>
                                <h3 className='display-4'>{app_?.title}</h3>
                                {!appEdit ? (
                                    <div
                                        className='pull-right icon bg-edit text-white'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            _updateAppEdit(true);
                                        }}
                                    >
                                        <img src={editBtn} />
                                    </div>
                                ) : null}
                            </div>
                            {!appEdit ? (
                                <>
                                    <Row>
                                        <Col sm='3'>Date: </Col>
                                        <Col sm='9'>
                                            {moment(app_?.start).format(
                                                "YYYY-MM-DD"
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm='3'>Status: </Col>
                                        <Col sm='9'>{app_?.status}</Col>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Row xs='12'>
                                        <Col xs='3'>
                                            <label className='form-control-label'>
                                                Date
                                            </label>
                                        </Col>
                                        <Col xs='7'>
                                            <FormGroup>
                                                <InputGroup className='input-group-alternative'>
                                                    <InputGroupAddon addonType='prepend'>
                                                        <InputGroupText>
                                                            <i className='ni ni-calendar-grid-58' />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <ReactDatetime
                                                        inputProps={{
                                                            placeholder:
                                                                "Select Date",
                                                            value: date_edit,
                                                            // disabled: true
                                                            // innerRef: register({ required: true, minLength: 1 }),
                                                            name: "aap_date",
                                                        }}
                                                        isValidDate={
                                                            isValidDate
                                                        }
                                                        timeFormat={false}
                                                        closeOnSelect={true}
                                                        onChange={(v) =>
                                                            setSelectedDate(v)
                                                        }
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row xs='12'>
                                        <Col xs='3'>
                                            <label className='form-control-label'>
                                                Status
                                            </label>
                                        </Col>
                                        <Col xs='3'>
                                            <FormGroup>
                                                <Input
                                                    name='app_status'
                                                    type='select'
                                                    className='form-control-alternative'
                                                    value={app_status}
                                                    onChange={(v) =>
                                                        _setApp_status(
                                                            v.target.value
                                                        )
                                                    }
                                                    defaultValue={app_.status}
                                                >
                                                    <option value=''>
                                                        --Select--
                                                    </option>
                                                    <option value='missed'>
                                                        Missed
                                                    </option>
                                                    <option value='upcoming'>
                                                        Upcoming
                                                    </option>
                                                    <option value='attended'>
                                                        Attended
                                                    </option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </>
                    ) : null}
                </div>
                <div className='modal-footer'>
                    {!appEdit ? (
                        <Button
                            className='btn-primary'
                            type='button'
                            onClick={() => toggleModal(false)}
                        >
                            Done
                        </Button>
                    ) : (
                        <Button
                            className='btn-success'
                            type='button'
                            // onClick={() =>toggleModal(false)}
                        >
                            Save
                        </Button>
                    )}
                </div>
            </Modal>

            {/* Navigation Tabs */}

            <Nav
                className='nav-fill flex-column flex-sm-row mb-4'
                id='tabs-text'
                pills
                role='tablist'
                tabs
                style={{ borderBottom: "1px solid #E0E0E0" }}
            >
                <section
                    style={{ width: "20%", marginTop: 30, display: "flex" }}
                >
                    <NavItem className='ml-5' style={{ height: 50 }}>
                        <div
                            aria-selected={activeTab === "1"}
                            style={{
                                height: "100%",
                                color: "#B3B8BF",
                                borderBottom:
                                    activeTab === "1"
                                        ? "2px solid #17A3AB"
                                        : "",
                            }}
                            onClick={(e) => toggle("1")}
                            // href="#generalInfo"
                            role='tab'
                        >
                            <h4
                                style={{
                                    color: "#B3B8BF",
                                }}
                            >
                                Calendar View
                            </h4>
                        </div>
                    </NavItem>
                    <NavItem>
                        <div
                            aria-selected={activeTab === "2"}
                            style={{
                                height: "100%",
                                color: "#B3B8BF",
                                borderBottom:
                                    activeTab === "2"
                                        ? "2px solid #17A3AB"
                                        : "",
                            }}
                            onClick={(e) => toggle("2")}
                            // href="#generalInfo"
                            role='tab'
                        >
                            <h4
                                style={{
                                    color: "#B3B8BF",
                                }}
                            >
                                List View
                            </h4>
                        </div>
                    </NavItem>
                </section>
            </Nav>

            {/* <Card className="mt-2">
        <CardBody className="p-0 " style={{ padding: 10 }}> */}
            <TabContent activeTab={activeTab}>
                <TabPane tabId='1'>
                    <Row className='align-items-center py-4'>
                        <Col xs='6'>
                            <h1
                                style={{
                                    fontFamily: "brandon-grotesque",
                                    fontWeight: 600,
                                    fontSize: 40,
                                    color: "#17A3AB",
                                    textTransform: "uppercase",
                                }}
                                className='fullcalendar-title h1 d-inline-block mb-0 mr-1'
                            >
                                {currentDate}
                            </h1>

                            <Button
                                className='fullcalendar-btn-prev btn-neutral'
                                color='default'
                                onClick={() => {
                                    let calendarApi = calendarRef.current.getApi();
                                    calendarApi.prev();
                                    setCurrentDate(calendarApi.view.title);
                                }}
                                size='sm'
                            >
                                <i className='fas fa-angle-left' />
                            </Button>
                            <Button
                                className='fullcalendar-btn-next btn-neutral'
                                color='default'
                                onClick={() => {
                                    let calendarApi = calendarRef.current.getApi();
                                    calendarApi.next();
                                    setCurrentDate(calendarApi.view.title);
                                }}
                                size='sm'
                            >
                                <i className='fas fa-angle-right' />
                            </Button>
                        </Col>
                        <Col xs='3' className='pull-right'>
                            <label className='form-control-label'>Clinic</label>
                            <FormGroup>
                                <Input
                                    name='clinic'
                                    type='select'
                                    value={clinicVal}
                                    className='form-control-alternative'
                                    onChange={(v) => _setClinicVal(v)}
                                    // disabled={clinicVal !== ""}
                                >
                                    <option value=''>--ALL--</option>
                                    <option value='0'>
                                        Pretoria Park Clinic
                                    </option>
                                    <option value='1'>Sallisburg Clinic</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    {/* Page Content */}

                    <Row>
                        {/* Calendar */}

                        <Col xs='9'>
                            {events ? (
                                <FullCalendar
                                    ref={calendarRef}
                                    events={groupedEvents}
                                    headerToolbar={false}
                                    contentHeight={"auto"}
                                    height='100%'
                                    aspectRatio={2000}
                                    eventDisplay={"block"}
                                    select={(event: any) => {
                                        //  console.log("ant");
                                        console.log(event);
                                        console.log("View: " + event.view.type);
                                        setFilterString(
                                            moment(event.start).format(
                                                "YYYY-MM-DD"
                                            )
                                        );

                                        const updated_app = events.filter(
                                            (doc_: any) => {
                                                // console.log(doc_)

                                                if (
                                                    event.startStr ===
                                                    doc_.start
                                                ) {
                                                    return doc_;
                                                }
                                            }
                                        );
                                        //console.log(updated_app);
                                        //  setEvents(updated_app);
                                        setListEvents(updated_app);
                                        setIsFilter("date");
                                        toggle("2");
                                    }}
                                    eventClick={(event: any) => {
                                        //  console.log("ant");
                                        console.log(event);
                                        console.log("View: " + event.view.type);
                                        setFilterString(
                                            moment(event.start).format(
                                                "YYYY-MM-DD"
                                            )
                                        );

                                        var updated_app =
                                            event.event._def.extendedProps.app;
                                        //console.log(updated_app);
                                        //  setEvents(updated_app);
                                        setListEvents(updated_app);
                                        setIsFilter("date");
                                        toggle("2");
                                    }}
                                    // handleRef={()=>{}}
                                    plugins={[dayGridPlugin, interactionPlugin]}
                                    initialView={"dayGridMonth"}
                                    // dayCellClassNames="fc-daygrid-day-number-darkteal"

                                    selectable={true}
                                    editable={false}
                                    // eventClassNames='event-styling'
                                    // dayCellContent={(args:any)=>{
                                    //   console.log(args);
                                    //   return{
                                    //    html:  '<h3 className="display-4">5</h3>'
                                    //   }
                                    // }}
                                    eventContent={(args: any) => {
                                        console.log(args);
                                        var count_event =
                                            args.event._def.extendedProps.app
                                                .length;
                                        return {
                                            html:
                                                '<i className="text-white">' +
                                                count_event +
                                                "</i>",
                                        };
                                    }}
                                    eventColor='#fff'
                                    // eventPop
                                />
                            ) : null}
                        </Col>

                        {/* Appointment Cards */}

                        <Col
                            xs='3'
                            style={{ height: "100vh", overflowX: "hidden" }}
                        >
                            <h2
                                style={{
                                    fontFamily: "brandon-grotesque",
                                    fontWeight: 600,
                                    fontSize: "1.2rem",
                                    color: "#178CCC",
                                    textTransform: "uppercase",
                                    textAlign: "center",
                                }}
                            >
                                {moment(today).format("DD MMM 'YY")}
                            </h2>
                            <div className='mt-1 d-flex flex-column justify-content-start align-items-center'>
                                {todayList.map((item_: any) => {
                                    return (
                                        <div className='appoint-card'>
                                            <h3
                                                style={{
                                                    fontFamily: "Gothic A1",
                                                    fontWeight: 600,
                                                    fontSize: "1rem",
                                                    color: "#ffffff",
                                                }}
                                            >
                                                {item_.title}
                                            </h3>
                                            <p>{clinic_[item_.clinic]}</p>
                                            {/* <p>25 Jan 20</p> */}
                                            {/* <p>8:30</p> */}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginTop: "2rem",
                                                }}
                                            >
                                                <Button
                                                    className='px-4'
                                                    size='sm'
                                                    style={{
                                                        fontFamily:
                                                            "brandon-grotesque",
                                                        fontWeight: 600,
                                                        fontSize: "0.7rem",
                                                        color: "#ffffff",
                                                        borderColor: "#41AEE9",
                                                        backgroundColor:
                                                            "#41AEE9",
                                                        borderRadius: 20,
                                                        textTransform:
                                                            "uppercase",
                                                    }}
                                                >
                                                    {item_.status}
                                                </Button>
                                                <Button
                                                    size='sm'
                                                    className='appoint-card-ld'
                                                    style={{
                                                        fontFamily:
                                                            "brandon-grotesque",
                                                        fontWeight: 600,
                                                        fontSize: "0.7rem",
                                                        color: "#ffffff",
                                                        borderColor: "#ffffff",
                                                        backgroundColor:
                                                            "transparent",
                                                        marginLeft: "2.2rem",
                                                    }}
                                                >
                                                    <i className='fas fa-chevron-right' />
                                                </Button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Col>
                    </Row>

                    <section
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                        }}
                    ></section>
                    {/* </CardBody>
              </Card>
            </Col>
          </Row> */}
                </TabPane>
                <TabPane tabId='2'>
                    <section
                        style={{
                            width: "95%",
                            marginLeft: 30,
                            paddingBottom: 20,
                        }}
                    >
                        <AppointmentTable
                            events_={listEvents}
                            isFilter={isFilter}
                            _filterValue={filterString}
                            setList={setListEvents_}
                        />
                    </section>
                </TabPane>
            </TabContent>
            {/* </CardBody>
      </Card> */}
        </>
    );
}

export default withRouter(withAppointmentState(withAuthState(CalendarMain)));
