import React from 'react'
import logo from '../logo.svg'
import '../App.css'
import TopNavbarMain from '../Components/TopNavbarMain'
import {
  CssBaseline,
  WithStyles,
  Toolbar,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  withStyles,
  Theme,
  createStyles,
  Button,
} from '@material-ui/core'
import MainLayout from '../Layouts/MainLayout'
import Paper from '@material-ui/core/Paper'
import MUIDataTable from 'mui-datatables'
import {
  Badge,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
} from 'reactstrap'
import { CardBody, CardTitle, Col, Card } from 'reactstrap'
import AddIcon from '@material-ui/icons/Add'

import Header from '../Components/Header'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { IPatient } from '../@types/patient'
import firebase from '../Services/firebase'
import withAuthState, { AuthProps } from '../Enhanchers/withAuthState'
import PatientTable from '../Components/PatientTable'

import AsyncSelect from 'react-select/async'
import OutreachForm from '../Forms/OutreachForm'
import withOutreachDD, { OutreachDDProps } from '../Enhanchers/withOutreachDD'

interface IState {
  sub_district_select_value: string
  sub_district: any[]
  location_select_value: string
  location: any[]
  wards_select_value: string
  wards: any[]
  sites_select_value: string
  sites: any[]
  area_select_value: string
  area: any[]
  peer_select_value: string
  peer: any[]
  team_leader_select_value: string
  team_leader: any[]
}

type Props = WithStyles<typeof styles> &
  RouteComponentProps &
  AuthProps &
  OutreachDDProps

class OutreachCreate extends React.Component<Props, IState> {
  constructor(props: any) {
    super(props)

    // should have default district key here
    // var district_key =
  }

  render() {
    return (
      <MainLayout>
        <div className='header bg-gradient-secondary pb-8 pt-5 pt-md-8'>
          <Container fluid>
            <div className='header-body'>
              {/* Card stats */}
              <Row>
                <Col xs='1'>
                  <Button
                    style={{ backgroundColor: 'transparent', marginTop: 8 }}
                    onClick={() => {
                      this.props.history.goBack()
                    }}
                  >
                    <i className='fa fa-arrow-left' style={{ fontSize: 20 }} />
                  </Button>
                </Col>
                <Col xs='4'>
                  <h1>Outreach / Group Activity</h1>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className='mt--6' fluid>
          {/* <h1>Patients</h1> */}

          <Card className='bg-secondary shadow'>
            <CardHeader className='bg-white border-0'>
              <h3>Create New Outreach</h3>
            </CardHeader>
            <CardBody>
              <OutreachForm />
            </CardBody>
          </Card>
        </Container>
      </MainLayout>
    )
  }
}
const styles = (theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    tableRow: {
      '&:hover': {
        borderLeft: '#17A3AB',
        borderLeftWidth: '10',
        borderLeftStyle: 'solid',
        boxShadow: '0 3px 5px 2px rgba(0,0,0,0.1)',
      },
    },
  })
export default withOutreachDD(
  withAuthState(withRouter(withStyles(styles)(OutreachCreate)))
)
