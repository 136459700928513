import React, { useState } from "react";
import {
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    UncontrolledTooltip,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    FormText,
    Modal,
    CardTitle,
} from "reactstrap";
import PatientForm from "./patientForm";

interface IProps {
    isOpen: boolean;
    togglefunct: any;
    setPatientToList: any;
}

type Props = IProps;

function AddPatientModal(props: Props) {
    const [isFinalCheck, setIsFinalCheck] = useState(false);
    const [patientInfo, setPatientInfo] = useState({});
    const lastCheckInit = (newpatient: any) => {
        console.log(newpatient);
        setPatientInfo(newpatient);
        setIsFinalCheck(true);
    };

    return (
        <Modal
            className='modal-dialog-centered '
            size='lg'
            // contentClassName="bg-gradient-info"
            isOpen={props.isOpen}
            toggle={() => props.togglefunct(false)}
        >
            <div className='modal-header'>
                <h5 className='modal-title'>Add New Patient</h5>
                <button
                    aria-label='Close'
                    className='close'
                    data-dismiss='modal'
                    type='button'
                    onClick={() => props.togglefunct(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className='modal-body'>
                {/* form to be added here */}
                {!isFinalCheck ? (
                    <PatientForm
                        isModalForm={true}
                        modalPatientSetFunc={lastCheckInit}
                    />
                ) : (
                    <CardTitle>
                        Patient Added to Patient List,{"\n"} Click Add Patient
                        below to add to your Form.
                    </CardTitle>
                )}
            </div>
            <div className='modal-footer'>
                {isFinalCheck ? (
                    <>
                        <Button
                            color='info'
                            type='button'
                            onClick={() => {
                                props.setPatientToList(patientInfo);
                                props.togglefunct(false);
                            }}
                        >
                            Add Patient
                        </Button>
                        <Button
                            className='ml-auto'
                            color='link'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => props.togglefunct(false)}
                        >
                            Close
                        </Button>
                    </>
                ) : null}
            </div>
        </Modal>
    );
}

export default AddPatientModal;
