import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Datetime from "react-datetime";
import moment, { Moment } from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// reactstrap components
import "../App.css";
import loader_ from "../Assets/img/grid.svg";
import {generate_id} from "../Services/API";

import {
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    UncontrolledTooltip,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    FormText,
    Modal,
} from "reactstrap";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { IPatient } from "../@types/patient";
import { clear } from "console";
import { RouteComponentProps, withRouter } from "react-router-dom";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import firebase from "../Services/firebase";
import { getDifference } from "../utils/Object";
import * as API from "../Services/API";
import { getSiteGroupNumber } from "../constants";

interface IProps {
    isPatientEdit?: boolean;
    patient?: any;
    isModalForm?: boolean;
    modalPatientSetFunc?: any;
}

type Props = RouteComponentProps & AuthProps & IProps;

function PatientForm(props: Props) {
    const {
        register,
        handleSubmit,
        watch,
        errors,
        formState,
        control,
        setError,
        clearError,
    } = useForm();

    var modalForm = false;
    // check if modal form
    if (props.isModalForm) {
        modalForm = true;
    }

    const [sexAtBirth, setSexAtBirth] = useState(false);

    const key_pop: any = {
        "": "",
        "1": "Client",
        "2": "Community",
        "3": "FSW",
        "4": "MSM",
        "5": "Transgender",
        "7": "MSM-SW",
        "10": "TGM",
        "11": "TGM-SW",
        "12": "TGNC",
        "13": "TGNC-SW",
        "8": "TGW",
        "9": "TGW-SW",
    };

    const _today = moment().format("YYYY/MM/DD");

    const [redcap_loading, _set_redcap_loading] = useState(false);

    const [clinic_fn, _set_clinic_fn] = useState(false);

    //Modal State
    const [defaultModal, setPatientModal] = useState(false);
    const [_init_date, _setInitDate] = useState(_today);
    // console.log(_init_date,  _today,props.patient.birthdate_id);
    if (props.isPatientEdit && moment(_init_date).isSame(_today)) {
        var dob = moment(props.patient.birthdate_id).format("YYYY/MM/DD");
        _setInitDate(dob);
    }

    const onSubmit = (data: any) => {
        ////console.log("-------------------");
        ////console.log("submitted value:");
        //console.log(formState);

        console.log(data);

        // if (data.contact_number_id.length !== 10) {
        //   alert('Please enter valid contact number')
        // }

        var _yearsTop = moment().subtract("years", 18);
        console.log(_yearsTop);
        console.log(moment(_init_date).isAfter(_yearsTop));

        // if(moment(_init_date).isAfter(_yearsTop)){
        //   alert("Patient is younger than 18");
        //   // return
        // }
        //console.log("-------------------");
        if (!modalForm) {
            setPatientModal(!defaultModal);
        }
        //console.log("-------------------");
        if (!modalForm) {
            setPatientModal(!defaultModal);
        } else if (modalForm) {
            console.log("submitting");
            createNewPatient(data);
        }
    };

    const editPatient = (values: any) => {
        //console.log(('saving new patient form');
        //console.log((values);
        var date_now = new Date();
        var new_patient_object = {
            ...props.patient,
            first_name_id: values.first_name_id,
            surname_id: values.surname_id,
            preferred_name_id: values.preferred_name_id,
            key_population_id: values.key_population_id,
            gender_id: values.gender_id,
            birthdate_id: moment(_init_date).format("YYYY/MM/DD"),
            race_id: values.race_id,
            contact_number_id: values.contact_number_id,
            clinic_folder_number_id: values.clinic_folder_number_id,
            date_capture_id: props.auth.user.uuid,
            last_of_capture_id: moment().format("YYYY/MM/DD HH:mm:ss"),
            client_id_complete: props.auth.user.role_access === 1 ? 2 : 0,
            lwazi_status:
                props.auth.user.role_access === 1 ? "updated" : "review",
        };

        var user_data_access_group = props.auth.user!.data_access_group.trim();
        // var trim_string = ("patients/"+ user_data_access_group + "/patients");
        var ref = firebase.db.collection(
            "main/" + props.auth.user!.data_access_group + "/patient_list"
        );

        ref.doc(props.patient?.uid)
            .update(new_patient_object)
            .then((data) => {
                console.log("Successfully Added: ");
                console.log("Document written with ID: ", props.patient?.uid);
                if (props.patient?.uid) {
                    var difference_ = getDifference(
                        new_patient_object,
                        props.patient
                    );
                    var activity_ = {
                        record_id: props.patient?.uid,
                        district: props.auth.user.data_access_group,
                        role: props.auth.user.role,
                        activity: "patient_edit",
                        capturer_id: props.auth.user.uuid,
                        capturer_name: props.auth.user.name,
                        date_and_time: moment().format("YYYY/MM/DD HH:mm:ss"),
                        form_status: props.auth.user.role_access === 1 ? 2 : 0,
                        update: difference_,
                    };
                    var activit_ref = firebase.db.collection("activity_log");
                    activit_ref
                        .add(activity_)
                        .then((data__) => {
                            if (!modalForm) {
                                // var doc_activity_ref = "main/"+props.auth.user!.data_access_group+"/patient_list/"+data.id+"/activity_log";
                                // var main_log =  firebase.db.collection("activity_log");
                                // ref.add()

                                props.history.goBack();
                            } else {
                                var patient_add = {
                                    uid: props.patient?.uid,
                                    ...new_patient_object,
                                };
                                props.modalPatientSetFunc(patient_add);
                            }
                        })
                        .catch((error) => {});
                }
            })
            .catch((error) => {
                console.log("error");
            });
    };

    const createNewPatient = (values: any) => {
        var date_now = new Date();
        var new_patient_object = {
            first_name_id: values.first_name_id,
            surname_id: values.surname_id,
            preferred_name_id: values.preferred_name_id,
            key_population_id: values.key_population_id,
            gender_id: values.gender_id,
            sex_at_birth_id: values.sex_at_birth_id
                ? values.sex_at_birth_id
                : values.gender_id,
            birthdate_id: _init_date,
            race_id: values.race_id,
            contact_number_id: values.contact_number_id,
            clinic_folder_number_id: "temp",
            date_capture_id: props.auth.user.uuid,
            record_id: "null",
            date_of_capture_id: moment().format("YYYY/MM/DD HH:mm:ss"),
            last_of_capture_id: moment().format("YYYY/MM/DD HH:mm:ss"),
            client_id_complete: props.auth.user.role_access === 1 ? 2 : 0,
            lwazi_status: props.auth.user.role_access === 1 ? "new" : "review",
        };

        var user_data_access_group = props.auth.user!.data_access_group.trim();
        // var trim_string = ("patients/"+ user_data_access_group + "/patients");
        var ref = firebase.db.collection(
            "main/" + props.auth.user!.data_access_group + "/patient_list"
        );

        ref.add(new_patient_object)
            .then((data) => {
                console.log("Successfully Added: ");
                console.log("Document written with ID: ", data.id);
                if (data.id) {
                    console.log(
                        getSiteGroupNumber(props.auth.user.data_access_group) +
                            "-" +
                            data.id
                    );
                    var activity_ = {
                        record_id:
                            getSiteGroupNumber(
                                props.auth.user.data_access_group
                            ) +
                            "-" +
                            data.id,
                        district: props.auth.user.data_access_group,
                        role: props.auth.user.role,
                        activity: "patient_create",
                        capturer_id: props.auth.user.uuid,
                        capturer_name: props.auth.user.name,
                        date_and_time: moment().format("YYYY/MM/DD HH:mm:ss"),
                        form_status: props.auth.user.role_access === 1 ? 2 : 0,
                    };
                    var activit_ref = firebase.db.collection("activity_log");
                    activit_ref
                        .add(activity_)
                        .then((data__) => {
                            if (!modalForm) {
                                // var doc_activity_ref = "main/"+props.auth.user!.data_access_group+"/patient_list/"+data.id+"/activity_log";
                                // var main_log =  firebase.db.collection("activity_log");
                                // ref.add()

                                props.history.goBack();
                            } else {
                                var patient_add = {
                                    uid:
                                        getSiteGroupNumber(
                                            props.auth.user.data_access_group
                                        ) +
                                        "-" +
                                        data.id,
                                    ...new_patient_object,
                                };
                                props.modalPatientSetFunc(patient_add);
                            }
                        })
                        .catch((error) => {});
                }
            })
            .catch((error) => {
                console.log("error");
            });
    };

    //console.log(errors);
    //console.log(formState.touched);
    //console.log(errors.birthday_id );
    //console.log(formState.dirtyFields);
    //   //console.log(watch("first_name_id")); // watch input value by passing the name of it

    var today = moment().subtract(1, "day");
    // var valid = function (current: any) {
    //     return current.isBefore(today);
    // };

    const setSelectedDate = (date: Moment | string) => {
        _setInitDate(moment(date).format("YYYY/MM/DD"));
        if (
            moment().diff(date, "years", false) < 18 &&
            String(date).length === 33
        ) {
            alert("Patient entered, is younger than 18");
        }
        return;
    };

    const ModalFu = () => {
        return (
            <Modal
                className='modal-dialog-centered modal-info'
                contentClassName='bg-gradient-info'
                isOpen={defaultModal}
                toggle={() => setPatientModal(false)}
            >
                <div className='modal-header'>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => setPatientModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body'>
                    <div className='py-3 text-center'>
                        <i className='ni ni-paper-diploma ni-3x' />
                        <h4 className='heading mt-4'>Please Confirm!</h4>
                        <p>
                            Hey {props.auth.user.name}, The following form will
                            be sent for review. This will be made available,
                            once reviewed and approved by Data Capturer.
                        </p>
                    </div>
                </div>
                <div className='modal-footer'>
                    <Button
                        className='btn-white'
                        color='default'
                        type='button'
                        onClick={handleSubmit(createNewPatient)}
                    >
                        Confirm!
                    </Button>
                    <Button
                        className='text-white ml-auto'
                        color='link'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => {
                            setPatientModal(!defaultModal);
                        }}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        );
    };

    const { dirty, isSubmitting, touched, submitCount } = formState;
    console.log({ dirty, isSubmitting, touched, submitCount });
    console.log(errors);
    // console.log(this.props.p)

    return (
        <>
            {/* CREATE PATIENT STARTS HERE */}
            {!props.isPatientEdit ? (
                <section
                    style={{
                        width: "100%",
                        height: modalForm ? "100vh" : "132vh",
                        marginTop: -32.5,
                        padding: "60px 0",
                    }}
                >
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {defaultModal && !modalForm ? <>{ModalFu()}</> : null}

                        {/* Chadd Edits Starts */}

                        <section className='ml-5'>
                            <Row className='ml-5'>
                                <Col xs='4' className='ml-5'>
                                    <label className='form-control-label'>
                                        Legal First Name
                                    </label>
                                    <FormGroup
                                        className={
                                            "" +
                                            ((errors.first_name_id !== true ||
                                                errors.first_name_id ===
                                                    undefined) &&
                                            !(
                                                !formState.dirty &&
                                                formState.submitCount !== 0
                                            ) &&
                                            formState.submitCount > 0
                                                ? "has-success"
                                                : "") +
                                            " " +
                                            (errors.first_name_id
                                                ? "has-danger"
                                                : "")
                                        }
                                    >
                                        <Input
                                            innerRef={register({
                                                required: true,
                                                minLength: 2,
                                            })}
                                            name='first_name_id'
                                            className='form-control-alternative'
                                            id='input-username'
                                            type='text'
                                            placeholder='Legal First Name'
                                        />
                                        {errors.first_name_id ? (
                                            <a
                                                className='text-danger'
                                                style={{ fontSize: 8 }}
                                            >
                                                Please Enter a valid name
                                            </a>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col xs='4' className='ml-5'>
                                    <label className='form-control-label'>
                                        Legal Surname
                                    </label>
                                    <FormGroup
                                        className={
                                            "" +
                                            ((errors.surname_id !== true ||
                                                errors.surname_id ===
                                                    undefined) &&
                                            !(
                                                !formState.dirty &&
                                                formState.submitCount !== 0
                                            ) &&
                                            formState.submitCount > 0
                                                ? "has-success"
                                                : "") +
                                            " " +
                                            (errors.surname_id
                                                ? "has-danger"
                                                : "")
                                        }
                                    >
                                        <Input
                                            innerRef={register({
                                                required: true,
                                                minLength: 2,
                                            })}
                                            name='surname_id'
                                            className='form-control-alternative '
                                            type='text'
                                            placeholder='Legal Surname'
                                        />
                                        {errors.surname_id ? (
                                            <a
                                                className='text-danger'
                                                style={{ fontSize: 8 }}
                                            >
                                                Please Enter a valid Surname
                                            </a>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='ml-5'>
                                <Col xs='4' className='ml-5'>
                                    <label className='form-control-label'>
                                        Preferred Name
                                    </label>
                                    <FormGroup
                                        className={
                                            "" +
                                            ((errors.preferred_name_id !==
                                                true ||
                                                errors.preferred_name_id ===
                                                    undefined) &&
                                            !(
                                                !formState.dirty &&
                                                formState.submitCount !== 0
                                            ) &&
                                            formState.submitCount > 0
                                                ? "has-success"
                                                : "") +
                                            " " +
                                            (errors.preferred_name_id
                                                ? "has-danger"
                                                : "")
                                        }
                                    >
                                        <Input
                                            innerRef={register({
                                                minLength: 1,
                                            })}
                                            name='preferred_name_id'
                                            className='form-control-alternative '
                                            type='text'
                                            placeholder='Preferred Name'
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs='4' className='ml-5'>
                                    <label className='form-control-label'>
                                        Key Population
                                    </label>
                                    <FormGroup
                                        className={
                                            "" +
                                            ((errors.key_population_id !==
                                                true ||
                                                errors.key_population_id ===
                                                    undefined) &&
                                            !(
                                                !formState.dirty &&
                                                formState.submitCount !== 0
                                            ) &&
                                            formState.submitCount > 0
                                                ? "has-success"
                                                : "") +
                                            " " +
                                            (errors.key_population_id
                                                ? "has-danger"
                                                : "")
                                        }
                                    >
                                        <Input
                                            innerRef={register({
                                                required: true,
                                                minLength: 1,
                                            })}
                                            name='key_population_id'
                                            type='select'
                                            className='form-control-alternative'
                                            placeholder='Key Population ID'
                                            onChange={(e) => {
                                                // e.preventDefault();
                                                var ans = e.target.value;
                                                console.log(ans);
                                                var ans_val = key_pop[ans];
                                                console.log(ans_val);
                                                if (ans_val.includes("TG")) {
                                                    setSexAtBirth(true);
                                                } else {
                                                    setSexAtBirth(false);
                                                }
                                            }}
                                        >
                                            <option value='1'>Client</option>
                                            <option value='2'>Community</option>
                                            <option value='3'>FSW</option>
                                            <option value='4'>MSM</option>
                                            {/* <option value='5'>
                                                Transgender
                                            </option> */}
                                            <option value='7'>MSM-SW</option>
                                            <option value='10'>TGM</option>
                                            <option value='11'>TGM-SW</option>
                                            <option value='12'>TGNC</option>
                                            <option value='13'>TGNC-SW</option>
                                            <option value='8'>TGW</option>
                                            <option value='9'>TGW-SW</option>
                                        </Input>
                                        {errors.key_population_id ? (
                                            <a
                                                className='text-danger'
                                                style={{ fontSize: 8 }}
                                            >
                                                Please Select Key Population
                                                Group
                                            </a>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='ml-5'>
                                {sexAtBirth ? (
                                    <Col xs='4' className='ml-5'>
                                        <FormGroup
                                            className={
                                                "" +
                                                ((errors.gender_id !== true ||
                                                    errors.gender_id ===
                                                        undefined) &&
                                                !(
                                                    !formState.dirty &&
                                                    formState.submitCount !== 0
                                                ) &&
                                                formState.submitCount > 0
                                                    ? "has-success"
                                                    : "") +
                                                " " +
                                                (errors.gender_id
                                                    ? "has-danger"
                                                    : "")
                                            }
                                        >
                                            <label className='form-control-label'>
                                                Sex At Birth:
                                            </label>
                                            <Input
                                                innerRef={register({
                                                    required: sexAtBirth,
                                                    minLength: 1,
                                                })}
                                                name='sex_at_birth_id'
                                                type='select'
                                                placeholder='Sex at Birth'
                                                className='form-control-alternative'
                                                defaultValue={
                                                    props.patient?.gender_id
                                                }
                                            >
                                                <option value=''>
                                                    --Select--
                                                </option>
                                                <option value='2'>Male </option>
                                                <option value='1'>
                                                    Female{" "}
                                                </option>
                                            </Input>
                                            {errors.gender_id ? (
                                                <a
                                                    className='text-danger'
                                                    style={{ fontSize: 8 }}
                                                >
                                                    Please Select Gender
                                                </a>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                ) : null}
                                {/* </Row>
              <Row className='ml-5'> */}
                                <Col xs='4' className='ml-5'>
                                    <FormGroup
                                        className={
                                            "" +
                                            ((errors.gender_id !== true ||
                                                errors.gender_id ===
                                                    undefined) &&
                                            !(
                                                !formState.dirty &&
                                                formState.submitCount !== 0
                                            ) &&
                                            formState.submitCount > 0
                                                ? "has-success"
                                                : "") +
                                            " " +
                                            (errors.gender_id
                                                ? "has-danger"
                                                : "")
                                        }
                                    >
                                        <label className='form-control-label'>
                                            Gender
                                        </label>

                                        <Input
                                            innerRef={register({
                                                required: true,
                                                minLength: 1,
                                            })}
                                            name='gender_id'
                                            type='select'
                                            placeholder='Gender'
                                            className='form-control-alternative'
                                        >
                                            <option value=''>Gender</option>
                                            <option value='1'>Male </option>
                                            <option value='2'>Female </option>
                                            <option value='3'>
                                                Transgender Man{" "}
                                            </option>
                                            <option value='4'>
                                                Transgender Woman{" "}
                                            </option>
                                            <option value='5'>
                                                Gender Non-Conforming
                                            </option>
                                        </Input>
                                        {errors.gender_id ? (
                                            <a
                                                className='text-danger'
                                                style={{ fontSize: 8 }}
                                            >
                                                Please Select Gender
                                            </a>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='ml-5'>
                                <Col xs='4' className='ml-5'>
                                    <label className='form-control-label'>
                                        Date of Birth
                                    </label>
                                    <FormGroup>
                                        <InputGroup className='input-group-alternative'>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <i className='ni ni-calendar-grid-58' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <ReactDatetime
                                                inputProps={{
                                                    placeholder:
                                                        "Date of Birth",
                                                    // value: _init_date,
                                                    name: "initiation_date",
                                                }}
                                                // isValidDate={valid}
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                onChange={(v) =>
                                                    setSelectedDate(v)
                                                }
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='ml-5'>
                                <Col xs='4' className='ml-5'>
                                    <label className='form-control-label'>
                                        Race
                                    </label>
                                    <FormGroup
                                        className={
                                            "" +
                                            ((errors.race_id !== true ||
                                                errors.race_id === undefined) &&
                                            !(
                                                !formState.dirty &&
                                                formState.submitCount !== 0
                                            ) &&
                                            formState.submitCount > 0
                                                ? "has-success"
                                                : "") +
                                            " " +
                                            (errors.race_id ? "has-danger" : "")
                                        }
                                    >
                                        <Input
                                            innerRef={register({
                                                required: true,
                                                minLength: 1,
                                            })}
                                            name='race_id'
                                            type='select'
                                            className='form-control-alternative'
                                        >
                                            <option value=''>Race</option>
                                            <option value='1'>Asian</option>
                                            <option value='2'>Black</option>
                                            <option value='3'>Coloured</option>
                                            <option value='4'>
                                                Mixed Race
                                            </option>
                                            <option value='5'>White</option>
                                            <option value='6'>Other</option>
                                        </Input>
                                        {errors.race_id ? (
                                            <a
                                                className='text-danger'
                                                style={{ fontSize: 8 }}
                                            >
                                                Please Select Race
                                            </a>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col xs='4' className='ml-5'>
                                    <label className='form-control-label'>
                                        Contact Number
                                    </label>
                                    <FormGroup
                                        className={
                                            "" +
                                            ((errors.contact_number_id !==
                                                true ||
                                                errors.contact_number_id ===
                                                    undefined) &&
                                            !(
                                                !formState.dirty &&
                                                formState.submitCount !== 0
                                            ) &&
                                            formState.submitCount > 0
                                                ? "has-success"
                                                : "") +
                                            " " +
                                            (errors.contact_number_id
                                                ? "has-danger"
                                                : "")
                                        }
                                    >
                                        <Input
                                            innerRef={register({
                                                required: true,
                                                pattern: /(^0[0-9]{9})(\/)?/,
                                                minLength: 1,
                                            })}
                                            name='contact_number_id'
                                            className='form-control-alternative'
                                            type='text'
                                            placeholder='Contact Number'
                                        />
                                        {errors.contact_number_id ? (
                                            <a
                                                className='text-danger'
                                                style={{ fontSize: 8 }}
                                            >
                                                Please Enter a valid Contact
                                                Number
                                            </a>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </section>

                        <section
                            style={{
                                position: "absolute",
                                right: "0",
                                bottom: "0",
                                marginRight: 60,
                                marginBottom: 40,
                            }}
                        >
                            <Button
                                className='btn-outline-primary'
                                type='button'
                                onClick={() => {
                                    props.history.goBack();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className='btn-primary-alternative'
                                type='submit'
                                onPress={() => onSubmit("submtttt")}
                            >
                                Create Patient
                            </Button>
                        </section>

                        {/* Chadd Edits End */}

                        {/* <div className='pl-lg-4'>
            <Row>
              <Col xs='4'>
                <label className='form-control-label'>Legal First Name</label>
                <FormGroup
                  className={
                    '' +
                    ((errors.first_name_id !== true ||
                      errors.first_name_id === undefined) &&
                    !(!formState.dirty && formState.submitCount !== 0) &&
                    formState.submitCount > 0
                      ? 'has-success'
                      : '') +
                    ' ' +
                    (errors.first_name_id ? 'has-danger' : '')
                  }
                >
                  <Input
                    innerRef={register({ required: true, minLength: 2 })}
                    name='first_name_id'
                    className='form-control-alternative'
                    id='input-username'
                    type='text'
                  />
                  {errors.first_name_id ? (
                    <a className='text-danger' style={{ fontSize: 8 }}>
                      Please Enter a valid name
                    </a>
                  ) : null}
                </FormGroup>
              </Col>
              <Col xs='4'>
                <label className='form-control-label'>Legal Surname</label>
                <FormGroup
                  className={
                    '' +
                    ((errors.surname_id !== true ||
                      errors.surname_id === undefined) &&
                    !(!formState.dirty && formState.submitCount !== 0) &&
                    formState.submitCount > 0
                      ? 'has-success'
                      : '') +
                    ' ' +
                    (errors.surname_id ? 'has-danger' : '')
                  }
                >
                  <Input
                    innerRef={register({ required: true, minLength: 2 })}
                    name='surname_id'
                    className='form-control-alternative '
                    type='text'
                  />
                  {errors.surname_id ? (
                    <a className='text-danger' style={{ fontSize: 8 }}>
                      Please Enter a valid Surname
                    </a>
                  ) : null}
                </FormGroup>
              </Col>

              <Col xs='4'>
                <label className='form-control-label'>
                  Preferred Name <span style={{ fontSize: 8 }}>(optional)</span>
                </label>
                <FormGroup
                  className={
                    '' +
                    ((errors.preferred_name_id !== true ||
                      errors.preferred_name_id === undefined) &&
                    !(!formState.dirty && formState.submitCount !== 0) &&
                    formState.submitCount > 0
                      ? 'has-success'
                      : '') +
                    ' ' +
                    (errors.preferred_name_id ? 'has-danger' : '')
                  }
                >
                  <Input
                    innerRef={register({ minLength: 1 })}
                    name='preferred_name_id'
                    className='form-control-alternative '
                    type='text'
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs='4'>
                <label className='form-control-label'>
                  Key Population Group
                </label>
                <FormGroup
                  className={
                    '' +
                    ((errors.key_population_id !== true ||
                      errors.key_population_id === undefined) &&
                    !(!formState.dirty && formState.submitCount !== 0) &&
                    formState.submitCount > 0
                      ? 'has-success'
                      : '') +
                    ' ' +
                    (errors.key_population_id ? 'has-danger' : '')
                  }
                >
                  <Input
                    innerRef={register({ required: true, minLength: 1 })}
                    name='key_population_id'
                    type='select'
                    className='form-control-alternative'
                  >
                    <
                    <option value='1'>Client</option>
                    <option value='2'>Community</option>
                    <option value='3'>FSW</option>
                    <option value='4'>MSM</option>
                    <option value='7'>MSM-SW</option>
                    <option value='10'>TGM</option>
                    <option value='11'>TGM-SW</option>
                    <option value='12'>TGNC</option>
                    <option value='13'>TGNC-SW</option>
                    <option value='8'>TGW</option>
                    <option value='9'>TGW-SW</option>
                  </Input>
                  {errors.key_population_id ? (
                    <a className='text-danger' style={{ fontSize: 8 }}>
                      Please Select Key Population Group
                    </a>
                  ) : null}
                </FormGroup>
              </Col>
              <Col xs='4'>
                <label className='form-control-label'>Gender</label>
                <FormGroup
                  className={
                    '' +
                    ((errors.gender_id !== true ||
                      errors.gender_id === undefined) &&
                    !(!formState.dirty && formState.submitCount !== 0) &&
                    formState.submitCount > 0
                      ? 'has-success'
                      : '') +
                    ' ' +
                    (errors.gender_id ? 'has-danger' : '')
                  }
                >
                  <Input
                    innerRef={register({ required: true, minLength: 1 })}
                    name='gender_id'
                    type='select'
                    className='form-control-alternative'
                  >
                    <
                    <option value='1'>Male </option>
                    <option value='2'>Female </option>
                    <option value='3'>Transgender Man </option>
                    <option value='4'>Transgender Woman </option>
                    <option value='5'>Gender Non-Conforming</option>
                  </Input>
                  {errors.gender_id ? (
                    <a className='text-danger' style={{ fontSize: 8 }}>
                      Please Select Gender
                    </a>
                  ) : null}
                </FormGroup>
              </Col>
              <Col xs='4'>
                <label className='form-control-label'>Date of Birth</label>
                <FormGroup>
                  <InputGroup className='input-group-alternative'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-calendar-grid-58' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: 'Select Date',
                        // value: _init_date,
                        name: 'initiation_date',
                      }}
                      isValidDate={valid}
                      timeFormat={false}
                      closeOnSelect={true}
                      onChange={(v) => setSelectedDate(v)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs='4'>
                <label className='form-control-label'>Race</label>
                <FormGroup
                  className={
                    '' +
                    ((errors.race_id !== true ||
                      errors.race_id === undefined) &&
                    !(!formState.dirty && formState.submitCount !== 0) &&
                    formState.submitCount > 0
                      ? 'has-success'
                      : '') +
                    ' ' +
                    (errors.race_id ? 'has-danger' : '')
                  }
                >
                  <Input
                    innerRef={register({ required: true, minLength: 1 })}
                    name='race_id'
                    type='select'
                    className='form-control-alternative'
                  >
                    <
                    <option value='1'>Asian</option>
                    <option value='2'>Black</option>
                    <option value='3'>Coloured</option>
                    <option value='4'>Mixed Race</option>
                    <option value='5'>White</option>
                    <option value='6'>Other</option>
                  </Input>
                  {errors.race_id ? (
                    <a className='text-danger' style={{ fontSize: 8 }}>
                      Please Select Race
                    </a>
                  ) : null}
                </FormGroup>
              </Col>
              <Col xs='4'>
                <label className='form-control-label'>Contact Number</label>
                <FormGroup
                  className={
                    '' +
                    ((errors.contact_number_id !== true ||
                      errors.contact_number_id === undefined) &&
                    !(!formState.dirty && formState.submitCount !== 0) &&
                    formState.submitCount > 0
                      ? 'has-success'
                      : '') +
                    ' ' +
                    (errors.contact_number_id ? 'has-danger' : '')
                  }
                >
                  <Input
                    innerRef={register({
                      required: true,
                      pattern: /^0[0-9]{9}$/,
                      minLength: 1,
                    })}
                    name='contact_number_id'
                    className='form-control-alternative'
                    placeholder='0000000000'
                    type='text'
                  />
                  {errors.contact_number_id ? (
                    <a className='text-danger' style={{ fontSize: 8 }}>
                      Please Enter a valid Contact Number
                    </a>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </div> */}

                        <Row>
                            {/* Patient Create */}
                            {/* <Col xs='6' className='text-right'>
                <Button
                  className='btn-outline-primary'
                  type='button'
                  onClick={() => {
                    props.history.goBack()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className='btn-primary'
                  type='submit'
                  onPress={() => onSubmit('submtttt')}
                >
                  Submit Patient
                </Button>
              </Col> */}
                        </Row>
                    </Form>
                </section>
            ) : (
                // CREATE PATIENT ENDS HERE
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {defaultModal ? <>{ModalFu()}</> : null}
                    <div className='pl-lg-4'>
                        <Row>
                            <Col xs='4'>
                                <label className='form-control-label'>
                                    Record ID:
                                </label>
                                <FormGroup>
                                    <Input
                                        innerRef={register({
                                            minLength: 1,
                                        })}
                                        name='record_id'
                                        className='form-control-alternative'
                                        placeholder='00-0000'
                                        type='text'
                                        disabled
                                        defaultValue={
                                            !String(
                                                props.patient?.record_id
                                            ).includes("-")
                                                ? getSiteGroupNumber(
                                                      props.auth.user
                                                          .data_access_group
                                                  ) +
                                                  "-" +
                                                  props.patient?.record_id
                                                : props.patient?.record_id
                                        }
                                        style={{ marginBottom: 10 }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs='4'>
                                <label className='form-control-label'>
                                    Clinic Folder Number:
                                </label>
                                <FormGroup>
                                    <Input
                                        innerRef={register({
                                            minLength: 1,
                                        })}
                                        name='clinic_folder_number_id'
                                        className='form-control-alternative'
                                        placeholder='00-0000'
                                        type='text'
                                        defaultValue={
                                            props.patient
                                                ?.clinic_folder_number_id
                                        }
                                        style={{ marginBottom: 10 }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='4'>
                                <label className='form-control-label'>
                                    Legal First Name
                                </label>
                                <FormGroup
                                    className={
                                        "" +
                                        ((errors.first_name_id !== true ||
                                            errors.first_name_id ===
                                                undefined) &&
                                        !(
                                            !formState.dirty &&
                                            formState.submitCount !== 0
                                        ) &&
                                        formState.submitCount > 0
                                            ? "has-success"
                                            : "") +
                                        " " +
                                        (errors.first_name_id
                                            ? "has-danger"
                                            : "")
                                    }
                                >
                                    <Input
                                        innerRef={register({
                                            required: true,
                                            minLength: 2,
                                        })}
                                        name='first_name_id'
                                        className='form-control-alternative'
                                        id='input-username'
                                        type='text'
                                        defaultValue={
                                            props.patient?.first_name_id
                                        }
                                    />
                                    {errors.first_name_id ? (
                                        <a
                                            className='text-danger'
                                            style={{ fontSize: 8 }}
                                        >
                                            Please Enter a valid name
                                        </a>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col xs='4'>
                                <label className='form-control-label'>
                                    Legal Surname
                                </label>
                                <FormGroup
                                    className={
                                        "" +
                                        ((errors.surname_id !== true ||
                                            errors.surname_id === undefined) &&
                                        !(
                                            !formState.dirty &&
                                            formState.submitCount !== 0
                                        ) &&
                                        formState.submitCount > 0
                                            ? "has-success"
                                            : "") +
                                        " " +
                                        (errors.surname_id ? "has-danger" : "")
                                    }
                                >
                                    <Input
                                        innerRef={register({
                                            required: true,
                                            minLength: 2,
                                        })}
                                        name='surname_id'
                                        className='form-control-alternative '
                                        type='text'
                                        defaultValue={props.patient?.surname_id}
                                    />
                                    {errors.surname_id ? (
                                        <a
                                            className='text-danger'
                                            style={{ fontSize: 8 }}
                                        >
                                            Please Enter a valid Surname
                                        </a>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col xs='4'>
                                <label className='form-control-label'>
                                    Preferred Name{" "}
                                    <span style={{ fontSize: 8 }}>
                                        (optional)
                                    </span>
                                </label>
                                <FormGroup
                                    className={
                                        "" +
                                        ((errors.preferred_name_id !== true ||
                                            errors.preferred_name_id ===
                                                undefined) &&
                                        !(
                                            !formState.dirty &&
                                            formState.submitCount !== 0
                                        ) &&
                                        formState.submitCount > 0
                                            ? "has-success"
                                            : "") +
                                        " " +
                                        (errors.preferred_name_id
                                            ? "has-danger"
                                            : "")
                                    }
                                >
                                    <Input
                                        innerRef={register({ minLength: 1 })}
                                        name='preferred_name_id'
                                        className='form-control-alternative '
                                        type='text'
                                        defaultValue={
                                            props.patient?.preferred_name_id
                                        }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs='4'>
                                <label className='form-control-label'>
                                    Key Population Group
                                </label>
                                <FormGroup
                                    className={
                                        "" +
                                        ((errors.key_population_id !== true ||
                                            errors.key_population_id ===
                                                undefined) &&
                                        !(
                                            !formState.dirty &&
                                            formState.submitCount !== 0
                                        ) &&
                                        formState.submitCount > 0
                                            ? "has-success"
                                            : "") +
                                        " " +
                                        (errors.key_population_id
                                            ? "has-danger"
                                            : "")
                                    }
                                >
                                    <Input
                                        innerRef={register({
                                            required: true,
                                            minLength: 1,
                                        })}
                                        name='key_population_id'
                                        type='select'
                                        className='form-control-alternative'
                                        placeholder='key Population id'
                                        defaultValue={
                                            props.patient?.key_population_id
                                        }
                                        onChange={(e) => {
                                            // e.preventDefault();
                                            var ans = e.target.value;
                                            console.log(ans);
                                            var ans_val = key_pop[ans];
                                            console.log(ans_val);
                                            if (ans_val.includes("TG")) {
                                                setSexAtBirth(true);
                                            } else {
                                                setSexAtBirth(false);
                                            }
                                        }}
                                    >
                                        <option value='1'>Client</option>
                                        <option value='2'>Community</option>
                                        <option value='3'>FSW</option>
                                        <option value='4'>MSM</option>
                                        {/* <option value='5'>Transgender</option> */}
                                        <option value='7'>MSM-SW</option>
                                        <option value='10'>TGM</option>
                                        <option value='11'>TGM-SW</option>
                                        <option value='12'>TGNC</option>
                                        <option value='13'>TGNC-SW</option>
                                        <option value='8'>TGW</option>
                                        <option value='9'>TGW-SW</option>
                                    </Input>
                                    {errors.key_population_id ? (
                                        <a
                                            className='text-danger'
                                            style={{ fontSize: 8 }}
                                        >
                                            Please Select Key Population Group
                                        </a>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            {sexAtBirth ? (
                                <Col xs='4'>
                                    <FormGroup
                                        className={
                                            "" +
                                            ((errors.sex_at_birth_id !== true ||
                                                errors.sex_at_birth_id ===
                                                    undefined) &&
                                            !(
                                                !formState.dirty &&
                                                formState.submitCount !== 0
                                            ) &&
                                            formState.submitCount > 0
                                                ? "has-success"
                                                : "") +
                                            " " +
                                            (errors.sex_at_birth_id
                                                ? "has-danger"
                                                : "")
                                        }
                                    >
                                        <label className='form-control-label'>
                                            Sex At Birth:
                                        </label>

                                        <Input
                                            innerRef={register({
                                                required: sexAtBirth,
                                                minLength: 1,
                                            })}
                                            name='sex_at_birth_id'
                                            type='select'
                                            placeholder='Sex at Birth'
                                            className='form-control-alternative'
                                            defaultValue={
                                                props.patient?.sex_at_birth_id
                                            }
                                        >
                                            <option value='2'>Male </option>
                                            <option value='1'>Female </option>
                                        </Input>
                                        {errors.sex_at_birth_id ? (
                                            <a
                                                className='text-danger'
                                                style={{ fontSize: 8 }}
                                            >
                                                Please Select Gender
                                            </a>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            ) : null}
                        </Row>
                        <Row>
                            <Col xs='4'>
                                <FormGroup
                                    className={
                                        "" +
                                        ((errors.gender_id !== true ||
                                            errors.gender_id === undefined) &&
                                        !(
                                            !formState.dirty &&
                                            formState.submitCount !== 0
                                        ) &&
                                        formState.submitCount > 0
                                            ? "has-success"
                                            : "") +
                                        " " +
                                        (errors.gender_id ? "has-danger" : "")
                                    }
                                >
                                    <label className='form-control-label'>
                                        Gender
                                    </label>

                                    <Input
                                        innerRef={register({
                                            required: true,
                                            minLength: 1,
                                        })}
                                        name='gender_id'
                                        type='select'
                                        placeholder='Gender'
                                        className='form-control-alternative'
                                        defaultValue={props.patient?.gender_id}
                                    >
                                        <option value='1'>Male </option>
                                        <option value='2'>Female </option>
                                        <option value='3'>
                                            Transgender Man{" "}
                                        </option>
                                        <option value='4'>
                                            Transgender Woman{" "}
                                        </option>
                                        <option value='5'>
                                            Gender Non-Conforming
                                        </option>
                                    </Input>
                                    {errors.gender_id ? (
                                        <a
                                            className='text-danger'
                                            style={{ fontSize: 8 }}
                                        >
                                            Please Select Gender
                                        </a>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='4'>
                                <label className='form-control-label'>
                                    Date of Birth
                                </label>
                                <FormGroup>
                                    <InputGroup className='input-group-alternative'>
                                        <InputGroupAddon addonType='prepend'>
                                            <InputGroupText>
                                                <i className='ni ni-calendar-grid-58' />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <ReactDatetime
                                            inputProps={{
                                                placeholder: "Select Date",
                                                value: _init_date,
                                                name: "initiation_date",
                                            }}
                                            // isValidDate={valid}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={(v) => setSelectedDate(v)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='4'>
                                <label className='form-control-label'>
                                    Race
                                </label>
                                <FormGroup
                                    className={
                                        "" +
                                        ((errors.race_id !== true ||
                                            errors.race_id === undefined) &&
                                        !(
                                            !formState.dirty &&
                                            formState.submitCount !== 0
                                        ) &&
                                        formState.submitCount > 0
                                            ? "has-success"
                                            : "") +
                                        " " +
                                        (errors.race_id ? "has-danger" : "")
                                    }
                                >
                                    <Input
                                        innerRef={register({
                                            required: true,
                                            minLength: 1,
                                        })}
                                        name='race_id'
                                        type='select'
                                        className='form-control-alternative'
                                        defaultValue={props.patient?.race_id}
                                    >
                                        <option value='1'>Asian</option>
                                        <option value='2'>Black</option>
                                        <option value='3'>Coloured</option>
                                        <option value='4'>Mixed Race</option>
                                        <option value='5'>White</option>
                                        <option value='6'>Other</option>
                                    </Input>
                                    {errors.race_id ? (
                                        <a
                                            className='text-danger'
                                            style={{ fontSize: 8 }}
                                        >
                                            Please Select Race
                                        </a>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col xs='4'>
                                <label className='form-control-label'>
                                    Contact Number
                                </label>
                                <FormGroup
                                    className={
                                        "" +
                                        ((errors.contact_number_id !== true ||
                                            errors.contact_number_id ===
                                                undefined) &&
                                        !(
                                            !formState.dirty &&
                                            formState.submitCount !== 0
                                        ) &&
                                        formState.submitCount > 0
                                            ? "has-success"
                                            : "") +
                                        " " +
                                        (errors.contact_number_id
                                            ? "has-danger"
                                            : "")
                                    }
                                >
                                    <Input
                                        innerRef={register({
                                            required: true,
                                            pattern: /(^0[0-9]{9})(\/)?/,
                                            minLength: 1,
                                        })}
                                        name='contact_number_id'
                                        className='form-control-alternative'
                                        placeholder='076533456'
                                        type='text'
                                        defaultValue={
                                            props.patient?.contact_number_id
                                        }
                                    />
                                    {errors.contact_number_id ? (
                                        <a
                                            className='text-danger'
                                            style={{ fontSize: 8 }}
                                        >
                                            Please Enter a valid Contact Number
                                        </a>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    {!redcap_loading ? (
                        <Row>
                            <Col xs='6'></Col>
                            <Col xs='6' className='text-right'>
                                {!modalForm ? (
                                    <Button
                                        className='btn-outline-primary'
                                        type='button'
                                        onClick={() => {
                                            props.history.goBack();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                ) : null}

                                <Button
                                    className='btn-primary-alternative'
                                    type='submit'
                                    onClick={handleSubmit(editPatient)}
                                >
                                    {props.auth.user.role_access === 1
                                        ? "Approve"
                                        : "Update"}
                                </Button>
                            </Col>
                        </Row>
                    ) : (
                        <Row
                            className='text-center'
                            xs='12'
                            style={{ justifyContent: "center" }}
                        >
                            <img src={loader_} alt={"loader"} />
                        </Row>
                    )}
                </Form>
            )}
        </>
    );
}

export default withAuthState(withRouter(PatientForm));
