import React, { useState } from "react";
import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormText,
  Modal,
  CardTitle,
  ModalBody,
} from "reactstrap";
import withPatientState, { PatientProps } from "../Enhanchers/withPatientState";
import AsyncSelect from "react-select/async";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import firebase from "../Services/firebase";
import { IPatient } from "../@types/patient";
import ReactDatetime from "react-datetime";
import moment, { Moment } from "moment";
import { RouteComponentProps, withRouter } from "react-router-dom";
import withPrepState, { PrepProps } from "../Enhanchers/withPrepState";
import withAppointmentState, { AppointmentProps } from "../Enhanchers/withAppointmentState";

interface IProps {
  isOpen: boolean;
  togglefunct: any;
  _isPrepCheck: boolean;
  _preCheck_patient?: any;
  _date?: any;
  _clinic?: any;
  _previous_appointment_info?: any;
  _prep_data?: any
}

type Props = IProps & PatientProps & AuthProps & RouteComponentProps & PrepProps & AppointmentProps;

function PrepCheck(props: Props) {

  const [prepCheckStage, setPrepCheckStage] = useState(0);
  const [clinicVal, setClinicVal] = useState("")


  const [recordManagerMode, setRecordManagerMode] = useState(false);

  const [inputValuePatientSearch, setInputValue] = useState("");
  const temp_pat = {
    uid:"",
    patient_id: "",
    full_name_id: "",
    first_name_id: "",
    surname_id: "",
    preferred_name_id: "",
    date_of_birth_id: "",
    contact_number_id: "",
    gender_id: "",
    hiv_status_id: "",
    counsellor_initials_id: "",
    test_conducted_id: "",
    referral_id: "",
    risk_assess_done_id: "",
    record_id: "",
    client_id_complete: 0,
  };
  const [selectOption, setSelectOption] = useState(temp_pat);

  const getPatient = (newValue: any, actionMeta: any) => {

    if (actionMeta.action === "select-option") {
      setSelectOption(newValue.value);
      var _patient = newValue.value;
      console.log(_patient);
      setPrepCheckStage(1);
      query_Preprecord(_patient);
    }
    console.groupEnd();
  };
  var temp:any;

  const [existingRecord, setExistingRecord] = useState(temp_pat)
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [isExisting, setIsExisting] = useState(false);
  const [foundPrepRecord, setFoundPrepRecord] = useState(temp);

  const query_Preprecord = (_patientInfo:any) => {
    var prep_stage = 0;

    var prep_record = props.prep_records.prep_records.filter((_record:any) =>{ return _record.patient_info.patient_uid === _patientInfo.uid});
    var appointments = props.appointment_records.appointment_records.filter((_doc:any)=>{return _doc.patient_id === _patientInfo.uid});
    console.log(prep_record, appointments);
    
    if(prep_record.length === 0){
      // no prep records found check apppointments
      if(appointments.length === 0){
            // no appointments no records 
            console.log("no records found");
            console.log(_patientInfo);
            setExistingRecord(
              _patientInfo
            )
            setIsNewRecord(true);

      }else{
        // found an appointment
      }
    }else if(prep_record.length > 0){
      setIsExisting(true)
      setFoundPrepRecord(prep_record[0])

    }
  };


  var temp_pat_list: any[] = [];
  props.patients.patients.map((patient: any) => {
    temp_pat_list.push({
      value: patient,
      label: patient.first_name_id +" "+ patient.surname_id,
    });
  });

  const filterPatientList = (inputValue: string) => {
    var list = temp_pat_list.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    list.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    var slice_list = list.slice(0, 10);
    return slice_list;
  };

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue;
    setInputValue(inputValue);
    return inputValue;
  };
  const loadOptions = (inputValue: any, callback: any) => {
    setTimeout(() => {
      callback(filterPatientList(inputValue));
    }, 900);
  };


  const patientStatus = (status: number) => {
    if (status === 0) {
      return (
        <>
          <span className="text-info mr-1">●</span>
          <small>Patient Record Review</small>
        </>
      );
    } else if (status === 1) {
      return (
        <>
          <span className="text-danger mr-1">●</span>
          <small>Incompleted Record</small>
        </>
      );
    } else if (status === 2) {
      return (
        <>
          <span className="text-success mr-1">●</span>
          <small>Completed Record</small>
        </>
      );
    }
  };

  const _setClinicVal = (v:any)=>{
    console.log(v.target.value);
    setClinicVal(v.target.value);
  }

  var today = moment().format("YYYY/MM/DD");
  var yesterday= moment(today).subtract( 1, 'day' );
  var valid = function( current: Moment ){
      return moment(current).isAfter(yesterday);
  };


  const [date_selected , _setDate] = useState(today)
  const setSelectedDate = (date:  Moment | string)=>{
    console.log(date)
    var new_date = moment(date).format("YYYY/MM/DD");
    _setDate(new_date)
    return
  }


  const _modalBody = () =>{

    if(prepCheckStage=== 0){
    // patient select


      return(
        <>
        <div className="modal-header text-center">
        <h3 className="display-4">Prep Check: Select Patient</h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => props.togglefunct(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <>
      <h5>Select Patient</h5>
                <Row>
                  <Col xs="12" style={{ marginTop: "2%" }}>
                    <AsyncSelect
                      style={{ width: "97px !important", marginTop: "7%" }}
                      cacheOptions
                      defaultOptions
                      isClearable
                      loadOptions={loadOptions}
                      // value={selectOption}
                      onChange={getPatient}
                      //  options={temp_pat_list}
                      onInputChange={handleInputChange}
                    />
                  </Col>
                </Row>
                </>
        </div>
        </>

      )

    }else if(prepCheckStage === 1){
      return(
        <>
        <div className="modal-header text-center">
        <h3 className="display-4">Prep Check: Results!</h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => props.togglefunct(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
      <>
                {isExisting ?(
                          <>
                          <div className="text-center">
                            <h3 className="text-success">
                              PrEP{"\n"}  history found !
                            </h3>

                          </div>
                          <Row>
                            <Col xs="12 align-items-center">
                                   <Button
                                   color="success"
                                   outline
                                   type="button"
                                   onClick={() => {
                                     // props.setPatientToList(patientInfo);
                                     // props.togglefunct(false);
                                    //  console.log(existingRecord);
                                    //  console.log(foundPrepRecord);
                                     props.history.push("/prep-view?ref=prep_id&id="
                                     +foundPrepRecord.uid)
                                   }}
                                 >
                                   View / Edit Prep Record
                                 </Button>
                            </Col>
                          </Row>
                        </>
                )
                : null}

              </>
      <>
                {isNewRecord ? (
                  <>
                    <div className="text-center">
                      <h3 className="text-danger">
                      Selected user {"\n"} has no appointments or PrEP history!
                      </h3>
                    </div>
                    <Row>
                      <Col xs="6">
                        <Button
                          color="danger"
                          type="button"
                          onClick={() => {
                            // props.setPatientToList(patientInfo);
                            // props.togglefunct(false);
                            props.history.push("/prep-create?ref=patient&id="+existingRecord.uid)
                          }}
                        >
                          Complete Prep Initiation Now
                        </Button>
                      </Col>
                      <Col xs="1"></Col>
                      <Col xs="5">
                        <Button
                          color="danger"
                          outline
                          type="button"
                          onClick={() => {
                            // props.setPatientToList(patientInfo);
                            // props.togglefunct(false);
                            setPrepCheckStage(2)
                          }}
                        >
                          Schedule Prep-Initiation
                        </Button>
                      </Col>
                    </Row>
                  </>
                  
               
                ) : null}




              </>  

      </div>
        </>
      )
    }
    else if(prepCheckStage === 2){
      return(
        <>
        <div className="modal-header text-center">
        <h3 className="display-4">Initiation Appointment</h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => props.togglefunct(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">

      <div className="align-items-center row">
              <Col xs="12 align-items-center text-center">
              <a className="avatar avatar-xs rounded-circle bg-info" href="#">
                <i className="fa fa-user text-white"></i>
              </a>
              {/* </Col> */}

              <Col xs="12 text-">
                <h4 className="mb-0">
                  <a href="#">{selectOption.first_name_id}</a>
                </h4>
                {patientStatus(selectOption.client_id_complete)}
              </Col>
              </Col>
              <Col xs="12">
              <label className="form-control-label">Date of Visit</label>
      <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
            </InputGroupAddon>
            <ReactDatetime
                inputProps={{
                  placeholder: "Select Date",
                  value: date_selected,
                  // disabled: true
                  // innerRef: register({ required: true, minLength: 1 }),
                  name: "date"

                }}
              isValidDate={ valid } 
              timeFormat={false}
              closeOnSelect={true}
              onChange={(v)=>setSelectedDate(v)}
            />
          </InputGroup>
        </FormGroup>
              </Col>
              <Col xs="12">
                <label className="form-control-label">Clinic</label>
                <FormGroup>
                  <Input
                    name="clinic"
                    type="select"
                    value={clinicVal}
                    className="form-control-alternative"
                    onChange={(v)=>_setClinicVal(v)}
                    disabled={clinicVal !== ""}
                  >
                    <option value="">Select</option>
                    <option value="0">Pretoria Park Clinic</option>
                    <option value="1">Sallisburg Clinic</option>
                  </Input>
                </FormGroup>
              </Col>
              </div>
        </div>
        </>
      )
    }
    else{
      return null
    }
  }




  return (
    <Modal
      className="modal-dialog-centered "
      // size="xs"
      // contentClassName="bg-gradient-info"
      isOpen={props.isOpen}
      toggle={() => props.togglefunct(false)}
    >
      {_modalBody()}
    
    </Modal>
  );
}

export default withAppointmentState(withPrepState(withRouter(withAuthState(withPatientState(PrepCheck)))));
