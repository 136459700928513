import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import * as actions from './actions';

export interface IAppointmentState {
  readonly appointment_records: any;
}

export type AppointmentActions = ActionType<typeof actions>;

const initialState: IAppointmentState = {
  appointment_records: []
};

export default combineReducers<IAppointmentState, AppointmentActions>({
  appointment_records: (state = initialState.appointment_records, action) => {
    switch (action.type) {
      case getType(actions.setAppointment): {
        return action.payload.appointment_records;
      }
      default: {
        return state;
      }
    }
  },

});
