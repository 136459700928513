
import * as React from 'react';
import firebase from './firebase';
import { useCollection, useCollectionData } from 'react-firebase-hooks/firestore';
import withAuthState, { AuthProps } from '../Enhanchers/withAuthState';
import withPatientState, { PatientProps } from '../Enhanchers/withPatientState';
import { IPatient } from '../@types/patient';
import { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import withAppointmentState, { AppointmentProps } from '../Enhanchers/withAppointmentState';
import { getDifference } from '../utils/Object';




type Props = AuthProps & AppointmentProps & RouteComponentProps;
class PatientListener extends React.Component<Props>{
  unsubscribes: Array<() => void> = [];

  unsubscribe = () => {
      this.unsubscribes.forEach(unsubscribe => unsubscribe());
      this.unsubscribes = [];
  };

  componentDidMount(){
    // console.log(this.props.auth.user);
    var temp_pat_list:any[] =[];

   // console.log("appointment check update", this.props.auth.user, prevProps.auth.user);
           var user = this.props.auth.user;
         var appointment_ref = firebase.db.collection('main/'+user.data_access_group+"/appointments");

         const appointment_sub = appointment_ref.onSnapshot( snapshot =>{
          var temp_pat_list:any[] =[];
           snapshot.docs.map( appointment =>{
             
              temp_pat_list.push({
                uid :appointment.id,
                ...appointment.data()
              })
          })
       //   console.log(temp_pat_list);
       var dif_ = getDifference(this.props.appointment_records.appointment_records, temp_pat_list);

       console.log(dif_)

       if (Object.keys(dif_).length === 0 && temp_pat_list.length !== 0) {
        this.props.setAppointment({appointment_records:temp_pat_list})
          
        }else{
          this.props.setAppointment({appointment_records:temp_pat_list})

        }

        })
        this.unsubscribes.push(appointment_sub);


  }
  componentWillUnmount() {
    this.unsubscribe();
}

  render(){
    return null;
  }
}


export default withRouter(withAppointmentState(withAuthState(PatientListener)))