import { createStandardAction } from 'typesafe-actions';
import { User } from 'firebase';
// import { IPatient } from '../../../@types/patient';

const FETCH_ACTIVITY = 'activityLog/FETCH_ACTIVITY';
const SET_DISTRICT_ACTIVITY = 'activityLog/SET_DISTRICT_ACTIVITY';
const SET_PERSONAL_ACTIVITY = 'activityLog/SET_PERSONAL_ACTIVITY';




export const fetchActivity = createStandardAction(FETCH_ACTIVITY)();

export interface ISetDistrictActivityPayload {
  district_activity: any;
}
export const setDistrictActivity = createStandardAction(SET_DISTRICT_ACTIVITY)<
  ISetDistrictActivityPayload
>();


export interface ISetPersonalActivityPayload {
  personal_activity: any;
}
export const setPersonalActivity = createStandardAction(SET_PERSONAL_ACTIVITY)<
ISetPersonalActivityPayload
>();