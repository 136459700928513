
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ISetOutreachDDPayload,  setOutreachDD } from '../Store/modules/outreach_translator/actions';
import { ISetOutreachDistrictDDPayload,  setOutreachDistrictDD } from '../Store/modules/outreach_translator/actions';

import { RootState } from '../@types/store';
import { IOutDDState as IOutreachDDReduxState } from '../Store/modules/outreach_translator/reducer';


const mapStateToProps = (state: RootState) => ({
  outreach_dd: state.outreach_dd,

});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setOutreachDD,
      setOutreachDistrictDD
      
    },
    dispatch
  );

interface IOutreachDDState {
  outreach_dd: IOutreachDDReduxState;
}

export interface IOutreachDDDispatchProps {
  setOutreachDD: (payload: ISetOutreachDDPayload) => void;
  setOutreachDistrictDD: (payload: ISetOutreachDistrictDDPayload) => void;

}

export type OutreachDDProps = IOutreachDDState & IOutreachDDDispatchProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
