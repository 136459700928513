import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import { useForm, Controller } from 'react-hook-form'

import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  Input,
  Modal,
  CardHeader,
  Container,
  Card,
  CardBody,
  Table,
} from 'reactstrap'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import withAuthState, { AuthProps } from '../Enhanchers/withAuthState'
import '../App.css'
import withOutreachDD, { OutreachDDProps } from '../Enhanchers/withOutreachDD'
import withPatientState, { PatientProps } from '../Enhanchers/withPatientState'
import ReactDatetime from 'react-datetime'
import { Moment } from 'moment'
import moment from 'moment'
import ReactQuill from 'react-quill'
import firebase from '../Services/firebase'
import AppointmentManagerModal from '../Forms/AppointmentManagerModal'
import withPrepState, { PrepProps } from '../Enhanchers/withPrepState'
import MainLayout from '../Layouts/MainLayout'
import { AnyCnameRecord } from 'dns'
import { getDifference } from '../utils/Object'
// interface IState{
//   select

// }
interface IProps {
  appointment?: any
}

type Props = RouteComponentProps &
  AuthProps &
  OutreachDDProps &
  PatientProps &
  IProps &
  PrepProps

function PrepMainForm(props: Props) {
  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState,
    control,
    setError,
    clearError,
  } = useForm()

  var temp_pat: any = {}

  // = {
  //   patient_id: "",
  //   full_name_id: "",
  //   first_name_id: "",
  //   surname_id: "",
  //   preferred_name_id: "",
  //   date_of_birth_id: "",
  //   contact_number_id: "",
  //   gender_id: "",
  //   hiv_status_id: "",
  //   counsellor_initials_id: "",
  //   test_conducted_id: "",
  //   referral_id: "",
  //   risk_assess_done_id: "",
  //   client_id_complete:0
  // };

  const _today = moment().format('YYYY/MM/DD')
  const [followupDate, _setFollowUpDate] = useState(_today)
  const [showManager, _setShowManager] = useState(false)
  const [selectOption, setSelectOption] = useState(temp_pat)

  // multiLine boxes
  const [commentMultiLine, setCommentMultiLine] = useState('')
  const [allergiesMultiLine, setAllergiesMultiLine] = useState('')
  const [medicalMultiLine, setMedicalMultiLine] = useState('')
  const [supportMultiLine, setSupportMultiLine] = useState('')

  var temp_obj: any
  const [app_, _setApp] = useState(temp_obj)
  const [prepMeta, setPrepMeta] = useState(temp_obj)

  const [prepFormError, setPrepFormError] = useState(false)
  const [inputValuePatientSearch, setInputValue] = useState('')

  const [patientList, setPatientList] = useState([])
  const [PatientMode, setPatientMode] = useState(true)
  const [attended, setAttended] = useState(false)
  var subdistrict_options_obj: any = ''
  const [subdistrict_options, setSubdistrict_options] = useState(
    subdistrict_options_obj
  )
  const [subdistrict_selected, setSubdistrict_selected] = useState(false)
  const [outreach_record, setOutreachRecord] = useState({
    kp_program_site_out: props.auth.user!.data_access_group.trim(),
    subdistrict_out: '',
    peer_responsible_out: '',
    team_leader_out: '',
  })

  // prepEdit State
  const [isPrepEdit, _SetPrepEdit] = useState(false)
  const [_prepEditRecord, _SetPrepEditRecord] = useState(temp_obj)

  // console.log(selectOption);

  // if(selectOption!== undefined ){
  //   setPatientMode(false)
  // }

  const { dirty, isSubmitting, touched, submitCount } = formState

  const checkValue = (values: any) => {
    // console.log(values);
  }

  const patientStatus = (status: number) => {
    if (status === 0) {
      return (
        <>
          <span className='text-info mr-1'>●</span>
          <small>Patient Record Review</small>
        </>
      )
    } else if (status === 1) {
      return (
        <>
          <span className='text-danger mr-1'>●</span>
          <small>Incomplete Record</small>
        </>
      )
    } else if (status === 2) {
      return (
        <>
          <span className='text-success mr-1'>●</span>
          <small>Complete Record</small>
        </>
      )
    }
  }

  const getPatient = (newValue: any, actionMeta?: any) => {
    //  console.group("Value Changed");
    // console.log(newValue);
    // console.log(actionMeta)
    // console.log(`action: ${actionMeta.action}`);
    if (actionMeta.action === 'select-option') {
      //  console.log(newValue.value);
      setSelectOption(newValue.value)
      setPatientMode(false)
    }
    //    console.groupEnd();

    // console.log(patient)
    // setSelectOption(patient);
  }

  var temp_pat_list: any[] = []
  props.patients.patients.map((patient: any) => {
    temp_pat_list.push({
      value: patient,
      label: patient.first_name_id + ' ' + patient.surname_id,
    })
  })

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, '')
    setInputValue(inputValue)
    return inputValue
  }

  const filterPatientList = (inputValue: string) => {
    var temp_list = []
    var list = temp_pat_list.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )
    temp_list = list.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    )
    temp_list = list.slice(0, 10)
    return temp_list
  }

  const loadOptions = (inputValue: any, callback: any) => {
    setTimeout(() => {
      callback(filterPatientList(inputValue))
    }, 100)
  }

  const setSelectedDate = (date: Moment | string) => {
    // console.log(date)
    setAttended(true)
    return
  }

  const setFollowUpDate = (date: Moment | string) => {
    // console.log(date)
    _setFollowUpDate(moment(date).format('YYYY/MM/DD'))
  }

  var temp_obj_prep: any = {
    appointment: temp_obj,
    prep_info: temp_obj,
    _prep_month: '',
  }
  const [isPrepEditResults, _setIsPrepEditResults] = useState(false)

  const [prepEditMeta_, _setPrepEditMeta] = useState(temp_obj_prep)
  const setMonthOnPrep_ = (key: number) => {
    //  console.log(key);
    // if(key !== NaN){
    // console.log(prepEditMeta_._prep_month);
    if (key !== Number(prepEditMeta_._prep_month)) {
      //console.log(_prepEditRecord);
      var _current_appointment =
        _prepEditRecord.appointment_history.appointment_history_list[key]
      var _current_prep_info = _prepEditRecord.prep_by_month[key]
      var _prep_month = key
      //console.log(_prep_month,_current_appointment, _current_prep_info);
      // var _newkey = key +1

      _setPrepEditMeta({
        appointment: _current_appointment,
        prep_info: _current_prep_info,
        _prep_month: '' + key,
      })
      //   handleMultiLineChange(_current_prep_info.notes.comments,'comments')
      // handleMultiLineChange(_current_prep_info.notes.allergies,'allergies')
      // handleMultiLineChange(_current_prep_info.notes.medical,'medical')
      // handleMultiLineChange(_current_prep_info.notes.support,'support')
    } else if (key === 0) {
      _setPrepEditMeta({
        appointment:
          _prepEditRecord.appointment_history.appointment_history_list[0],
        prep_info: _prepEditRecord.prep_by_month[0],
        _prep_month: '' + 0,
      })
    }
  }

  // if(values.sub_district_out && subdistrict_selected){
  // console.log(values)

  // // if(dirtyFields.subdistrict_out){
  //   setSubdistrict_selected(true)
  // // }

  // }

  // console.log({ dirty, isSubmitting, touched, submitCount });
  // console.log(errors);
  var today = moment().format('YYYY/MM/DD')
  var tomorrow = moment(today).add(1, 'day')
  var yesterday = moment(today).subtract(1, 'day')

  var valid = function (current: moment.MomentInput) {
    return moment(current).isBefore(yesterday)
  }
  var valid_followup = function (current: moment.MomentInput) {
    return moment(current).isSameOrAfter(today)
  }

  const handleMultiLineChange = (value: any, multi_: string) => {
    if (multi_ === 'comments') {
      setCommentMultiLine(value)
    } else if (multi_ === 'allergies') {
      setAllergiesMultiLine(value)
    } else if (multi_ === 'medical') {
      setMedicalMultiLine(value)
    } else if (multi_ === 'support') {
      setSupportMultiLine(value)
    }
  }

  if (props.appointment) {
    //console.log(props.appointment.patient)
    // setSelectOption(props.appointment.patient);
  }
  if (props.location.search.length > 0) {
    var split_params = props.location.search.split('&')
    var reason = split_params[0].split('=')[1]
    var recordId = split_params[1].split('=')[1]
    //console.log(reason, recordId);

    if (reason === 'prep_id' && selectOption !== undefined && PatientMode) {
      // appointment

      var prep_record_: any
      props.prep_records.prep_records.filter((prep_record: any) => {
        if (prep_record.uid === recordId) {
          prep_record_ = prep_record
        }
      })
      var patient_record
      props.patients.patients.filter((_pat_: any) => {
        if (_pat_.uid === prep_record_.patient_id) {
          // console.log(_pat_);
          patient_record = _pat_
        }
      })
      //console.log(patient_record);
      //console.log(prep_record_);
      _SetPrepEditRecord(prep_record_)

      setSelectOption(patient_record)
      _setApp(patient_record)
      setPatientMode(false)
      _SetPrepEdit(true)
    }
  }
  if (props.location.search.length === 0 && PatientMode) {
    setPatientMode(false)

    setPrepFormError(true)
  }

  const acceptPrep = () => {
    var prep_t = {
      ..._prepEditRecord,
      form_status: props.auth.user.role_access === 1 ? 2 : 0,
    }

    var ref = firebase.db.collection(
      'main/' + props.auth.user!.data_access_group + '/prep_list'
    )

    ref
      .doc(prep_t.uid)
      .update(prep_t)
      .then((data) => {
        console.log('Successfully Added: ')
        console.log('Document written with ID: ', prep_t.uid)
        if (prep_t.uid) {
          var difference_ = getDifference(_prepEditRecord, prep_t.uid)
          var activity_ = {
            record_id: prep_t.uid,
            district: props.auth.user.data_access_group,
            role: props.auth.user.role,
            activity: 'prep_review_approve',
            capturer_id: props.auth.user.uuid,
            capturer_name: props.auth.user.name,
            date_and_time: moment().format('YYYY/MM/DD HH:mm:ss'),
            form_status: props.auth.user.role_access === 1 ? 2 : 0,
            update: difference_,
          }
          var activit_ref = firebase.db.collection('activity_log')
          activit_ref.add(activity_).then((data__) => {
            props.history.goBack()
          })
        }
      })
  }

  const rejectPrep = () => {
    var prep_t = {
      ..._prepEditRecord,
      form_status: props.auth.user.role_access === 1 ? 2 : 0,
    }

    var ref = firebase.db.collection(
      'main/' + props.auth.user!.data_access_group + '/prep_list'
    )

    ref
      .doc(prep_t.uid)
      .update(prep_t)
      .then((data) => {
        console.log('Successfully Added: ')
        console.log('Document written with ID: ', prep_t.uid)
        if (prep_t.uid) {
          var difference_ = getDifference(_prepEditRecord, prep_t.uid)
          var activity_ = {
            record_id: prep_t.uid,
            district: props.auth.user.data_access_group,
            role: props.auth.user.role,
            activity: 'prep_review_reject',
            capturer_id: props.auth.user.uuid,
            capturer_name: props.auth.user.name,
            date_and_time: moment().format('YYYY/MM/DD HH:mm:ss'),
            form_status: 1,
            update: difference_,
          }
          var activit_ref = firebase.db.collection('activity_log')
          activit_ref.add(activity_).then((data__) => {
            props.history.goBack()
          })
        }
      })
  }

  const onSubmit = (values: any) => {
    console.log(values)
    console.log(prepEditMeta_)

    var index = Number(prepEditMeta_._prep_month)
    var prep_by_month = _prepEditRecord.prep_by_month
    prep_by_month[index] = {
      ...prep_by_month[index],
      test_results: values,
    }
    console.log(prep_by_month, index)
    var prep_t = {
      ..._prepEditRecord,
      prep_by_month: prep_by_month,
      form_status: props.auth.user.role_access === 1 ? 2 : 0,
    }

    _setPrepEditMeta({
      ...prepEditMeta_,
      prep_info: prep_t.prep_by_month[index],
      _prep_month: '' + index,
    })
    console.log(prep_t)
    console.log('updated prep', prep_t.prep_by_month[index])

    var ref = firebase.db.collection(
      'main/' + props.auth.user!.data_access_group + '/prep_list'
    )

    ref
      .doc(prep_t.uid)
      .update(prep_t)
      .then((data) => {
        console.log('Successfully Added: ')
        console.log('Document written with ID: ', prep_t.uid)
        _setIsPrepEditResults(false)

        if (prep_t.uid) {
          var difference_ = getDifference(_prepEditRecord, prep_t.uid)
          var activity_ = {
            record_id: prep_t.uid,
            district: props.auth.user.data_access_group,
            role: props.auth.user.role,
            activity: 'prep_edit',
            capturer_id: props.auth.user.uuid,
            capturer_name: props.auth.user.name,
            date_and_time: moment().format('YYYY/MM/DD HH:mm:ss'),
            form_status: props.auth.user.role_access === 1 ? 2 : 0,
            update: difference_,
          }
          var activit_ref = firebase.db.collection('activity_log')
          activit_ref.add(activity_).then((data__) => {
            _setPrepEditMeta({
              ...prepEditMeta_,
              prep_info: prep_t.prep_by_month[index],
              _prep_month: '' + index,
            })
          })
        }
      })
  }
  if (isPrepEdit && prepEditMeta_._prep_month === '') {
    setMonthOnPrep_(_prepEditRecord.prep_stage - 1)
  }

  const updateShowManager = (v: boolean) => {
    // console.log(selectOption);
    if (app_) {
      _setShowManager(v)
    } else {
      _setApp({ clinic: '' })
      _setShowManager(v)
    }
  }

  //console.log(selectOption);
  //  setPatientMode(false);
  return (
    <MainLayout>
      {/* Prep View Lwazi Header */}

      <Container fluid style={{ marginTop: 30 }}>
        <Row>
          <Col xs='1'>
            <Button
              style={{ backgroundColor: 'transparent', marginTop: 13 }}
              onClick={() => {
                props.history.goBack()
              }}
            >
              <i className='fa fa-arrow-left  ml-5' style={{ fontSize: 20 }} />
            </Button>
          </Col>
          <Col xs='11'>
            <h1 className='pl-0'>Prep Initiation & Monitoring</h1>
          </Col>
        </Row>

        <Row xs='12'>
          <Col>
            <hr />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <section>
          <Table>
            <thead>
              <tr>
                <th colSpan={2} className='text-center'>
                  Logo
                </th>
                <th colSpan={9} className='text-center'>
                  Prep Clinical Form (Initiation)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colSpan={2}>Name</th>
                <td colSpan={3}></td>
                <th colSpan={3}>Folder Number</th>
                <td colSpan={3}></td>
              </tr>
              <tr>
                <th colSpan={2}>Surname</th>
                <td colSpan={3}></td>
                <th colSpan={3}>Phone Number</th>
                <td colSpan={3}></td>
              </tr>
              <tr>
                <th colSpan={2}>DOB</th>
                <td colSpan={3}></td>
                <th colSpan={3}>Address</th>
                <td colSpan={3}></td>
              </tr>
              <tr>
                <th colSpan={2}>ID Number</th>
                <td colSpan={3}></td>
                <td colSpan={3}></td>
                <td colSpan={3}></td>
              </tr>
              <tr>
                <td colSpan={11} className='breaking'>
                  <strong>Instructions:</strong> Please use the below form to
                  capture initiation, continuation, discontinuation, and
                  re-initiation for PrEP. If a patient discontinues, continue
                  the record with the corresponding date of discontinuation.
                  Should a patient re-start PrEP, re-initiation and subsequent
                  visits will be captured into this same form. Adherence support
                  and other notes can be captured further below.
                </td>
              </tr>
            </tbody>
          </Table>
      
          <Table style={{ marginTop: -10 }} className='text-center'>
            <thead>
              <tr>
                <th colSpan={11}>
                  Steps Prior to PrEP Initiation/Re-Initiation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th rowSpan={2}>Date of Visit</th>
                <th rowSpan={2}>HIV Test Result</th>
                <th rowSpan={2} colSpan={2}>
                  PrEP Counselling Conducted?
                </th>
                <th rowSpan={2}>Proceed with Screening?</th>
                <th colSpan={6}>Prep Screening</th>
              </tr>
              <tr>
                <th className='wrapping'>Creatinine (eGFR)</th>
                <th className='wrapping'>Pregnancy</th>
                <th className='wrapping'>Weight (Kgs)</th>
                <th className='wrapping'>Hep B Result</th>
                <th className='wrapping'>STI Screening</th>
                <th className='wrapping'>Decide to Initiate?</th>
              </tr>
              <tr>
                <td></td>
                <td>+ / -</td>
                <td colSpan={2}>Y / N</td>
                <td>Y / N</td>
                <td></td>
                <td>+ / - / N</td>
                <td></td>
                <td></td>
                <td>+ / -</td>
                <td>Y / N</td>
              </tr>
              <tr>
                <td></td>
                <td>+ / -</td>
                <td colSpan={2}>Y / N</td>
                <td>Y / N</td>
                <td></td>
                <td>+ / - / N</td>
                <td></td>
                <td></td>
                <td>+ / -</td>
                <td>Y / N</td>
              </tr>
              <tr>
                <td></td>
                <td>+ / -</td>
                <td colSpan={2}>Y / N</td>
                <td>Y / N</td>
                <td></td>
                <td>+ / - / N</td>
                <td></td>
                <td></td>
                <td>+ / -</td>
                <td>Y / N</td>
              </tr>
              <tr>
                <td></td>
                <td>+ / -</td>
                <td colSpan={2}>Y / N</td>
                <td>Y / N</td>
                <td></td>
                <td>+ / - / N</td>
                <td></td>
                <td></td>
                <td>+ / -</td>
                <td>Y / N</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan={11}>
                  PrEP - Initiation/Re-Initiation and Monitoring
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={2} rowSpan={2}>
                  Original Prep Initiation Date
                </td>
                <td colSpan={2} rowSpan={2}></td>
                <td rowSpan={2}>Transfer In</td>
                <td>Date</td>
                <td colSpan={5}>From Clinic</td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={5}></td>
              </tr>
              <tr>
                <th rowSpan={2} className='wrapping'>
                  # of months on PrEP
                </th>
                <th rowSpan={2} className='wrapping'>
                  Next visit Date:
                </th>
                <th rowSpan={2} className='wrapping'>
                  Actual visit:
                </th>
                <th rowSpan={2} className='wrapping'>
                  Staying on PrEP?
                </th>
                <th colSpan={7} className='wrapping'>
                  Test Results (if applicable)
                </th>
              </tr>
              <tr>
                <th className='wrapping'>HIV Test</th>
                <th className='wrapping'>Creatinine (eGFR)</th>
                <th className='wrapping'>Weight (Kgs)</th>
                <th className='wrapping'>Pregnancy</th>
                <th className='wrapping'>STI Screening</th>
                <th className='wrapping'>
                  Outcome (RIP, LTF, TFO, Sero, DNA, Disc)
                </th>
                <th className='wrapping'>Month of Outcome</th>
              </tr>
              <tr>
                <td>0</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Y / N</td>
                <td>+ / -</td>
                <td></td>
                <td></td>
                <td>+ / - / N</td>
                <td>+ / -</td>
                <td></td>
              </tr>
              <tr>
                <td>1</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Y / N</td>
                <td>+ / -</td>
                <td></td>
                <td></td>
                <td>+ / - / N</td>
                <td>+ / -</td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Y / N</td>
                <td>+ / -</td>
                <td></td>
                <td></td>
                <td>+ / - / N</td>
                <td>+ / -</td>
                <td></td>
              </tr>
              <tr>
                <td>4</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Y / N</td>
                <td>+ / -</td>
                <td></td>
                <td></td>
                <td>+ / - / N</td>
                <td>+ / -</td>
                <td></td>
              </tr>
              <tr>
                <td>5</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Y / N</td>
                <td>+ / -</td>
                <td></td>
                <td></td>
                <td>+ / - / N</td>
                <td>+ / -</td>
                <td></td>
              </tr>
              <tr>
                <td>6</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Y / N</td>
                <td>+ / -</td>
                <td></td>
                <td></td>
                <td>+ / - / N</td>
                <td>+ / -</td>
                <td></td>
              </tr>
              <tr>
                <td>7</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Y / N</td>
                <td>+ / -</td>
                <td></td>
                <td></td>
                <td>+ / - / N</td>
                <td>+ / -</td>
                <td></td>
              </tr>
              <tr>
                <td>8</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Y / N</td>
                <td>+ / -</td>
                <td></td>
                <td></td>
                <td>+ / - / N</td>
                <td>+ / -</td>
                <td></td>
              </tr>
              <tr>
                <td>9</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Y / N</td>
                <td>+ / -</td>
                <td></td>
                <td></td>
                <td>+ / - / N</td>
                <td>+ / -</td>
                <td></td>
              </tr>
              <tr>
                <td>10</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Y / N</td>
                <td>+ / -</td>
                <td></td>
                <td></td>
                <td>+ / - / N</td>
                <td>+ / -</td>
                <td></td>
              </tr>
              <tr>
                <td>11</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Y / N</td>
                <td>+ / -</td>
                <td></td>
                <td></td>
                <td>+ / - / N</td>
                <td>+ / -</td>
                <td></td>
              </tr>
              <tr>
                <td>12</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Y / N</td>
                <td>+ / -</td>
                <td></td>
                <td></td>
                <td>+ / - / N</td>
                <td>+ / -</td>
                <td></td>
              </tr>
              <tr>
                <td>13</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Y / N</td>
                <td>+ / -</td>
                <td></td>
                <td></td>
                <td>+ / - / N</td>
                <td>+ / -</td>
                <td></td>
              </tr>
              <tr>
                <td>14</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Y / N</td>
                <td>+ / -</td>
                <td></td>
                <td></td>
                <td>+ / - / N</td>
                <td>+ / -</td>
                <td></td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan={11} className='wrapping text-left'>
                  Notes: Reason for discontinuation / adherence support /
                  allergies / medical history / hospitalisations / TB history.
                  *Please state reason for discontinuation in detail (client's
                  choice / change in risk profile / adverse effects, etc.)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={11}></td>
              </tr>
            </tbody>
          </Table>
        </section>
      </Container>
    </MainLayout>
  )
}

export default withPrepState(
  withPatientState(withOutreachDD(withAuthState(withRouter(PrepMainForm))))
)
