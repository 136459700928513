import React from 'react'
import logo from '../logo.svg'
import '../App.css'
import TopNavbarMain from '../Components/TopNavbarMain'
import {
  CssBaseline,
  WithStyles,
  Toolbar,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  withStyles,
  Theme,
  createStyles,
} from '@material-ui/core'
import MainLayout from '../Layouts/MainLayout'
import Paper from '@material-ui/core/Paper'
import MUIDataTable from 'mui-datatables'
import {
  Badge,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Button,
  UncontrolledTooltip,
} from 'reactstrap'
import { CardBody, CardTitle, Col, Card } from 'reactstrap'
import AddIcon from '@material-ui/icons/Add'

import Header from '../Components/Header'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { IPatient } from '../@types/patient'
import firebase from '../Services/firebase'
import withAuthState, { AuthProps } from '../Enhanchers/withAuthState'
import PatientTable from '../Components/PatientTable'
import withPatientState, { PatientProps } from '../Enhanchers/withPatientState'
import AsyncSelect from 'react-select/async'

import { UncontrolledAlert } from 'reactstrap'
import editBtn from '../Assets/img/icons/common/edit-btn.svg'

import { Alert } from 'reactstrap'

interface IState {
  patient_list: any
  last_visible: any
  completed_list: any
  amended_counter: any
  incomplete_list: any
  selectedTag: any
  review_list: any
}
type Props = WithStyles<typeof styles> &
  RouteComponentProps &
  AuthProps &
  PatientProps

class Home extends React.Component<Props, IState> {

  render() {
    const onRowSelect = (patient: any) => {
      //console.log(patient, index);

      if (patient.client_id_complete !== 0) {
        this.props.history.push({
          pathname: '/patient/' + patient.uid,
          state: patient,
        })
      } else {
        //  console.log("review")
      }
    }

    const onEdit = (patient: any) => {
      //console.log(patient, index);

      if (this.props.auth.user.role_access !== 1) {
        this.props.history.push({
          pathname: '/patient-edit/' + patient.uid,
          state: patient,
        })
      } else {
        //  console.log("review");
        if (
          patient.client_id_complete === 0 ||
          patient.client_id_complete === 1
        ) {
          this.props.history.push({
            pathname: '/patient-review/' + patient.uid,
            state: patient,
          })
        } else {
          this.props.history.push({
            pathname: '/patient-edit/' + patient.uid,
            state: patient,
          })
        }
      }
    }

    // if (this.state.patient_list.length > 0) {
      return (
        <>
          <MainLayout>
            {/* Patient Top Menu */}

            <div style={{ width: '95%', marginLeft: 30, paddingBottom: 20 }}>
              <PatientTable
                onRowSelect={onRowSelect}
                onEdit={onEdit}
              />
            </div>
          </MainLayout>
        </>
      )

  }
}
const styles = (theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    tableRow: {
      '&:hover': {
        borderLeft: '#17A3AB',
        borderLeftWidth: '10',
        borderLeftStyle: 'solid',
        boxShadow: '0 3px 5px 2px rgba(0,0,0,0.1)',
      },
    },
  })
export default withPatientState(
  withAuthState(withRouter(withStyles(styles)(Home)))
)
