
import { BrowserRouter as Router, Route, Link, Switch, withRouter, RouteComponentProps } from 'react-router-dom'
import { render } from 'react-dom';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Landing from './Pages/Landing';
import Outreach from './Pages/Outreach';
import PatientView from './Pages/PatientView';
import PatientCreate from './Pages/PatientCreate';
import PushMessaging from './Services/PushMessaging';
import PatientEdit from './Pages/PatientEdit';
import OutreachCreate from './Pages/OutreachCreate';
import OutreachView from './Pages/OutreachView';
import PatientReview from './Pages/PatientReview';
import React from 'react';
import { Container } from '@material-ui/core';


class Loading extends React.Component<{}> {


    render(){

        return(
            <>
            <div className="main-content">
              {/* <AuthNavbar /> */}
              <div className="header  py-7 py-lg-8">
                <Container>
                  <div className="header-body text-center mb-7">
                    <h1> LOADING</h1>
                    </div>
                    </Container>
                    </div>
                    </div>
                    </>

        )
    }
}


export default Loading;
