import React, { useState } from "react";
import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormText,
  Modal,
  CardTitle,
} from "reactstrap";
import withPatientState, { PatientProps } from "../Enhanchers/withPatientState";
import PatientForm from "./patientForm";
import AsyncSelect from "react-select/async";
import withAuthState, { AuthProps } from "../Enhanchers/withAuthState";
import firebase from "../Services/firebase";
import { IPatient } from "../@types/patient";
import ReactDatetime from "react-datetime";
import moment, { Moment } from "moment";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface IProps {
  isOpen: boolean;
  togglefunct: any;
  _isPrepCheck: boolean;
  _isPrepFollowup?: boolean;
  _preCheck_patient?: any;
  _date?: any;
  _clinic?: any;
  _previous_appointment_info?: any;
  _prep_data?: any
  _meds_dispense: any;
}

type Props = IProps & PatientProps & AuthProps & RouteComponentProps;

function AppointmentManagerModal(props: Props) {
  const [isFinalCheck, setIsFinalCheck] = useState(false);
  var temp:any;

  const [isLoading, setIsLoading] = useState(false);
  const [clinicVal, setClinicVal] = useState("")

  const [initSchedule, setInitSchedule] = useState(false);

  const [prepValidationMode, setPrepValidationMode] = useState(false);

  //patient check
  const [recordManagerMode, setRecordManagerMode] = useState(false);
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [isExisting, setIsExisting] = useState(false);
  // const lastCheckInit = (newrecord:any) =>{
  //     console.log(newpatient);
  //     // setPatientInfo(newpatient);
  //     // setIsFinalCheck(true);
  // }

  const [inputValuePatientSearch, setInputValue] = useState("");

  const temp_pat = {
    uid: "",
    patient_id: "",
    full_name_id: "",
    first_name_id: "",
    surname_id: "",
    preferred_name_id: "",
    date_of_birth_id: "",
    contact_number_id: "",
    gender_id: "",
    hiv_status_id: "",
    counsellor_initials_id: "",
    test_conducted_id: "",
    referral_id: "",
    risk_assess_done_id: "",
    record_id: "",
    client_id_complete: 0,
  };
  const [selectOption, setSelectOption] = useState(temp_pat);

  const [existingRecord, setExistingRecord] = useState(temp)
  const getPatient = (newValue: any, actionMeta: any) => {
    // console.group('Value Changed');
    // console.log(newValue);
    // console.log(actionMeta)
    // console.log(`action: ${actionMeta.action}`);
    if (actionMeta.action === "select-option") {
      setSelectOption(newValue.value);
      var _patient = newValue.value;
      // console.log(_patient);
      setRecordManagerMode(true);
      query_Preprecord(_patient);
    }
    console.groupEnd();
  };

  const _isPrepGetPatient = (patient:any, date:any, followupSchedule:any)=>{
    // console.log(patient, date, followupSchedule);
   if( !props._isPrepFollowup){
    setSelectOption(patient);
    var _patient = patient;
    // console.log(_patient);
    setRecordManagerMode(true);
    // query_Preprecord(_patient);
    setExistingRecord(
      _patient    )
    setIsNewRecord(true);

    // console.log("errror ---- ",props);
    setInitSchedule(true);
    _setDate(props._date);
    setClinicVal(props._clinic)
    }else{
      var _patient = patient;

      // console.log("patient info",props._preCheck_patient);
      // console.log(props);
      setRecordManagerMode(true);
      // query_Preprecord(_patient);
      setExistingRecord(_patient);
      setSelectOption(patient);
      setIsNewRecord(true);
      setInitSchedule(true);
      _setDate(props._date);
      setClinicVal(props._clinic)
    }

}

  const query_Preprecord = (_patientInfo: IPatient) => {
    var ref = firebase.db
      .collection("main/" + props.auth.user.data_access_group + "/appointments")
      .where("patient_id", "==", _patientInfo.record_id);

    ref.get().then((data) => {
      // console.log(data);

      if (data.size > 0) {
        // console.log("records found");

        setIsExisting(true)
        var record
      data.forEach(record =>{
        if(record){
  setExistingRecord({
          uid: record.id,
          ...record.data()
        })
      }
      })
      
      } else {
  //  /     console.log("no records found");
        // console.log(_patientInfo);
        setExistingRecord(
          _patientInfo
        )
        setIsNewRecord(true);

      }
    });
  };

  var temp_pat_list: any[] = [];
  props.patients.patients.map((patient: any) => {
    temp_pat_list.push({
      value: patient,
      label: patient.first_name_id + " " + patient.surname_id,
    });
  });

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, "");
    setInputValue(inputValue);
    return inputValue;
  };

  const filterPatientList = (inputValue: string) => {
    var list = temp_pat_list.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    list.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    var slice_list = list.slice(0, 10);
    return slice_list;
  };

  const loadOptions = (inputValue: any, callback: any) => {
    setTimeout(() => {
      callback(filterPatientList(inputValue));
    }, 900);
  };

  const patientStatus = (status: number) => {
    if (status === 0) {
      return (
        <>
          <span className="text-info mr-1">●</span>
          <small>Patient Record Review</small>
        </>
      );
    } else if (status === 1) {
      return (
        <>
          <span className="text-danger mr-1">●</span>
          <small>Incompleted Record</small>
        </>
      );
    } else if (status === 2) {
      return (
        <>
          <span className="text-success mr-1">●</span>
          <small>Completed Record</small>
        </>
      );
    }
  };

  var today = moment().format("YYYY/MM/DD");
  var yesterday= moment(today).subtract( 1, 'day' );
  var valid = function( current: Moment ){
      return moment(current).isAfter(yesterday);
  };



  const [date_selected , _setDate] = useState(today)
  const setSelectedDate = (date:  Moment | string)=>{
    // console.log(date)
    var new_date = moment(date).format("YYYY/MM/DD");
    _setDate(new_date)
    return
  }

  const createApp_Prep = () =>{
    console.log("creating prep  initiation appointment ");
    var next_appointment:any;
    var final_prep_form:any ;

    if (!props._isPrepCheck){
    var new_appointment = {
      title: "Prep Initiation: "+selectOption.first_name_id+" "+selectOption.surname_id,
      start: moment(date_selected).format("YYYY-MM-DD"),
      end: moment(date_selected).format("YYYY-MM-DD"),
      allDay: true,
      className: 'bg-info',
      status: "upcoming",
      clinic: clinicVal,
      patient: selectOption,
      patient_id: selectOption.uid,
      prep_stage: 0,
      type: "initiation",
      
    }
    var appointment_ref = firebase.db.collection('main/'+props.auth.user.data_access_group+"/appointments");
    appointment_ref.add(new_appointment);

  
  //   .then(data =>{
  //    var id = data.id;
  //    console.log("appointment added : ",id);
  // alert("appointment added : "+id);

  //   }).catch(err =>{
  //     console.log(err);
  //   })
    props.togglefunct(false)

  }else{

    if(!props._isPrepFollowup){
    // console.log(props._prep_data);
    var prep_appointment_num = 1;
    if(!props._meds_dispense.dispense){
    next_appointment = {
     title: "Prep Follow up "+prep_appointment_num+" : "+selectOption.first_name_id+" "+selectOption.surname_id,
      start: moment(date_selected).format("YYYY-MM-DD"),
      end: moment(date_selected).format("YYYY-MM-DD"),
      allDay: true,
      className: 'bg-info',
      status: "upcoming",
      clinic: clinicVal,
      patient: selectOption,
      patient_id: selectOption.uid,
      type: "follow_up_1",
      prep_stage: 1,
      link: props._previous_appointment_info.clinic !== "" ? props._previous_appointment_info.uid: "none"
    }
  }
    else{
      var num = props._meds_dispense._m;
     next_appointment = {
        title: "Prep Follow up "+num+" : "+selectOption.first_name_id+" "+selectOption.surname_id,
         start: moment(date_selected).format("YYYY-MM-DD"),
         end: moment(date_selected).format("YYYY-MM-DD"),
         allDay: true,
         className: 'bg-info',
         status: "upcoming",
         clinic: clinicVal,
         patient: selectOption,
         patient_id: selectOption.uid,
         type: "follow_up_"+num,
         prep_stage: num,
         link: props._previous_appointment_info.clinic !== "" ? props._previous_appointment_info.uid: "none"
       }
    }
  


    var appointment_ref = firebase.db.collection('main/'+props.auth.user.data_access_group+"/appointments");
    appointment_ref.add(next_appointment)
    .then(value =>{

      var new_app_id = value.id;
var app_history =[];
      if(props._previous_appointment_info.clinic === ""){
        app_history.push(next_appointment)
      }else{
app_history =[props._previous_appointment_info];
      }


      if(!props._meds_dispense.dispense){
       final_prep_form = {
        clinic_id: clinicVal,
        initiation_date: props._prep_data.actual_visit_date,
        patient_id: props._preCheck_patient.uid,
        first_name_id: props._preCheck_patient.first_name_id,
        surname_id:props._preCheck_patient.surname_id,
        ...props._prep_data,
        prep_by_month :[
          {prep_stage: 0, 
            ...props._prep_data,
            meds_dispense_skip: false,
          }
        ],
        appointment_history: {
          current_appointment: new_app_id,
          status: 'attended',
          appointment_history_list: app_history,
        },
        prep_stage: 1,
        date_capture_id: props.auth.user.uuid,
        date_of_capture_id: moment().format("YYYY-MM-DD HH:mm:ss"),
        form_status: props.auth.user.role_access === 1 ? 2: 0
      }
    }else {
      var num = parseInt(props._meds_dispense._m);
      var temp_prep_month = [];

      temp_prep_month.push(  {prep_stage: 0, 
        ...props._prep_data,
        meds_dispense_skip: false,
        meds_dispense_months: num
      });

      for(var i =0; i < num ; i++){
        console.log(i,num)
        temp_prep_month.push({prep_stage: i+1, 
          ...props._prep_data,
          meds_dispense_skip: true,
          meds_dispense_months: num -  i+1 
        })
      }

      final_prep_form = {
        clinic_id: clinicVal,
        initiation_date: props._prep_data.actual_visit_date,
        patient_id: props._preCheck_patient.uid,
        first_name_id: props._preCheck_patient.first_name_id,
        surname_id:props._preCheck_patient.surname_id,
        ...props._prep_data,
        prep_by_month :temp_prep_month,
        appointment_history: {
          current_appointment: new_app_id,
          status: 'attended',
          appointment_history_list: app_history,
        },
        prep_stage: 0+num,
        date_capture_id: props.auth.user.uuid,
        date_of_capture_id: moment().format("YYYY-MM-DD HH:mm:ss"),
        form_status: props.auth.user.role_access === 1 ? 2: 0
      }
    }

    console.log(next_appointment);
    console.log(final_prep_form);


    if(props._previous_appointment_info.clinic === ""){
      var prep_ref = firebase.db.collection('main/'+props.auth.user.data_access_group+"/prep_list");
      prep_ref.add(final_prep_form).then(_d =>{
        if(_d.id){
          var activity_ ={
             record_id: _d.id,
             district: props.auth.user.data_access_group,
             role: props.auth.user.role,
             activity: "prep_initiation_create",
             capturer_id: props.auth.user.uuid,
             capturer_name: props.auth.user.name,
             date_and_time: moment().format("YYYY/MM/DD HH:mm:ss"),
             form_status: props.auth.user.role_access === 1 ? 2: 0
           }
           var activit_ref = firebase.db.collection("activity_log");
           activit_ref.add(activity_).then(data__ =>{
           
  
               // var doc_activity_ref = "main/"+props.auth.user!.data_access_group+"/patient_list/"+data.id+"/activity_log";
               // var main_log =  firebase.db.collection("activity_log");
               // ref.add()
   
           props.history.goBack();
          
           }).catch(error =>{
 
           });
         }

        setPrepValidationMode(true)
      })
  
    }else{
    var previous_app_ref = firebase.db.collection('main/'+props.auth.user.data_access_group+"/appointments");
    previous_app_ref.doc(props._previous_appointment_info.uid)
    .update({
      status: "attended",
      link: "head",
      actual_visit_date: props._date

      // link:
    }).then(update =>{
      console.log("everything set successfully", update);
      var prep_ref = firebase.db.collection('main/'+props.auth.user.data_access_group+"/prep_list");
      prep_ref.add(final_prep_form).then(_d =>{
        setPrepValidationMode(true)
      })

    })
  }


})
 
}else{

    // console.log("follow up save");
    // console.log(props);
    var final_prep_form:any = {}
    var prep_appointment_num_f =0;
    var prep_by_month = [];
    var app_history =[];
    prep_appointment_num_f = props._previous_appointment_info.prep_by_month.length+1;

    if(props._meds_dispense.dispense){
       num = parseInt(props._meds_dispense._m);
      var temp_prep_month = [];
      prep_appointment_num_f +=  num;
    }
      // console.log(props)
     next_appointment = {
     title: "Prep Follow up "+(prep_appointment_num_f)+" : "+selectOption.first_name_id+" "+selectOption.surname_id,
      start: moment(date_selected).format("YYYY-MM-DD"),
      end: moment(date_selected).format("YYYY-MM-DD"),
      allDay: true,
      className: 'bg-info',
      status: "upcoming",
      clinic: clinicVal,
      patient: selectOption,
      patient_id: selectOption.uid,
      type: "follow_up_"+prep_appointment_num_f,
      prep_stage: prep_appointment_num_f,
      link: props._previous_appointment_info.appointment_history.current_appointment,
      
      // _previous_appointment_info
    }
  


    // // console.log(final_prep_form);
    var appointment_ref = firebase.db.collection('main/'+props.auth.user.data_access_group+"/appointments");
    appointment_ref.add(next_appointment)
    .then(value =>{

       var new_app_id = value.id;

  
      prep_by_month =  props._previous_appointment_info.prep_by_month;
         app_history  = props._previous_appointment_info.appointment_history.appointment_history_list;
        app_history.push({...next_appointment,uid: new_app_id});
              

        if(!props._meds_dispense.dispense){
       prep_by_month.push({prep_stage: prep_appointment_num_f, ...props._prep_data})

        final_prep_form = {
         ...props._previous_appointment_info,
        prep_by_month :prep_by_month,
        prep_stage: prep_appointment_num_f,
        first_name_id: props._preCheck_patient.first_name_id,
        surname_id:props._preCheck_patient.surname_id,
        appointment_history: {
          current_appointment: new_app_id,
           status: 'attended',
          appointment_history_list: app_history
        },
        date_capture_id: props.auth.user.uuid,
        date_of_capture_id: moment().format("YYYY/MM/DD HH:mm:ss"),
        form_status: props.auth.user.role_access === 1 ? 2: 0

      }
    }else {

      // prep_by_month.push({prep_stage: prep_appointment_num_f, ...props._prep_data})

      var num = parseInt(props._meds_dispense._m);
      var temp_prep_month = [];
      console.log(num);
      temp_prep_month  = prep_by_month
      console.log(temp_prep_month);
       var lenp = temp_prep_month.length ;
       lenp++;
       var p = num;
      for(var i =0; i < num+1 ; i++){
        console.log(i,num)
       p--
        temp_prep_month.push({prep_stage: lenp+i, 
          ...props._prep_data,
          meds_dispense_skip: true,
          meds_dispense_months: p
        })
        // num--
      }
      console.log(temp_prep_month)
      console.log("correct");
      final_prep_form = {
        clinic_id: clinicVal,
        initiation_date: props._previous_appointment_info.initiation_date,
        patient_id: props._preCheck_patient.uid,
        first_name_id: props._preCheck_patient.first_name_id,
        surname_id:props._preCheck_patient.surname_id,
        ...props._prep_data,
        prep_by_month :temp_prep_month,
        appointment_history: {
          current_appointment: new_app_id,
          status: 'attended',
          appointment_history_list: app_history,
        },
        prep_stage: temp_prep_month.length -1,
        date_capture_id: props.auth.user.uuid,
        date_of_capture_id: moment().format("YYYY-MM-DD HH:mm:ss"),
        form_status: props.auth.user.role_access === 1 ? 2: 0
      }
    }
      console.log(final_prep_form);
      console.log(next_appointment);
    console.log(props._previous_appointment_info.appointment_history);
      var previous_app_ref = firebase.db.collection('main/'+props.auth.user.data_access_group+"/appointments");
    previous_app_ref.doc(props._previous_appointment_info.appointment_history.current_appointment)
    .update({
      status: "attended",
      link: "head",
      actual_visit_date: props._date

      // link:
    }).then(update =>{
      console.log("everything set successfully", update);
      console.log(final_prep_form);
      console.log(next_appointment);
      var prep_ref = firebase.db.collection('main/'+props.auth.user.data_access_group+"/prep_list");
      prep_ref.doc(props._previous_appointment_info.uid).set(final_prep_form).then(_d =>{
        setPrepValidationMode(true)
      })
    })

     })
  }
}
  }


  if(props._isPrepCheck && recordManagerMode === false) {
      _isPrepGetPatient(props._preCheck_patient,props._date, "1");
  }

  const _setClinicVal = (v:any)=>{
    // console.log(v.target.value);
    setClinicVal(v.target.value);
  }

  return (
    <Modal
      className="modal-dialog-centered "
      // size="xs"
      // contentClassName="bg-gradient-info"
      isOpen={props.isOpen}
      toggle={() => props.togglefunct(false)}
    >
      {!prepValidationMode?
      <>
      {!initSchedule ? (
        <>
          <div className="modal-header text-center">
            <h1 className="modal-title">Appointment Manager</h1>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => props.togglefunct(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">
            {/* Form To go here */}
            {!recordManagerMode ? (
              <>
                <h5>Select Patient</h5>
                <Row>
                  <Col xs="12" style={{ marginTop: "2%" }}>
                    <AsyncSelect
                      style={{ width: "97px !important", marginTop: "7%" }}
                      cacheOptions
                      defaultOptions
                      isClearable
                      loadOptions={loadOptions}
                      // value={selectOption}
                      onChange={getPatient}
                      //  options={temp_pat_list}
                      onInputChange={handleInputChange}
                    />
                  </Col>
                </Row>
              </>
            ) : (

              <>

              <>
                {isExisting ?(
                          <>
                          <div className="text-center">
                            <h3 className="text-success">
                             Appointment {"\n"} Found!
                            </h3>
                          </div>
                          <Row>
                            <Col xs="12 align-items-center">
                              <Button
                                color="success"
                                type="button"
                                onClick={() => {
                                  // props.setPatientToList(patientInfo);
                                  // props.togglefunct(false);
                                  props.history.push("/prep-create?ref=appoint&id="+existingRecord.uid)
                                }}
                              >
                                View Prep Record
                              </Button>
                            </Col>
                          </Row>
                        </>
                )
                : null}

              </>
              <>
                {isNewRecord ? (
                  <>
                    <div className="text-center">
                      <h3 className="text-danger">
                        The Following User {"\n"} Got No Prep History!
                      </h3>
                    </div>
                    <Row>
                      <Col xs="6">
                        <Button
                          color="danger"
                          type="button"
                          onClick={() => {
                            // props.setPatientToList(patientInfo);
                            // props.togglefunct(false);
                            props.history.push("/prep-create?ref=create-patient&id="+existingRecord.uid)
                          }}
                        >
                          Complete Prep Initiation Now
                        </Button>
                      </Col>
                      <Col xs="1"></Col>
                      <Col xs="5">
                        <Button
                          color="danger"
                          outline
                          type="button"
                          onClick={() => {
                            // props.setPatientToList(patientInfo);
                            // props.togglefunct(false);
                            setInitSchedule(true)
                          }}
                        >
                          Schedule Prep-Initiation
                        </Button>
                      </Col>
                    </Row>
                  </>
                  
               
                ) : null}
              </>  
               </>

            )}
          </div>
        </>
      ) : (
        <>
          <div className="modal-header text-center">
          {!props._isPrepCheck ?  <h1 className="modal-title">Initiation Appointment</h1>
          : 
          <h1 className="modal-title">Prep Follow Up Appointment</h1>}
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => props.togglefunct(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">

            <div className="align-items-center row">
              <Col xs="12 align-items-center text-center">
              <a className="avatar avatar-xs rounded-circle bg-info" href="#">
                <i className="fa fa-user text-white"></i>
              </a>
              {/* </Col> */}

              <Col xs="12 text-">
                <h4 className="mb-0">
                  <a href="#">{selectOption.first_name_id}</a>
                </h4>
                {patientStatus(selectOption.client_id_complete)}
              </Col>
              </Col>
              <Col xs="12">
              <label className="form-control-label">Date of Visit</label>
      <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
            </InputGroupAddon>
            <ReactDatetime
                inputProps={{
                  placeholder: "Select Date",
                  value: date_selected,
                  disabled: props._meds_dispense.dispense,
                  // innerRef: register({ required: true, minLength: 1 }),
                  name: "date"

                }}
              isValidDate={ valid } 
              timeFormat={false}
              closeOnSelect={true}
              onChange={(v)=>setSelectedDate(v)}
            />
          </InputGroup>
        </FormGroup>
              </Col>
              <Col xs="12">
                <label className="form-control-label">Clinic</label>
                <FormGroup>
                  <Input
                    name="clinic"
                    type="select"
                    value={clinicVal}
                    className="form-control-alternative"
                    onChange={(v)=>_setClinicVal(v)}
                    disabled={clinicVal !== ""}
                  >
                    <option value="">Select</option>
                    <option value="0">Pretoria Park Clinic</option>
                    <option value="1">Sallisburg Clinic</option>
                  </Input>
                </FormGroup>
              </Col>
           
              <Col xs="12">
                {clinicVal.length >0 && date_selected.length > 0 ?
              <Button type="button" color="success" onClick={()=>createApp_Prep()}>
                    Schedule Now
                  </Button>
                  : null
}
              </Col>
              {/* <Col>
                  <Button type="button" color="primary" onClick={()=>setPatientMode(true)}>
                    Change Patient
                  </Button>
                </Col> */}
            </div>
          </div>
        </>
      )}
    </>
    :
    // if prep validation mode
    <>
    <div className="modal-header text-center">
    <h1 className="modal-title">Appointment Manager</h1>
    <button
      aria-label="Close"
      className="close"
      data-dismiss="modal"
      type="button"
      onClick={() => props.togglefunct(false)}
    >
      <span aria-hidden={true}>×</span>
    </button>
  </div>

  <div className="modal-body">
    <h1> Prep Initiation Saved</h1>
              <p> Follow up Scheduled For {props._date}</p>
              <Col xs="5">
                        <Button
                          color="success"
                    
                          type="button"
                          onClick={() => {
                            // props.setPatientToList(patientInfo);
                            // props.togglefunct(false);
                            props.history.goBack();
                            props.togglefunct(false);

                          }}
                        >
                          Done
                        </Button>
                      </Col>
    </div>
    </>


              }
    </Modal>
  );
}

export default withRouter(withAuthState(withPatientState(AppointmentManagerModal)));
