
import * as React from 'react';
import firebase from './firebase';
import { useCollection, useCollectionData } from 'react-firebase-hooks/firestore';
import withAuthState, { AuthProps } from '../Enhanchers/withAuthState';
import withPatientState, { PatientProps } from '../Enhanchers/withPatientState';
import { IPatient } from '../@types/patient';
import { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import withPrepState, {PrepProps} from '../Enhanchers/withPrepState';
import { getDifference } from '../utils/Object';




type Props = AuthProps & PrepProps & RouteComponentProps;
class PrepListener extends React.Component<Props>{
  unsubscribes: Array<() => void> = [];

  unsubscribe = () => {
      this.unsubscribes.forEach(unsubscribe => unsubscribe());
      this.unsubscribes = [];
  };


  componentDidMount(){
    var temp_pat_list:any[] =[];

           var patient_ref = firebase.db.collection('main/'+this.props.auth.user.data_access_group+"/prep_list");
  
            const patient_sub = patient_ref.onSnapshot( snapshot =>{
              var temp_pat_list:any[] =[];
               snapshot.docs.map( patient =>{
                  //  console.log("PREP RECORDS :",patient);
                  temp_pat_list.push({
                    uid :patient.id,
                    ...patient.data()
                  })
              })


              var dif_ = getDifference(this.props.prep_records.prep_records, temp_pat_list);

              console.log(dif_)

              if (Object.keys(dif_).length === 0 && temp_pat_list.length !== 0) {
                this.props.setPrep({
                prep_records: temp_pat_list
              })
              
            }else{
              this.props.setPrep({
                prep_records: temp_pat_list
              })
            }
          
  
            })
          this.unsubscribes.push(patient_sub);


  }



  componentWillUnmount() {
    this.unsubscribe();
}

  render(){
    return null;
  }
}
// type Props = AuthProps & PatientProps;
// const PatientCollectionListener = (props: Props) => {
//     const [initialize, setInitialize] = useState(false);
//     if(props.auth.user){
//         var user = props.auth.user;
//       console.log(user);
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const [values, loading, error] = useCollection(
//     firebase.db.collection('main/'+user.data_access_group+"/patient_list"),
//     {
//       snapshotListenOptions: { includeMetadataChanges: true },
//     }
//   );
//  // console.log(values);
//   var temp_pat_list:any[] = []
// //   values?.docChanges().forEach(function(change) {
// //     if (change.type === "added" && props.patients.patients.length > 0) {
// //         console.log("New patient: ", change.doc.data());
// //     }
// //     if (change.type === "modified") {
// //         console.log("Modified patient: ", change.doc.data());
// //     }
   
    
// // });

// // if(props.patients.patients.length >0 ){
// //     initialize =true
// // }


// if(props.patients.patients.length === 0 && values?.size !== 0){
//     temp_pat_list = []
//     var map_list:any[] =[];
//      values?.docs.map(doc =>{ map_list.push( doc.data())});
//      console.log("initialize");

// console.log(map_list);
//   if(map_list.length >0 && !initialize){
//       console.log("setting patients", initialize);
//        props.setPatients({patients: map_list});
//        setInitialize(true);


//   }

// }
//   return(null)

// }else{


//   return (
// null
//   );
// }

    
// };

export default withRouter(withPrepState(withAuthState(PrepListener)))